
import {GraphVisualizationController} from './graphvisctrl.js';

export class Graph extends GraphVisualizationController {
  static initClass() {
    this.func_name = 'graph';
    this.pretty_name = 'Network Diagram';
  }
  constructor() {
    super(...arguments);
  }

  receive(spogi) {
    super.receive(...arguments);
    const meth = () => {
      return document.dispatchEvent(new CustomEvent("dataset-loaded", {detail: this.graph_uri}));
    };
    if (this.dataset_loaded_timebomb != null) {
      clearTimeout(this.dataset_loaded_timebomb);
    }
    this.dataset_loaded_timebomb = setTimeout(meth,300);

    //@noodb.log.alert "receive()",spogi
    if ((this.spogi_count % 100) === 0) {
      this.widget.show_state_msg("parsed relation " + this.spogi_count);
    }
    const {
      o
    } = spogi;
    let o_type =  "http://www.w3.org/1999/02/22-rdf-syntax-ns#object"; // rdf:object
    let o_value = o.getValue();

    if (!o.isUri) {
      o_type = "http://www.w3.org/1999/02/22-rdf-syntax-ns#PlainLiteral"; // rdf:literal
      o_value = o.getValue();
    }
      //o_value = JSON.parse(o_value)
    const q = {
      s: spogi.s.key(),
      p: spogi.p.key(),
      o: {
        type: o_type,
        value: o_value
      },
      g: spogi.i.key()//.replace("nrn:",'')
    };
    //@noodb.log.alert(o.isUri, o_type, o_value)
    return this.widget.add_quad(q);
  }
}
Graph.initClass();


export class OntoGraph extends Graph {
  static initClass() {
    this.func_name = "ontograph";
    this.pretty_name = "Ontology Graph";
  }

  make_widget(args) {
    return new this.huviz.OntoViz(args);
  }
}
OntoGraph.initClass();
