import {
  VisualizationController
} from './visualizationcontroller.js';

export class Spangler extends VisualizationController {
  static initClass() {
    this.func_name = "spangler";
    this.pretty_name = "Spangler";
    this.docs = "the fractal string art toy: Spangler";
  }
  init_spangler() {
    this.prep_html();
    let scr = 'http://span.gl/spangler.js';  // FIXME why does this not work?
    scr = "https://raw.githubusercontent.com/smurp/spangler_js/master/spangler.js";
    //$.getScript(scr, @on_spangler_script_loaded).fail(()->alert('failed'))
    this.init_via_cachedScript(scr);
  }
  init_via_cachedScript(script_uri) {
    return this.cachedScript(script_uri)
      .done((script_src, textStatus) => {
        $.globalEval(script_src);
        return this.on_spangler_script_loaded();
      }).fail((jqXHR, textStatus) => {
        return this.setInnerHTML(`<h1>${script_uri}</h1><h2>${textStatus}</h2>`+`<pre>${JSON.stringify(jqXHR,null,4)}</pre>`);
    });
  }

  prep_html() {
    return this.setInnerHTML(`\
<div>
  <canvas id="spangler" width="300" height="300"/>
  <code id="show_spec_here">2,5</code>
</div>`);
  }

  on_spangler_script_loaded() {
    //@widget = Object.create(SpanglePanel)
    //@widget.init()
    return this.setInnerHTML('<h1>script loaded</h1>');
  }

  constructor() {
    super(...arguments);
    this.on_spangler_script_loaded = this.on_spangler_script_loaded.bind(this);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    this.init_spangler();
  }
}
Spangler.initClass();
