/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//  BrowserLog = exports = module.exports = BrowserLog = (level) ->
//    level = exports[level.toUpperCase()]  if "string" is typeof level
//    @level = level or exports.DEBUG
//    return

export class BrowserLog {
  static initClass() {
    this.prototype.EMERGENCY = 0;
    this.prototype.ALERT = 1;
    this.prototype.CRITICAL = 2;
    this.prototype.ERROR = 3;
    this.prototype.WARNING = 4;
    this.prototype.NOTICE = 5;
    this.prototype.INFO = 6;
    this.prototype.DEBUG = 7;
    this.prototype.meth = {
      EMERGENCY: console.error,
      ALERT: console.error,
      CRITICAL: console.error,
      ERROR: console.error,
      WARNING: console.warn,
      NOTICE: console.info,
      INFO: console.info,
      DEBUG: console.debug
    };
  }

  constructor(levelStr) {
    if (levelStr == null) { levelStr = 'DEBUG'; }
    this.set_level(levelStr);
  }

  set_level(levelStr) {
    let levelNo;
    levelStr = levelStr.toUpperCase();
    if ("string" === typeof levelStr) {
      levelNo = this[levelStr];
    }
    this.levelStr = levelStr;
    return this.levelNo = levelNo;
  }
    //console.log("constructor() levelStr:",@levelStr,"levelNo:",@levelNo)

  help() {
    if (console && console.log) {
      console.info("set log level with:");
      console.info("  noodb.log.set_level('EMERGENCY|ALERT|CRITICAL|ERROR|WARNING|NOTICE|INFO|DEBUG')");
      return console.info("currently:",this.getLevelStr());
    }
  }

  levelStr2No(levelStr) {
    return this[levelStr.toUpperCase()];
  }

  getLevelStr() {
    return this.levelNo2Str(this.levelNo);
  }

  levelNo2Str(levelNo) {
    for (let k in this) {
      const v = this[k];
      if ((v === levelNo) && (k !== 'levelNo')) {
        return k;
      }
    }
    return 'unknown';
  }

  log(levelStr, args) {
    //console.log "log() levelStr:", levelStr, '@[levelStr]:', @[levelStr], "levelNo:", @levelNo
    if (this.levelStr2No(levelStr) <= this.levelNo) {
      const meth = this.meth[levelStr];
      // the [].slice.call(args) trick converts an Arguments instance into an Array
      const newargs = new Array("[" + new Date + "]" + " " + levelStr).concat([].slice.call(args));
      meth.apply(console, newargs);
    }
  }

  emergency(msg) {
    this.log("EMERGENCY", arguments);
  }

  alert(msg) {
    this.log("ALERT", arguments);
  }

  critical(msg) {
    this.log("CRITICAL", arguments);
  }

  error(msg) {
    this.log("ERROR", arguments);
  }

  warning(msg) {
    this.log("WARNING", arguments);
  }

  notice(msg) {
    this.log("NOTICE", arguments);
  }

  info(msg) {
    this.log("INFO", arguments);
  }

  debug(msg) {
    this.log("DEBUG", arguments);
  }
}
BrowserLog.initClass();

