import {TabularVisualizationController} from './tabular.js';

export class SubjectsTable extends TabularVisualizationController {
    constructor(...args) {
      super(...args);
      this.cell_value_onchange_handler = this.cell_value_onchange_handler.bind(this);
    }

    static initClass() {
      this.func_name = 'subjects';
      this.pretty_name = "Subjects Table"; // TODO convert " = " to ": "
      let id = "subjectss_table";
       // TODO fix, document or remove this
    }

    build_tw_args() {
      const tw_args = super.build_tw_args();
      tw_args.interaction_mode = 'spreadsheet';
      tw_args.cell_value_onchange_handler = this.cell_value_onchange_handler;
      return tw_args;
    }

    cell_value_onchange_handler(evt, cell) {
      const localized_id = cell.cand_obj.id;
      const s = this.unstrengthen_id(localized_id);
      const p = cell.crit.id;
      const o = evt.target.value;
      const {
        g
      } = this.kwargs;
      return this.noodb.allege(s,p,o,g);
    }

    receive(spogi) {
      // Every unique subj becomes a row.
      // Every unique predicate becomes a column.
      // Every spogi is accumulated in an 'evaluation' ie a cell value.
      // For the moment the candidate labels and criterion labels are just the ids.

      const s_key = spogi.s.key();
      const p_key = spogi.p.key();
      const o_key = spogi.o.key();

      const APPLY_LABELS_AND_TYPES = true;

      if (APPLY_LABELS_AND_TYPES) { // TODO break this stanza out like...
        //                           return null if @capture_labels_and_types(spogi)
        if (p_key.match(/rdfs:type/)) { // is 'rdfs:type'
          const xsd_type = o_key.match(/xsd\:(.*)/);
          if (xsd_type) {
            //alert "ignoring #{xsd_type[0]} likely criterion type: " + spogi.toString()
            this.setCriterionType(spogi, xsd_type[1]);
          }
          return;
        }

        // TODO make p_key able to be compared with the prefixed version, eg: 'foaf:name'
        if (['foaf:name',
            'http://xmlns.com/foaf/0.1/name',
            'rdfs:label',
            'http://www.w3.org/2000/01/rdf-schema#label'].includes(p_key)) {
          this.setLabelOnCandidateOrCriterion(spogi);
          return;
        }
      }

      const pred_id = p_key;
      const criterion_status = {};
      const criterion = this.getOrCreatePredicate(pred_id, status); // ie the criterion, the column

      const subj_url = this.strengthen_id(s_key);
      const subj_label_fallback = s_key;
      // now make the candidate (AKA the row, the subject)
      const candidate_status = {};
      const candidate = this.getOrCreateSubject(subj_url, subj_label_fallback, candidate_status);
      if (candidate) {
        candidate.tr().setAttribute('id', subj_url);

        // populate the evaluation (the cell value)
        return this.widget.add_datum([subj_url, pred_id, spogi.o.getNativeValue()]); // TODO implement aggregation
      } else {
        return this.noodb.log.debug(`no candidate found for '${subj_url}': \nSPOGI: ${spogi.toString()}`);
      }
    }

    describeDraggable(draggableElem) {
      // Our purpose in this method is to return a description of cell which
      // was clicked or dragged so it can be determined how to visualize it.
      // These descriptions should be equivalent to english sentences such as:
      //   "'rdfs:label' is the URI of a predicate."
      //      .thing_value: "rdfs:label"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'p'  # a predicate
      //   "'nrn:primordialKB' is the qname of a graph."
      //      .thing_value: "nrn:primordialKB"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'g'  # a graph
      // Weirdly, for the allegations() visualization anyway, the .i of the
      // row the cell is from doesn't really matter.  It seems hard to assert,
      //  but the graph doesn't really matter either.
      const desc = super.describeDraggable(draggableElem);
      const cand_id = this.findTRid(draggableElem);
      const crit_idx = this.getTableColumnNum0(draggableElem);
      const weak_cand_id = this.unstrengthen_id(cand_id);
      if (crit_idx != null) {
        const criterion = this.widget.get_criterion_by_idx(crit_idx);
        if (criterion != null) {
          const crit_id = criterion.id;
          if (crit_id != null) {
            const datum = this.widget.get_datum_by_cand_id_and_crit_id(cand_id, crit_id);
            desc.thing_isa = 'nrn:evaluationAggregation';
            desc.thing_crit_id = crit_id; // eg: s,p,o,g,whn,who,i
            desc.thing_cand_id = weak_cand_id;
            desc.thing_graph_id = this.kwargs.g;
            desc.thing_valuetype = datum.get_valuetype();
            desc.thing_value = datum.get_saveable_value();
          }
        }
      } else { // it was the row (ie candidate) label itself being dragged
        desc.thing_isa = "TBD:Q_WhatIsTheTypeOfCandidatesInThisTable";
        desc.thing_cand_id = weak_cand_id;
        desc.thing_graph_id = this.kwargs.g;
      }

      // desc.id = weak_cand_id # does not matter!!!
      return desc;
    }
}
SubjectsTable.initClass();


export class ClassTable extends SubjectsTable {
    static initClass() {
      this.func_name = 'class'; // TODO rename this for safety vs Javascript
      this.pretty_name = "Class Instances";
      this.docs = "Like subjects() but all rows are instances (or shown as) of the class. WIP";
      let id = "class_table";
    }
}
ClassTable.initClass();
  // For this to work there will need to be a new type of query:
  //   any Spogi where the subject of that spogi has a triple


export class KBsTable extends ClassTable {
  constructor(...args) {
      super(...args);
      this.make_add_new_cursors = this.make_add_new_cursors.bind(this);
    }

    static initClass() {
      this.func_name = 'kbs';
      this.pretty_name = "Knowledge Bases";
      this.docs = "All rows are KBs. WIP";
      let id = "kbs_table";
    }

    make_add_new_cursors() {
      let evl, TextCursor;
      if ((TextCursor == null)) {
        ({
          TextCursor
        } = require('textcursor'));
      }
      //txtcrsr = new TextCursor('div', "click where you'd like to add")
      const cand = new TextCursor(this.localize('.candidateCell'), 'new Knowledge Base');
      const crit = new TextCursor(this.localize('.criterionControl'), 'new criterion');
      return evl  = new TextCursor(this.localize('td.eval'), 'new evaluation');
    }
}
KBsTable.initClass();
