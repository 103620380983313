/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
"use strict";

export class FormurlaController {
  contstructor(name, args, kwargs, container, fracpanel) {
    this.name = name;
    this.args = args;
    this.kwargs = kwargs;
    return this._container = container;
  }

  getContainer() {
    return this._container;
  }

  setContainer(container) {
    this._container = container;
  }

  close() {
    // Tear self down.  Tell container.
  }

  getFormurla() {
    // return formurla for self
    return this.toString();
  }

  split(side) {}

  toString() {
    return this.name + "(" + ")";
  }
}

export class FormurlaContainerController extends FormurlaController {
  constructor(name, args, kwargs, container) {
    super(name, args, kwargs, container);
    if (!(['above', 'beside'].includes(name))) {
      throw new Error('FormurlaContainerController must be either above or beside');
    }
    this.children = [];
  }

  add(child) {
    // add to children and registers this as the container of the child
    this.children.push(child);
    child.setContainer(this);
  }

  adopt(child) {
    const prevContainer = child.getContainer();
    if (prevContainer) {
      prevContainer.abandon(child);
    }
    this.add(child);
  }

  abandon(child) {
    child.setContainer(null);
    const idx = this.children.indexOf(child);
    if (index > -1) {
      this.children.splice(idx, 1);
    }
  }

  abdicate() {
    if (this.children.length !== 1) {
      throw new Error(`${this.name} has ${this.children.length} children so cannot abdicate`);
    }
    const child = this.children.pop();
    this.getContainer().adopt(child);
  }

  getFormurla() {
    // return formurla for self, recursing through children
    return this.name + this.children.join(',');
  }
}
