
import {SubjectsTable} from './subjectstable.js';

export class DiscriminatorMenu extends SubjectsTable {
  static initClass() {
    this.func_name = 'discriminators';
    this.pretty_name = 'Discriminator Menu';
    this.docs = `This visualization shows the menu of Discriminators in the\
`;
    this.prototype.default_discriminator_src = "Latest(everyone)";
  }

  constructor() {
    super(...arguments);
    this.replace_discriminator_with_clicked = this.replace_discriminator_with_clicked.bind(this);
  }

  get_title() {
    return "Pick the voices to hear";
  }

  get_candidate_click_callback() {
    return this.replace_discriminator_with_clicked;
  }

  replace_discriminator_with_clicked(evt) {
    this.knob_which_controls_me.hide_popup();
    const discriminator_uri = this.get_qname_from_elem(evt.target);
    return this.replace_discriminator_by_uri(discriminator_uri);
  }

  replace_discriminator_by_uri(discriminator_uri) {
    const the_viz = this.knob_which_controls_me.get_visualization_I_accessorize();
    return the_viz.set_discriminator(discriminator_uri);
  }
}
DiscriminatorMenu.initClass();

export class VisualizationMenu extends SubjectsTable {
  static initClass() {
    this.func_name = 'visualizations';
    this.pretty_name = 'Visualization Menu';
    this.docs = `This visualization shows the menu of visualizations in the
'visualization_menu' in the upper left of every FractalPanel.

It should be distinguished by what it returns.
What should the criteria be?
How do purposes, defaults and individual evaluations (preferences?) relate?\
`;
  }

  constructor() {
    super(...arguments);
    this.replace_visualization_function_with_clicked = this.replace_visualization_function_with_clicked.bind(this);
  }

  get_title() {
    return "Pick a Visualization...";
  }

  replace_visualization_function_with_clicked(evt) {
    this.knob_which_controls_me?.hide_popup();
    let viz_class_name = this.get_qname_from_elem(evt.target);
    let func_name = 'print';
    if (viz_class_name) {
      viz_class_name = viz_class_name.replace("nrn:","");
      ({
        func_name
      } = VIZ[viz_class_name]);
    }
    const function_src = `print(func_name_is_${func_name})`;
    const msg = `clicked ${func_name}`;
    const {
      fracpanel
    } = this.visualization_picker_for; // really, both are FractalComponents
    return this.formurlaManager.replace_function(fracpanel, func_name);
  }

  get_candidate_click_callback() {
    return this.replace_visualization_function_with_clicked;
  }
}
VisualizationMenu.initClass();
