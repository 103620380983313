/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {Formurla, InputStream, TokenStream} from './formurla.js';
import {
  FormurlaController,
  FormurlaContainerController
} from './formurla-controller.js';
const edge_to_func = {
  east: 'beside',
  west: 'beside',
  north: 'above',
  south: 'above'
};

export class FormurlaManager {
  static initClass() {
    // Yes, this is called Formurla meaning formula in an url. eg
    // /beside(above(a,b),beside(c,d))
    //   +---+---+---+
    //   | a |   |   |
    //   +---+ c | d |
    //   | b |   |   |
    //   +---+----+--+
    // Where each of the above boxes is a FractalComponent and a,b,c and d are
    // different kinds of content, for example
    //   a = Graph(foaf2014)
    //   b = AllegationsTable(authLog)
    //   c = SubjectsTable(authLog)
    //   d = d   # the most appropriate visualizer for this identifier will display it
    // So the real url might look like this:
    //   /beside(above(graph(g=foaf20140114.nq),allegations(authLog)),\
    //           beside(SubjectsTable(AuthLog),d))
    // but really just mentioning an identifier should visualize that
    // in the fashion the user prefers, so:
    //   /beside(authLog,systemLog,1d_2)
    // would portray authLog, systemLog and 1d_2 with the user's preferred
    // visualizations for those entities, or the default visualizer, failing
    // back to print().
    //
    // Note these points:
    //    /beside(beside,above)
    //       would actually show the code for beside and above beside one another.
    //
    //    /beside(beside(),above())
    //       would show the panes but they would be empty, ready to receive drops
    //          +---+---+---+
    //          |   |   |   |
    //          +   +   +---+
    //          |   |   |   |
    //          +---+---+---+
    //
    // Questions:
    // 1) Does this mean the default front page has the formurla?
    //      /above(header,welcome)
    // 2) Should it be shown?
    // 3) Once logged in should it be?
    //      /above(header,beside(metaKB,intro))
    // 4) Those these are the appropriate formurlae, should we bother showing them?
    //    Uh, that is NOT clear!
    // 5) Could coming up with shortcuts for formurlae help?
    //    For example the formurla
    //      /above(header,beside(metaKB,intro))
    //    could have the shortcut
    //      /zBga
    //    which might be stored in the assertion:
    //      <zBga> <hasFormurla> "above(header,beside(metaKB,intro))"
    //    which might be nestable, eg
    //      /beside(zBga,zBga)   # yada yada, but you wouldn't bother presumably
    //
    this.prototype.formurla_prefix = '/__/';
    this.prototype.default_formurla = 'print("getting default_formurla from FormurlaManager")';
  }

  //default_formurla: "above(intro(),showDiv(FormurlaAstInspector),showDiv(FormurlaInspector))"
  //default_formurla: 'beside(print(10),intro(),print(3.14159))'
  //default_formurla: 'beside(print(1),print(2),intro())'
  //default_formurla: 'beside(1,2,3,4,intro())'
  //default_formurla: 'above(intro(),"1")'
  //default_formurla: 'beside(intro())'
  //default_formurla: 'above(beside(intro()))'
  //default_formurla: 'above(beside("NW","NE"),beside("SW","SE"))'
  onpopstate_handler(event) { // fat arrow so window.onpopstate can point here
    // WORK IN PROGRESS
    if (event.state) {
      const formurla_src = event.state.formurla;
      this.replace_formurla(this.rootPanel, formurla_src);
    }
  }

  constructor(rootPanel, noodb, window, global_args) {
    //window.onpopstate = @onpopstate_handler
    // pass window in, possibly a mock
    // Ideally contexts are coming from knowledge.
    //   dragAndDropContext = dci.context(roles, interactions)
    //   someViz = dragAndDropContext.getVisualizationFor({
    //       nodeTypeInVisualization: someType # eg: s,p,o,g,i,a,w
    //       visualizationNodeWasIn: someVisualization # eg: evaluations, subjects, graph, etc
    //       user: theCurrentUser # is this ever needed or is it available from FormurlaManager or NooDB?
    //     })
    //
    //   screenContext = dci.context(roles, interactions)
    //   screenContext.visualizeThingAt({droppee: someThing, dropZone: fracPanel, visualization: someViz})
    //
    this.onpopstate_handler = this.onpopstate_handler.bind(this);
    this.rootPanel = rootPanel;
    this.noodb = noodb;
    this.window = window;
    this.global_args = global_args;
    this.dci_contexts = {};
    if (this.global_args == null) { this.global_args = {}; }
    if (this.global_args.default_div == null) {
      this.global_args.default_div = 'blurb';
    }
    this.subscribe_to_essentials();
  }

  subscribe_to_essentials() {
    const qryTerms = [{g: 'nrn:NooronDiscriminators'}];
    return this.noodb.subscribe(qryTerms);
  }

  get_location_formurla() {
    return this.extract_formurla_from_url(decodeURI(this.window.location.pathname));
  }

  set_location_formurla(the_formurla) {
    return this.window.location.pathname = this.formurla_prefix + the_formurla;
  }

  update_location_formurla(the_formurla_or_expression) {
    if (typeof the_formurla_or_expression === 'string') {
      if (typeof this.formurla_src === 'string') {
        if (this.formurla_src !== the_formurla_or_expression) {
          return this.set_location_formurla(the_formurla_or_expression);
        }
      }
    }
  }

  push_formurla(formurla) {
    const url = this.window.location.origin + this.formurla_prefix + formurla;
    return this.window.history.pushState({formurla}, formurla, url);
  }

  update_top_formurla() {
    this.a_formurla = this.compile_formurla(this.formurla_src);
    this.display_top_formurla_AST();
    this.display_top_formurlaSrc();
  }

  display_top_formurla_AST() {
    return this.getOrCreateJSElem_top_formurla_AST().html(
      JSON.stringify(this.a_formurla.ast,null,4));
  }

  display_top_formurlaSrc() {
    return $('.formurlaSrc').html(this.a_formurla.formurla);
  }

  getOrCreateJSElem_top_formurla_AST() {
    // If there isn't a class .top_formurla_AST found (see BUITLIN_formurlaAST)
    // then make one up in the .nooHeader
    if (!$('.top_formurla_AST').length) {
      $('.nooHeader').append('<pre class="top_formurla_AST"></pre>');
    }
    return $('.top_formurla_AST');
  }

  // If formurla is of the form:
  //   symbol
  //   eg:
  //     beside      # a built-in function name (has no underscores)
  //     usr:1d      # a namespaced id (has a colon)
  //     nrn:authLog # a namespaced id
  //     a1_2        # an id for something in nooron (a session, here)
  //     a1_2_1_1    # an id for something in nooron (an allegation, here)
  //   then:
  //     1) look it up
  //     2) find an appropriate visualization for it, eg: 'symViz'
  //     3) and run symViz(symbol)
  //
  // If the formurla is of the form:
  //   symbol(...)
  //   eg:
  //     a1_2_23(nrn:1d_2, "penguin")
  //   then:
  //     1) look up the symbol
  //     2) if it is executable, then run it with the arguments ...
  //     3) if it is not executable, then display an error in frac
  //
  // If formurla is of the form:
  //   literal
  //     eg:
  //       'tallest man 8\'11"'   # a string with escaping
  //       "bob's yr unk"         # a string with the other quotes
  //       "line1\nline2\n"       # a string with newlines
  //       99                     # a number
  //       9.99                   # a number
  //       0xCAFEBABE             # a hex number
  //       0b11111111             # a binary number
  //       0o31                   # an octal number
  //       2001-09-11             # a date
  //       2001-09-11T08:43:00EDT # a dateTime
  //   then the literal is displayed in the default visualizer for that type
  //     calendar(2001-09-11)
  //   TODO Clarify when literal is an argument to an outer formurla or to be rendered
  run_formurla(frac, formurla_or_expression) {
    let visualization;
    if (formurla_or_expression == null) {
      formurla_or_expression = this.default_formurla;
    }
    this.update_location_formurla(formurla_or_expression);
    let expression = null;
    if (this.a_formurla != null) {
      expression = formurla_or_expression;
    } else {
      this.formurla_src = formurla_or_expression;
      this.update_top_formurla();
      if (this.a_formurla.ast.prog && (this.a_formurla.ast.prog.length > 0)) {
        expression = this.get_first_expression(this.a_formurla);
      }
    }

    if (expression != null) {
      let func, func_name;
      switch (expression.type) {
        case 'call':
          func_name = expression.func.value;
          func = this.resolve_function(func_name);
          break;
        case 'var':
          func = this.find_visualizer_for(expression.value);
          break;
        default: // eg: num, str
          func = this.resolve_function('print');
          this.noodb.log.error("not a func", expression);
      }
      if (func != null) {
        expression.the_function = func;
        visualization = func.apply(this, [frac, expression]);
        this.noodb.log.info("just created visualization:", visualization);
        return visualization;
      } else {
        this.noodb.log.error(`function «${func_name}» could not be resolved`);
      }

      if (typeof $ !== 'undefined' && $ !== null) { // if jQuery exists
        $(".formurlaAST").html(this.a_formurla.dumpAST());
        $(".formurlaSrc").html( this.formurla_src );
        $("#noorontime").show().appendTo($("#ca_2"));
      }

    } else {
      const msg = `no function found for: «${this.formurla_src}» could not be resolved`;
      this.noodb.log.alert(msg);
      const print = this.resolve_function('print');
      //oexpression.the_function = print
      visualization = print.apply(this, [frac, msg]);
    }
     // returning null means no visualization created
  }

  compile_formurla(src) {
    return new Formurla(src);
  }

  get_first_expression(formurla) {
    return formurla.ast.prog[0];
  }

  ast_to_src(ast) {
    // this is a utility which could just as well live on Formurla
    this.noodb.log.warning("ast_to_src() does not properly walk the tree");
    let src = "";
    let comma = "";
    if (ast.type === 'call') {
      src += ast.func.value;
      src += "(";
      for (let arg of Array.from(ast.args)) {
        //src += comma
        if (['var','num','str'].includes(arg.type)) {
          src += arg.value;
        }
        if ((arg.type === 'assign') && (arg.operator === '=')) {
          src += `${comma}${arg.left.value}=${arg.right.value},`;
        }
        comma = ",";
      } // not needed first time through
      src += ")";
      src = src.replace(/,,/g,','); // FIXME do not cause duplicate commas rather than removing them
      src = src.replace(/,\)/,')');
      return src;
    } else {
      return 'print("... ast_to_src() does not know how to handle expression_ast.type'
        + `=${ast.type}` + '")';
    }
  }

  replace_discriminator(viz_inst, suppress_reload) {
    const {
      fracpanel
    } = viz_inst;
    const discr = viz_inst.discriminator;
    viz_inst.discriminator_old;
    const ast = viz_inst.expression_ast;
    this.set_discriminator_in_ast(discr, ast);
    const formurla_src = this.ast_to_src(ast);
    //console.log "FORMURLA", formurla_src
    if (!suppress_reload) {
      return this.replace_formurla(fracpanel, formurla_src);
    }
  }

  set_discriminator_in_ast(discr, ast) {
    //console.log(Array(80).join('='))
    //console.log("set_discriminator_in_ast() <==", JSON.stringify(ast, null, 4))
    let arg, found;
    for (arg of Array.from(ast.args)) {
      if (arg.left && (arg.left.type === 'var') && (arg.left.value === 'discr')) {
        found = arg;
        break;
      }
    }
    if (!found) { // ie a discriminator was NOT found
      //console.log "not found"
      arg = {
        left: {
          type: 'var',
          value: 'discr'
        },
        operator: '=',
        type: 'assign'
      };
      ast.args.push(arg);
    }
    if (discr.uri && discr.uri.is_qname) {
      arg.right = {
        type: 'var',
        value: discr.uri
      };
    } else {
      arg.right = {
        type: 'str',
        value: (discr.uri && discr.uri) || discr.src
      };
    }
    //console.log "set_discriminator_in_ast() ==>", JSON.stringify(ast, null, 4)
  }

  replace_function(frac, new_function_name) {
    // Replace what is displayed in 'frac' with the execution of 'function_name'
    // with the same arguments as the visualization function currently displayed.

    const old_viz_inst = frac.visualization_instance;
    const {
      expression_ast
    } = old_viz_inst;
    expression_ast.func.value = new_function_name;
    let formurla_src = "print(oink,said,the,duck,strangely)";
    formurla_src = this.ast_to_src(expression_ast);
    return this.replace_formurla(frac, formurla_src);
  }
  // old_viz_inst.destroy()

  replace_formurla(frac, formurla) {
    this.noodb.log.notice("replace_formurla() formurla:", formurla);
    this.previous_formurla = this.a_formurla;
    delete this.a_formurla;
    return this.run_formurla(frac, formurla);
  }

  resolve_function(func_name) {
    const full_name = 'BUILTIN_'+func_name;
    const func = this[full_name];
    if (func != null) {
      this.noodb.log.info(`found function ${func_name}`);
    }
    return func;
  }

  find_visualizer_for(symbol) {
    // TODO vis_for_type < user_vis_for_type < vis_for_symbol < user_for_symbol
    return this.resolve_function('print');
  }

  extract_formurla_from_url(url) {
    const parts = url.split(this.formurla_prefix);
    if (parts.length > 1) {
      return parts[1];
    }
    return undefined;
  }

  BUILTIN_above(frac, expression) {
    return this.above_or_beside(frac, expression, 'above', 'south');
  }

  BUILTIN_beside(frac, expression) {
    return this.above_or_beside(frac, expression, 'beside', 'east');
  }

  above_or_beside(frac, expression, directionName, sideName) {
    if ((expression.args == null)) { return; }
    const fcc = new FormurlaContainerController(directionName); // above or beside
    expression.args.forEach((arg,idx) => {
      if (idx > 0) {
        frac = frac.add_sibling(sideName); // south or east
      }
      this.run_formurla(frac, arg);
      const functionName = arg.func.value;
      const fc = new FormurlaController(functionName);
      fcc.adopt(fc);
    });
  }

  BUILTIN_intro(frac, expression) {
    this.BUILTIN_showDiv(frac, {type: "var", value: this.global_args.default_div});
    // Replace the bogus xiframes with iframes within blurb to get
    // around the ytimg DNS timeout problem.
    return Array.prototype.slice.call($("#blurb xiframe")).forEach(
      elem => elem.outerHTML = elem.outerHTML.replace(/xiframe/,"iframe"));
  }

  BUILTIN_formurlaSrc(frac, expression) {
    this.BUILTIN_print(frac, null, '<pre class="formurlaSrc"></pre');
    return this.display_top_formurlaSrc();
  }

  BUILTIN_formurlaAST(frac, expression) {
    this.BUILTIN_print(frac, null, '<pre class="top_formurla_AST"></pre');
    return this.display_top_formurla_AST();
  }

  BUILTIN_showDiv(frac, expression) {
    let selector;
    window.showDiv_expression = expression;
    try {
      selector = "#" + expression.args[0].value;
    } catch (e) {
      null;
    }
    if ((typeof $ !== 'undefined' && $ !== null) && selector) {
       $(selector).appendTo(frac.content_area);
       $(selector).show();
     }
    return frac.visualization_button.hide();
  }

  BUILTIN_print(frac, expression, raw_html) {
    if ((raw_html == null) && expression.args && expression.args[0]) {
      raw_html = expression.args[0].value;
    }
    frac.content_area.html(raw_html);
    frac.visualization_button.hide();
    return frac.action_button.hide();
  }

  BUILTIN_dumpVIZ(frac, expression, raw_html) {
    /*
      dumpVIZ() replaces itself with a formurla invoking all visualizations.
     */
    var cnt = Object.keys(this.name2class).length;
    var formurla = '';
    var cnt = 0;
    for (let func_name in this.name2class) {
      let funcall = `${func_name}()`;
      if (cnt) {
        if (cnt % 2) {
          formurla = `above(${formurla},${funcall})`;
        } else {
          formurla = `beside(${formurla},${funcall})`;
        }
      } else {
        formurla = funcall;
      }
      cnt++;
    }
    return this.replace_formurla(frac,formurla)
  }

  makeFormurlaFromDescription(description) {
    // We should call the mythical and as-yet-unavailable method
    //   pick_the_best('visualization',forUser='bob', forThing=description)
    let vis_id;
    const isa = description.thing_isa;
    this.noodb.log.info("makeFormurlaFromDescription()",description);
    if (isa === 'i') {
      // TODO make visualization 'allegation()' for viewing and authoring single allegations.
      //   This should probably be a degenerate form of 'subject()' which should
      //   essentially be a generic form
      return `allegations(i=${description.thing_value})`;
    }
    const args = [];
    if (isa === 'nrn:evaluationAggregation') {
      vis_id = 'evaluations';
      if (description.thing_cand_id != null) {
        args.push(`s=${description.thing_cand_id}`);
      }
      if (description.thing_crit_id != null) {
        args.push(`p=${description.thing_crit_id}`);
      }
      if (description.thing_graph_id != null) {
        args.push(`g=${description.thing_graph_id}`);
      }
    } else {
      if ((description.thing_graph_id === 'nrn:metaKB') &&
          (description.thing_cand_id != null)) {
        vis_id = 'subjects';
        args.push(`g=${description.thing_cand_id}`);
      }
    }
    if (args.length) {
      return `${vis_id}(${args.join(',')})`;
    }
    if (['p','s','g'].includes(isa)) {
      this.noodb.log.warning("processing of description.thing.value is not principled");
      return `evaluations(${isa}=${description.thing_value})`;
    }
    let str = "  makeFormurlaFromDescription(DESCRIPTION:   ";
    for (let k in description) {
      let v = description[k];
      if (k === 'viz') {
        v = v.__proto__.constructor.name;
      }
      str += `${k}=${v}, `;
    }
    str += ") FAILED TO PLAN A FORMURLA";
    return `print(\"${str}\")`;
  }

  closePanel(the_panel) {
    console.log(`closePanel(${the_panel.frac_id})`, the_panel);
    // There are three scenarios
    // 1) this is the last panel
    //   If so, why is it even getting the close signal?
    //   The close button is not supposed to be visible on the last panel.
    //   At the moment (2019-08-17) it still *is* visible
    // 2) this panel IS NOT the last panel in a beside or above
    // 3) this panel IS the last panel in a beside or above
    return this.update_top_formurla();
  }

  get_above_or_beside_from_edge_id(edge_id) {
    return edge_to_func[edge_id] || false;
  }

  add_formurla_to_end_of_above_or_beside(formurla_src) {
    const trailing_paren_regex = /\)$/;
    const comma_formurla_paren = "," + formurla_src + ")";
    this.formurla_src = this.formurla_src.replace(
      trailing_paren_regex, comma_formurla_paren);
  }

  wrap_formurla_in_above_or_beside(above_or_beside, another_formurla_src) {
    this.formurla_src = above_or_beside +
        "(" + this.formurla_src +
        "," + another_formurla_src + ")";
  }

  update_my_formurla(above_or_beside, another_formurla_src) {
    if (above_or_beside) {
      // The value of above_or_beside is either 'above' or 'beside'
      if (this.formurla_src.startsWith(above_or_beside)) {
        // We are already IN a matching above_or_beside situation so there is
        // no need to create one, just append another_formurla_src.
        this.add_formurla_to_end_of_above_or_beside(another_formurla_src);
      } else {
        // This is NOT an above_or_beside situation and must be made one.
        this.wrap_formurla_in_above_or_beside(above_or_beside, another_formurla_src);
      }
    } else {
      // above_or_beside is falsey, meaning that the current formurla should be replaced
      this.formurla_src = another_formurla_src;
    }
    this.push_formurla(this.formurla_src);
    this.update_top_formurla();
  }

  visualizeDescriptionAtFracSide(description, frac_id, edge_id) {
    const formurla_src = this.makeFormurlaFromDescription(description);
    //if formurla_src?
    //  alert formurla_src
    //if console.clear?
    //  console.clear()
    const msg = `FORMURLA: ${formurla_src} FRAC ${frac_id} EDGE ${edge_id}`;
    this.noodb.log.info(msg);
    if (frac_id == null) { ({
      frac_id
    } = description); } // if no frac_id then use the current one
    const above_or_beside = this.get_above_or_beside_from_edge_id(edge_id);
    if (edge_id != null) {
      // An edge_id has been provided, meaning that it is to that side of
      // the current panel that the new formurla should be displayed.
      this.update_my_formurla(above_or_beside, formurla_src);
      // Create the_panel to run formurla_src in...
      const the_panel = this.rootPanel.split_frac_on_side(frac_id, edge_id);
      // then run it there...
      return this.run_formurla_src_in_panel(formurla_src, the_panel);
    } else {
      // If no edge_id is provided that means the current panel
      // should have its contents replaced with the new formurla
      this.replace_formurla(this.rootPanel.get_frac(frac_id), formurla_src);
      return;
    }
  }

  run_formurla_src_in_panel(the_src, the_panel) {
    const formurla = new Formurla(the_src);
    const expression = formurla.ast.prog[0];
    return this.run_formurla(the_panel, expression);
  }

  getContext(ctxName) {
    // Usage:
    //   screen_ctx = @formurlaManager.getContext('screen_ctx')
    //   # will load the module: 'screen_ctx'  (a failed experiment)
    if (this.contextCache == null) { this.contextCache = {}; } // assign if empty
    if (this.contextCache[ctxName] == null) {
      this.contextCache[ctxName] = require(ctxName);
    }
    return this.contextCache[ctxName].makeContext(this);
  }
}
FormurlaManager.initClass();
