import {
  VisualizationController
} from '../visualizationcontroller.js';

// Abstract VisualizationControllers are not in VIZ because they are not exported
let default_vidsrc = undefined;
let form_extension_from_concrete_video_recorder = undefined;
let DEFUNCT_video_provider_form = undefined;
let video_provider_form = undefined;

export class AbstractVideoProvider extends VisualizationController {
    constructor(...args) {
      super(...args);
      this.on_click_vidsrc = this.on_click_vidsrc.bind(this);
      this.on_submit_video_provider = this.on_submit_video_provider.bind(this);
      this.submit_vidsrc_hosted = this.submit_vidsrc_hosted.bind(this);
      this.submit_vidsrc_upload = this.submit_vidsrc_upload.bind(this);
      this.on_file_upload_error = this.on_file_upload_error.bind(this);
      this.resolve_getMediaRecorderStream = this.resolve_getMediaRecorderStream.bind(this);
      this.reject_getMediaRecorderStream = this.reject_getMediaRecorderStream.bind(this);
      this.onDataAvailable = this.onDataAvailable.bind(this);
      this.submit_vidsrc_record = this.submit_vidsrc_record.bind(this);
      this.on_done_submitting_record = this.on_done_submitting_record.bind(this);
      this.on_done_submitting_upload = this.on_done_submitting_upload.bind(this);
      this.on_done_submitting_hosted_before = this.on_done_submitting_hosted_before.bind(this);
      this.on_done_submitting_RecUpl_before = this.on_done_submitting_RecUpl_before.bind(this);
    }

    static initClass() {
      default_vidsrc = 'upload';
      form_extension_from_concrete_video_recorder = "";
      DEFUNCT_video_provider_form = `\
<form class="video_provider" method="POST" style="clear:both">
  <fieldset>
    <legend>Provide a Video</legend>
    <div class="recording_status"></div>
    <label for="video_title">Video Title</label>
    <input type="text" name="rdfs:label" id="video_title" size=40
           value="working title" required/><br/>
  
    <label for="video_description">Description</label>
    <textarea name="schema:description"
              id="video_description"></textarea>
    <fieldset>
      <legend>Choose where to get video from</legend>
  
      <input type="radio" id="upload" name="vidsrc">
      <label for="upload">Upload a Video</label>
  
      <input type="radio" id="record" name="vidsrc">
      <label for="record">Record a Video</label>
  
      <input type="radio" id="hosted" name="vidsrc">
      <label for="hosted">Hosted Video</label>
  
      <fieldset class="upload_fields" style="display:none">
        <input type="file" name="file_to_upload"/>
      </fieldset>
  
      <fieldset class="hosted_fields" style="display:none">
        <label for="hosted_video_uri">URL of hosted video</label>
        <input type="url" id="hosted_video_uri" size=30
               name="oa:hasBody"
               value="https://youtu.be/uImk2RgCq_U"
               placeholder="eg https://youtu.be/wh4t3v3r"/>
        <details>
          <summary><i class="fas fa-question-circle"></i></summary>
          <p>
            <i class="fab fa-youtube"></i> YouTube
            urls should be formatted like <code>https://youtu.be/wh4t3v3r</code>
            as provided by the <button><i class="fas fa-share"></i> Share</button>
            button rather than like
            <code>https://youtube.com/watch?v=wh4t3v3r</code>
            as shown in the location bar.
          </p>
          <p>
            Currently, support for no other hosted video service is provided,
            though it is planned.
          </p>
        </details>
      </fieldset>
  
      <fieldset class="record_fields" style="display:none">
        <video class="video_recorder" autoplay muted style="" disabled="disabled"></video>
        <div><i class="fas fa-circle" style="color:red"><input
            type="button" function="rec-stop"
            value="Record" style="border-left-color: red;"/></i>
        <div>
      </fieldset>
    </fieldset>
  
    <div class="additions"></div>
    <button type="submit">Submit Video</button>
  </fieldset>
</form>\
`;
      video_provider_form = `\
<form class="video_provider" method="POST" style="clear:both">
  
    		  <div class="mobile_page record_video">
            <div class="local_action_bar"><h3>Record New Video</h3> </div>
    			  <fieldset class="record_fields">
    				  <video class="video_recorder" autoplay muted disabled="disabled"></video>
    				  <div class="rec_controls">
    					  <input class="big_rec_btn" type="button" function="rec-stop" value="Record"/>
    					  <div class="change_camera_icon" style="display:none">
    						  <i class="fas fa-camera"></i><i class="fas fa-sync-alt"></i>
    					  </div>
    				  </div>
          			  <div class="recording_status"></div>
              	  </fieldset>
    		  </div>
  
          <!-- this is the idea: VIDSRC_video eg 'upload_video' so we can target them with JS -->
    		  <div class="mobile_page upload_video">
    			  <div class="local_action_bar"><h3>Upload Video</h3> </div>
    			  <fieldset class="upload_fields">
                <div class="file_upload_wrap">
                    <label for="file_to_upload" class="custom_file_upload"><span><i class="fas fa-cloud-upload-alt"></i> Upload Video</span><input type="file" name="file_to_upload"/></label>
                </div>
                </fieldset>
    		  </div>
    		  <div class="mobile_page hosted_video">
            <div class="local_action_bar"><h3>Add Link</h3></div>
    			  <fieldset class="hosted_fields">
                  <label for="hosted_video_uri">Add link to Youtube</label>
                  <input type="url" id="hosted_video_uri" size="30"
                         name="oa:hasBody"
                         placeholder="example: https://youtu.be/wh4t3v3r"/>
                  <p>
                    <i class="fab fa-youtube"></i> YouTube urls should be links
                    as provided by the <button><i class="fas fa-share"></i> Share</button> button.
                  </p>
                </fieldset>
    		  </div>
    		  <div class="mobile_page add_video_details">
    			  <div class="select_vid_type">
    				  <div class="video_type_selection">
    					  <select name="oa:motivatedBy" class="custom_select">
                  <option value="dvrsont:neutral">Neutral</option>
                  <option value="dvrsont:support">Support</option>
    						  <option value="dvrsont:checker">Fact Checker</option>
    						  <option value="dvrsont:contradiction">Contradiction</option>
    						  <option value="dvrsont:custom">Custom</option>
    					  </select>
    				  </div>
    				  <div class="subject_video_thumb tiny_thumb source"></div>
              <div class="subject_video_thumb tiny_thumb comment"></div>
    			  </div>
    				  <fieldset>
    					  <label for="video_title">title</label>
    					  <input type="text" name="rdfs:label" id="video_title" size="40"
                    placeholder="please enter your title" required/>
    					  <label for="video_description">description</label>
    					  <textarea name="schema:description" class="video_description_text"
                    placeholder="please enter your description"></textarea>
    					  <!-- <label for="video_keyword_tags">tags <span class="small_text">(Optional)</span></label>
                <textarea name="schema:tags" id="video_keyword_tags"></textarea> -->
    				  </fieldset>
    				  <fieldset style="display:none">
                <label for="thumbnail">thumbnail</label>
    					  default <input type="radio" name="gender" value="default">
                random <input type="radio" name="gender" value="random">
                select <input type="radio" name="gender" value="select">
                upload <input type="radio" name="gender" value="upload">
    				  </fieldset>
    				  <fieldset style="display:none">
                <label for="privacy">privacy</label>
    					  Choose Privacy
    				  </fieldset>
  
          	  </div>
          <div class="additions"></div>
    		  <button type="submit">submit</button>
          </form>\
`;
  
      this.prototype.available_vidsrc = ['record', 'upload', 'hosted'];
      this.prototype.unavailable_vidsrc = [];
    }

    ensure_videoProvider(args) {
      // The videoProvider offers the different ways to provide a video
      //   (currently three)
      //   * upload
      //   * record
      //   * hosted
      if (args == null) { args = {}; }
      if (args.hidden == null) { args.hidden = false; }
      if ((this.videoProvider == null)) {
        this.build_videoProvider();
        if (args.hidden) {
          return $(this.videoProvider).hide();
        }
      }
    }

    build_videoProvider() {
      $("#"+`${this.content_id}`).append(video_provider_form);
      $(".video_type_selection .custom_select").selectmenu({
        appendTo: ".select_vid_type .video_type_selection"
      });
      this.videoProvider = this.myQrySel("form.video_provider");
      this.videoProviderSubmitButton = this.myQrySel("[type='submit']");
      this.expose_only_available_vidsrc();
      $("input[name='vidsrc']").click(this.on_click_vidsrc); // make them switchable
      $(`#${default_vidsrc}`).trigger('click'); // pick the default vidsrc
      return this.videoProvider.onsubmit = this.on_submit_video_provider;
    }

    expose_only_available_vidsrc() {
      let vidsrc;
      for (vidsrc of Array.from(this.unavailable_vidsrc)) {
        $(this.myQrySel(`[for='${vidsrc}']`)).hide();
        $(this.myQrySel(`#${vidsrc}`)).hide();
      }
      for (vidsrc of Array.from(this.available_vidsrc)) {
        $(this.myQrySel(`[for='${vidsrc}']`)).show();
        $(this.myQrySel(`#${vidsrc}`)).show();
      }
    }

    on_click_vidsrc(evt) {
      const priorVidsrc = this.currentVidsrc;
      const nextVidsrc = evt.target.id;
      for (let fieldset of Array.from(this.available_vidsrc)) {
        //alert("#{fieldset} #{nextVidsrc}")
        if (fieldset === nextVidsrc) {
          $(`#${this.content_id} fieldset.${fieldset}_fields`).show('slow');
        } else {
          $(`#${this.content_id} fieldset.${fieldset}_fields`).hide('slow');
        }
      }
      // call the incoming enabler, eg @enable_vidsrc_record()
      this.enable_vidsrc(nextVidsrc);
    }

    enable_vidsrc(nextVidsrc) {
      const priorVidsrc = this.currentVidsrc;
      const enabler = this[`enable_vidsrc_${nextVidsrc}`];
      if (enabler != null) { enabler.call(this); }
      if (priorVidsrc) {
        // call the outgoing disabler eg @disable_vidsrc_record()
        const disabler = this[`disable_vidsrc_${priorVidsrc}`];
        if (disabler != null) { disabler.call(this); }
      }
      this.currentVidsrc = nextVidsrc;
    }

    enable_vidsrc_record() {
      this.ensure_videoRecorder();
      $(".video_provider video.video_recorder").attr('disabled', null);
      return $(".video_provider :submit").attr('disabled', true);
    }

    enable_vidsrc_upload() {
      return $(`#${this.content_id} .upload_fields [type='file']`).attr("required", "required");
    }
    disable_vidsrc_upload() {
      return $(`#${this.content_id} .upload_fields [type='file']`).attr("required", null);
    }

    enable_vidsrc_hosted() {
      return $(`#${this.content_id} .hosted_fields [type='url']`).attr("required", "required");
    }
    disable_vidsrc_hosted() {
      return $(`#${this.content_id} .hosted_fields [type='url']`).attr("required", null);
    }

    on_submit_video_provider(evt) {
      // Called at beginning of submission process to figure out which vidsrc to deal with
      // This is the right place for operations which should happen before executing any type of submission.
      evt.preventDefault();
      this.show_submitting();
      const submitter = this[`submit_vidsrc_${this.currentVidsrc}`];
      if (submitter != null) { submitter.call(this, evt); }
    }

    canonicalize_hosted_uri(uri) {
      // Returns [canonical_url, error_msg] with only one of them having a value
      let vidId;
      const canonicalYT = 'https://youtu.be/';
      const url = new URL(uri);
      if (url.hostname === 'youtu.be') {
        if (vidId = url.pathname) {
          return [canonicalYT + vidId];
        }
      } else if (url.hostname === 'www.youtube.com') {
        if (vidId = url.searchParams.get('v')) {
          return [canonicalYT + vidId];
        }
      }
      return [false, 'Unrecognized URL type: ' + uri];
    }

    submit_vidsrc_hosted(event) {
      const form = this.myQrySel(".video_provider");
      const hosted_uri = form.hosted_video_uri.value;
      const [canonical_hosted_uri, msg] = Array.from(this.canonicalize_hosted_uri(hosted_uri));
      if (msg) {
        this.show_progress_info(msg, true);
        return false;
      }
      this.on_done_submitting_hosted(canonical_hosted_uri);
    }

    on_done_submitting_hosted(hosted_url) {
      const [full_uri, triples, curie] = Array.from(this.on_done_submitting_hosted_before(hosted_url));
      this.on_done_submitting_common(full_uri, triples, curie);
    }

    submit_vidsrc_upload(event) {
      event.preventDefault();
      const form = this.myQrySel(".video_provider");
      const fd = new FormData();
      fd.append('file', form.file_to_upload.files[0]);
      const jaxArgs = {
        type: 'POST',
        url: '/m/v/save',
        data: fd,
        processData: false,
        contentType: false
      };
      return $.ajax(jaxArgs)
          .done(this.on_done_submitting_upload)
          .error(this.on_file_upload_error);
    }

    on_file_upload_error(jqxhr, textStatus, err) {
      return this.set_recording_status(textStatus);
    }

    ensure_videoRecorder() {
      if ((this.videoRecorder == null)) {
        return this.build_videoRecorder();
      }
    }

    build_videoRecorder() {
      //$("#"+"#{@content_id}").append(video_rec_controls)
      this.videoRecorder = document.querySelector(this.localize('.video_recorder'));
      // Do not use ids to select these guys, nooron might have many panes!
      //@record_button = document.querySelector(@localize("input[value='record']")) - DEPRECATED
      //@stop_recording_button = @myQrySel("input[value='stop']") - DEPRECATED
      //@stop_recording_button.setAttribute("disabled","disabled") - DEPRECATED
      //@record_slash_stop_button = @myQrySel("input[function ='rec-stop']")
      this.record_slash_stop_button = this.myQrySel("input[function='rec-stop']");

      if (!navigator.mediaDevices) {
        alert('getUserMedia support required to use this page');
      }

      this.recordingChunks = [];

      // Not showing vendor prefixes.
      const constraints = {
        audio: true,
        video: {
          width: 640,
          height: 480
        }
      };
      const streamPromise = navigator.mediaDevices.getUserMedia(constraints);
      return streamPromise.then(this.resolve_getMediaRecorderStream,
                         this.reject_getMediaRecorderStream);
    }

    set_recording_status(str) {
      return this.myQrySel(".recording_status").innerHTML = str;
    }

    resolve_getMediaRecorderStream(mediaStream) {
      this.mediaRecorder = new MediaRecorder(mediaStream);
      this.mediaRecorder.ondataavailable = this.onDataAvailable;
      this.videoRecorder.srcObject = mediaStream;
      //url = window.URL.createObjectURL(mediaStream)
      //video.src = url
      this.record_slash_stop_button.onclick = () => {
        const mode = this.record_slash_stop_button.getAttribute('value');
        if (mode === 'Record') {
          this.record_slash_stop_button.setAttribute('value', "Stop");
          this.mediaRecorder.start();
          return this.set_recording_status('recorder started');
        } else {
          this.record_slash_stop_button.setAttribute('value', "Record");
          this.mediaRecorder.stop();
          $(".big_rec_btn").blur();
          this.set_recording_status('recorder stopped');
          //aThumbNail = @grabThumbNail()
          return this.videoRecorder_thumbnail = this.grab_thumbnail_from_video('image/jpeg', this.videoRecorder);
        }
      };
        //@record_button.setAttribute("value","stop")
        //@stop_recording_button.setAttribute("value","record")

      //@record_button.onclick = () => - DEPRECATED
        //@record_button.setAttribute("disabled","disabled")
        //@stop_recording_button.removeAttribute("disabled")
        //@mediaRecorder.start()
        //@set_recording_status('recorder started')
        // aThumbNail = @grabThumbNail() - DEPRECATED
      //@stop_recording_button.onclick = () => - DEPRECATED
        //@stop_recording_button.setAttribute("disabled","disabled")
        //@record_button.removeAttribute("disabled")
        //@mediaRecorder.stop()
        //@set_recording_status('recorder stopped')
      this.videoRecorder.onloadedmetadata = e => {
        return console.log('onloadedmetadata', e);
      };
      return this.mediaRecorder.onstop = e => {
        console.log('e', e);
        console.log('chunks', this.recordingChunks);
        this.enable_form_submission();
        this.bigVideoBlob = new Blob(this.recordingChunks,
                                 {'type' : 'video/webm; codecs=webm' });
      };
    }

    enable_form_submission() {
      return $(this.videoProviderSubmitButton).prop('disabled',false);
    }

    disable_form_submission() {
      return $(this.videoProviderSubmitButton).prop('disabled',true);
    }

    grab_thumbnail_from_video(contentType, videoElem) {
      if (contentType == null) { contentType = 'image/jpeg'; }
      if (videoElem == null) { videoElem = this.videoRecorder; }
      const canvas = document.createElement('canvas');
      canvas.width = videoElem.videoWidth;
      canvas.height = videoElem.videoHeight;
      canvas.getContext('2d').drawImage(videoElem, 0, 0);
      return canvas.toDataURL(contentType);
    }

    make_thumb_uri_from_curie(videoCurie) {
      const localPart = localPartOfCurie(videoCurie);
      if (videoCurie.startsWith('youtu')) {
        return `https://i.ytimg.com/vi/${localPart}/sddefault.jpg`;
      }
      if (videoCurie.startsWith('dvrsvid')) {
        return `/m/v/${localPart}.jpg`;
      }
      if (videoCurie.startsWith('dvrsdata')) {
        return `/m/v/${localPart}.jpg`;
      }
      console.log(`make_thumb_uri_from_curie(${videoCurie}) returning default`);
      return "/vhost/graphics/video_default_thumb.png";
    }

    reject_getMediaRecorderStream(err) {
      return console.log("error: " + err);
    }

    onDataAvailable(e) {
      return this.recordingChunks.push(e.data);
    }

    show_submitting() {
      const submittingProgress = `\
<div class="submit_progress"></div><div class="progress_info">Submitting Video.... <i class="fas fa-spinner fa-spin"></i></div>\
`;
      $(".video_provider").after(submittingProgress);
    }

    submit_vidsrc_record(event) {
      const fd = new FormData();
      fd.append('fname', 'test.webm');
      fd.append('data', this.bigVideoBlob);
      return $.ajax({
        type: 'POST',
        url: '/m/v/save',
        data: fd,
        processData: false,
        contentType: false
      }).done(this.on_done_submitting_record)
       .error(this.on_file_upload_error);
    }

    reset_video_recording() {
      this.recordingChunks = [];
      // TODO turn off camera because when we are on PlayMedia the recorder needs to be torn down more after use
    }

    on_done_submitting_record(fullname_w_ext) {
      const [full_uri, triples, curie] = Array.from(this.on_done_submitting_RecUpl_before(fullname_w_ext));
      // now we can over-ride any RecUpl triples or set Rec specific ones

      //  * length
      //  * width
      //  * height
      //  * gps location
      //  * mimetype

      this.reset_video_recording();
      this.on_done_submitting_common(full_uri, triples, curie);
    }

    on_done_submitting_upload(fullname_w_ext) {
      const [full_uri, triples, curie] = Array.from(this.on_done_submitting_RecUpl_before(fullname_w_ext));
      this.on_done_submitting_common(full_uri, triples, curie);
    }

    get_hosted_preds() {
      return this.get_RecUpl_preds();
    }

    get_RecUpl_preds() {
      return ['rdfs:label', 'schema:description'];
    }

    ttl_value_from_input(value, input) {
      const {
        type
      } = input;
      if (['text'].includes(type)) {
        return `\"${value}\"`;
      }
      if (['textarea'].includes(type)) {
        return `\"\"\"${value}\"\"\"`;
      }
      return value;
    }

    get_pred_val_entries_for(predicates) {
      const entries = [];
      for (let curie of Array.from(predicates)) {
        const field_name = curie;
        const inputs = this.myQrySelAll(`[name='${field_name}']`);
        if (inputs.length) {
          for (let input of Array.from(inputs)) {
            if ((input.type === 'checkbox') && !input.checked) {
              continue;
            }
            const {
              value
            } = input;
            if ((value != null) && (value !== "")) {
              const ttlValue = this.ttl_value_from_input(value, input);
              entries.push([curie, ttlValue]);
            }
          }
        }
      }
              // $("##{@content_id}").append("<dl><dt>#{curie}</dt><dd>#{value}</dd></dl>")
      return entries;
    }

    make_hosted_curie_from_fullUri(fullUri) {
      const youtubeId = fullUri.split('/')[-1];
      return 'youtu:' + youtubeId;
    }

    on_done_submitting_hosted_before(hosted_uri, triples) {
      const fullUri = hosted_uri;
      //#curie = @make_vid_hosted_curie_from_fullUri(fullUri)
      const curie = this.make_vid_curie_from_fullUri(fullUri);
      if (triples == null) { triples = []; }
      const pred_val_entries = this.get_pred_val_entries_for(this.get_hosted_preds());
      triples.push([curie, 'rdf:type', 'video:Video']); // REVIEW what should this be for youtube, vimeo etc????
      for (let [subj, obj] of Array.from(pred_val_entries)) {
        triples.push([curie, subj, obj]);
      }
      return [fullUri, triples, curie];
    }

    on_done_submitting_RecUpl_before(fullname_w_ext, triples) {
      const fullUri = window.location.origin + `/m/v/${fullname_w_ext}`;
      const curie = this.make_vid_curie_from_fullUri(fullUri);
      if (triples == null) { triples = []; }
      const pred_val_entries = this.get_pred_val_entries_for(this.get_RecUpl_preds());
      triples.push([curie, 'rdf:type', 'video:Video']);
      for (let [subj, obj] of Array.from(pred_val_entries)) {
        triples.push([curie, subj, obj]);
      }
      return [fullUri, triples, curie];
    }

    make_vid_curie_from_fullUri(fullUri) {
      // FIXME oh god this is so unprincipled!!!!
      let prefix;
      const path = fullUri.split('/');
      const fname = path.pop();
      const fid = fname.split('.')[0];
      if (fullUri.includes('youtube') || fullUri.includes('youtu.be')) {
        prefix = 'youtu';
      } else {
        prefix = 'dvrsvid';
      }
      return `${prefix}:${fid}`;
    }

    receive_transaction_response(tranxRes) {
      // After the server has processed an allege_transaction() call it sends a
      // tranxRes which contains at least
      return alert(tranxRes.placeHolder2Curie); // what the placeholders became
    }

    makePlaceholder(term) { // Make Resource Placeholder
      // Purpose:
      //   Create a placeholder for a resource which will be created on the server side.
      //   The purpose of the 'term' being provided is so that all the matching uses
      //   of a particular term can be replaced on the server side with the same new
      //   resource id.
      return `?${term}?`;
    }

    XXX_add_form_triples(triples) {
      const THE_ANNOT = this.makePlaceholder('dvrsdata:anno');
      const THE_VIDEO = this.makePlaceholder('dvrsvid:video');
      let THE_ACTUAL_VIDEO = null;
      for (let elem of Array.from(this.videoProvider)) {
        if (elem.type === 'fieldset') {
          continue;
        }
        if ((elem.name === 'oa:Motivation') && elem.checked) {
          triples.push([THE_ANNOT, 'oa:Motivation', elem.value]);
        }
        if (elem.value != null) {
          if (['rdf:label', 'schema:description'].includes(elem.name)) {
            if (['oa:hasBody'].includes(elem.name)) {
              THE_ACTUAL_VIDEO = elem.value;
            }
            triples.push([THE_VIDEO, elem.name, `\"\"\"${elem.value}\"\"\"`]);
          }
          if (['oa:hasBody'].includes(elem.name)) { // FIXME only for the HOSTED video source
            triples.push([THE_ANNOT, elem.name, elem.value]);
          }
        }
      }
      if (THE_ACTUAL_VIDEO != null) {
        for (let triple of Array.from(triples)) {
          if (triple[0] === THE_VIDEO) {
            triple[0] = THE_ACTUAL_VIDEO;
          }
        }
      }
    }

    on_done_submitting_common(fullUri, triples, curie) {
      this.create_triples_for_transaction(fullUri, triples);
      // @add_form_triples(triples) # REVIEW is add_form_triples now redundant????
      const defaultQuad = [null, null, null, this.get_writable_graph()];
      this.submitTransaction(triples, defaultQuad);
      $(".progress_info").html("Video Posted");
      this.set_recording_status('Your Video Was Posted!');
      // subclasses might go somewhere or close provider pane from here
    }

    make_playmedia_formurla(vidCurie, moreTerms) {
      // moreTerms is an optional list of Formurla terms such as "g=nrn:dvrsvid" without separating commas
      moreTerms = moreTerms || [];
      const terms = [vidCurie];
      for (let term of Array.from(moreTerms)) {
        if (term.startsWith(',')) {
          throw new Error(`term '${term}' should not start with ,`);
        }
        terms.push(term);
      }
      return `playmedia(${terms.join(",")})`;
    }

    run_playmedia_formurla(vidCurie) {
      let graph;
      const moreTerms = [];
      if (graph = this.get_writable_graph()) {
        moreTerms.push(`g=${graph}`);
      }
      const formurla = this.make_playmedia_formurla(vidCurie, moreTerms);
      this.run_formurla(formurla);
    }

    create_triples_for_transaction(fullUri, triples) {
      // Purpose:
      //   Marshal the triples which are common to all vidsrc, ie record, upload and hosted.
      // Legend:
      //   ?dvrsvid:whatever?        # Placeholder for server-side expansion into an actualCurie
      //   ?dvrsdata:annot?          # Placeholder for server-side expansion into actualCure for the Annotation
      //                             # Not a blank node because
      //   dvrsvid:BODY_VID          # this is the client-side CURIE for the video which IS the reponse
      //                             # examples:
      //                                 youtu:5pl3XwTSfek    the response is a youtube video
      //                                 dvrsvid:Jusa98H      the response has been uploaded or recorded by a user
      //   dvrsvid:TARGET_VID        # this is the client-side CURIE for the video being responded to
      //                             # examples:
      //                                 youtu:5pl3XwTSfek    the response is a youtube video
      //                                 dvrsvid:Jusa98H      the response has been uploaded or recorded by a user
      // The Triples:
      //   dvrsvid:BODY_VID          rdf:type            video:Video
      //   dvrsvid:BODY_VID          rdfs:label          "working title"
      //   dvrsvid:BODY_VID          schema:description  "title says it all"
      //
      //   ?dvrsdata:annot?          rdf:type            oa:Annotation
      //   ?dvrsdata:annot?          oa:motivatedBy      oa:linking
      //   ?dvrsdata:annot?          oa:hasBody          ?dvrsvid:bodyVideo?       # actualCurie not a BNode
      //
      //   ?dvrsdata:annot?          oa:hasTarget        ?dvrsvid_:targetBNode?    # BNode for Annotation TARGET
      //   ?dvrsvid_:targetBNode?    oa:hasSource        dvrsvid:TARGET_VID        # URL of video being responded to
      //   ?dvrsvid_:targetBNode?    oa:hasSelector      ?dvrsvid_:targetSelector? # BLANK NODE for TARGET Selector
      //
      //   ?dvrsvid_:targetSelector? rdf:type            oa:FragmentSelector
      //   ?dvrsvid_:targetSelector? dcterms:conformsTo  <http://www.w3.org/TR/media-frags/>
      //   ?dvrsvid_:targetSelector? rdf:value           "t=12.0,22.9"             # start and stop secs in TARGET


      return;
      // ALL OF THESE ENTRIES ARE REDUNDANT
      // because the equivalents have been provided in recSrc-sensitive way by on_done_submitting_RecUpl_before and friends

      // Let's keep these here for reference on how to grab values this way

      // Triples about the video being uploaded, so needed for response and root videos
      const targetVidCurie = this.video_curie;
      const bodyVidCurie = this.make_vid_curie_from_fullUri(fullUri);
      triples.push([bodyVidCurie, 'rdf:type', 'video:Video']);
      let elem = this.videoProvider.querySelector("[name='rdfs:label']");
      if (elem && (elem.value !== undefined)) {
        triples.push([bodyVidCurie, 'rdfs:label', this.n3Quote(elem.value)]);
      }
      elem = this.videoProvider.querySelector("[name='schema:description']");
      if (elem && (elem.value !== undefined)) {
        return triples.push([bodyVidCurie, 'schema:description', this.n3Quote(elem.value)]);
      }
    }

    n3Quote(val) {
      return `\"${val}\"`;
    }

    build_triples_for_upload(videoUrl, triples) {
      if (triples == null) { triples = []; }
      return triples;
    }

    build_triples_for_recording(videoUrl, triples) {
      if (triples == null) { triples = []; }
      return triples;
    }

    choose_vidsrc_if_indicated() {
      let vidsrc;
      if (vidsrc = this.kwargs.vidsrc) { // ie upload, record, hosted
        $('#'+vidsrc).click();
        return this.choose_provid(vidsrc);
      }
    }

    choose_provid(vidsrc) {
      $(this.videoProvider).show();
      this.hide_vidsrc_other_than(vidsrc);
      this.show_vidsrc(vidsrc);
      this.enable_vidsrc(vidsrc);
    }

    hide_vidsrc_other_than(vidsrc) {
      for (let aVidsrc of Array.from(this.available_vidsrc)) {
        if (aVidsrc !== vidsrc) {
          for (let elem of [this.localSel(`.${aVidsrc}_video`)]) {
            if (elem) {
              elem.setAttribute('style','display:none');
            }
          }
        }
      }
    }

    show_vidsrc(vidsrc) {
      let elem;
      if (elem = this.localSel(`.${vidsrc}_video`)) {
        elem.setAttribute('style',null);
      }
    }
}
AbstractVideoProvider.initClass();
