/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {date_to_now, one_million} from './nanoclock.js';
import {rebase, int_to_base, base_to_int} from './rebase.js';

export class WhoWhen {
  constructor(user_symbol, session_no, date) {
    this.user_symbol = user_symbol;
    this.session_no = session_no;
    if (date != null) {
      this.setTime(date);
    }
  }
  parse(who_when_str) {
    this.who_when_str = who_when_str;
    const part = this.who_when_str.split("_");
    this.user_no = base_to_int(part[0]);
    this.user_symbol = part[0];
    this.session_no = base_to_int(part[1]);
    this.sec = base_to_int(part[2]);
    this.nsec = base_to_int(part[3]);
    return this;
  }
  build(user_symbol, session_no, sec, nsec) {
    this.user_symbol = user_symbol;
    this.session_no = session_no;
    this.sec = sec;
    this.nsec = nsec;
    return this;
  }
  build_from_user_sess_time(user_symbol, session_no, date) {
    // TODO improve time accuracy of nsec
    //   https://www.npmjs.com/package/performance-now
    //     add performance-now to package.json
    // https://developer.mozilla.org/en-US/docs/Web/API/Performance.now
    // console.warn("use of WhoWhen.buidl_from_user_sess_time() is hazardous, use NooDB.clock")
    const now = date_to_now(date);
    return this.build(user_symbol, session_no, now.sec, now.nsec);
  }
  setTime(date) {
    // console.warn("use of WhoWhen.setTime() is hazardous, use NooDB.clock")
    const now = date_to_now(date);
    this.sec = now.sec;
    this.nsec = now.nsec;
    return this;
  }
  getDate() {
    const msec = (this.sec * 1000) + ((this.nsec / one_million) | 0);
    return new Date(msec);
  }
  getISO() {
    return this.getDate().toISOString();
  }
  toString() {
    return `user: ${this.user_symbol}, sess: ${int_to_base(this.sess_no)}, sec: ${this.sec}, nsec: ${this.nsec}`;
  }
  repr() {
    return [this.user_symbol,
            int_to_base(this.session_no),
            int_to_base(this.sec),
            int_to_base(this.nsec)].join("_");
  }
  toSafeCURIE() {
    return `[nrn:${this.repr()}]`;
  }
  now() {
    return {sec: this.sec, nsec: this.nsec};
  }
  whn() {
    return this.now();
  }
  sess() {
    return {user_symbol: this.user_symbol, session_no: this.session_no};
  }
  cmp(other) {
    if (this.sec > other.sec) {
      return 1;
    }
    if (this.sec === other.sec) {
      if (this.nsec > other.nsec) {
        return 1;
      }
      if (this.nsec === other.sec) {
        return 0;
      }
    }
    return -1;
  }
}

