/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

let N3Util;
try {
  N3Util = require('n3').Util;
} catch (error) {
  //  console.log("expost n3 to browser")
  const e = error;
  N3Util = {};
}

export class HardenedNooDB {
  // HardenedNooDB is a facade over NooDB so the underlying noodb need not be exposed
  constructor(noodb) {
    this.get_new_id = () => noodb.synthetic_key_factory_next();
    this.uniform_echo = a => a;
    this.make_new_kb = kbName => noodb.socket.emit("make_new_kb", {kbName});
  }
}

export const NoorVM = {
  func_src_predicate: 'http://nooron.com/_/NooronAppArchitecture.ttl#FNC',
  default_prefix: 'http://nooron.com/_/NooronAppArchitecture.ttl#',
  // https://regex101.com/#javascript
  func_args_and_body_regex: /^\s*function\(([^\)]*)\)\s*{([\n\s\r\S]*)}\s*$/,   // /m means multiline
  make_vm(settings, self, caching) {
    if (self == null) { self = {}; }
    if (settings == null) { settings = {}; }
    if (settings.prefix == null) { settings.prefix = this.default_prefix; }
    if (settings.caching == null) { settings.caching = false; }
    if (settings.expose_noodb_safely == null) { settings.expose_noodb_safely = true; }
    if (settings.expose_n3util == null) { settings.expose_n3util = true; }
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Proxy
    const noodb = this;
    if (settings.expose_noodb_safely) {
      this.facade = new HardenedNooDB(noodb);
      self.noodb = this.facade; // FIXME can this be eliminated?
    }
    if (settings.expose_n3util) {
      self.n3util = N3Util;
    }
    self._newline = String.fromCharCode(10);
    const announce = function(msg, more) {
      console.log(msg, more);
      throw new Error(msg + "()");
      return null;
    };
    const notImplemented = function() {
      // sadly this does not work
      console.log("NAME", arguments.callee.name);
      return null;
    };
    const make_handler = function(settings, self) {
      return {
        get(target, name) {
          noodb.log.info(`Proxy_handler.get(target, '${name}')`);
          if (!(name in target)) {
            // console.log("get(target,'#{name}')")
            let retval;
            const full_name = settings.prefix + name;
            let err = null;
            try {
              retval = noodb.find_func(name);
            } catch (e) {
              err = 3;
              //noodb.log.error(e)
              retval = noodb.find_func(full_name);
            }
            if (!retval) {
              noodb.log.error(`failed to find_func('${name}') or find_func('${full_name}')`);
              if (err) {
                throw err;
              }
            }
            if (settings.caching) {
              target[name] = retval;
            }
            return retval;
          }
          return target[name];
        },
        //get: ->
        //  announce "get", arguments
        someRandomFunction: notImplemented, // TODO it wold be great if this worked
        getPrototypeOf() {
          return announce("getPrototypeOf", arguments);
        },
        setPrototypeOf() {
          return announce("setPrototypeOf", arguments);
        },
        isExtensible() {
          return announce("isExtensible", arguments);
        },
        preventExtensions() {
          return announce("preventExtensions", arguments);
        },
        getOwnPropertyDescriptor() {
          return announce("getOwnPropertyDescriptor", arguments);
        },
        defineProperty() {
          return announce("defineProperty", arguments);
        },
        has() {
          return announce("has");
        },
        set() {
          return announce("set");
        },
        deleteProperty() {
          return announce("deleteProperty");
        },
        ownKeys() {
          return announce("ownKeys");
        },
        apply() {
          return announce("apply");
        },
        construct() {
          return announce("construct");
        }
      };
    };
    return this.new_Proxy(self, make_handler(settings, self));
  },

  new_Proxy(target, handler) {
    return new Proxy(target, handler);
  },

  apply_by_name(func_name, vm, args) {
    // Call a function by name with a list of arguments; return a value.
    // TODO(smurp) Shawn should figure out which version when he's smarter
    //
    // @method call_by_name
    // @param {String} func_name
    // @param {Object} vm The execution context in which the function will be applied.
    // @param {Array} args An array of arguments to the function
    // @return {Object} The return value of the named function.
    this.log.info(`apply_by_name('${func_name}',VM,${args})`);
    if (typeof func_name === 'undefined') {
      throw new Error('VM says apply_by_name() func_name should not be undefined');
    }
    const func = vm[func_name];
    //if not func
    //  throw new Error("could not find function #{func_name}")
    this.log.debug("func",func);
    return func.apply(vm, args);
  },

  get_facade_func(func_name, vm, args) {
    return (this.facade != null) && this.facade[func_name];
  }, // facade might be disabled

  find_func(func_name) {
    // A function body lives in the KB as an string literal on a predicate like
    //   nrn:ADD naa:FNC """function(p1, p2){return p1 + p2;}""" .
    // So @find_func('nrn:ADD') ==> "function(p1,p2){return p1 + p2;}"
    // @method find_func
    // @param {String} func_name
    // @return {String} Returns the javascript source for the named function or false
    const func = this.get_facade_func(func_name);
    if (func) {
      return func;
    }
    const func_src = this.get_func_src(func_name); //or ""
    this.log.info("func_src:", func_src, typeof func_src , ", func_src[0]:", func_src);
    const func_a_and_b = this.parse_func_args_and_body(func_src);
    this.log.info("func_a_and_b:", func_a_and_b);
    if (func_a_and_b) {
      return this.compile_func(func_a_and_b);
    } else {
      return false;
    }
  },

  get_func_src(func_name) {
    this.warn_once("get_func_src() always gets the latest version THIS IS A SECURITY HOLE");
    this.log.info(`get_func_src(${func_name})`);
    const spogi = this.query([{s: func_name}, {p: this.func_src_predicate}], {which: "last"});
    if (spogi) {
      const retval = spogi.o.key();
      return retval;
    } else {
      throw new Error(`VM says function ${func_name} can not be found by @get_func_src()`);
    }
  },


  parse_func_args_and_body(func_src) {
    const m = func_src.match(this.func_args_and_body_regex);
    this.log.debug("parse_func_args_and_body()");
    if (m) {
      const retval = {
        args: ((Array.from(m[1].split(',')).map((t) => t.trim()))), // remove surrounding spaces
        body: m[2]
      };
      while (retval.args[0] === "") { // drain [""] to []
        retval.args.pop();
      }
      return retval;
    } else {
      throw new Error(`args and body not found in ${func_src}`);
      return false;
    }
  },

  compile_func(func_args_and_body) {
    // Return a Function specified by args and body on func_args_and_body
    // It is assumed that func_src is already safe because it has been
    // compiled on the server side by a trusted method. See:
    //   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function
    //
    // @method compile_func
    // @param func_args_and_body A javascript function equivalent to:
    //   "function(p1,p2){return p1 + p2;}"
    // but in the form:
    //   args: ['p1', 'p2']
    //   body: 'return p1 + p2;'
    // @return {Function} The compiled version of the function.
    const args = func_args_and_body.args || [];
    const body = func_args_and_body.body || "return;";
    this.log.debug("func_args_and_body", func_args_and_body);
    return new Function(args, body);
  }
};

