var InputStream = function(input) {
  // copied from http://lisperator.net/pltut/parser/input-stream
  // usage:
  //     var stream = InputStream(string)
  var pos = 0, line = 1, col = 0;
  return {
    next  : next,
    peek  : peek,
    eof   : eof,
    croak : croak,
  };
  function next() {
    var ch = input.charAt(pos++);
    if (ch == "\n") line++, col = 0; else col++;
    //console.log("input.next() ===> ",ch);
    return ch;
  }
  function peek() {
    return input.charAt(pos);
  }
  function eof() {
    return peek() == "";
  }
  function croak(msg) {
    var adj = 1;  // 0=normal; or 1 if point COMBINES with the prior character
    var point;
    if (adj) { // COMBINING
      point = '\u0305'; // COMBINING OVERLINE
      point = '\u0332'; // COMBINING UNDERLINE
    } else { // NON-COMBINING
      point = '¿'; // 0x00BF
      point = '█'; // 0x2588
    }
    var show = point;
    try {
      if (line > 1) {
	show = input.split("\n")[line-1];
      } else {
	show = input + ""; // make a copy
      }
      // note adjustment of location of point using adj when COMBINING
      show = show.substr(0, col+adj) + point + show.substr(col+adj);
    } catch(e) {}
    throw new Error(msg + " at  " + point +
		    " (" + line + ":" + col + ")" +
		    "   " + show);
  }
};


var TokenStream = function(input) {
  // based on http://lisperator.net/pltut/parser/token-stream
  var current = null;
  var reserved = "with let ";
  // TODO Probably want to pass the builtins in as an argument
  var builtins = "above rows beside cols graph table chart head login" +
      " allegations subjects ";
  var keywords = " everyone me notme others my OR NOT "; // + reserved + builtins;
  var methodnames = " re "; // eg "max(me).re(quality)"
  return {
    next  : next,
    peek  : peek,
    eof   : eof,
    croak : input.croak
  };
  function is_keyword(x) {
    return keywords.indexOf(" " + x + " ") >= 0;
  }
  function XXXis_methodname(x) {
    return methodnames.indexOf(" " + x + " ") >= 0;
  }
  function XXXis_member_char(ch) {
    return /\w/.test(ch);
  }
  function is_digit(ch) {
    return /[0-9]/i.test(ch);
  }
  function is_id_start(ch) {
    // Nooron ids exclude big oh "O" and little ell "l" but here they're good
    return /[a-z]/i.test(ch);
  }
  function is_id(ch) {
    // We accept _ (for Nooron spogi ids) and : (for namespace delimiters)
    return is_id_start(ch) || "0123456789_:.".indexOf(ch) >= 0;
  }
  function is_atuser_start(ch) {
    return /@/.test(ch);
  }
  function is_userid(ch) {
    return /\w/.test(ch);
  }
  function is_QName(ch) {
    // https://en.wikipedia.org/wiki/QName
    return /[\w\:]/.test(ch);
  }
  function is_op_char(ch) {
    // We'll defer "+-*/%&|<>!" operations for the moment
    //   = is interesting for keyword parameter assignement only
    return "=".indexOf(ch) >= 0;
  }
  function is_punc(ch) {
    /*
      ,  delimits users and delimits criteria
      () surround users and delimits criteria
      ;  delimits PredicateReducers
      .  separates an aggregation call from any .re(crit1,crit2) afterward
      @  is prepended to atuser mentions
      |  delimits (aka OR) equivalency classes
      ~  delimits (aka NOT) introduces rejected user
    */
    return ",();.@|~".indexOf(ch) >= 0;
  }
  function is_whitespace(ch) {
    return " \t\n".indexOf(ch) >= 0;
  }
  function read_while(predicate) {
    var str = "";
    while (!input.eof() && predicate(input.peek()))
      str += input.next();
    return str;
  }
  function read_number() {
    /*
      It will be desireable to support some additional formats in future:
      0x41  JavaScript hexadecimal
      0o31  ECMAScript 6
      0b11  ECMAScript 6

      http://stackoverflow.com/a/2803188/1234699
    */
    var has_dot = false;
    var number = read_while(function(ch){
      if (ch == ".") {
	if (has_dot) return false;
	has_dot = true;
	return true;
      }
      return is_digit(ch);
    });
    // TODO test for negative number support
    return { type: "num", value: parseFloat(number) };
  }
  function read_ident() {
    var id = read_while(is_id);
    if (id == 'me' || id == 'everyone' || id == 'others' || id == 'notme') {
      return {
	type : 'genuser',
	value: id
      }
    }
    return {
      // unless and until something like "with" or "let" are
      // implemented.
      type  : is_keyword(id) ? "kw" : "var",
      value : id
    };
  }
  function read_atuser() {
    input.next();
    var user_id = read_while(is_userid);
    return {
      type : 'atuser',
      value: user_id
    }
  }
  function XXXread_method_call() {
    //console.log("read_method_call() 1:",input.next());
    //console.log("read_method_call() 2:",input.next());
    var crit_id = read_while(is_QName);
    return {
      type : 'critid',
      value: crit_id
    }
  }
  function XXXread_member_or_method() {
    // A "method" like ".re(Crit1,Crit2)"
    // or eventually a "member" like  ".mother"
    console.log("read_member_or_method():",input.next());
    var member_name = read_while(is_member_char);
    if (is_methodname(member_name)) {
      return read_method_call(member_name);
    } else {
      return {
	type : 'member',
        value: member_name
      }
    }
  }
  function read_escaped(end) {
    var escaped = false, str = "";
    input.next();
    while (!input.eof()) {
      var ch = input.next();
      if (escaped) {
	str += ch;
	escaped = false;
      } else if (ch == "\\") {
	escaped = true;
      } else if (ch == end) {
	break;
      } else {
	str += ch;
      }
    }
    return str;
  }
  function read_string() {
    return { type: "str", value: read_escaped('"') };
  }
  function skip_comment() {
    // Use Guillemet or "Latin quotation marks" for comments.
    //    https://en.wikipedia.org/wiki/Guillemet
    //        «comments␠go␠here»
    //
    // Consider the use of ␠ ie U+2420 "SYMBOL FOR SPACE" as
    // a space character in comments because it reads fairly well
    // as a space and does not get translated into %20 as all the
    // invisible Unicode space characters seem to:
    //    https://www.cs.tut.fi/~jkorpela/chars/spaces.html
    // Here is an example of a FormURLa with such a comment:
    //   print("hello, world"«this␠comment␠has␠some␠(weird!)␠spaces␠in␠it»)
    read_while(function(ch){ return ch != "»" });
    input.next();
  }
  function read_next() {
    read_while(is_whitespace);
    if (input.eof()) return null;
    var ch = input.peek();
    if (ch == "«") {  // left pointing double angle quotation mark
      skip_comment();
      return read_next();
    }
    if (ch == '"') return read_string();
    //if (ch == '.') return read_member_or_method();
    if (is_digit(ch)) return read_number();
    if (is_id_start(ch)) return read_ident(); // TODO optimize for everyone|me|my.rel(mother)|etc
    if (is_atuser_start(ch)) return read_atuser();
    if (is_punc(ch)) return {
      type  : "punc",
      value : input.next()
    };
    if (is_op_char(ch)) return {
      type  : "op",
      value : read_while(is_op_char)
    };
    input.croak("Can't handle character: «"+ch+"»");
  }
  function peek() {
    return current || (current = read_next());
  }
  function next() {
    var tok = current;
    current = null;
    return tok || read_next();
  }
  function eof() {
    return peek() == null;
  }
};

var FALSE = { type: "bool", value: false };
function parse(input) {
  // based on http://lisperator.net/pltut/parser/the-parser
  var PRECEDENCE = {
    // NONE OF THIS IS IN USE in DiscirminatorReader
    "=": 1,
    "||": 2,
    "&&": 3,
    "<": 7, ">": 7, "<=": 7, ">=": 7, "==": 7, "!=": 7,
    "+": 10, "-": 10,
    "*": 20, "/": 20, "%": 20,
  };
  return parse_toplevel();
  function is_punc(ch) {
    var tok = input.peek();
    return tok && tok.type == "punc" && (!ch || tok.value == ch) && tok;
  }
  function is_kw(kw) {
    var tok = input.peek();
    return tok && tok.type == "kw" && (!kw || tok.value == kw) && tok;
  }
  function is_op(op) {
    var tok = input.peek();
    return tok && tok.type == "op" && (!op || tok.value == op) && tok;
  }
  function skip_punc(ch) {
    if (is_punc(ch)) input.next();
    else input.croak("Expecting punctuation: \"" + ch + "\"");
  }
  function skip_kw(kw) {
    if (is_kw(kw)) input.next();
    else input.croak("Expecting keyword: \"" + kw + "\"");
  }
  function skip_op(op) {
    if (is_op(op)) input.next();
    else input.croak("Expecting operator: \"" + op + "\"");
  }
  function unexpected() {
    input.croak("Unexpected token: " + JSON.stringify(input.peek()));
  }
  function maybe_binary(left, my_prec) {
    var tok = is_op();
    if (tok) {
      var his_prec = PRECEDENCE[tok.value];
      if (his_prec > my_prec) {
	input.next();
	return maybe_binary({
	  type     : tok.value == "=" ? "assign" : "binary",
	  operator : tok.value,
	  left     : left,
	  right    : maybe_binary(parse_atom(), his_prec)
	}, my_prec);
      }
    }
    return left;
  }
  function delimited(start, stop, separator, parser, interveners) {
    var a = [], first = true, v, intervened, current;
    interveners = interveners ? interveners : [] ;
    skip_punc(start);
    while (!input.eof()) {
      if (is_punc(stop)) break;
      if (first) {
	first = false;
      } else {
	intervened = false;
	current = input.peek();
	for (var i = 0; i < interveners.length; i++) {
	  v = interveners[i];
	  if (v == current.value) {
	    intervened = v;
	    break;
	  }
	}
	if (intervened) {
	  skip_punc(intervened);
	  a.push(current);
	} else {
	  skip_punc(separator);
	}
      }
      if (is_punc(stop)) break;
      a.push(parser());
    }
    skip_punc(stop);
    return a;
  }
  function parse_call(func) {
    return {
      type: "call",
      func: func,
      args: delimited("(", ")", ",", parse_expression, ['|','~']),
    };
  }
  function parse_method_call(func) {
    return {
      type: "method",
      func: func,
      args: delimited("(", ")", ",", parse_expression),
    };
  }
  function parse_aggregator(func) {
    return {
      type: "aggr",
      func: func,
      args: delimited("(",")",",", parse_aggregator),
    }
  }
  function parse_varname() {
    var name = input.next();
    if (name.type != "var") input.croak("Expecting variable name");
    return name.value;
  }
  function parse_if() {
    skip_kw("if");
    var cond = parse_expression();
    if (!is_punc("{")) skip_kw("then");
    var then = parse_expression();
    var ret = {
      type: "if",
      cond: cond,
      then: then,
    };
    if (is_kw("else")) {
      input.next();
      ret.else = parse_expression();
    }
    return ret;
  }
  function parse_lambda() {
    return {
      type: "lambda",
      vars: delimited("(", ")", ",", parse_varname),
      body: parse_expression()
    };
  }
  function parse_bool() {
    return {
      type  : "bool",
      value : input.next().value == "true"
    };
  }
  function maybe_userspec(expr) {
    //expr = expr();
    return is_punc("@") ? parse_userid(expr) : expr;
  }
  function maybe_call(expr) {
    expr = expr();
    return is_punc("(") ? parse_call(expr) : expr;
  }
  function maybe_method(expr) {
    return is_punc("(") ? parse_method_call(expr) : expr;
  }
  function maybe_aggregator(expr) {
    //console.log("maybe_aggregator() expr: ", expr);
    //expr = expr();
    return is_punc("(") ? parse_aggregator(expr) : expr;
  }
  function maybe_pred_reducer(expr) {
    expr = expr();
    return is_punc("(") ? parse_pred_reducer(expr) : expr;
  }
  function parse_atom() {
    return maybe_call(function(){
      if (is_punc("(")) {
	input.next();
	var exp = parse_expression();
	skip_punc(")");
	return exp;
      }
      if (is_punc('.'))
	return parse_member();

      /*
	if (is_punc("{")) return parse_prog();
	if (is_kw("if")) return parse_if();
	if (is_kw("true") || is_kw("false")) return parse_bool();
	if (is_kw("lambda") || is_kw("λ")) {
	input.next();
	return parse_lambda();
	}
      */
      var tok = input.next();
      if (tok.type == "var" || tok.type == "num" || tok.type == "str" ||
	  tok.type == 'atuser' || tok.type == 'genuser' )
	return tok;
      unexpected();
    });
  }
  function parse_toplevel() {  // TODO rename 'prog' to 'discriminator'
    // Remember:
    //   A Discriminator is a ;-delimited list of PredicateReducers
    //      max(latest(everybody)).re(bucks); latest(me)
    //   A PredicateReducer is a DataReducer with an optional .re() appended
    //      max(latest(everybody)).re(bucks)
    //   A DataReducer is a set of nested aggregation functions, ultimately
    //   containing a WhoToHeed spec.
    //      max(latest(@bob,@alice) OR earliest(everybody))
    //      TODO this is not yet fully clearly defined!!!!
    var prog = [];
    var glimpse;
    var method;
    var pred_reducer;
    while (!input.eof()) {
      //prog.push(parse_expression());
      //console.log("PEEK:", input.peek());
      pred_reducer = parse_pred_reducer();
      glimpse = input.peek();
      if (glimpse && glimpse.value == '.') {
	skip_punc('.');
	method = parse_method();
        if (method.name) {
	  pred_reducer.method = method;
	}
      }
      prog.push(pred_reducer);
      if (!input.eof()) skip_punc(";");
    }
    return { type: "prog", prog: prog };
  }
  function parse_criterion() {
    var criterion = input.next();
    if (criterion.type != "var") input.croak("Expecting criterion QName");
    return criterion.value;
  }
  function parse_method() {
    var method = input.next();
    var criteria = delimited("(", ")", ",", parse_criterion);
    return {
      type : "method",
      name : method.value,
      args : criteria
    };
  }
  function parse_prog() {
    var prog = delimited("{", "}", ";", parse_expression);
    if (prog.length == 0) return FALSE;
    if (prog.length == 1) return prog[0];
    return { type: "prog", prog: prog };
  }
  function parse_expression() { // TODO remove???
    return maybe_call(function(){
      return maybe_binary(parse_atom(), 0);
    });
  }
  function parse_pred_reducer() {
    /*
      A PredicateReducer is an Aggregator with optional .re(..) appended
    */
    return maybe_pred_reducer(function() {
      return maybe_userspec(parse_atom(), 0);
    });
  }
  function parse_aggregator() {
    return maybe_aggregator(function() {
      return maybe_userspec(parse_atom(), 0);
    });
  }
}

export class DiscriminatorReader {
  constructor(a_discriminator) {
    this.discriminator = a_discriminator;
    this.ast = parse(TokenStream(InputStream(a_discriminator)))
  }
}
