import {ActionKnob} from './actionknob.js';

export class DCIKnob extends ActionKnob {
  static initClass() {
    this.prototype.function_spec = {
      name: 'dcimenu',
      g: 'nrn:NooronActions'
    };
  }
  make_panel() {
    super.make_panel(...arguments);
    this.elem.hide();
    this.watch_for(':visible', null, () => this.reveal());
  }
}
DCIKnob.initClass();
