/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// # Docs
//
//  DataReducers:
//    Min(@alice)
//      the minimum value from @alice
//      etc: Min|Max|Average|Earliest|Latest
//
//    Latest(everyone)
//      the most recent expressed value from anyone
//
//    Min(@alice) or Max(everyone)
//      The lowest value alice has ever expressed or the highest value anyone else has.
//
//    Min(@alice,@bob) or Min(@carol) or Max(everyone)
//      The lowest value either alice or bob have expressed
//        or the lowest value carol has expressed
//        or the highest value anyone else has expressed.
//
//    Min( Latest(@alice,@bob) or Latest(@carol) )
//      The lesser of either alice or bob's most recently asserted value
//      OR the most recently asserted value by carol.
//
//    Avg( Min( Latest(@alice or @bob)) or Max( Earliest(@carol or @dan) ) )
//      We consider the latest value from alice and the latest from bob.
//      We consider the earliest from carol and the earliest from dan.
//      Return the average of
//        the minimum of the latest from alice OR the latest from bob
//        and the maximum of the latest from carol OR the latest from dan.
//
//      Notice that this is as deep as a DataReducer can get.
//      Why?
//      Alice and Bob are an "equivilence class" -- meaning that evaluations
//      from either of them are treated as being interchangeable.
//
//    Avg( 6*Min(@alice) or 4*Min(@bob) or Min(@carol) )
//    Avg( Min(6*@alice or 6*@bob or @carol) )
//      Return a value which weighs
//        the minimum value from alice as 5 times
//          the minimum value from bob which is 3 times
//             the minimum value from carol as the unit value.
//      eg
//        Min(@alice) is 10
//        Min(@bob) is 20
//        Min(@carol) is 25
//        Return (6*10 + 4*20 + 25)/(6+4+1) ==> (60 + 80 + 25) / 11 ==> 15
//
//        Q1. Or is each tier (equivalence class) N-times greater than the next?
//            return (6*4*10 + 4*20 + 25)/((6*4)+4+1) ==> (240 + 80 + 25)/29 => 345/29 => 11.89
//            The advantage of this approach is that one can keep adding equivalence classes
//            and not have to 'rebalance the weightings'.  Rather, the spirit of successively
//            dominating equivalenct classes seems to be more along the lines of multiplicative
//            weighting than additive weighting -- if that makes any sense.
//
//    Latest(Min(@alice,@bob or @carol))  # 'or' replaces '>' to mean "dominates"
//
//    Latest(Min(@alice,@bob,@carol) or Max(everyone))

// # Observations
//
// 1. If there is only one person mentioned then there is only need for one aggregation function.
// 2. If there is more than one person (explicitly or implicitly, ie 'everyone' counts as more than one)
//       but they all occupy the same equivalence class
//       then there is a need for two and only two aggregation functions
//        -- one to apply to each individual
//           and one to apply across the participating individuals.
// 3. If there are multiple equivalence classes
//       then there is a need for an aggregation function across them
//       and a need for aggregation functions within them.
//
//
//
// # Historical
//
// A DataReduction is a 'reduced data set' after WhoToHeed and Aggregation and Weighting.
//
// Another way to think of this name is the product of "data reduction" process.
// Perhaps it should be called KnowledgeReduction.
// What Nooron needs is a StreamKnowledgeReducer which processes a stream of spogi
// and produces a changing "reduced value".
//
//   * WhoToHeed()
//     eg: me, 'betty', 'bob' > 'alice', 'cathy' > everyone - 'goofy'
//
//     Where each set of sources delimited by a > is an "equivalency class"
//     meaning that if an allegation is provided by anybody in that set
//     (eg "'alice', 'cathy'") then they dominate any "lesser" sources but because
//     they are equivalent should have their alleged values aggregated
//
//   * Aggregation()
//
//     Aggregation techniques are either Electors or Digestors.
//       Electors elect one of the things which go in as the source of the output value.
//         suitable for: both object-values and literal-values
//         eg: First(), Latest(), Mode()
//       Digestors digest all of the things which go in to produce an output value.
//         suitable for: a subset of literal-values
//         They are suitable for numeric/linear/sortable data like numbers and datetimes.
//         eg: Average() [aka Mean()], Max(), Min()
//         problems: some literals can not be meaningfully Averaged, eg strings
//
//     Problem: all of the examples above retain the same range and units but Sum()
//       and other aggregation functions like StdDev(), Variance() have different units
//       and ranges, so though they are required perhaps those are best dealt with by
//       "Functional Predicates" (aka "Computed Columns").
//
//   * Weighting()
//      Weight([5,2,1] # a list of vote multipliers for the equivalency classes
//      eg: "me/betty/bob allegations are worth 5x, alice/cathy are 2x from everyone"
//     Problem: there are plausibly multiple algorithms
//     Priority: Lower than simple Aggregation and even "Computed Columns"
//
//    There are various terms for different kinds of data that might be aggregated.
//     * (n)scalar http://goo.gl/rgMpvY
//          a variable quantity which cannot be resolved into components
//     * (n)compound http://goo.gl/mysg5F
//          a whole formed by a union of two or more elements or parts
//     * (adj) linear
//     * (adj) numeric
//     * (adj) non-numeric
//     * (adj) sortable
//
// Problems:
//   * This job is not done until it is possible to have a single DataReducer be shared
//     across the set of criteria which are using it, so a single instance can serve
//     an entire visualization which just happens to use the same DataReducer for all
//     "criteria"
//   Q. How do we prevent "stuffing the ballot box" intentionally or otherwise?
//     For example what if some person has provided multiple allegations through
//     time -- either because their opinion has changed for some reason or they are
//     attempting to game the system by "stuffing the ballot box" with either the
//     same equivalent values?
//   A. We could have two kinds of aggregation function:
//     * within_person_aggregator (WPA): for all the allegations from one person
//     * equivalent_person_aggregator (EPA): after the WPA across an equivalency class
//     * across_class_aggregator (ACA): after the EPA across equivalency classes
//     This strategy clarifies that the simple "Weighted()" strategy above is really
//     just one of many possible ACAs.

import {DiscriminatorReader} from './discriminator.js';
let ACA, UNICODE_REGEXP, WCA;

// set a flag to indicate support for ES6-style Unicode support in RegExp
try { new RegExp('','u') && (UNICODE_REGEXP = true); } catch (e) { UNICODE_REGEXP = false; }

const JSON_pps = obj => JSON.stringify(obj, null, 4);
const JSON_pp = obj => console.log(JSON_pps(obj));

// equiv is an integer indicating which "equivalence class" the
//   author of the spogi was found to be in.  If equiv is 0 that means
//   they were either explicitly ignored (eg " - 'bob'") or not included
//   (for example by there being no everyone term).
//   Examples:
//      WhoToHeed("'alice' > 'bob' > everyone - 'cathy'").heed('cathy') ==> 0
//      WhoToHeed("'alice' > 'bob' > everyone - 'cathy'").heed('alice') ==> 1
//      WhoToHeed("'alice' > 'bob' > everyone - 'cathy'").heed('bob') ==> 2
//      WhoToHeed("'alice' > 'bob' > everyone - 'cathy'").heed('newton') ==> 3
//      WhoToHeed("'alice' > 'bob'").heed('cathy') ==> 0

const GENERIC = {};

// Provide a way to override the per-type (WPA|WCA|ACA) versions of Aggregator classes
const WPA = (WCA = (ACA = GENERIC));

export class DataReducer {
  static initClass() {
    this.docs = "A DataReducer generically works across subjects and/or predicates";
  
    // See http://shapecatcher.com/ to find unicode by drawing them.
    //     http://shapecatcher.com/unicode/block/Arrows
    this.prototype.symbol_to_name = {
      "↡": {
        name: "Min",
        hex: '21A1'
      },
      "↟": {
        name: "Max",
        hex: '219F'
      },
      "⏦": {
        name: "Average",
        hex: '23E6'
      },
      "↺": {
        name: "Earliest",
        hex: '21BA'
      },
      "↻": {
        name: "Latest",
        hex: '21BB'
      },
      "∀": {
        name: "Earliest",
        hex: '2200'
      }
    };
  
    this.prototype.support_symbolic_aggr_names = UNICODE_REGEXP;
  
    this.prototype.eg_src = "avg(max(latest(@alice|everyone~@bob)),earliest(me)).re(naughty,nice)";
    this.prototype.eg_json = [];
  }

  constructor(dr_spec_or_ast, me_is) {
    //dr_spec_or_ast ?= "Latest(Latest(Latest(everyone)))"
    this.me_is = me_is;
    if (typeof dr_spec_or_ast === 'string') {
      this.dd_spec = dr_spec_or_ast;
      this.dd_ast = new (DiscriminatorReader(dr_spec_or_ast).ast.prog[0]);
    } else {
      if (typeof dr_spec_or_ast === 'object') {
        this.dd_ast = dr_spec_or_ast;
      } else {
        throw new Error("new DataReducer() was passed neither a string nor an AST");
      }
    }
    this.build_from_ast(this.dd_ast);
  }

  build_from_ast(ast) {
    if (!(ast.type === 'call')) {
      console.log("ast:",JSON_pps(ast));
      throw new Error("build_from_ast() did not receive a well-formed Discriminator");
    }

    this.u_to_aggregator = {};
    this.root = {};
    this.make_branches(this.root, ast);

    if (ast.method != null) {
      this.re_predicates = ast.method.args.slice(); // shallow copy
    } else {
      this.re_predicates = [];
    }
  }
      //@re_predicates = [true]

  make_branches(parent, ast) {
    switch (ast.type) {
      case 'call':
        var this_node = {
          fn_name: ast.func.value,
          up: (((typeof window === 'undefined' || window === null)) && 'parent') || parent // to avoid circularity for JSON.stringify
        };
        if ((parent.kids == null)) {
          parent.kids = [];
        }
        parent.kids.push(this_node);
        return (() => {
          const result = [];
          for (let arg of Array.from(ast.args)) {
            if (['atuser','genuser'].includes(arg.type)) {
              this_node.heed = ast.args; // bail and send whole heed AST
              break;
            }
            result.push(this.make_branches(this_node, arg));
          }
          return result;
        })();
    }
  }
      // when 'atuser'
      //   user = arg.value
      //   @u_to_aggregator[user] = parent
      // when 'genuser'
      //   switch ast.value
      //     when 'everyone'
      //       @u_to_aggregator[EVERYONE] = parent
      //     when 'others'
      //       @u_to_aggregator[OTHERS] = parent
      //     when 'me'
      //       @u_to_aggregator[ME] = parent

  spawn(s_p_key) {
    return new DataReduction(this);
  }
}
DataReducer.initClass(); // see docs/branched_and_complex_discriminator.json

export class GenericAggregator {
  constructor() {
    this.members = {};
    this.value = null;
  }
  init_reduction() {
    console.log(this.constructor.name,"init_reduction() should be removed");
  }
  init_member(member_id) {
    return this.members[member_id] =
      {id: member_id};
  }
  ensure_member(member_id) {
    //console.log "ensuring member", member_id
    return this.members[member_id] || this.init_member(member_id);
  }
  cascade(spogi, from_inner) {
    // call the HeedElector and recursively to the final aggregator
    const rollup = this;
    const member = this.consume(spogi, rollup, from_inner);
    if (!member) {
      return member;
    }
    if (this.outer_aggr != null) {
      return this.outer_aggr.cascade(spogi, member);
    }
    return member;
  }

  consume(spogi, rollup, member) {
    // Return either false or the member whose aggregation was affected by the spogi
    return this.caused_promotion(spogi, rollup, member);
  }
  caused_promotion(spogi, rollup, member) {
    if ((rollup.value == null) || this.should_promote(spogi, rollup, member)) {
      this.promote_member(spogi, rollup, member);
      return this;
    }
    return false;
  }
  promote_spogi(spogi, rollup, member) {
    rollup.spogi_prior = rollup.spogi;
    rollup.spogi = member.spogi;
  }
  promote_value(spogi, rollup, member) {
    rollup.value_prior = rollup.value;
    rollup.value = member.value;
  }
  should_promote(spogi, rollup, member) {
    console.log(this.constructor.name,"should_elect() must be implemented");
  }
}

export class DataReduction {
  constructor(data_reducer) {
    // the @members become the equivalency classes
    // the member_id is a 1-based integer index
    this.data_reducer = data_reducer;
    this.user_to_aggr = {};
    this.value = null;
    this.compile(this.data_reducer.root.kids[0], this);
  }

  cascade(spogi, inner_aggr) {
    this.spogi = inner_aggr.spogi;
    this.value = inner_aggr.value;
    return true;
  }
    //return @spogi

  // ReductionDispatcher.compile()
  compile(abstract_branch, outer_aggr) {
    if (abstract_branch.fn_name) {
      const this_aggr = new (GENERIC[abstract_branch.fn_name])();
      if ((outer_aggr != null) && (outer_aggr.constructor.name !== 'DataReducer')) {
        this_aggr.outer_aggr = outer_aggr;
      }
      if (abstract_branch.kids != null) {
        for (let abstract_kid of Array.from(abstract_branch.kids)) {
          this.compile(abstract_kid, this_aggr);
        }
      }
      if (abstract_branch.heed != null) {
        const tiers = WhoToHeed.build_tiers_from_args(
              abstract_branch.heed, this.data_reducer.me_is);
        const he = new HeedElector();
        he.who_to_heed = new WhoToHeed(tiers, this.data_reducer.me_is);
        he.test_for_domination = true;
        he.outer_aggr = this_aggr;
        return (() => {
          const result = [];
          for (let incl_u in tiers.incl_all) { // will be userid OR true (for everyone)
            if ((this.user_to_aggr[incl_u] == null)) {
              this.user_to_aggr[incl_u] = [];
            }
            if (incl_u === true) {
              throw new Error("incl_u should never be true, rather _everyone, _others, _notme or @WHATEVER");
            }
            result.push(this.user_to_aggr[incl_u].push(he));
          }
          return result;
        })();
      }
    }
  }

  get_aggregators_for_user(u_key) {
    let retlist = []. // TODO handle _other correctly
      concat(this.user_to_aggr[u_key] || []).
      concat(this.user_to_aggr[EVERYONE] || []);
    //return retlist if false
    const {
      me_is
    } = this.data_reducer;
    if ((me_is == null)) {
      return [];
    }
      //throw new Error("me_is should be defined")
    if ((this.user_to_aggr[u_key] == null)) { // ie u_key is not mentioned explicitly
      retlist = retlist.concat(this.user_to_aggr[OTHERS] || []);
    }
    if (u_key !== me_is) { // if u_key isnt the current user they are OTHERS
      retlist = retlist.concat(this.user_to_aggr[NOTME] || []);
    }
    return Array.from(new Set(retlist)); // uniqueify
  }

  consume_spogi(spogi) {
    const u_key = spogi.ww().user_symbol;
    // Iterate through the possibly multiple entry points in the aggregation
    // tree matching u_key (via explicit include, everyone, me or others)
    let last_truthy_result = false;
    const iterable = this.get_aggregators_for_user(u_key);
    for (let idx = 0; idx < iterable.length; idx++) {
      const aggr = iterable[idx];
      const spogi_or_false = aggr.cascade(spogi);
      if (spogi_or_false) {
        last_truthy_result = spogi_or_false;
      }
    }
    // return false OR the LAST truthy_result because it accumulates all changes
    return last_truthy_result;
  }
}

export class HeedElector extends GenericAggregator { // TODO rename to ReductionDispatcher
  // Expose the GenericAggregator interface but just process WhoToHeed
  // In other words, this is the innermost aggregation method and its
  // sole purpose is to determine whether the spogi should even be processed
  // based on WhoToHeed logic.
  //
  // Actually, "sole purpose" is wrong.
  // If ACA is happening then the a check for whether equiv_class_index1
  // is_dominated by a prior value should occur.
  // If ACA IS NOT happening then there is no need for is_dominated testing.

  consume(spogi, rollup_IGNORED, member_IGNORED) {
    // rollup and member are ignored because this is the first consume() called
    const usym = spogi.ww().user_symbol;
    if (!usym) {
      throw new Error("expect user_symbol to be truthy", usym);
    }
    const equiv_class_index1 = this.who_to_heed.heed(usym);
    if ((equiv_class_index1 === 0) || this.is_dominated(equiv_class_index1)) {
      return false;
    }
    const member = this.ensure_member(equiv_class_index1);
    member.spogi = spogi;
    member.value = spogi.o.getNativeValue();
    //member.outer_aggr = @outer_aggr
    //@value = member.value
    //@spogi = spogi
    return member;
  }

  is_dominated(equiv_class_index1) {
    if (!this.test_for_domination) {
      return false;
    }
    let by_class = equiv_class_index1 - 1;
    while (by_class > 0) {
      if (this.members[by_class] != null) {
        return true;
      }
      by_class = by_class - 1;
    }
    return false;
  }
}

export class GenericElector extends GenericAggregator {
  promote_member(spogi, rollup, member) {
    this.promote_spogi(spogi, rollup, member);
    this.promote_value(spogi, rollup, member);
  }
}

export class GenericDigestor extends GenericAggregator {
  promote_member(spogi, rollup, member) {
    this.promote_value(spogi, rollup, member);
  }
}

GENERIC.Latest = class Latest extends GenericElector {
  should_promote(spogi, rollup, member) {
    return rollup.spogi.ww().cmp(member.spogi.ww()) < 0;
  }
};
GENERIC['↻'] = GENERIC.Latest;

GENERIC.Earliest = class Earliest extends GenericElector {
  should_promote(spogi, rollup, member) {
    return rollup.spogi.ww().cmp(member.spogi.ww()) > 0;
  }
};
GENERIC['↺'] = GENERIC.Earliest;

GENERIC.Max = class Max extends GenericDigestor { // TODO make this an Elector or document why not
  should_promote(spogi, rollup, member) {
    return rollup.value < member.value;
  }
};
GENERIC['↟'] = GENERIC.Max;

GENERIC.Min = class Min extends GenericDigestor { // TODO make this an Elector or document why not
  should_promote(spogi, rollup, member) {
    //debugger
    return rollup.value > member.value;
  }
};
GENERIC['↡'] = GENERIC.Min;

GENERIC.Every = class Every extends GenericElector {
  should_promote(spogi, rollup, member) {
    return true;
  }
};
GENERIC['∀'] = GENERIC.Every;

GENERIC.Average = class Average extends GenericDigestor {
  should_promote(spogi, rollup, member) {
    return true;
  }
  promote_value(spogi, rollup, member) {
    rollup.value_prior = rollup.value || 0;
    if ((rollup.count == null)) {
      rollup.count = 0;
    }
    const count_prior = rollup.count;
    rollup.count = rollup.count + 1;
    rollup.value = ((count_prior * rollup.value_prior) + member.value) / rollup.count;
  }
};

// TODO get Distinct working
//class GenericAccumulator extends GenericAggregator
//  @docs = "An Accumulator 'collects' the members into a Set"
//
//class GENERIC.Distinct extends GenericAccumulator
//  @docs = "Superset 'collects' all the distinct values"
//  should_promote: (spogi, rollup, member) ->
//    return true
//  promote_value: (spogi, rollup, member) ->


// WhoToHeed() use cases:
//   me
//     Just heed me
//   me, personA
//     Just heed me and personA
//   me > personA
//     Heed me over personA (ie my evaluations instead of theirs)
//   (me, personA) > personB
//     Heed me and personA over personB
//   me > (personA, personB)
//     Heed me over personA and personB
//   me > (personA, personB) > everyone
//     Heed me over personA and personB and them over everyone else
//   me > everyone > (personA, personB)
//     Heed me over everyone and everyone over personA and personB
//   me > everyone
//     Heed me over everyone
//   everyone
//     Heed everyone
//   everyone - personA
//     Heed everyone except personA
//   everyone - (personA, personB)
//     Heed everyone except personA and personB
//   me > (tom, bob) > (everyone - (personA, personB)) > personC > personA - personB
// Problems:
//   How to represent everyone?
//     1
//   How to represent exclusion?
//       who_to_heed:
//         include: true # everyone
//         exclude: new Set(['tom'])
//         chain:
//           include: new Set(['tom'])
//           exclude: new Set(['bob'])
//       # Notes:
//       #   if chain then exclude membership does not mean reject, yet
//   How to represent named groups of people? (the admins, my friends, black hats)
//   How to represent people playing roles? (owner, member, creator, admin)

var EVERYONE = '_everyone';
var OTHERS = '_others';
var NOTME = '_notme';
const ME = '_me';

export class WhoToHeed {
  static build_tiers_from_args(args_ast, me_is) {
    // Build a tree structure with nodes like:
    //   node:
    //     include: [true] # or [alice, bob, ladygaga]
    //     exclude: [alice, bob, ladygaga]
    //     chain:   # another node

    const tiers = {
      incl_all:{},
      excl_all:{}
    };
    let current_tier = tiers;
    for (let arg of Array.from(args_ast)) {
      var incl_or_excl, incl_or_excl_all;
      if ((current_tier.include == null)) {
        current_tier.include = [];
        incl_or_excl = current_tier.include;
        incl_or_excl_all = tiers.incl_all;
      }
      if (['atuser','genuser'].includes(arg.type)) {
        var val_to_push;
        if (arg.type === 'atuser') {
          val_to_push = arg.value;
        } else { // arg.type is 'genuser' ie everyone|me|others|notme
          if (arg.value === 'me') {
            val_to_push = me_is;
          } else {
            val_to_push = '_' + arg.value;
          }
        }
        incl_or_excl.push(val_to_push);
        incl_or_excl_all[val_to_push] = true; //current_tier
      }
      if (arg.type === 'punc') {
        if (arg.value === '~') {
          if ((current_tier.exclude == null)) {
            current_tier.exclude = [];
          }
          incl_or_excl = current_tier.exclude;
          incl_or_excl_all = tiers.excl_all;
        }
        if (arg.value === '|') {
          current_tier.chain = {};
          current_tier = current_tier.chain;
        }
      }
    }
    return tiers;
  }

  constructor(tier, me_or_top) {
    // Pass me_is as the second argument to the top of a WhoToHeed tree
    // otherwise pass the top as the second argument.
    if (typeof me_or_top === 'string') {
      // this IS the top of a WhoToHeed tree
      this.me_is = me_or_top;
      this.top = this;
      this.tier = tier;
    } else {
      this.top = me_or_top;
    }
    if (tier === 'everyone') {
      tier = 1;
    }
    if (tier == null) { tier = 0; } // default to heeding no one if nothing is said to the contrary
    if (typeof tier === 'number') {
      this.force = tier;
      return;
    }
    if (tier.exclude != null) {
      this.exclude = new Set(tier.exclude);
    }
    if (tier.include != null) {
      this.include = new Set(tier.include);
    }
    if (tier.chain != null) {
      this.chain = new WhoToHeed(tier.chain, this.top);
    }
  }

  heed(who, otherwise, equiv) {
    // Return either 0 or the 1-based index of the "Heeding equivalency class"
    if (this.force != null) {
      return this.force;
    }
    if (equiv != null) {
      equiv = equiv + 1;
    } else {
      equiv = 1;
    }
    const {
      me_is
    } = this.top;
    if ((me_is == null)) {
      throw new Error("@top.me_is must be defined");
    }
    if (this.exclude != null) {
      if (this.exclude.has(true)) { // true means EVERYONE
        throw new Error("user refs should never be true, rather _everyone, _others, _notme");
      }
      if (this.exclude.has(who)) {
        if (this.chain != null) {
          return this.chain.heed(who, 0, equiv);
        } else {
          return 0;
        }
      }
      if (this.exclude.has(OTHERS) &&
          (!this.top.tier.excl_all[me_is]) && (!this.top.tier.incl_all[me_is])) {
        return 0;
      }
    }
    if (this.include != null) {
      if (this.include.has(who)) { // explicit inclusion dominates chained exclusion
        return equiv;
      }
      if (this.include.has(true)) { // true means EVERYONE
        throw new Error("user refs should never be true, rather _everyone, _others, _notme or @WHATEVER!!!");
      }
      if (this.include.has(EVERYONE)) {
        otherwise = equiv;
      }
      // if not @me_is?
      //   throw new Error("@me_is must be defined")
      // else
      //   console.log "me_is:", @me_is
      if ((who !== me_is) && this.include.has(NOTME)) {
        return equiv;
      }
      if (this.include.has(OTHERS) &&
          (!this.top.tier.excl_all[me_is]) && (!this.top.tier.incl_all[me_is])) {
        otherwise = equiv;
      }
    }
    if (this.chain != null) {
      return this.chain.heed(who, otherwise, equiv);
    }
    if (otherwise != null) {
      return otherwise;
    }
    return equiv;
  }
}

const expect = val => ({
  to: {
    equal(check, msg) {
      if (check !== val) {
        return console.log(`expected ${val} to equal ${check} ${msg || ''}`);
      } else {
        return console.log(".");
      }
    }
  }
});

const DataReduction_suite = function(noodb) {
  let log;
  if ((noodb == null)) {
    const {
      NooDB
    } = require((((typeof window === 'undefined' || window === null) && '../lib/') || '') + 'noodb');
    const Log = require("log");
    log = new Log('ERROR');
    const ARGS = {verbosity: 0, log};
    noodb = new NooDB("test/test_noodb.nq", "http://example.com/", ARGS);
  }

  window.IN_JIG = true;

  let jig = new ReductionJig(noodb, "Latest(everyone)", 'bob');
  let redox = jig.reduction;
  const aggr_chains = redox.get_aggregators_for_user('ann');
  expect(jig.do({s:'_:gilligan', p:'_:iq', o:75, g:'_:minnow'})).to.equal(75);
  expect(jig.do({o:76})).to.equal(76);
  expect(jig.do({o:77})).to.equal(77);

  jig = new ReductionJig(noodb, "Min(Latest(everyone))", 'bob');
  expect(jig.do({s:'_:gilligan', p:'_:iq', o:75, g:'_:minnow'})).to.equal(75);
  expect(jig.do({o:76})).to.equal(75);
  expect(jig.do({o:74})).to.equal(74);

  jig = new ReductionJig(noodb, "Average(Latest(everyone))", 'bob');
  redox = jig.reduction;
  const heed_elector = redox.get_aggregators_for_user('ann')[0];
  expect(heed_elector.outer_aggr.constructor.name).to.equal('Latest');
  expect(heed_elector.outer_aggr.outer_aggr.constructor.name).to.equal('Average');
  expect(jig.do({s:'_:ginger', p:'_:iq', o:120, g:'_:minnow'})).to.equal(120);
  expect(jig.do({o:60})).to.equal((120+60)/2);        // 180/2 => 90
  expect(jig.do({o:180})).to.equal((120+60+180)/3);   // 360/3 => 120
  expect(jig.do({o:40})).to.equal((120+60+180+40)/4); // 400/4 => 100

  console.log("SUCCESS");
  expect(1).to.equal(2, 'PROOF THAT THIS GHETTO expect() WORKS');
};

const Discriminator_suite = function(noodb) {
  let first_spogi, initial_spogi, jig, log, xtnd;
  console.clear();
  if ((noodb == null)) {
    const {
      NooDB
    } = require((((typeof window === 'undefined' || window === null) && '../lib/') || '') + 'noodb');
    const Log = require("log");
    log = new Log('ERROR');
    const ARGS = {verbosity: 0, log};
    noodb = new NooDB("test/test_noodb.nq", "http://example.com/", ARGS);
  }

  const alice = {user_symbol: 'alice', session_no: 1};
  const bob = {user_symbol: 'bob', session_no: 1};
  const carol = {user_symbol: 'carol', session_no: 1};
  const dan = {user_symbol: 'dan', session_no: 1};
  const eve = {user_symbol: 'eve', session_no: 1};
  const fred = {user_symbol: 'fred', session_no: 1};

  window.IN_JIG = false;

  if (true) {
    jig = new DiscriminatorJig(noodb, "Every(Latest(Every(@alice,@bob|@carol,@dan)))", 'bob');
    initial_spogi = {s:'_:gilligan', p:'iq', o:75, g:'_:minnow', who:fred};
    xtnd = _.extend;
    expect(jig.disc(initial_spogi)).to.equal(null);
    expect(jig.disc({o:120, who:dan})).to.equal(120);
    expect(jig.disc({o:130, who:bob})).to.equal(130);
    expect(jig.disc({o:120, who:carol})).to.equal(null, "should be dominated by bob");
    expect(jig.disc({o:140, who:alice})).to.equal(140, "alice should replace bob");
  }

  if (true) {
    jig = new DiscriminatorJig(noodb, "Min(Latest(me),Latest(others))", 'bob');
    initial_spogi = {s:'_:gilligan', p:'iq', o:75, g:'_:minnow', who:alice};
    xtnd = _.extend;
    expect(jig.disc(initial_spogi)).to.equal(75, "alice's value");
    expect(jig.disc(xtnd(initial_spogi,{o:74, who:bob}))).to.equal(74,
      "bob's latest is lower");
    expect(jig.disc(xtnd(initial_spogi,{o:76, who:bob}))).to.equal(null,
      "bob's latest is more than alice's so show hers");
  }

  if (true) {
    jig = new ReductionJig(noodb, "Latest(notme)", 'bob');
    first_spogi = {s:'_:doc', p:'_:iq', o:140, g:'_:minnow', who:bob};
    expect(jig.do(first_spogi)).to.equal(null);
    expect(jig.do({o:120, who:carol})).to.equal(120);
    expect(jig.do({o:145, who:bob})).to.equal(120);
    expect(jig.do({o:135, who:alice})).to.equal(135);
  }

  if (true) {
    jig = new ReductionJig(noodb, "Latest(everyone~@alice)", 'bob');
    first_spogi = {s:'_:doc', p:'_:iq', o:140, g:'_:minnow', who:bob};
    expect(jig.do(first_spogi)).to.equal(140);
    expect(jig.do({o:120, who:carol})).to.equal(120);
    expect(jig.do({o:145, who:dan})).to.equal(145);
    expect(jig.do({o:135, who:alice})).to.equal(145);
  }

  if (true) {
    jig = new DiscriminatorJig(noodb, "Latest(everyone).re(iq)", 'bob');
    expect(jig.disc({s:'_:gilligan', p:'iq', o:75, g:'_:minnow'})).to.equal(75);
    expect(jig.disc({o:76})).to.equal(76);
    expect(jig.disc({o:77})).to.equal(77);
    expect(jig.disc({p:'lb', o:98})).to.equal(null);
  }

  if (true) {
    jig = new DiscriminatorJig(noodb, "Average(Latest(everyone))", 'bob');
    const gilligan_iq = {s:'_:gilligan', p:'iq', o:120, g:'_:minnow'};
    const skipper_iq = {s:'_:skipper', p:'iq', o:150, g:'_:minnow'};
    xtnd = _.extendOwn;
    expect(jig.disc(gilligan_iq)).to.equal(120);
    expect(jig.disc(skipper_iq)).to.equal(150);
    expect(jig.disc(xtnd(gilligan_iq,{o:60}))).to.equal((120+60)/2); // 90
    expect(jig.disc(xtnd(skipper_iq,{o:50}))).to.equal((150+50)/2); // 100
    expect(jig.disc(xtnd(gilligan_iq,{o:180}))).to.equal((120+60+180)/3); // 120
    expect(jig.disc(xtnd(skipper_iq,{o:130}))).to.equal((150+50+130)/3); // 110
    expect(jig.disc(xtnd(gilligan_iq,{o:40}))).to.equal((120+60+180+40)/4); // 100
    expect(jig.disc(xtnd(skipper_iq,{o:82}))).to.equal((150+50+130+82)/4); // 103 = 412/
  }

  console.log("SUCCESS");
  expect(1).to.equal(2, 'PROOF THAT THIS GHETTO expect() WORKS');
};

const WhoToHeed_suite = function() { // same as in test/test_noodb.coffee but here for debugging
  const no_one = new WhoToHeed();
  expect(no_one.heed('bob')).to.equal(0);

  const everyone = new WhoToHeed({
    include: [true]});
  expect(everyone.heed('bob')).to.equal(1);

  const force_everyone = new WhoToHeed(1);
  expect(force_everyone.heed('bob')).to.equal(1);

  const force_nobody = new WhoToHeed(0);
  expect(force_nobody.heed('bob')).to.equal(0);

  const not_bob = new WhoToHeed({
    include: [true],
    exclude: ['bob']});
  expect(not_bob.heed('alice')).to.equal(1);
  expect(not_bob.heed('bob')).to.equal(0);

  const not_bert_and_annie_last = new WhoToHeed({
    include: [true],
    exclude: ['bert','annie'],
    chain: {
      include: ['annie']
    }});
  expect(not_bert_and_annie_last.heed('bert')).to.equal(0, "looking for bert");
  expect(not_bert_and_annie_last.heed('annie')).to.equal(2, "looking for annie");

  const everyone_but_bob = new WhoToHeed({
    include: [true],
    chain: {
      exclude: ['bob']
    }});
  expect(everyone_but_bob.heed('bob')).to.equal(0);
  expect(everyone_but_bob.heed('alice')).to.equal(1);

  const msg = "This intentionally broken test shows that expect() works";
  expect(everyone_but_bob.heed('alice')).to.equal(false, msg);
};

//WhoToHeed_suite()

export class Discriminator {
  static initClass() {
    this.docs = "A Discriminator spawns a PredicateReducer per unique Subject-Criterion pairing";
  }

  constructor(discriminator_src, me_is) {
    this.me_is = me_is;
    this.criterion_reducer_mapping = {};
    this.default_reducer = null;
    this.discr_ast = new DiscriminatorReader(discriminator_src);
    this.populate_criterion_reducer_mapping_from_discr_ast();
    this.s_p_to_reduction = {};
  }

  populate_criterion_reducer_mapping_from_discr_ast() {
    this.tree = {};
    if (!((this.discr_ast != null) && this.discr_ast.ast && this.discr_ast.ast.prog)) {
      throw new Error("no Discriminator was parsed");
    }
    const predicate_reducer_ASTs = this.discr_ast.ast.prog;
    const last_data_reducer = predicate_reducer_ASTs[predicate_reducer_ASTs.length - 1];
    return (() => {
      const result = [];
      for (let predicate_reducer_AST of Array.from(predicate_reducer_ASTs)) {
        const new_pred_red = new DataReducer(predicate_reducer_AST, this.me_is);
        for (let p_key of Array.from(new_pred_red.re_predicates)) {
          const old_pred_red = this.criterion_reducer_mapping[p_key];
          this.criterion_reducer_mapping[p_key] = new_pred_red;
        }
        if (new_pred_red.re_predicates.length === 0) {
          result.push(this.default_reducer = new_pred_red);
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  discriminate(spogi, noodb) {
    // returns this spogi OR a synthetic one (if the product of digestion) OR null
    const p_key = spogi.p.key();
    const reduction = this.get_reduction_for_spogi(spogi);
    if ((reduction != null) && reduction.consume_spogi(spogi)) {
      // if it returns null the spogi did not affect the summary
      return this.get_real_or_synthetic(reduction, spogi, noodb);
    }
    return null;
  }

  get_reduction_for_spogi(spogi) {
    // The goal of this method is to return a reduction tuned to
    // the subject, the predicate and the current user (see @me_is)
    //
    // There are many different ways a user (say @bob) can be processed:
    //  1. they are explicitly included in the HeedSpec
    //        @bob
    //  2. the author of the spogi matches 'me'
    //        me
    //  3. implicitly included
    //        everyone
    //  4. implicitly included then explicitly excluded
    //        everyone~@bob
    //  5. included via membership in a group
    //        my.rel('friend')
    //  6. excluded via membership in a group
    //        everyone~my.rel('friend')
    //  7. implicitly included but explicitly included in a lesser equivalency
    //        everyone|@bob
    //
    const s_key = spogi.s.key();
    const p_key = spogi.p.key();
    const s_p_key = s_key + " " + p_key;
    let reduction = this.s_p_to_reduction[s_p_key];
    if (!reduction) {
      const reducer = this.get_reducer_for_p(p_key);
      reduction = ((reducer != null) && reducer.spawn(s_p_key)) || null;
      this.s_p_to_reduction[s_p_key] = reduction;
    }
    return reduction;
  }

  get_reducer_for_p(p_key) {
    const retval = this.criterion_reducer_mapping[p_key];
    if ((retval == null)) {
      //console.log "using @default_reducer for #{p_key}", @default_reducer
      return this.default_reducer;
    }
    return retval || this.default_reducer;
  }

  get_real_or_synthetic(reduction, spogi, noodb) {
    // Return Elected spogis or synthesize a spogi
    let ret_spogi = reduction.spogi; // only produced by GenericElector subclasses
    if ((ret_spogi == null)) {  // so if no ret_spogi let us synthesize one
      const sess = {
        // TODO Examine how to make this a reference to the Discriminator
        //      on the theory that the Discriminator is the "Agent" responsible
        //      for creating the synthetic value.
        user_symbol: 'synthetic',
        session_no: 1  // TODO examine this for improvement
      };
      const s_key = spogi.s.key();
      const p_key = spogi.p.key();
      const g_key = spogi.g.key();
      if (typeof window !== 'undefined' && window !== null) {
        ret_spogi = noodb.allege_local(s_key, p_key, reduction.value, g_key, sess);
      } else {
        ret_spogi = noodb.allege(s_key, p_key, reduction.value, g_key, sess);
      }
    }
    return ret_spogi;
  }
}
Discriminator.initClass();

export class ReductionJig {
  static initClass() {
    this.prototype.doc = ".do() returns the current value of a DataReduction starting with null.  It is assumed that all spogi changes sent to .do() are for the same S_P pair.";
  }

  constructor(noodb, discr_form, me_is) {
    this.noodb = noodb;
    this.discr_form = discr_form;
    this.me_is = me_is;
    this.reducer = new DataReducer(discr_form, this.me_is);
    this.reduction = this.reducer.spawn();
  }

  do(new_args) {
    let a_spogi;
    if (this.args == null) { this.args = {}; }
    for (let k in new_args) {
      const v = new_args[k];
      this.args[k] = v;
    }
    const a = this.args;
    if (typeof window !== 'undefined' && window !== null) { // is this the browser environment? (as opposed to ther server)
      a_spogi = this.noodb.allege_local(a.s, a.p, a.o, a.g, a.who);
    } else {
      a_spogi = this.noodb.allege(a.s, a.p, a.o, a.g, a.who);
    }
    if (!this.reduction.consume_spogi) {
      console.log("@reduction", this.reduction);
    }
    this.reduction.consume_spogi(a_spogi);
    return this.reduction.value;
  }
}
ReductionJig.initClass();

export class DiscriminatorJig {
  static initClass() {
    this.prototype.doc = ".disc() runs .discriminate() returning a spogi IF THERE WAS CHANGE else null";
  }

  constructor(noodb, discr_form, me_is) {
    this.noodb = noodb;
    this.discr_form = discr_form;
    this.me_is = me_is;
    this.discriminator = new Discriminator(discr_form, this.me_is);
  }

  disc(new_args) {
    let a_spogi;
    if (this.args == null) { this.args = {}; }
    for (let k in new_args) {
      const v = new_args[k];
      this.args[k] = v;
    }
    const a = this.args;
    if (typeof window !== 'undefined' && window !== null) {
      a_spogi = this.noodb.allege_local(a.s, a.p, a.o, a.g, a.who);
    } else {
      a_spogi = this.noodb.allege(a.s, a.p, a.o, a.g, a.who);
    }
    const onward = this.discriminator.discriminate(a_spogi, this.noodb);
    if (onward) {
      return onward.o.getNativeValue();
    }
    return null;
  }
}
DiscriminatorJig.initClass();
