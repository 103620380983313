/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// # FractalPanel
//
// FractalPanel is an extensible dashboard layout system integrated with
// FormURLa so the page URL dynamically represents and controls that layout.
// Drag and drop operations within and between the panels can create new panels.
// Furthermore, a system of knobbies in the corners and around the permitter
// of the panes provide per-pane context-sensitive menus and controls.  Overall
// the system functions as the Tiling Window Manager for the Nooron Collaboratory.
//
// Here is an example of an URL and the resultant layout
//
//     /beside(above(a,b),beside(c,d))
//       +---+---+---+
//       | a |   |   |
//       +---+ c | d |
//       | b |   |   |
//       +---+----+--+
//
// In this example a, b, c and d can be any "FormURLa Function" such as
// `print(a)`, `graph(...)`, `chart(...)`, etc where ... represents arguments.
//
// ## Knobbies
//
// Each of the panes is by default provided with 'knobbies' around the permitter
// such as:
//   * visualization -- offers alternative visualizations for the current data
//   * voices -- provides for selection of discriminators to govern data sourcing
//   * vantage -- provides nameable 'worldviews' to govern data display
//   * action -- displays dynamically sourced *menu items*
//   * search -- searches with the pane
//   * close -- button to close the pane
//
// When clicked each of the knobbies (except the close button) displays a
// pluggable panel to act as a visualization to make the choice or perform
// the needed action.  The visualizations used may themselves be chosen by
// the users.  This provides for the excitement of (pointless) infinite descent.
//
// ## Dragging and dropping (Dragon Droppings)
//
// Objects within the visualzations represent semantically addressable entities.
// Once dragging of those entities begins then North, South, East and West
// 'drop zones' appear on the edges of all panes which could be split on those
// sides to provide new places to display the entity when droppped.

const golden_fraction = 61.803;
const golden_percent = `${golden_fraction}%`;
const golden_complement = `${100 - golden_fraction}%`;
export const direction = {
  north: 'height',
  south: 'height',
  east: 'width',
  west: 'width',
  inside: false
};
export const orientation = {
  north: 'horizontal',
  south: 'horizontal',
  east: 'vertical',
  west: 'vertical',
  horizontal: ['north', 'south'],
  vertical: ['west', 'east']
};
export const other = {
  north: 'south',
  south: 'north',
  east: 'west',
  west: 'east'
};
export const div_loc = {
  north: 'bottom',
  south: 'bottom',
  east: 'right',
  west: 'right'
};
export const side_to_seq = { // should standardize on 'side' over 'edge' because 'inside' is better than false
  north: 'first',
  south: 'second',
  east: 'second',
  west: 'first',
  inside: 'first'
};
const colors = [
  "rgba(255, 0, 0, 0.5)",
  "rgba(0, 255, 0, 0.5)",
  "rgba(0, 0, 255, 0.5)",
  "rgba(255, 255, 0, 0.5)",
  "rgba(255, 127, 0, 0.5)",
  "rgba(127, 0, 127, 0.5)"
];

const frac_and_elem_agree = function(frac) {
  const frac_elem_id = frac.elem.attr('id');
  if (frac.frac_id !== frac_elem_id) {
    const equality = `${frac.frac_id} <> ${frac_elem_id}`;
    console.warn(equality);
    console.warn("frac", frac);
  }
  //else
  //  console.info "#{frac.frac_id} == #{frac_elem_id}"
};


const lineage = {
  2: [1],
  3: [1, 5],
  4: [1],
  6: [5],
  5: [4]
};
const check_lineage = function(a_frac) {
  let msg, pid;
  return;
  const id = a_frac.frac_id;
  if (a_frac.parent_frac) {
    pid = a_frac.parent_frac.frac_id;
  }
  if ((id != null) && (pid != null) && (lineage[id] != null)) {
    if (Array.from(lineage[id]).includes(pid)) {
      return console.warn(`LINEAGE: ${id} in ${lineage[id]} as expected`);
    } else {
      msg = `LINEAGE: expected ${id} in ${lineage[id]} not ${pid}`;
      console.error(msg);
      return alert(msg);
    }
  } else {
    msg = `LINEAGE: frac_id:${id}, parent_frac.frac_id: ${pid}`;
    return console.error(msg);
  }
};

export class FractalPanel {
  static initClass() {
    this.last_ids = {};
    this.color_idx = 0;
  }
  // This class is essentially a wrapper around split-pane-component divs,
  // represented as @elem
  // Like a window has a pane, a FractalPanel always has at least one FractalComponent.
  // FractalPanels can have either one or two FractalCompents. If one then its 'side'
  // is 'inside' otherwise the two FractalCompents have sides 'south' and 'north' or
  // 'east' and 'west'.  When a FractalComponent which is a single child is told to
  // split it calls the add_fracinner method on its parent_frac, a FractalPanel,
  // which creates another FracalCompnent and a moveable divider between them.
  constructor(parent, name, args) { // FractalPanel
    //console.log "FractalPanel.constructor()"
    this.show_drop_zones = this.show_drop_zones.bind(this);
    this.hide_drop_zones = this.hide_drop_zones.bind(this);
    this.name = name;
    if (args == null) { args = {}; }
    if (this.propagate == null) { this.propagate = args.propagate || {}; }
    this.defaults = {
      always_show_NEWS_buttons: false,
      inject_tree_path: false,
      color_panels_uniquely: false,
      make_frame: false,
      always_show_close_button: false,
      suppress_content_area: false,
      second_frac: false,
      content_area_editable: false,
      show_NEWS_handle: {
        N: true,
        E: true,
        W: true,
        S: true
      }
    };
    Object.assign(this, this.defaults, this.propagate, args);

    this.parent = $(parent); // the parent_elem
    if (this.make_frame) {
      const frame = $("<div class=\"split-pane-frame\"></div>");
      this.parent.append(frame);
      this.parent = frame;
    }
    this.direction = false; // when split, becomes either 'width' or 'height'

    if (!this.elem) {
      this.elem = $('<div></div>');
      this.elem.addClass('fractalpanel_outer');
      this.elem.addClass('split-pane');
      FractalPanel.assign_random_id(this.elem, "fp_");
      this.frac_id = this.elem.attr("id");
      /*
      console.log "@parent_frac", @parent_frac
      console.log("just added FractalPanel.id: #{@frac_id} " +
        "to parent: #{@parent_frac?frac_id}")
      */
      this.parent.append(this.elem);
    } else {
      alert("@elem is already defined");
    }

    if ((this.first_frac == null)) {
      this.first_frac = new FractalComponent(this, "oink", {propagate: this.propagate});
    }
    if (!this.first_frac.parent_frac) {
      this.first_frac.parent_frac = this;
    }
    //@first_frac.fracs_and_elems_agree("ORIGIN+0") # should fail
    if (!this.elem.children().length) {
      this.elem.append(this.first_frac.elem);
      this.first_frac.parent_frac = this;
    }
    this.splitPane();
    this.first_frac.fracs_and_elems_agree("ORIGIN+1");
    if (!this.parent_frac) {
      this.first_frac.elem.css('height','100%');
    }
    if (this.first_frac.parent_frac !== this) {
      throw new Error("first_frac.parent_frac should equal this");
    }
    check_lineage(this);
  }
  static next_id(prefix) {
    if (!this.last_ids[prefix]) {
      this.last_ids[prefix] = 0;
    }
    this.last_ids[prefix] = this.last_ids[prefix] + 1;
    return prefix + this.last_ids[prefix];
  }
  static get_color() {
    const retval = colors[this.color_idx];
    this.color_idx++;
    if (this.color_idx >= (colors.length - 1)) {
      const color_idx = 0;
    }
    return retval;
  }
  static assign_random_id(what, prefix) {
    //rand_id = prefix + new Date().getTime()
    const rand_id = this.next_id(prefix);
    $(what).attr('id', rand_id);
    return rand_id;
  }

  deknob() { // remove all the knobs
    $('.fractalpanel_knob_corner').remove();
    return $('.fractalpanel_knob_edge').remove();
  }

  discontent() { // remove all contents
    return $('.fractalpanel_content_area').html('');
  }

  move_divider_to(x,y) {
    // Accept x or y (not both) which are int values in pixels and move the divider there
    let pct;
    if (x != null) {
      pct = (100 * (1 - (x/parseInt(this.elem.css('width').replace(/px/,''))))) + "%";
      this.set_right(pct);
    }
    if (y != null) {
      pct = (100 * (1 - (y/parseInt(this.elem.css('height').replace(/px/,''))))) + "%";
      return this.set_bottom(pct);
    }
  }

  set_bottom(bottom) {
    $(this.north).css('bottom',bottom);
    $(this.divider).css('bottom',bottom);
    return $(this.south).css('height',bottom);
  }

  set_right(right) {
    $(this.west).css('right',right);
    $(this.divider).css('right',right);
    return $(this.east).css('width',right);
  }

  split_frac_on_side(id, side) {
    const frac = this.get_frac(id);
    if (frac) {
      return frac.split(side);
    }
  }

  get_frac(id) { // FractalPanel
    if (this.first_frac.frac_id === id) {
      return this.first_frac;
    }
    if (this.second_frac && (this.second_frac.frac_id === id)) {
      return this.second_frac;
    }
    return this.first_frac.get_frac(id) || (this.second_frac && this.second_frac.get_frac(id));
  }

  show_drop_zones(args) { // FractalPanel
    this.first_frac.show_drop_zones(args);
    if (this.second_frac) {
      return this.second_frac.show_drop_zones(args);
    }
  }

  hide_drop_zones() { // FractalPanel
    this.first_frac.hide_drop_zones();
    if (this.second_frac) {
      return this.second_frac.hide_drop_zones();
    }
  }

  reorient(a_frac, new_edge_id, proportion) { // FractalPanel
    //alert("why is 'E' 'x' leaving div.id=2 as fractalpanel_west?")
    // change the side (north|south|east|west|false) of a_frac
    const old_edge_id = a_frac.my_side; //or 'inside'
    //console.group "reorient(#{a_frac.frac_id}, new_edge_id:#{new_edge_id}, proportion:#{proportion}) old_edge_id:#{old_edge_id}"
    a_frac.my_side = new_edge_id;
    const {
      elem
    } = a_frac;
    const was_inside = old_edge_id === 'inside';
    // console.log "was",old_edge_id
    // debugger
    if (was_inside) {
      elem.css("height","");
      elem.css("width","");
    } else {
      elem.removeClass(`fractalpanel_${old_edge_id}`);
      elem.css(direction[old_edge_id],"");  // eg width
      elem.css(div_loc[old_edge_id],"");    // eg right
    }
    // console.log "now",new_edge_id
    const now_inside = new_edge_id === 'inside';
    if (now_inside) {
      elem.css(`margin-${div_loc[new_edge_id]}`, ""); // eg margin-bottom
      elem.css(`${div_loc[new_edge_id]}`, ""); // eg bottom
      elem.css('height', '100%');
    } else {
      elem.addClass(`fractalpanel_${new_edge_id}`);
      if (side_to_seq[new_edge_id] === 'first') {
        elem.css(div_loc[new_edge_id], proportion);
      } else {
        elem.css(direction[new_edge_id], proportion);
      }
    }
    return console.groupEnd();
  }

  replace(goner_frac, comer_frac) { // FractalPanel
    // replace the goner with the comer then do goner.tear_down()
    let proportion, which;
    const the_goner_side = goner_frac.my_side;
    const the_direction = direction[the_goner_side];
    console.groupCollapsed(`${this.frac_id}.replace(${goner_frac.frac_id}, ${comer_frac.frac_id}) the_direction:`, the_direction, "the_goner_side:", the_goner_side);
    if (this.first_frac === goner_frac) {
      which = 'first';
    } else if (this.second_frac === goner_frac) {
      which = 'second';
    } else {
      const msg = [goner_frac.frac_id, "can't be replaced by",comer_frac.frac_id,
        "because it's not on",this.frac_id].join(' ');
      throw msg;
    }
    const old_div_loc = goner_frac.elem.css(div_loc[the_goner_side]);
    goner_frac.elem.before(comer_frac.elem);
    if (the_direction) {
      proportion = goner_frac.elem.css(the_direction) || golden_complement;
    } else {
      proportion = golden_complement;
    }
    if (side_to_seq[the_goner_side] === 'first') {
      proportion = old_div_loc;
    }
    this.reorient(comer_frac, the_goner_side, proportion);
    this[comer_frac.my_side] = comer_frac;
    this[which + '_frac'] = comer_frac;
    comer_frac.parent_frac = this;
    //@[which] = comer_frac.elem # DEPRECATE should be eliminated when @second and @first go
    check_lineage(comer_frac);
    check_lineage(comer_frac.parent_frac);
    return console.groupEnd();
  }

  tear_up() { // FractalPanel
    // tear_up() should collapse any redundant upward nesting but this might be
    // covered by remove_inner and replace.  Though perhaps tear_up() comes into
    // play when a branch looses its only leaf.
    console.error("tear_up() is NOOP");
    return this;
  }

  tear_down() { // FractalPanel
    // console.log "FractalPanel.tear_down() id:#{@frac_id}"
    if (this.first_frac) {
      this.first_frac.elem.remove();
    }
    if (this.second_frac) {
      this.second_frac.elem.remove();
    }
    if (this.elem) {
      this.elem.remove();
      return this.elem = false;
    }
  }

  splitPane() { // FractalPanel
    $('div.split-pane').splitPane();
    return $('.split-pane-resize-shim + .split-pane-resize-shim').remove();
  }

  add_fracpanel(edge_id, inner_frac) { // FractalPanel
    // Make a new FractalPanel, move inner_frac within it, then split the FractalPanel
    // Recipe:
    // * make a shallow_clone_component of inner_frac.elem
    // * put the shallow_clone component after the original
    // * make a new FractalPanel within the shallow_clone_component
    // * move the original within the shallow_clone_component
    console.groupCollapsed(`${this.frac_id}.add_fracpanel(${edge_id},` +
      ` ${inner_frac.my_side})`
    );
    const clone_side = inner_frac.my_side;
    const component = inner_frac.elem;
    const clone_elem = this.clone_component(component); // make shallow clone
    component.after(clone_elem); // put the clone after the original
    clone_elem.css('background-color', '');
    clone_elem.css('margin-bottom', '');
    const clone_id = FractalPanel.assign_random_id(clone_elem, "fp_");
    const clone_frac = new FractalComponent(this, `${clone_elem.attr('id')}`, {
      elem: clone_elem,
      suppress_content_area: true,
      suppress_corner_buttons: true,
      show_NEWS_handle: {}, // suppress handles on FractalComponents containing FractalPanels
      frac_id: clone_id,
      my_side: clone_side
    }
    );
    this[side_to_seq[clone_side]+"_frac"] = clone_frac;
    //@[side_to_seq[clone_side]] = clone_elem
    this[clone_side] = clone_frac;
    component.css('margin-bottom', '');
    if (!(inner_frac.my_side === 'inside')) {
      const divider_location = div_loc[inner_frac.my_side];
      const tmp = component.css(divider_location);
      component.css(divider_location, "");
      const new_dir = direction[edge_id];
    }
    const new_fracpanel = new FractalPanel(clone_elem, edge_id, {
      propagate: this.propagate,
      //my_side: 'inside' #inner_frac.my_side
      my_side: inner_frac.my_side,
      parent_frac: clone_frac,
      first_frac: inner_frac
    }
    );
      //first: component
    this[inner_frac.my_side] = new_fracpanel;
    clone_frac.panel = new_fracpanel;
    const retval = new_fracpanel.add_fracinner(edge_id);
    console.groupEnd();
    return retval;
  }

  clone_component(component) { // FractalPanel
    const clone = $('<div></div>');
    for (let attr of Array.from(component[0].attributes)) {
      if (attr.specified) {
        clone.attr(attr.name, attr.value);
      }
    }
    return clone;
  }

  add_fracinner(edge_id) { // FractalPanel
    let fraction;
    console.groupCollapsed(`${this.frac_id}.add_fracinner(${edge_id})`);
    this.divider = $("<div></div>");
    this.divider.addClass("split-pane-divider");
    this.divider.attr('title', this.name);
    this.reorient(this.first_frac, other[edge_id], "50%");
    const new_component = new FractalComponent(this, `${this.name}.${edge_id}`, {
      propagate: this.propagate,
      my_side: edge_id
    }
    );
    this.second_frac = new_component;
    //@second = @second_frac.elem
    this.reorient(this.second_frac, edge_id, "50%");
    if (['east','south'].includes(edge_id)) { // the new divs should come AFTER
      this.first_frac.elem.after(this.second_frac.elem);
      this.first_frac.elem.after(this.divider);
      fraction = golden_complement;
    } else { // the new divs should come before
      this.first_frac.elem.before(this.second_frac.elem);
      this.first_frac.elem.before(this.divider);
      fraction = golden_percent;
    }
    this.north = false;
    this.south = false;
    this.east = false;
    this.west = false;
    this.inside = false;
    this[edge_id] = this.second_frac.elem;
    this[this.first_frac.my_side] = this.first_frac.elem;
    if (['east','west'].includes(edge_id)) {
      this.elem.addClass("vertical-percent");
      this.divider.addClass('vertical-divider');
      this.direction = "width";
      this.west.css("right", fraction);
      this.east.css("width", fraction);
    } else {
      this.north.css('height','');
      this.elem.addClass("horizontal-percent");
      this.divider.addClass('horizontal-divider');
      this.direction = "height";
      this.north.css("bottom", fraction);
      this.south.css("height", fraction);
    }
    this.divider_location = div_loc[edge_id];
    this.divider.css(this.divider_location, fraction);
    this.second_frac.update_close_button();
    this.splitPane();
    this.first_frac.fracs_and_elems_agree("add_fracinnger() first_frac:");
    this.second_frac.fracs_and_elems_agree("add_fracinner() second_frac:");
    console.groupEnd();
    return new_component;
  }

  remove_inner(frac_inner) { // FractalPanel
    //console.groupCollapsed "FractalPanel_#{@frac_id}.remove_inner(#{frac_inner.frac_id} (will replace #{@parent_frac?frac_id}.#{@parent_frac?my_side}))"
    let remaining, removing;
    const victim_frac_id = frac_inner.frac_id;
    this.first_frac.fracs_and_elems_agree("ZZZZZ");
    if (this.second_frac) {
      this.second_frac.fracs_and_elems_agree("YYYYY");
    }
    if (!this.elem) {
      alert("elem is missing");
    }
    if (frac_inner === this.first_frac) {
      remaining = "first_frac";
      removing = "first_frac";
      this.first_frac = this.second_frac;
    } else {
      remaining = "first_frac";
      removing = "second_frac";
    }
    frac_inner.elem.remove();
    //@first_frac.fracs_and_elems_agree("UUUUU")
    this.second_frac = false;
    //@second = false # TODO remove
    if (this.divider) {
      this.divider.remove();
    }
    this.divider = false;
    this.north = false;
    this.south = false;
    this.east = false;
    this.west = false;
    this.direction = false;
    this.remove_percent_classes();
    if (this.parent_frac) { // ie is this NOT the root frac?
      if (this.first_frac) { // is there, in fact, still an inner frac?
        this.first_frac.fracs_and_elems_agree("AAAAA", false);
        if (this.parent_frac.parent_frac != null) {
          this.parent_frac.parent_frac.replace(this.parent_frac, this.first_frac);
        } else {
          alert(`while ${victim_frac_id}.close() ${this.frac_id} has no grandmother`);
        }
        this.first_frac.fracs_and_elems_agree("BBBBB");
        this.parent_frac.tear_down();
      }
        //@reorient(@first_frac, 'inside')
        //@first_frac.fracs_and_elems_agree("CCCCC")
    } else {
      if (false) {
        console.warn("there is no @parent_frac");
        console.warn("     my_side:", this.my_side);
        console.warn("  first_frac:", this.first_frac);
        console.warn("  first_frac:", this.first_frac.frac_id);
        console.warn(" second_frac:", this.second_frac);
        console.warn(" second_frac:", this.second_frac.frac_id);
      }
      if (this.first_frac) {
        this.reorient(this.first_frac, 'inside');
      }
    }
    return console.groupEnd();
  }

  remove_percent_classes() { // FractalPanel
    this.elem.removeClass("vertical-percent");
    return this.elem.removeClass("horizontal-percent");
  }

  is_already_split() {
    return (this.divider != null);
  }

  find_furthest_ancestor_with_same_direction(direction_to_match) {
    if ((direction_to_match == null)) { // commencing recursion at this level
      direction_to_match = this.direction;
    }
    if (this.parent_frac != null) {
      if (this.parent_frac.direction === direction_to_match) {
        return this.parent_frac.find_furthest_ancestor_with_same_direction(direction_to_match);
      } else {
        return this; // we are the furthest parent with matching direction
      }
    } else {
      return this;
    }
  }
}
FractalPanel.initClass(); // we are the top of the tree

class FractalComponent {
  // This class is essentially a wrapper around split-pane-component divs,
  // represented as @elem.
  // A FractalCompnent always has a @parent_frac which is a FractalPanel.
  constructor(parent_frac, name, args) { // FractalComponent
    //console.log "FractalComponent.constructor()"
    this.close_button_handler = this.close_button_handler.bind(this);
    this.NEWS_click = this.NEWS_click.bind(this);
    this.show_drop_zones = this.show_drop_zones.bind(this);
    this.parent_frac = parent_frac;
    this.name = name;
    if (args == null) { args = {}; }
    if (this.propagate == null) { this.propagate = args.propagate || {}; }
    this.defaults = {
      always_show_NEWS_buttons: true,
      inject_tree_path: false,
      color_panels_uniquely: false,
      make_frame: false,
      always_show_close_button: false,
      suppress_corner_buttons: false,
      my_side: 'inside', // will be north, south, east, or west after split
      insert_my_side_on_mouseover: false,
      content_area_filler: '',
      action_button__title: "Action Menu...",
      vantages_button__title: "Vantages Menu...",
      visualization_button__title: "Visualization Menu...",
      voices_button__title: "Voices Menu...",
      close_button__title: "Close"
    };
    Object.assign(this, this.defaults, this.propagate, args);

    if ((this.elem == null)) {
      this.elem = $("<div></div>");
    }
    this.elem.addClass('fractalpanel_inner');
    this.elem.addClass('split-pane-component');
    if ((this.parent_frac == null)) {
      this.elem.height("100%");
    }
    if (this.color_panels_uniquely) { // add arg to set colors like this
      const color_spec = FractalPanel.get_color();
      //@elem.text(color_spec)
      this.elem.css("background-color", color_spec);
    }
    if ((this.frac_id == null)) {
      this.frac_id = FractalPanel.assign_random_id(this.elem, "fp_");
    }
    this.add_NEWS_handles();
    if (!this.suppress_corner_buttons) {
      this.add_TBLR_handles();
    }
    if (!this.suppress_content_area) {
      this.add_content_area();
    }
    //@fracs_and_elems_agree("FractalComponent():")
    check_lineage(this);
  }

  get_frac(id) { // FractalComponent
    return this.panel && this.panel.get_frac(id);
  }

  add_content_area() { // FractalComponent
    const content_area_div = `\
<div class="fractalpanel_content_area">
  ${this.content_area_filler}
</div>\
`;
    this.content_area = $(content_area_div);
    if (this.content_area_editable) {
      this.content_area.attr('contentEditable','true');
    }
    FractalPanel.assign_random_id(this.content_area, "ca_");
    this.elem.append(this.content_area);
    if (this.insert_my_side_on_mouseover) {
      this.content_area.mouseenter(() => {
        return this.content_area.append(`<div>${this.my_side}</div>`);
      });
    }
    return this.show_identifier_if_desired();
  }

  show_identifier_if_desired() { // FractalComponent
    if (this.inject_tree_path) {
      return this.content_area.append(`<h1>[ ${this.elem.attr('id')} ]</h1>`);
    }
  }
      //@content_area.append('<p>line</p>\n'.repeat(100))

  fracs_and_elems_agree(label, expecting) { // FractalComponent
    if (expecting == null) { expecting = true; }
    const parent_elem = this.elem.parent();
    const parent_elem_id = parent_elem.attr('id');
    if (this.parent_frac.frac_id !== parent_elem_id) {
      const inequality = `${label}: ` +
        `${this.frac_id}.parent_frac.frac_id:${this.parent_frac.frac_id} ` +
        `<> @elem.parent().attr('id'):${parent_elem_id}`;
      console.error(inequality);
      //console.error "parent_elem", parent_elem
      if (expecting) {
        throw new Error(`expecting ${expecting}`);
      }
    }
  }

  close(target) { // FractalComponent
    frac_and_elem_agree(this);
    this.fracs_and_elems_agree("CLOSE");
    // TODO This might be the right place for
    //     https://bitbucket.org/smurp/libnoo/issues/231/when-closing-a-panel-ask-the-visualization
    // TODO Is this where to prevent closure if this is the only panel?
    if (this.parent_frac) {
      // TODO Is this where to tell the FormURLa to remove this item?
      const forMan = this.get_formurlaManager();
      if (forMan != null) {
        forMan.closePanel(this);
      }
      return this.parent_frac.remove_inner(this);
    } else {
      return alert("No parent!?!");
    }
  }

  tear_down() { // FractalComponent
    // console.log "FractalComponent.tear_down() id:#{@frac_id}"
    this.elem.remove();
    this.elem = false;
    return this.elem = false;
  }

  should_show_close_button() { // FractalComponent
    // Do not show the close button if this fracpanel is the only one.
    return this.always_show_close_button || !!this.parent_frac;
  }

  update_close_button() { // FractalComponent
    if (this.close_button) {
      if (this.should_show_close_button()) {
        return this.close_button.show();
      } else {
        return this.close_button.hide();
      }
    }
  }

  make_visualization_menu_panel() {
    if (true) { // TODO remove this legacy indent
      const vis_menu = $(`\
<div class="visualization_menu"></div>\
`);

      // TODO make visualization_menu work like action_menu and voices_menu
      this.visualization_menu_panel = new FractalPanel(vis_menu, "thingy", {
        make_frame: false,
        suppress_corner_buttons: false,
        propagate: {
          always_show_NEWS_buttons: false,
          make_frame: false,
          color_panels_uniquely: true,
          inject_tree_path: false,
          suppress_corner_buttons: false,
          suppress_content_area: false,
          show_NEWS_handle: {
            E: true,
            S: true,
            N: true,
            W: true
          }
        }
      }
      );

      const vis_id = FractalPanel.assign_random_id(vis_menu, "vis_");
      this.elem.append(vis_menu);

      //vis_menu.on "mouseleave", () =>
      //  @visualization_menu_panel.parent.hide()

      const forMan = this.get_formurlaManager();
      const menuVizFunc = forMan.resolve_function('visualizations'); // embed VisualizationMenu
      const src = 'visualizations(g=nrn:visualizationKB)';
      const formurla = forMan.compile_formurla(src);
      const expression = forMan.get_first_expression(formurla);
      this.visualization_picker = menuVizFunc.apply(forMan,[this.visualization_menu_panel.first_frac, expression]);
      this.visualization_picker.visualization_picker_for = this.visualization_instance;

      // TODO will be made redundant when visualization_menu works like voices_menu
      return this.visualization_picker.knob_which_controls_me = {
        hide_popup: () => {
          return this.visualization_menu_panel.elem.hide();
        }
      };
    }
  }

  set_knob_label(knob, label) {
    if (knob != null) {
      return knob.find(".knob_label").text(label);
    }
  }

  hide_visualization_menu_panel() {
    return this.elem.parent().parent().hide();
  }

  add_TBLR_handles() { // FractalComponent
    // TL TR BR BL
    //Create panel control bar
    this.controlBar = "<div class='fractalpanel_controls_bar'></div>";
    this.elem.append(this.controlBar);

    this.add_visualization_knobby();
    this.add_voices_knobby();
    this.add_vantage_knobby();
    this.add_action_knobby();
    this.add_search_knobby();
    this.add_close_knobby();

    this.attach_all_knob_controllers();
    this.update_close_button();
  }

  add_visualization_knobby() { // FractalComponent
    // the Visualization selection button
    this.TL_handle = $(`\
  <div class="fractalpanel_knob">
  <i class="fa fa-line-chart"></i>
  <div class="knob_label"></div>
</div>\
`);
    this.elem.children('.fractalpanel_controls_bar').append(this.TL_handle);
    this.visualization_button = this.TL_handle;
    //@visualization_button.hide()
    this.visualization_button.click(evt => {
      if ((this.visualization_menu_panel == null)) {
        this.make_visualization_menu_panel();
      }
      return this.visualization_menu_panel.parent.show();
    });
  }

  add_voices_knobby() { // FractalComponent
    // the "Voices" AKA "Discriminators" button
    this.TL1_handle = $(`\
<div class="voices_knob fractalpanel_knob">
  <i class="fa fa-users"></i>
  <div class="knob_label"></div>
</div>\
`);
    this.elem.children('.fractalpanel_controls_bar').append(this.TL1_handle);
    this.voices_button = this.TL1_handle;
    //@voices_button.attr("title", @voices_button__title)
    //@voices_button.hide()
  }

  add_vantage_knobby() { // FractalComponent
    // the "Vantage" button
    // TODO figure out a more principled place to put the bugs button
    // <i class="far fa-eye"></i> is great for vantage
    this.TL2_handle = $(`\
<div class="fractalpanel_knob">
  <a class="fa fa-bug" href="https://bitbucket.org/nooron/nooron/issues"
    target="bugs" title="Bugs? Suggestions?"></>
  <!--<div class="knob_label"></div>-->
</div>\
`);
    this.elem.children('.fractalpanel_controls_bar').append(this.TL2_handle);
    this.vantages_button = this.TL2_handle;
    //@vantages_button.attr("title", @vantages_button__title)
    //@vantages_button.hide()
  }

  add_action_knobby() { // FractalComponent
    // the Bottom Left action button
    this.BL_handle = $(`\
<div class="fractalpanel_knob fractalpanel_knob_corner fractalpanel_knob_bl">
  <i class="fa fa-bars"></i>
</div>\
`);
    this.elem.append(this.BL_handle);
    this.action_button = this.BL_handle;
    this.action_button.hide();
  }

  add_search_knobby() { // FractalComponent
    // the Bottom Right search button
    this.BR_handle = $(`\
<div class="fractalpanel_knob fractalpanel_knob_corner fractalpanel_knob_br">
  <i class="fa fa-search"></i>
</div>\
`);
    this.elem.append(this.BR_handle);
    this.search_button = this.BR_handle;
    this.search_button.attr("title","Search...");
    this.search_button.hide();
  }

  add_close_knobby() { // FractalComponent
    // the Top Right close button
    this.TR_handle = $(`\
<div class="fractalpanel_knob fractalpanel_knob_corner fractalpanel_knob_tr">
  <i class="fa fa-times"></i>
</div>\
`);
    this.elem.append(this.TR_handle);
    this.close_button = this.TR_handle;
    this.close_button.attr("title", this.close_button__title);
    this.close_button.click(this.close_button_handler);
  }

  attach_all_knob_controllers() {
    return (() => {
      const result = [];
      for (let which_button of ['action_button', 'visualization_button', 'voices_button']) { //, 'vantages_button']
        const title_key = which_button + '__title';
        const class_key = which_button + '__class';
        const ctlr_key = which_button + '__ctlr';
        if (this[title_key] != null) {
          this[which_button].attr("title",this[title_key]);
        }
          //@[which_button].show()
        if (this[class_key] != null) { // class_key is assumed to be a subclass of KnobController
          this[ctlr_key] = new (this[class_key])(this, this[which_button]);
          result.push(this[which_button].show());
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  close_button_handler(evt) {
    if (this.custom_close_button_handler) {
      return this.custom_close_button_handler(evt);
    } else {
      return this.close(evt.target);
    }
  }

  add_NEWS_handles() {
    if (this.show_NEWS_handle.N) {
      this.N_handle = $(`\
<div data-edge="north" class="fractalpanel_knob_north fractalpanel_knob_edge fractalpanel_knob">
  N
</div>\
`);
      this.elem.append(this.N_handle);
    }

    if (this.show_NEWS_handle.E) {
      this.E_handle = $(`\
<div data-edge="east" class="fractalpanel_knob_east fractalpanel_knob_edge fractalpanel_knob">
  E
</div>\
`);
      this.elem.append(this.E_handle);
    }

    if (this.show_NEWS_handle.W) {
      this.W_handle = $(`\
<div data-edge="west" class="fractalpanel_knob_west fractalpanel_knob_edge fractalpanel_knob">
  W
</div>\
`);
      this.elem.append(this.W_handle);
    }

    if (this.show_NEWS_handle.S) {
      this.S_handle = $(`\
<div data-edge="south" class="fractalpanel_knob_south fractalpanel_knob_edge fractalpanel_knob">
  S
</div>\
`);
      this.elem.append(this.S_handle);
    }
    this.NEWS_handles().click(this.NEWS_click);
    if (!this.always_show_NEWS_buttons) {
      return this.NEWS_handles().hide();
    }
  }

  NEWS_click(evt) { // FractalComponent
    // It is not expected that showing the handles will be a typical use
    // since what should be shown there?  Generally the handles will have
    // something dropped on them which will provide a clue as to what should
    // displayed in the new FractalPanel
    const edge_id = $(evt.target).data("edge");
    return this.split(edge_id);
  }

  split(edge_id, ancestor_to_split) { // FractalComponent
    if (ancestor_to_split == null) { ancestor_to_split = this.parent_frac; }
    //if ancestor_to_split is @parent_frac
    //alert("splitting #{ancestor_to_split.frac_id} on side #{edge_id}")
    if (ancestor_to_split.is_already_split()) {
      // Make a new Pane and put this Component inside it, then split that Pane
      return ancestor_to_split.add_fracpanel(edge_id, this);
    } else {
      // split the Panel this Component is in
      return ancestor_to_split.add_fracinner(edge_id);
    }
  }

  add_sibling(edge_id) {
    // WIP see BUILTIN_above and BUILTIN_beside, motivated by issue #130
    return this.split(edge_id, this.parent_frac.find_furthest_ancestor_with_same_direction());
  }

  NEWS_handles() { // FractalComponent
    return $(this.elem).find(".fractalpanel_knob_edge");
  }

  TLBR_handles() { // FractalComponent
    return $(this.elem).find(".fractalpanel_knob_corner");
  }

  show_drop_zones(args) { // FractalComponent
    this.NEWS_handles().show(args != null ? args : {});
    return this.NEWS_handles().droppable({
      hoverClass: 'hovering_drop_target',
      tolerance: 'pointer', // 'pointer' minimizes multi-handle overlap 'fit|intersect|pointer|touch'
      drop: args.drop_handler || this.EXPLANATION_HANDLER
    });
  }

  EXPLANATION_HANDLER(evt, ui) {
    return alert("pass args.drop_handler to show_drop_zones(args) to handle the drop");
  }

  hide_drop_zones() { // FractalComponent
    return this.NEWS_handles().hide();
  }
    //@child??hide_drop_zones() # PROPAGATE hide_drop_zones() down to any children

  hide_all_buttons() {
    this.close_button.hide();
    this.search_button.hide();
    this.visualization_button.hide();
    this.voices_button.hide();
    this.vantages_button.hide();
    return this.action_button.hide();
  }

  show_all_buttons() {
    this.close_button.show();
    this.search_button.show();
    this.visualization_button.show();
    this.voices_button.show();
    this.vantages_button.show();
    return this.action_button.show();
  }

  register_visualization(visualization_instance, vis_func_name) {
    this.visualization_instance = visualization_instance;
    if (this.visualization_button) { // some visualization might not have a visualization picker present
      this.set_knob_label(this.visualization_button, this.visualization_instance.get_title());
    }
    // vis_func_name like "vis-func-provid"
    return this.elem.addClass(vis_func_name);
  }

  get_formurlaManager() {
    if (this.visualization_instance) {
      return this.visualization_instance.formurlaManager;
    }
  }
}
