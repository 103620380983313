import {AbstractBlocklyController} from './abstractblocklyctrl.js';

export class BlocklyController extends AbstractBlocklyController {
  constructor() {
    super(...arguments);
    this.showCodeScript = this.showCodeScript.bind(this);
    this.showDiscriminatorAST = this.showDiscriminatorAST.bind(this);
    this.resize_handler = this.resize_handler.bind(this);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    $("#"+`${this.content_id}`).append('<div id="blocklyDiv-' + this.content_id + '" style="height: ' + this.get_panelSize().height + 'px; width: ' + this.get_panelSize().width + 'px;overflow:hidden"></div>');
    //console.log(@toolbox())
    this.initBlocks();
    this.toolbox();
    this.initializeWorkspace();

    //Show code area
    $("#blocklyDiv-" + this.content_id).append('<div class="codeDisplay" style="z-index:99;position:absolute;top:0;right:0;width:400px;background-color:#eee;padding:10px;">Code Window</div>');
    $("#blocklyDiv-" + this.content_id).append('<pre class="discriminatorAST" style="z-index:99;position:absolute;bottom:0;right:0;width:600px;background-color:#eee;padding:10px;">Discriminator AST Woot</pre>');
    this.workspace = Blockly.inject('blocklyDiv-' + this.content_id, {toolbox: this.toolbox, scrollbars: false});

    Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(this.initXML), this.workspace);

    //@create_api_workspace(@workspace)
    this.initializeDiscriminator();
    this.workspace.render();

    this.workspace.addChangeListener(Blockly.Events.disableOrphans);
    //console.log(@workspace)

    this.addResizeListener();
    this.workspace.addChangeListener(this.showCodeScript);
    this.workspace.addChangeListener(this.showDiscriminatorAST);
    //@workspace.addChangeListener(@showCodeScript, @showDiscriminatorAST)
    if (!this.kwargs.showAST) {
      $(this.localize(".discriminatorAST")).hide();
    }
    if (!this.kwargs.showCode) {
      $(this.localize(".codeDisplay")).hide();
    }
  }

  initBlocks() {}
    // to be overridden by subclasses

  showCodeScript() {
    this.display_code = Blockly.JavaScript.workspaceToCode(this.workspace).replace(/\s+/g, '');
    this.display_code += Blockly.Xml.domToPrettyText(Blockly.Xml.workspaceToDom(this.workspace));
    return $(this.localize(".codeDisplay")).text(this.display_code);
  }

  showDiscriminatorAST() {
    let output = '';
    try {
      const src = Blockly.JavaScript.workspaceToCode(this.workspace).replace(/\s+/g, '');
      //src = "Latest(Min(me|everyone)).re(Truth,Beauty);Latest(everyone);"
      //src = "AVG(MAX(EARLIEST(@clinton,@obama~@trump|everyone~@trump2))).re(Criteria,Criteria2);"

      const discriminator = new Discriminator(src);
      output = JSON.stringify(discriminator.discr_ast, null, 4);
    } catch (e) {
      output = e.toString();
    }
    //console.log output
    return $(this.localize(".discriminatorAST")).text(output);
  }

  addResizeListener() {
    return $(this.fracpanel.content_area).bind("_splitpaneparentresize", this.resize_handler);
  }

  resize_handler() {
    this.bheight = this.get_panelSize().height-20; //panel -20px to avoid scroll bars appearing on resize
    this.bwidth = this.get_panelSize().width-20;
    return $("#"+`${this.content_id}` + " #blocklyDiv").width(this.bwidth).height(this.bheight);
  }

  get_panelSize() {
    return {
      width: $(this.visContent).width(),
      height: $(this.visContent).height()
    };
  }
}
