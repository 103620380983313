/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// based on https://github.com/talis/rdfquads.js

import {toNative} from './xsd2native.js';

const uriRegex = /<([^>]*)>/;
const literalRegex = /^([-]?\d.*)|(?:\"(.*)\")(?:\@([a-z]+)|\^\^(.*)|(?:))$/;

function isString(str) {
  return (str && typeof(str.valueOf()) === 'string');
}

export class RdfUri {
  constructor(url) {
    const match = url.match(uriRegex);
    if (match) {
      this.raw = match[1];
    } else {
      this.raw = url;
    }
  }
  toString() {
    return this.raw;
  }
}

export class RdfObject {
  constructor(val) {
    if ((typeof val !== undefined) && val.match) {
      const uriMatch = val.match(uriRegex);
      //console.log "WE ARE HERE",val
      if (uriMatch) {
        //console.log "uriMatch",uriMatch
        this.raw = uriMatch[0];
        this.value = uriMatch[1];
        this.type = "uri";
        this.where = "A";
      } else {
        const literalMatch = val.match(literalRegex);
        //console.log "literalMatch:", literalMatch, ">#{val}<"
        if (literalMatch) {
          this.raw = literalMatch[0];
          this.type = 'literal';
          this.where = "B";
          if (literalMatch[1] && (literalMatch[1].indexOf('-') < 1)) { // starts with digit
            this.isNum = true;
            this.value = literalMatch[1];
          }
          if (literalMatch[2]) { // wrapped in quotes
            this.value = literalMatch[2];
          }
          if (literalMatch[3]) { // @lang
            this.where += "_lang";
            this.literal_lang = literalMatch[3];
          }
          if (literalMatch[4]) { // ^^literalType
            this.where += "_type";
            this.literal_type = literalMatch[4];
          }
        } else {
          //console.log "literalRegex failed for", val
          this.raw = val;
          this.type = 'FAILED';
          this.value = val;
          this.where = "C_literalRegex failed";
        }
      }
    } else {
      this.raw = val;  // maybe an integer?  TODO tighten this up big time!
      this.type = "literal";
    }
  }
  getNativeValue() {
    if ((this.ntval == null)) {
      this.ntval = toNative(this.value,this.literal_type,this.isNum,this.raw);
      if ((this.ntval == null)) {
        return this.value;
      }
    }
    return this.ntval;
  }
  toString() {
    return this.raw;
  }
  repr() {
    console.log("NO, THIS DOES NOT GET CALLED!");
    if (this.type === 'literal') {
      if (isString(this.raw)) {
        return `\"${this.raw}\"`;
      } else if (Number.isFinite(this.raw)) {
        return this.raw;
      } else {
        throw new Error(`RdfObject(${this.raw}) is a literal which is neither a String nor a Number`);
      }
    } else if (this.type === 'uri') {
      return `<${this.raw}>`;
    } else {
      throw new Error(`RdfObject(${this.raw}).type is neither literal nor uri`);
    }
  }
  isUri() {
    return this.type === "uri";
  }
  isLiteral() {
    return this.type === "literal";
  }
  getLiteralType() {
    return this.literal_type;
  }
  uri_or_literal() {
    if (this.isLiteral()) {
      return [this.raw];  // TODO remove this and the complementary unwrapping of the array
    } else {
      return this.raw; // "<#{@raw}>"
    }
  }
  getRawOrValue() {
    if (this.isLiteral()) {
      return this.raw;
    } else {
      return this.value;
    }
  }
}

export class Quad { // TODO change to SPOGI
  constructor(subject, pred, obj, graph, id) {
    this.s = new RdfUri(subject);
    this.p = new RdfUri(pred);
    this.o = new RdfObject(obj);
    this.g = new RdfUri(graph);
    this.i = id;
  }
  toString() {
    const id = ((this.i != null) && ` # ${this.i}`) || "";
    return "<" + this.s + "> <" + this.p + "> " + this.o.repr() + " <" + this.g + `> .${id}`;
  }
  repr() {
    return this.toString();
  }
  asLine() {
    return this.toString() + "\n";
  }
}

export const spogiRegex = new RegExp(`\
\
\\s*\
\
(<[^>]*>\
|_:[A-Za-z][A-Za-z0-9]*)\
\
\\s+\
\
(<[^>]*>\
|_:[A-Za-z][A-Za-z0-9]*)\
\
\\s+\
\
(\
(?:[-]?\\d[^\\s]*)\
|(?:\\"(?:.*)\\")\
(?:\\@(?:[a-z_]+)\
|\\^\\^(?:.*)\
|(?:)\
)\
|(?:<[^>]*>|)\
)\
\
\\s+\
(<[^>]*>|)\
\\s*\\.\
\
\
\\s*\\#*\\s*(\
.*\
\
\
\
\
)$\
`);

// https://regex101.com/r/A48eDR/1/   this captures everything into a single group with (?:
// 
export const ttlObjectRegex = new RegExp(`\
\
(\
(\\"\
.*\
\\"\
(\
(\\@[a-z][a-z])\
|\
(\\^\\^\
([\\w]+\\:[\\w]*\
|<[^>]+>\
)\
)\
|\
)\
)\
|\
([\\w]+\\:[\\w]*\
|<[^>]+>\
)\
)\
\
`);

// https://regex101.com/r/FJVaaM/5/
export const n5Regex = new RegExp(`\
^\
\
\\s*\
\
([\\w]+\\:[\\w\\-]*\
|<[^>]+>\
)\
\
\\s+\
\
([\\w]+\\:[\\w\\-]*\
|<[^>]+>\
)\
\
\\s+\
\
\
(\
(\\"\
.*\
\\"\
(\
(\\@[a-z][a-z])\
|\
(\\^\\^\
([\\w]+\\:[\\w\\-]*\
|<[^>]+>\
)\
)\
|\
)\
)\
|\
(?:[\\w]+\\:[\\w\\-]*\
|<[^>]+>\
)\
)\
\
\\s+\
\
([\\w]+\\:[\\w\\-]*\
|<[^>]+>\
)\
\
\\s+\
\\.\
\\s+\
\\#\
\\s+\
\
([\\w]+)\
\
\
\
\
\
\
.*\
$\
`);

export const isComment = /^\s*\/\//;

export const parseQuadLine = function(line) {
  if ((line == null) || (line === "") || line.match(isComment)) {
    return null;
  } else {
    const match = line.match(spogiRegex);
    if (match) {
      const retval = {
        s: new RdfUri(match[1].trim()).raw,
        p: new RdfUri(match[2].trim()).raw,
        o: new RdfObject(match[3].trim()).uri_or_literal(),
        g: new RdfUri(match[4].trim()).raw,
        i: match[5].trim()
      };
      return retval;
    } else {
      console.warn("spogiRegex FAILED:", line);
    }
  }
};

export const parseQuintLineToPenta = function(line) {
  if ((line == null) || (line === "") || line.match(isComment)) {
    return null;
  } else {
    const match = line.match(spogiRegex);
    if (match) {
      const retval = {
        s: new RdfUri(match[1].trim()),
        p: new RdfUri(match[2].trim()),
        o: new RdfObject(match[3].trim()),
        g: new RdfUri(match[4].trim()),
        i: match[5].trim()
      };
      return retval;
    } else {
      console.warn("spogiRegex FAILED:", line);
    }
  }
};

export const parseQuadLineToQuint = function(line) {
  if ((line == null) || (line === "") || line.match(isComment)) {
    return null;
  } else {
    const match = line.match(spogiRegex);
    if (match) {
      const retval = [
        new RdfUri(match[1].trim()).raw,
        new RdfUri(match[2].trim()).raw,
        new RdfObject(match[3].trim()).getRawOrValue(),
        new RdfUri(match[4].trim()).raw,
        match[5].trim()];
      return retval;
    } else {
      console.warn("spogiRegex FAILED:", line);
    }
  }
};

export const parseN5LineToQuint = function(line) {
  if ((line == null) || (line === "") || line.match(isComment)) {
    return null;
  } else {
    const match = line.match(n5Regex);
    if (match) {
      // TODO make full use of the hints in these other groups to perfect persistence
      // group 3 is the object URI or CURIE (when present)
      // group 4 is the object string value (when present)
      // group 5 is the (when present)
      // group 6 is the language spec (when present) eg '@en'
      // group 7 is ^^ (when present) eg '^^'
      // group 8 is the datatype spec (when present) eg 'xsd:int' | <http://oink.ly>
      const noodbId = match[10].trim();
      const retval = [
        new RdfUri(match[1].trim()).raw,
        new RdfUri(match[2].trim()).raw,
        new RdfObject(match[3].trim()).getRawOrValue(),
        new RdfUri(match[9].trim()).raw,
        noodbId];
      return retval;
    } else {
      console.warn("n5Regex FAILED:", line);
    }
  }
};
