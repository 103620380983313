/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export class DragAndDropOrClickToVisualize {
  constructor(originViz) {
    // @originViz has .formurlaManager and .noodb and .rootPanel properties
    this.click = this.click.bind(this);
    this.drag = this.drag.bind(this);
    this.drop = this.drop.bind(this);
    this.originViz = originViz;
    this.rootPanel = this.originViz.formurlaManager.rootPanel;
  }
  click(evt, ui) {
    //@draggeeDescription = @originViz.describeDraggable(evt.toElement)
    this.gatherDescription(evt);
    this.originViz.noodb.log.info("KWARGS:",this.originViz.kwargs,"\nDESC:",this.draggeeDescription);
    return this.showThingAtFracEdge(this.draggeeDescription);
  }
  drag(evt, ui) {
    this.gatherDescription(evt);
    this.originViz.noodb.log.info("KWARGS:",this.originViz.kwargs,"DESC:",this.draggeeDescription);
    return this.rootPanel.show_drop_zones({
      drop_handler: this.drop});
  }
  drop(evt, ui) {
    evt.stopPropagation();
    if (evt.target != null) {
      const handle_elem = evt.target;
      const edge_id = handle_elem.dataset.edge; // might be empty
      const frac_id = handle_elem.parentElement.getAttribute('id');
      if (ui.helper != null) {
        $(ui.helper).remove();
      }
      return this.showThingAtFracEdge(this.draggeeDescription, frac_id, edge_id);
    }
  }
  gatherDescription(evt) {
    let sourceElem = evt.toElement;
    if (evt.type === 'click') {
      sourceElem = evt.target;
    } else {
      sourceElem = evt.originalEvent.target;
    }
    return this.draggeeDescription = this.originViz.describeDraggable(sourceElem);
  }
  showThingAtFracEdge(desc, frac_id, edge_id) {
    this.rootPanel.hide_drop_zones();
    return this.originViz.formurlaManager.visualizeDescriptionAtFracSide(desc, frac_id, edge_id);
  }
};
