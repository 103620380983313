import {AnnotationSemClass} from './annotationsem.js';

export class Annotation extends AnnotationSemClass {
  static initClass() {
    this.prototype._curieToJsonld = {
      'oa:hasBody': 'body',
      'oa:bodyValue': 'bodyValue',
      'oa:hasTarget': 'target',
      'oa:motivatedBy': 'motivation',
      'oa:styledBy': 'stylesheet'
    };
  }

  isDisplayable() {
    return this.isBodyDisplayable() && this.isTargetDisplayable();
  }

  isBodyDisplayable() {
    if (this.body != null) {
      //if @getBodyCurie().split(':')[0] in ['youtu', 'dvrsvid']
      //  return true
      if (typeof this.body === 'string') {
        if (this.body.startsWith('dvrsvid:') || this.body.startsWith('youtu:')) {
          return true;
        }
      } else {
        if (typeof this.body.id === 'string') {
          if (this.body.id.startsWith('dvrsvid:') || this.body.id.startsWith('youtu:')) {
            return true;
          }
        }
      }
    }
    return false;
  }

  isTargetMatch(curie) {
    if (this.target != null) {
      if (this.target === curie) {
        return true;
      } else {
        if (this.target.oa__hasSource != null) {
          if (this.target.oa__hasSource.id === curie) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getBodyCurie() {
    return ((typeof this.body === 'string') && this.body) || ((typeof this.body.id === 'string') && this.body.id);
  }

  getTargetLabel() {
    let label, src, target;
    if ((target = this.target) && (src = target.oa__hasSource) && (label = src.rdfs__label)) {
      return (Array.isArray(label) && label[0]) || label; // might be array or string
    }
    return '';
  }

  getBodyLabel() {
    let body, label, src;
    let retval = '';
    if ((body = this.body) &&
        ((label = body.rdfs__label) ||
         ((src = body.oa__hasSource) && (label = src.rdfs__label)))) {
      retval = unquoteLiteral((Array.isArray(label) && label[0]) || label); // might be array or string
    }
    return retval;
  }

  getBodyDescription() {
    let body, desc, src;
    let retval = '';
    if ((body = this.body) &&
        ((desc = body.schema__description) ||
         ((src = body.oa__hasSource) && (desc = src.schema__description)))) {
      retval = unquoteLiteral((Array.isArray(desc) && desc[0]) || desc); // might be array or string
    }
    return retval;
  }

  getMotivation() {
    let motive;
    let retval = '';
    if (motive = this.motivation) {
      retval = motive;
    }
    return retval;
  }
}
Annotation.initClass();
