/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

//const _ = ((typeof window !== 'undefined' && window !== null) && window._) || require('underscore');
import {RsrcDb, PrefixDb} from './rsrcidx.js';
import {Spogi} from './spogi.js';
import {WhoWhen} from './whowhen.js';
import {RdfObject} from './quadparser.js';
import {rebase, int_to_base} from './rebase.js';
import {default as _} from 'lodash-es';

//import {intersection} from 'lodash-es';

export class Query {
  constructor(terms, select_spec) {
    this.terms = terms;
    this.select_spec = select_spec;
  }

  isSatisfiedBy(spogi) {
    let satisfied = true;
    for (let term of Array.from(this.terms)) {
      let list = [];
      for (let spog_or_i in term) {
        const one_or_list = term[spog_or_i];
        if (Array.isArray(one_or_list)) {
          list = one_or_list;
        } else {
          list = [one_or_list];
        }
        const rsrc = spogi[spog_or_i];
        const val = rsrc.key();
        if (list.lastIndexOf(val) === -1) {
          satisfied = false;
          break;
        }
      }
      if (!list.length) {
        // TODO figure out what to do when the query term is empty
        console.log("isSatisfiedBy() query term is empty", term);
      }
    }
    return satisfied;
  }
}

export class NooDBAbstract {
  warn_once(warning) {
    if (!this._warnings) {
      this._warnings = {};
    }
    if (this._warnings[warning]) {
      return this._warnings[warning]++;
    } else {
      this._warnings[warning] = 1;
      return this.log.warning(warning);
    }
  }
      //console.warn(warning)

  report(report_name, why) {
    return this.log.debug(`report(${why})`);
  }
    //why.TYP=1
    //@allege('nrn:'+report_name, 'dc:comment', why, 'nrn:test_db')

  set_log_level_by_num(levelNo) {
    return this.log.level = levelNo;
  }

  name_it(thing, name) {
    if (thing != null) {
      if (thing._name != null) {
        throw `thing._name = '${thing._name}' so can not assign '${name}'`;
      }
      return thing._name = name;
    }
  }
    // else
    //   throw "there is NO thing to name '#{name}'"

  resolve_defaults(defaults, args, Log) {
    // Precedence is: args > defaults > this
    Object.assign(this, defaults);
    Object.assign(this, args);
    if (!this.log) {
      this.log = new Log(this.log_level); // https://github.com/tj/log.js
    }
    return this.log.info(`log_level: ${this.log_level}`);
  }

  set_server_start_time(date) {
    return this.server_start_time = date;
  }

  init_db(rd_ctx, init_callback) {
    //@name_it(init_callback, "init_callback")

    this.set_server_start_time(this.clock.asDate());
    this.read_count = 0;
    this.read_warn_last_time = this.clock.longAgo();

    const open_callback = () => {
      if (init_callback != null) {
        init_callback();
      }
    };
    this.name_it(open_callback, "open_callback");

    const read_callback = () => {
      this.server_log("nrn:serverLoadedSpogiCount", [this.read_count]);
      this.server_log("nrn:serverStartedAt", [this.clock.asDate().toISOString()]);
      this.open_db(open_callback);
    };
    this.name_it(read_callback, "read_callback");
    return this.read_db(this.fname, rd_ctx, read_callback);
  }

  get_array_of_random() {
    throw new Error("get_array_of_random should be implemented in subclasses");
  }

  next_entity_id(digits) {
    if (digits == null) { digits = 10; }
    return rebase.symbol_from_bytes(this.get_array_of_random(digits));
  }

  read_db(fname, read_more, fname_queue, callback) {
    return this.synthetic_key_factory_init(this.clock); // TODO better is file change date
  }

  synthetic_key_factory_init(synthetic_key_factory_clock) {
    this.synthetic_key_factory_clock = synthetic_key_factory_clock;
  }

  synthetic_key_factory_last(last_key) {
    const parts = last_key.split("_");
    const last_sec_baseXX = parts[2];
    const last_nsec_baseXX = parts[3];
    this.clock.last.sec = base_to_int(last_sec_baseXX);
    return this.clock.last.nsec = base_to_int(last_nsec_baseXX);
  }

  synthetic_key_factory_next() {
    const ss = this.get_server_session();
    const now = this.clock.now();
    return new WhoWhen().build(
        ss.user_symbol,
        ss.session_no,
        now.sec,
        now.nsec).repr();
  }

  now_baseXX() {
    return int_to_base(this.clock.now().sec);
  }

  build_indices() {
    this._query_listeners = {}; // TODO ren

    this.prefixdb =  new PrefixDb();
    this.by_subj =   new RsrcDb(this.prefixdb);
    this.by_pred =   new RsrcDb(this.prefixdb);
    this.by_obj =    new RsrcDb(this.prefixdb);
    this.by_graph =  new RsrcDb(this.prefixdb);
    this.by_id =     new RsrcDb(this.prefixdb);
    return this.by = {
      s: this.by_subj,
      p: this.by_pred,
      o: this.by_obj,
      g: this.by_graph,
      i: this.by_id
    };
  }

  preload_prefixes() {
    let k, v;
    for (k in this.universal_prefixes) {
      v = this.universal_prefixes[k];
      this.declare_prefix(k, v);
    }
    if (this.preloadable_prefixes) {
      return (() => {
        const result = [];
        for (k in this.preloadable_prefixes) {
          v = this.preloadable_prefixes[k];
          result.push(this.declare_prefix(k, v));
        }
        return result;
      })();
    }
  }

  make_spogi_from_penta(penta) {
    console.log("make_spogi_from_penta() is DEPRECATED");
    // A penta is JS object with keys 's,p,o,g,i'
    // and values either RdfUri or RdfObject as appropriate
    // though .i is a string
    //console.log "====> make_spogi_from_penta() o_isUri:#{o_isUri} o_value:#{o_value}"
    const spogi = new Spogi(
        this.by_subj.getOrCreate(penta.s.raw, true),
        this.by_pred.getOrCreate(penta.p.raw, true),
        this.by_obj.getOrCreate(penta.o.value, penta.o.isUri()),
        this.by_graph.getOrCreate(penta.g.raw, true),
        this.by_id.getOrCreate(penta.i, false));
    return spogi;
  }

  make_spogi_from_quint(quint, context) {
    // A quint is a 5-tuple with all values being strings in the order s,p,o,g,i
    // and with values which are URIs for s,p,g (and sometimes o) and a NID for i.
    // Sometimes o?  When the third term, o, satisfies o.match(/^["-+\d]/)
    // ie when o is a literal value because it is like:
    //   "whatever"@en
    //   "cafebabe"^^xsd:anyThing
    //   "whatever"
    //   3.14159
    //   +3.14159
    //   -3
    //   3
    // Incidentally, NIDs (eg: A2Xef9_f934z_94aS_23r) never start like a literal o either.
    //
    // Note also that a url can start with : as a prefix character

    // TODO
    //    1) ensure that quints as defined above are passed to this method
    //    2) ensure that RsrcDb..getOrCreate() conforms to this new interface
    // TODO clarify: this is either context or write_ctx
    if (context == null) { context = this.make_default_context(); }
    //@log.debug("make_spogi_from_quint() quint:", quint)
    //isObjUri = quint[2].match(
    const spogi = new Spogi(
        this.by_subj.getOrCreate(quint[0], null, context),
        this.by_pred.getOrCreate(quint[1], null, context),
        this.by_obj.getOrCreate(quint[2], null, context),
        this.by_graph.getOrCreate(quint[3], null, context),
        this.by_id.getOrCreate(quint[4], null, context));
    //@log.debug "  spogi:", typeof spogi #spogi?['constructor']?['name']
    //@log.debug("  <-- spogi:", spogi and spogi.asLine() or "d'oh")
    return spogi;
  }

  index(quint, context) {
    const try_writing = ((context != null) && context.try_writing) || false;
    // the id should be unique so deal with it first in case of collision
    if (/ontology YAGO3/.exec(quint[2])) {
      console.log(`THE FIRST CHAR OF '...ontology YAGO3...' IS <${quint[2][0]}>`);
    }
    const key = quint[4];
    if (this.by_id.exists(key)) {
      let msg;
      const existing = this.by_id.getOnly(key);
      //throw new Error "should handle id collision: #{key}"
      if (existing.eqlQuint(quint)) {
        // TODO replace with semantic logging
        msg = `index(${key}) ignored exact duplicate`;
        this.log.info(msg);
        return;
      } else {
        const e_str = existing.asTTL();
        const q_str = ""+quint;
        msg = `index(${key}) failed: key match but not content. ${e_str} <> ${q_str}`;
        const error_on_nonmatching_id_collision = false;
        if (error_on_nonmatching_id_collision) {
          throw new Error(msg);
        } else {
          this.log.warning(msg);
          return;
        }
      }
    }

    context = context || quint[5]; // REVIEW(smurp) should opts be dominating (or replacing) quint[5]
    const spogi = this.make_spogi_from_quint(quint, context);
    if (try_writing && (this.writeable_path != null)) {
      this.persist(spogi);
    }

    // Register this spogi on the various indices, either processing queries or not
    const processQueries = true;
    spogi.s.addObj(spogi, processQueries);
    spogi.p.addObj(spogi, processQueries);
    spogi.o.addObj(spogi, processQueries);
    spogi.g.addObj(spogi, processQueries);
    spogi.i.addObj(spogi, processQueries);
    return spogi;
  }

  declare_prefix(prefix, expansion) {
    try {
      return this.prefixdb.add_prefix(prefix, expansion);
    } catch (e) {
      if (!e.message.match('already been defined')) { // ignore these 'no harm' collisions
        throw e;
      }
    }
  }
      //else
      //  console.warn e.message

  allege_line(line, sess, date) {
    const quint = parseQuadLineToQuint(line);
    quint[4] = undefined; // differentiate between parseQuadLine and parseQuintLine
    return this.allege(quint[0], quint[1], quint[2], quint[3], sess, date);
  }

  allege(s, p, o, g, sess, date, context) {
    let now;
    if (typeof o !== 'string') { // TODO find a more correct way to see if o is a String
      o = `${o}`;
    }
    if ([s, p, o].includes(undefined)) {
      throw new Error(`everything must be defined of <s:${s}> <p:${p}> <o:${o}>`);
    }
    if ((sess == null)) {
      sess = this.get_server_session();
    }
    if (date != null) {
      now = date_to_now(date);
    } else {
      now = this.clock.now();
    }
    if (context == null) { context = this.make_default_write_ctx(); }
    const ww = new WhoWhen().build(
                        sess.user_symbol,
                        sess.session_no,
                        now.sec,
                        now.nsec);
    g = g != null ? g : this.default_graph;
    return this.index([s, p, o, g, ww.toSafeCURIE()], context); // REVIEW(smurp) context smells
  }

  select(lol, select_spec) {
    // select() takes lol - a list of lists and select_spec
    //   which:
    //     all, first, last
    //   columns (optional):
    //     A list of characters indicating which columns to return (s,p,o,g,i)
    //     If columns is provided then the return values is a list of lists
    //     and if it is not provided then a list of spogi is returned.
    //   flat (optional):
    //     A boolean to indicate that the return value should be a single
    //     list of the one column indicated in the columns setting
    //if @log.level is 7
    //  @log.debug "crushing it"
    //  for list,j in lol
    //    @log.debug("line #{j}", (item.key() for item in list) )
    let projector;
    this.log.debug(`select() lol.length: ${lol.length} select_spec:`,select_spec);
    this.log.debug("  lol:",[Array.from(lol)], lol);
    if (global.TRACE) {
      console.log("    lol:");
      console.log(lol);
      console.log("    select_spec:");
      console.log(select_spec);
    }
    if ((select_spec.columns != null) && (select_spec.columns.length > 0)) {
      this.log.debug("projector returns listOfLists");
      projector = function(listOfSpogi) {
        const flatten = (select_spec.columns.length === 1) && select_spec.flat;
        const listOfLists = [];
        for (let spogi of Array.from(listOfSpogi)) {
          const row = [];
          for (let columnId of Array.from(select_spec.columns)) {
            row.push(spogi[columnId].key());
          }
          if (flatten) {
            listOfLists.push(row[0]);
          } else {
            listOfLists.push(row);
          }
        }
        return listOfLists;
      };
    } else {
      this.log.debug("projector is NOOP", lol.length, lol[lol.length-1]);
      projector = listOfSpogi => listOfSpogi;
    }
    switch (select_spec.which) {
      case 'all':
        if (!this.instrumented) {
          return projector(_.intersection.apply(this,lol));
        } else {
          this.log.debug(lol);
          this.log.debug("lol: \n  " + lol.join(";\n  "));
          const pass1 = projector(_.intersection.apply(this,lol));
          this.log.debug("intersection: \n  " + pass1.join(";\n  "));
          return pass1;
        }
      case 'first':
        return projector(_.first(_.intersection.apply(this, lol)));
      case 'last':
        return projector(_.last(_.intersection.apply(this, lol)));
      case null:
        throw "NooDB.select(list_of_lists, {which: 'all'|'first'|'last'})";
    }
  }

  q(s, p, o, g, i) {
    let canned_query;
    this.log.debug(`q(${s}, ${p}, ${o}, ${g}, ${i})`);
    const terms = [];
    // FIXME this is so ugly! (but maybe it is the most efficient? probably)
    //   Wanted to do something like:
    //     terms =  _.object(_.zip("spogi".split(''),arguments))
    //   but mumble mumble
    if (s != null) {
      terms.push({s});
    }
    if (p != null) {
      terms.push({p});
    }
    if (o != null) {
      terms.push({o});
    }
    if (g != null) {
      terms.push({g});
    }
    if (i != null) {
      terms.push({i});
    }

    const server = this;
    return canned_query = {
      terms,
      run: _.bind(server.query, server, terms),
      last() {
        return this.run({which: 'last'});
      },
      first() {
        return this.run({which: 'first'});
      },
      all() {
        return this.run();
      }
    };
  }

  make_default_context() {
    //return {blank_prefix: null}
    //return {blank_prefix: '_'}
    return {blank_prefix: 'DEFAULT_BLANK_PREFIX'};
  }

  make_default_write_ctx() {
    return this.make_default_context();
  }

  query(terms, select_spec) {
    // supported queries:
    //   query([{s: "mailto:bob"}])
    //     all spogi with mailto:bob as subj
    //   query([{s: ["mailto:bob","mailto:betty"}])
    //     all spogi with either mailto:bob OR mailto:betty as subj
    //   query([{s: "nrn:fred", o: "nrn:barney"}])
    //     all spogi with subj=fred OR obj=barney
    //   query([{s: "nrn:plato"}, {p: "nrn:ate"}, {o: "nrn:hemlock"}])
    //     all spogi where plato AND ate AND hemlock
    //   query([{s: "nrn:Pagliacci"}, {p: "nrn:laughed"}], {which: 'last'})
    //     the last (latest) spogi where Pagliacci AND laughed
    //   query([{s: "nrn:nuke"}, {p: "nrn:explodedAtTime"}], {which: 'first'})
    //     the first (earliest) spogi where a nuke AND exploded
    let l;
    select_spec = select_spec != null ? select_spec : {};
    if (select_spec.which == null) { select_spec.which = "all"; }
    if (select_spec.context == null) { select_spec.context = this.make_default_context(); }
    const lol = []; // list of lists
    this.log.debug("query()");
    for (let term of Array.from(terms)) {
      l = [];
      this.log.debug("  term:", term);
      for (let k in term) {
        var list;
        const one_or_list = term[k];
        if (Array.isArray(one_or_list)) {
          list = one_or_list;
        } else {
          list = [one_or_list];
        }
        for (let v of Array.from(list)) {
          //safe_curie_v = @prefixdb.toSafeCURIE(v)
          //canonical_v = @by[k]get_canonical_form_typ_pair(v)[0]
          //console.log("safe_curie_v:", safe_curie_v)
          //l = l.concat(@by[k].getAll(safe_curie_v))
          if (global.TRACE) {
            console.log(`query() processing term k='${k}' v='${v}'`);
          }
          const more = this.by[k].getAll(v, null, select_spec.context);
          this.log.debug(`  l.concat(@by.${k}.getAll('${v}')`, more);
          l = l.concat(more);
          //@log.debug k, Object.keys(@by[k].mbrs)
          this.log.debug("  l:", l);
        }
      }
      if (!l.length) {
        // we can short circuit this failed query because this term is empty
        this.log.debug("  SHORT CIRCUIT", l, lol);
        this.select([[]], select_spec); // let select prepare the result
      }
      lol.push(l);
    }
    this.log.debug("  -> lol:", [(() => {
      const result = [];
      for (l of Array.from(lol)) {         result.push(l.constructor.name);
      }
      return result;
    })()], lol);
    return this.select(lol, select_spec);
  }

  exists(terms) {
    const resp = this.query(terms, {which: 'first'});
    if ((resp == null)) {
      return false;
    } else {
      return true;
    }
  }

  // http://rdflib.readthedocs.org/en/latest/univrdfstore.html#formula-context-interfaces
  contexts() {
    // TODO this should be async
    // TODO should accept a triple to constrain response
    const retval = [];
    for (let graph in this.by_graph.mbrs) {
      retval.push(graph);
    }
    return retval;
  }

  on_do(query_terms, callback) {
    const icl = new InsecureCallbackListener(callback);
    return this.subscribe_listener(icl, query_terms);
  }
    // TODO decide whether it should be a Query or just a 'terms'
    //   presumably a select_spec is needed too, especially for
    //   queries where the response spogi count should be 1, eg .last(), first() etc

  at_log_level_run(log_level, func) {
    const orig_log_level = this.log.level;
    this.set_log_level_by_num(log_level);
    const retval = func();
    this.set_log_level_by_num(orig_log_level);
    return retval;
  }

  subscribe(query_terms) {
    return this.on_do(query_terms, spogi => {
      return this.noodb.log("drop on floor:", spogi.toString());
    });
  }
      // a NOOP subscription, ie unconditionally subscribe to the query_terms

  subscribe_listener(listener, qry) {
    const symbol = toSymbol(qry);
    this.log.debug("symbol:",symbol);
    let q4l = this._query_listeners[symbol];
    if ((q4l == null)) {
      q4l = new QueryForListeners(qry, symbol, this);
      this._query_listeners[symbol] = q4l;
    }
    q4l.add_listener(listener);
    this.log.debug('  q4l.listeners:', q4l.listeners, ".query:",q4l.query);
    const permission_p = 'nrn:readableBy';
    this.query(q4l.query.terms, {which: 'all'}).forEach(spogi => {
      this.log.debug("subscribe() initial query spogi:", spogi.asRaw());
      return q4l.sendIfPermitted(spogi, permission_p, listener);
    });
  }

  unsubscribe_listener(listener, qry) { // TODO ensure this gets called eg when a visualization closes
    const symbol = toSymbol(qry);
    const q4l = this._query_listeners[symbol];
    if (q4l != null) {
      return q4l.remove_listener(listener);
    }
  }

  dump_matching(regex) {
    return (() => {
      const result = [];
      for (let k in this.by_id.mbrs) {
        const v = this.by_id.mbrs[k];
        if ((regex == null) || k.match(regex)) {
          result.push(console.log(k, v.occs[0].toString()));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  extractDateFromCurie(curie) {
    return (new WhoWhen().parse(curie).getDate());
  }
}

export class QueryForListeners {
  // TODO organize QueryForListeners into a 'grove' so
  constructor(terms, symbol, noodb) {
    this.symbol = symbol;
    this.noodb = noodb;
    this.query = new Query(terms);
    this.listeners = [];
    this.registerQueryOnFirstResource();
  }

  registerQueryOnFirstResource() {
    // Find the resources mentioned in the first term of the query
    // and hang this q4l off it so when satisfying spogi are indexed
    // they can have their listeners notified.
    const term = this.query.terms[0];
    if (term != null) {
      return (() => {
        const result = [];
        for (var spog_or_i in term) {
          const one_or_list = term[spog_or_i];
          var list = [];
          if (Array.isArray(one_or_list)) {
            list = one_or_list;
          } else {
            list = [one_or_list];
          }
          result.push((() => {
            const result1 = [];
            for (let rsrc_key of Array.from(list)) {
              const rsrc = this.noodb.by[spog_or_i].getOrCreate(rsrc_key); // Rsrc might not exist yet, so OrCreate
              if (rsrc != null) {
                this.noodb.log.info("ADDING QUERY FOR LISTENERS for rsrc_key:",rsrc_key);
                result1.push(rsrc.addQueryForListeners(this));
              } else {
                result1.push(this.noodb.log.error(`QueryForListener.registerQueryOnFirstResource() no Rsrc for key: ${rsrc_key}`));
              }
            }
            return result1;
          })());
        }
        return result;
      })();
    } else {
      return this.noodb.log.error("Query has no terms");
    }
  }

  add_listener(listener) {
    return this.listeners.push(listener);
  }

  remove_listener(listener) {
    const idx = this.listeners.indexOf(listener);
    if (idx) {
      return this.listeners.splice(idx, 1);
    }
  }

  sendIfSatisfies(spogi) {
    //@noodb.log.debug "sendIfSatisfies() spogi: #{spogi.toString()}"
    //console.log "sendIfSatisfies() spogi: #{spogi.toString()}"
    if (this.query.isSatisfiedBy(spogi)) {
      return this.listeners.forEach(listener => {
        let retval;
        const permission_p = 'nrn:readableBy';
        return retval = this.sendIfPermitted(spogi, permission_p, listener);
      });
    }
  }
        // TODO should retire listeners if their select_spec is satisfied (eg: 'first')

  sendIfPermitted(spogi, permission_p, listener) {
    //for k,v of listener
    //  console.log k
    //console.log "listener",listener
    //listener.send spogi
    if (listener.hasPermission(spogi, permission_p, this.noodb)) {
      listener.send(spogi);
      return true;
    }
    return false;
  }
}

export class AbstractListener {
  getUser(noodb) {
  }
}

export class InsecureListener extends AbstractListener {
  hasPermission(spogi, permission_p, noodb) {
    return true;
  }
}

export class InsecureCallbackListener extends InsecureListener {
  constructor(callback) {
    super();
    this.callback = callback;
    this.id = (new Date()).toISOString();
  }
  get_id() {
    return this.id;
  }
  send(spogi) {
    return this.callback(spogi);
  }
}

export class SecureListener extends AbstractListener {
  constructor() {
    super();
    this.perms = {}; // this is a cache of memoized responses for hasPermission
  }

  //userHasPermissionOnGraph: (noodb, g_key) ->
  userHasPermissionOnGraph(noodb, permission_p, g_key) {
    // Return false if there is no user or that user can not read the graph
    //   either because they have personal or group privileges to it.
    // TODO add graphIsReadableByGroupUserIsIn()
    let why;
    let retval = false;
    const user = this.getUser(noodb);
    noodb.log.info("user:",JSON.stringify(user));
    const o_list = ['nrn:public','nrn:unlisted'];
    if (user != null) {
      o_list.push(user.userCURIE);
    }
    //noodb.log.alert "o_list:", o_list
    const public_unlisted_or_the_user = noodb.exists([
        {s: g_key}
      ,
        {p: permission_p}
      ,
        {o: o_list}
      ,
        {g:'nrn:permissionsKB'}
      ]);
    if (public_unlisted_or_the_user) {
      return true;
    }
    if ((user == null)) {
      why =  `userHasPermissionOnGraph(${permission_p}, ${g_key}) ==> false, BECAUSE there is no user`;
      noodb.report('SecureListener', why);
      return false;
    }
    if ((user.groups == null)) {
      // if we do not yet know the user groups, cache them now
      user.groups = noodb.query([
          {s: user.userCURIE}
        ,
          {p: 'nrn:inGroup'}
        ,
          {g: 'nrn:permissionsKB'}
        ], {which:'all', columns:['o'], flat:true});
    }


    if (user.groups.length > 0) {
      // The user is in some groups, so is g_key readableBy any of them?
      noodb.log.debug(`${user.userCURIE} is in ${user.groups}`);
      retval = noodb.exists([
          {s: g_key}
        ,
          {p: permission_p}
        ,
          {o: user.groups}
        ,
          {g:'nrn:permissionsKB'}
        ]);
    }
    //else
    //  # The user is in no groups, so can not be permitted because of membership.
    //  why = "userHasPermissionOnGraph(#{permission_p}, #{g_key}) ==> false, BECAUSE user #{user.userCURIE} is in no groups"
    //  noodb.report('SecureListener', why)

    if (!retval) {
      // So far no positive permission has been found.
      // Check to see if the container this KB was found in gives permission
      retval = this.userHasPermissionOnGraphContainer(noodb, permission_p, g_key, user);
    }

    if (retval === false) {
      why = `userHasPermissionOnGraph(${permission_p}, ${g_key}) ==> false, BECAUSE user ${user.userCURIE} groups not permitted`;
      noodb.report('SecureListener', why);
    }
    return retval;
  }

  userHasPermissionOnGraphContainer(noodb, permission_p, g_key, user) {
    const p = 'nrn:inheritsPermissionsFrom';
    const inheritedPermissions = noodb.query([
        {s: g_key}
      ,
        {p}
      ,
        {g: 'nrn:permissionsKB'}
      ]);
    const containers = {};
    for (let perm of Array.from(inheritedPermissions)) {
      const container = perm.o.key();
      if (!containers[container]) { // has it not been tested before?
        if (this.userHasPermissionOnGraph(noodb, permission_p, container)) {
          return true; // permission found, bail out
        }
        containers[container] = true; // mark as tested, continue
      }
    }
    return false;
  }

  hasPermission(spogi, permission_p, noodb) {
    let userId;
    const user = this.getUser(noodb);
    const g_key = spogi.g.key();
    if ((this.perms[permission_p] == null)) { // eg permission_p = 'nrn:readableBy'
      this.perms[permission_p] = {};
    }
    let perm = this.perms[permission_p][g_key];
    if (user != null) {
      ({
        userId
      } = user);
    } else {
      userId = 'undefined';
    }
    noodb.log.info(`hasPermission(${spogi.toString()}) userId:${userId} perm:${perm}`);
    if ((perm == null)) {
      perm = this.userHasPermissionOnGraph(noodb, permission_p, g_key);
      this.perms[permission_p][g_key] = perm;
    }
    return perm;
  }
}

const keys = 'spogi'.split('');

var toSymbol = function(query) {
  // Purpose:
  //   Return concatented property/value pairs of query to use as JS identifier
  // Notes:
  //   A query is just a bare object with possible keys: s,p,o,g,i
  //   The keys, if present should always be concated in the same order
  //console.log("toSymbol()",query)
  const out = [];
  for (let term_idx in query) {
    //console.log "k:",term_idx
    const term = query[term_idx];
    //console.log " ", term_idx, term
    if (term != null) {
      for (let k in term) {
        const v = term[k];
        out.push(k+":"+v);
      }
    }
  }
  const retval = out.join(',');
  //console.log("  ==>", retval)
  return retval;
};
