import {AbstractAnnotationRecorder} from './abstractannotationrecorder.js';
import {
  colorlog, noop, strip_quotes, unquoteLiteral,
  Description,
  Discriminator, DataReducer,
  DragAndDropOrClickToVisualize,
  VisualizationController
} from '../visualizationcontroller.js';
let video_defaults = undefined;
let main_file_player = undefined;
let video_player_controls = undefined;
let video_types = undefined;
let con_video_dispay = undefined;
let num_vids = undefined;
export class PlayMediaAndAnnotations extends AbstractAnnotationRecorder {
    static initClass() {
      //CSSDependencies: ['/']
      // see views/front.html.eco for these. they were loading as text and what about crossorigin?
      this.prototype.DISABLED_ScriptDependencies = [
        "https://unpkg.com/prop-types@15.6/prop-types.min.js", //crossorigin
        "https://unpkg.com/react@16/umd/react.development.js", //crossorigin
        "https://unpkg.com/react-dom@16/umd/react-dom.development.js", //crossorigin
        "/bower_components/d3/d3.min.js",
        '/diversus-flower/index.js'
        ];
  
      this.func_name = 'playmedia';
      this.pretty_name = "Play Video";
      this.docs = "Show a video and its annotations";
      // Alternatives to native HTML5 player
      //   https://plyr.io/
  
      video_defaults = {
        comment_video_width: {
          max: 250,
          min: 150
        }
      };
  
      main_file_player = `\
<video class="main_video" controls curie="CURIE">
  <source src="SRC" type="video/TYP" />
  Your browser does not support the video tag.
</video>\
`;
  
      video_player_controls  = `\
<div class="playvid_flexbox">
  <div class="row main_vid_wrapper">
  </div>
  <div class="row video_details_wrapper">
    <div class="feedback__likes"> Views: 234, Links: 234</div>
    <div class="oa__motivation"></div>
    <div class="main_video_creation_date"></div>
    <h3 class="main_video_title"></h3>
    <p class="main_video_description"></p>
    <div class="fade_handle"><i class="fas fa-sort-down"></i><i class="fas fa-sort-up"></i></div>
  </div>
</div>`;
      // Those wacky little triangles to use as video range setter thumbs
      //   https://www.fileformat.info/info/unicode/char/25e2/index.htm
      //   https://www.fileformat.info/info/unicode/char/25e3/index.htm
      //       <span>&#9698;</span>
      //       <span>&#9699;</span>
      //       <br/>
  
      video_types = [
        {
        name: 'supporting',
        color: 'green'
        },
        {
        name: 'contradicting',
        color: 'black'
        },
        {
        name: 'assessing',
        color: 'darkblue'
        },
        {
        name: 'questioning',
        color: 'darkgoldenrod'
        }
      ];
      con_video_dispay = '';
      num_vids = 0;
  
      this.prototype.available_vidsrc = ['record', 'upload', 'hosted'];
  
      this.prototype.time_inputs = `\
<fieldset class="time_range_set">
  <legend>time range main video</legend>
  <div class="record_time_in">
    <label for="UNIQUE_begin">begin</label>
    <input id="UNIQUE_begin" type="number" step="0.01" min="0" name="rangeBegin" value="0.00"/>
    <button for="UNIQUE_begin" class="begin_lock fas fa-lock-open" type="button"></button>
  </div>
  <div class="record_time_out">
    <label for="UNIQUE_end">end</label>
    <input id="UNIQUE_end" type="number" step="0.01" min="0" name="rangeEnd" value="0.00"/>
    <button for="UNIQUE_end" class="end_lock fas fa-lock-open" type="button"></button>
  </div>
</fieldset>
<fieldset class="time_range_set">
  <legend>time range response video</legend>
  <div class="record_time_in">
    <label for="UNIQUE_begin">begin</label>
    <input id="UNIQUE_begin" type="number" step="0.01" min="0" name="rangeBegin" value="0.00"/>
    <button for="UNIQUE_begin" class="begin_lock fas fa-lock-open" type="button"></button>
  </div>
  <div class="record_time_out">
    <label for="UNIQUE_end">end</label>
    <input id="UNIQUE_end" type="number" step="0.01" min="0" name="rangeEnd" value="0.00"/>
    <button for="UNIQUE_end" class="end_lock fas fa-lock-open" type="button"></button>
  </div>
</fieldset>
  \
`;
    }
    //unavailable_vidsrc: ['hosted']

    constructor() { // playmedia(dvrsvid:big_buck_bunny__5mb) # PlayMediaAndAnnotations
      this.DEFUNCT_start_cntrl_drag = this.DEFUNCT_start_cntrl_drag.bind(this);
      this.DEFUNCT_end_cntrl_drag = this.DEFUNCT_end_cntrl_drag.bind(this);
      this.DEFUNCT_leave_drag_area = this.DEFUNCT_leave_drag_area.bind(this);
      this.update_progress_knob = this.update_progress_knob.bind(this);
      this.DEFUNCT_open_as_featured_video = this.DEFUNCT_open_as_featured_video.bind(this);
      this.YTPlayer_onStateChange = this.YTPlayer_onStateChange.bind(this);
      this.make_youtube_currentTime_monitor = this.make_youtube_currentTime_monitor.bind(this);
      this.change_displayer_duration = this.change_displayer_duration.bind(this);
      this.flowerRootClickHandler = this.flowerRootClickHandler.bind(this);
      this.YTPlayer_onReady = this.YTPlayer_onReady.bind(this);
      this.flowerPetalClickHandler = this.flowerPetalClickHandler.bind(this);
      this.flowerPetalDblClickHandler = this.flowerPetalDblClickHandler.bind(this);
      this.reset_layout = this.reset_layout.bind(this);
      this.video_playing = this.video_playing.bind(this);
      this.toggleRangeLock = this.toggleRangeLock.bind(this);
      this.svg_play_video = this.svg_play_video.bind(this);
      this.play_or_pause_video = this.play_or_pause_video.bind(this);
      this.polar_to_cartesian = this.polar_to_cartesian.bind(this);
      this.on_video_time_update = this.on_video_time_update.bind(this);
      this.start_video_response = this.start_video_response.bind(this);
      super(...arguments);
      this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
      this.video_curie = this.args[0];
      this.displayedAnnotations = {};
      this.queuedAnnotations = new Set();
      this.perform_subscriptions();
      this.subscribe_to_annotations();
      this.setInnerHTML(video_player_controls); // TODO this should be made more generic when other than videos desired
      this.displayersByCurie = {};
      // If the focusedCurie is the root then that is the Web Annotation "Target"
      // ie the thing that all the other petals are about.
      // If the focusedCurie is a petal then the curie represents an Annotation
      //     https://www.w3.org/TR/annotation-model/#web-annotation-principles
      this.focusedCurie = this.video_curie;
      this.main_displayer = this.get_or_create_displayer_for_curie(this.video_curie);
      this.create_response_controls(this.video_curie);
      this.create_video_responses_box();
      this.show_related_media();
      this.ensure_videoProvider({hidden: true});
      this.attach_myNavigator();
      window.addEventListener("orientationchange", this.orientchange_adjust);
      $("form.video_provider").addClass('playmedia');
      $(".video_details_wrapper").click(this.toggle_description_wrapper_size);
    }

    toggle_description_wrapper_size() {
      return $(".video_details_wrapper").toggleClass('full_height');
    }

    orientchange_adjust() {
      return $(".oa__motivation").text(window.orientation);
    }

    choose_provid(vidsrc) {
      super.choose_provid(vidsrc);
      return console.info(`PlayMedia.choose_provid(${vidsrc}) hide flower or scroll down to bottom?`);
    }
      // hide flower?
      // scroll down to bottom?

    receiveAnnotation(annot) {
      // Purpose:
      //   Every Annotation which arrives (or changes) comes here to
      //   be considered for possible display or other processing.
      const dump = msg => {
        if (this.kwargs.dump) {
          $(`#${this.content_id}`).append(`<li style='color:orange'>${msg}</li>`);
          return window.scrollTo(0,document.body.scrollHeight);
        }
      };
      if (annot.rdf__type === "oa:Annotation") {
        if (!this.displayedAnnotations[annot.id]) {
          if (annot.isBodyDisplayable()) {
            if (annot.isTargetMatch(this.video_curie)) {
              this.queueAnnotation(annot);
            } else { dump(`not isTargetMatch(${this.video_curie})`); }
          } else { dump('not isBodyDisplayable()'); }
        } else { dump('not already displayed, WTF????'); }
      } else { dump('not Annotation'); }
    }

    queueAnnotation(annot) {
      this.queuedAnnotations.add(annot);
      return this.drainQueuedAnnotationsIfReady();
    }

    readyToDrainQueuedAnnotations() {
      const main_displayer = this.getMainDisplayer();
      return !!main_displayer.video_duration;
    }

    drainQueuedAnnotationsIfReady() {
      let annot;
      if (!this.readyToDrainQueuedAnnotations()) {
        return;
      }
      const iterator = this.queuedAnnotations.values();
      while (annot = iterator.next().value) {
        this.displayAnnotation(annot);
        this.queuedAnnotations.delete(annot);
      }
    }

    displayAnnotation(annot) {
      this.displayedAnnotations[annot.id] = annot;
      this.addAnnotationToVisualization(annot);
      //@display_response_video_player(annot)
    }

    DEFUNCT_display_response_video_player(annot) {
      const player = this.create_response_video_player(annot, "100%");
      const vid_box = `<div class='vid_group'>${player}</div>`;
      $(this.response_box).append(player);
      this.video_play_pause_cntrl = $(this.localize(".play-cntrl"));
      this.video_play_pause_cntrl.off('click').click(this.svg_play_video);
      this.video_play_pause_cntrl.dblclick("dblclick", this.open_as_featured_video);
      this.slide_cntrl = $(this.localize(".slide-cntrl"));
      this.slide_cntrl.draggable().
          on("mousedown", this.start_cntrl_drag).
          on("drag", this.update_progress_knob).
          on("mouseup", this.end_cntrl_drag);
      $(this.localize(".vid-wrap")).
          on("mouseleave", this.leave_drag_area); //stop working controls when outside container
    }

    on_done_submitting_common(fullUri, triples, curie) {
      super.on_done_submitting_common(fullUri, triples, curie);
      this.hide_provide_widget();
      // focus on our contribution?
    }

    motivationToColor(motivation_curie) {
      //[prefix, local] = motivation_curie.split(':')
      //mapping = {'neutral': 'grey', 'support': 'green', 'checker': 'blue', 'contradiction': 'red',
      //  'custom': "yellow"}
      //return mapping[local or 'neutral'] or 'orange'
      return 'grey';
    }

    addAnnotationToVisualization(annot) {
      const petalArgs = {
        key: annot.id,
        title: annot.body.rdfs__label,
        thumbUrl: this.make_thumb_uri_from_curie(annot.getBodyCurie()),
        stroke: this.motivationToColor(annot.getMotivation()),
        relPos: .3
      };
      const relPos = this.getAnnotationStartMomentAsRelPos(annot);
      if (!relPos || (relPos > 1)) {
        colorlog(`${annot.body.id} has bad relPos ${relPos}`);
      } else {
        petalArgs.relPos = relPos;
      }
      return this.zeFlower.addPetal(petalArgs);
    }

    getVideoDurationPromise(videoElem, thenable) {
      // TASK: convert this so it is based on onloadedmetadata
      if (videoElem.duration === Infinity) {
        console.log(video,"has .duration of ", videoElem.duration);
        video.currentTime = 99999999;
        const delay = () => {
          // HACK delay the restart of the video once the proper duration is discovered
          return this.play_or_pause_video(videoElem.srcElement);
        };
        return setTimeout(delay, 500);
      }
    }

    getMainDisplayer() {
      return this.displayersByCurie[this.video_curie];
    }

    getMainVideoDuration() {
      const displayer = this.getMainDisplayer();
      const retval = displayer && displayer.video_duration;
      if (!retval) {
        colorlog("getMainVideoDuration() failed", 'red', '3em');
      }
      return retval;
    }

    getAnnotationStartMomentAsRelPos(annot) {
      let mediafrag, selector, startSec, target;
      const durationOfRoot = this.getMainVideoDuration();
      if (!durationOfRoot) {
        console.log("durationOfRoot unavailable");
        return;
      }
      if (!((target = annot.target) &&
              (selector = target.oa__hasSelector) &&
              (selector.dcterms__conformsTo === 'medifrag:') &&
              (mediafrag = selector.rdf__value)
              )) {
        console.log("can not get annot.target.oa__hasSelector.rdf__value for",annot.body.id);
        return;
      }
      mediafrag = mediafrag.replace(/\"/g, ''); // FIXME these surrounding quotes should already be gone
      mediafrag = mediafrag.replace(/^t\=/, ''); // strip leading "t="
      const [startSecStr, stopSecStr] = Array.from(mediafrag.split(','));
      try {
        startSec = parseFloat(startSecStr);
      } catch (e) {
        console.log("Annotation", annot.body.id, "parseFloat(${startSecStr})", e);
      }
      //debugger if not startSec?
      return (startSec / durationOfRoot);
    }

    DEFUNCT_start_cntrl_drag(e) {
      const vid_wrap = e.currentTarget.parentNode.parentNode;
      let video = vid_wrap.getElementsByTagName('video');
      video = video[0];
      return video.pause();
    }

    DEFUNCT_end_cntrl_drag(e) {
      console.log("mouse in, start dragging");
      const vid_wrap = e.currentTarget.parentNode.parentNode;
      let video = vid_wrap.getElementsByTagName('video');
      return video = video[0];
    }

    DEFUNCT_leave_drag_area(e) {
      console.log("exit and now ignore dragging");
      // drop the control handle when outside of vid container
      const cntrl = e.currentTarget;
      return $(cntrl).trigger("mouseup");
    }

    update_progress_knob(e) {
      const x = e.offsetX;
      const y = e.offsetY;
      const vid_wrap = e.currentTarget.parentNode.parentNode;
      //console.log e
      let video = vid_wrap.getElementsByTagName('video');
      video = video[0];
      //console.log video
      //console.log video.duration + "  " + video.currentTime
      const width = e.currentTarget.parentNode.clientWidth;
      const vid_offset = width * 0.05;
      const vid_width = (width * 0.9) / 2;
      const cntrl_radius = vid_width-vid_offset;
      const centerX = vid_width + vid_offset;
      const centerY = vid_width + vid_offset;
      //Cartesian coordinates to polar arc coordinates
      //console.log x + ", " + y + "  " + "width: " + vid_width
      const adj_x = x - (width/2);
      const adj_y = (width/2) - y;
      const radians = Math.atan2(adj_y,adj_x );
      const arc_pos = (radians * (-180/Math.PI)) + 90;
      //console.log adj_x + ", " + adj_y + "  " + "rad: " + radians# + "arc_pos: " + arc_pos
      const cntrl_pos = this.polar_to_cartesian(centerX, centerY, cntrl_radius, arc_pos);
      const slide_cntrl = e.currentTarget;
      slide_cntrl.setAttribute("cy", cntrl_pos.y);
      slide_cntrl.setAttribute("cx", cntrl_pos.x);
      slide_cntrl.setAttribute("r", "7px");
      // Calculate the new time
      const new_radians = radians + Math.PI;
      //console.log "radidans: " + new_radians
      let new_arc_pos = (radians * (-180/Math.PI)) + 90;
      if (new_arc_pos < 0) {
        new_arc_pos = new_arc_pos + 360;
      }
      const new_time = (new_arc_pos / 359.9) * video.duration;
      //console.log "arc: " + new_arc_pos  + " -> new time: " + new_time
      return video.currentTime = new_time;
    }

    DEFUNCT_open_as_featured_video(e) {
      //TODO - This is pretty ugly. Would be better if video info was from meta-data, I think.
      const {
        target
      } = e;
      const video_div = $(target).parent().parent().find("source").attr('src');
      let vid_name = video_div.split('.')[0];
      vid_name = vid_name.split('/').pop(-1);
      const vidCurie = `dvrsvid:${vid_name}`;
      this.run_playmedia_formurla(vidCurie);
    }
      //window.location.href = "playmedia(dvrsvid:#{vid_name},g=nrn:dvrsdata)"

    DEFUNCT_create_response_video_player(annot) {
      let motivation;
      const [body_namespace, body_id] = Array.from(annot.body.id.split(':'));
      if (annot.motivation != null) {
        motivation = annot.motivation.split(':').pop();
      } else {
        motivation = '';
      }
      const video_info = {
        src: body_id,
        type: 'webm',
        comm_type: motivation
      };
      return this.create_video_player_package(video_info);
    }

    create_video_displayer(curie) {
      // Build the right kind of video_player for the type of video this is: video, youtube, vimeo, dailymotion, etc
      let formatted_creation_date;
      const displayerRec = {
        curie, // TODO rename this to annot_or_root_curie
        isRoot: curie === this.video_curie
      };
      console.info(curie,"create_video_displayer()");
      // TODO clean up this creation date capture
      try {
        formatted_creation_date = this.format_post_date(curie);
      } catch (e) {
        console.log(`cannot format_post_date(${curie})`);
      }
      displayerRec.formatted_creation_date = formatted_creation_date || '';

      if (displayerRec.isRoot) { // ie curie is the ROOT PETAL or MAIN VIDEO
        displayerRec.video_curie = curie;
      } else { // curie is an Annotation with a body video associated with it
        let annot;
        if (annot = this.displayedAnnotations[curie]) {
          displayerRec.annot = annot;
          displayerRec.video_curie = annot.getBodyCurie();
          displayerRec.video_title = annot.getBodyLabel();
          displayerRec.video_description = annot.getBodyDescription();
        } else {
          throw new Error(`failed to find video_curie (aka body.id) for ${curie}`);
        }
      }
      const is_youtube = displayerRec.video_curie.startsWith('youtu:');
      if (is_youtube) {
        // FIXME this should be fancier: @main_youtube_player should instead be in an assoc keyed by curie
        const ytPlayer = this.create_youtube_player(displayerRec.video_curie, displayerRec);
        displayerRec.elem = ytPlayer.a;
        displayerRec.ytPlayer = ytPlayer;
        displayerRec.type = 'youtube';
      } else {
        // FIXME this should be fancier: @main_youtube_player should instead be in an assoc keyed by curie
        const video = this.create_video_player(displayerRec.video_curie, displayerRec);
        video.ondurationchange = evt => {
          return this.change_displayer_duration(evt, displayerRec);
        };
        displayerRec.elem = video;
        displayerRec.type = 'video';
      }
      return displayerRec;
    }

    create_response_controls(curie) {
      this.main_video_title = this.myQrySel(".main_video_title");
      this.main_video_description = this.myQrySel(".main_video_description");
      this.main_video_creation_date = this.myQrySel(".main_video_creation_date");
      this.main_video_motivation = this.myQrySel(".oa__motivation");
      window.addEventListener("resize", this.reset_layout);
    }

    create_youtube_player(curie, displayer) {
      // https://developers.google.com/youtube/iframe_api_reference#Loading_a_Video_Player
      //   Note that documented technique is to await the onYouTubeIframeAPIReady() call
      //   The following could fail as a result of possibly being called before YT.Player exists.
      //   If so the use of onYouTubeIframeAPIReady() would make sense.
      const video_id = localPartOfCurie(curie);
      const html = `<div class="main_youtube_video" curie="${curie}"></div>`;
      const youtube_video = this.insert_displayer_html_for_curie_and_class(html, curie, 'main_youtube_video');
      // https://developers.google.com/youtube/player_parameters#Parameters
      //   consider using params:
      //     fs: show fullscreen button? (0/1)
      //     controls: suppress youtube controls? (0/1)
      const ytpArgs = {
        height: 320,
        width: 480,
        rel: 0, // youtube will still show related vids, but 0 means at least from same channel
        //startSeconds: 300
        //endSeconds: .04 # about one frame
        modestbranding: 1, // simplify youtube branding
        iv_load_policy: 3, // suppress video annotations
        disablekb: 1,
        videoId: video_id,
        playsinline: 1, // cause iOS devices to NOT go fullscreen when playing
        //playerVars:
        //  autoPlay: 1
        events: {
          onReady: evt => {
            return this.YTPlayer_onReady(evt, displayer);
          },
          onStateChange: evt => {
            return this.YTPlayer_onStateChange(evt, displayer);
          }
        }
      };
      const youtube_player = new YT.Player(youtube_video, ytpArgs);
      youtube_player.a.setAttribute('curie', curie);
      return youtube_player;
    }

    YTPlayer_onStateChange(evt, displayer) {
      // https://developers.google.com/youtube/iframe_api_reference#Events
      console.log('onStateChange', evt);
      let ytp = YT.PlayerState;
      const toName = {
        '-1': 'UNSTARTED',
        '0': 'ENDED',
        '1': 'PLAYING',
        '2': 'PAUSED',
        '3': 'BUFFERING',
        '5': 'CUED'
      };
      ytp = YT.PlayerState;
      if (evt.data === ytp.PLAYING) {
        this.start_youtube_currentTime_monitor(displayer);
      } else if ([ ytp.PAUSED, ytp.ENDED ].includes(evt.data)) {
        this.stop_youtube_currentTime_monitor(displayer);
      } else {
        console.log("YP.PlayerState", evt.data, toName[""+evt.data], 'ignored');
      }
    }

    start_youtube_currentTime_monitor(displayer) {
      if (displayer.currentTime_monitor != null) {
        this.stop_youtube_currentTime_monitor(displayer);
      }
      return displayer.currentTime_monitor = setInterval(this.make_youtube_currentTime_monitor(displayer), 30);
    }

    stop_youtube_currentTime_monitor(displayer) {
      if (displayer.currentTime_monitor != null) {
        clearInterval(displayer.currentTime_monitor);
        return delete displayer.currentTime_monitor;
      }
    }

    make_youtube_currentTime_monitor(displayer) {
      return () => {
        const currentTime = displayer.ytPlayer.getCurrentTime();
        this.update_currentTime_listeners(currentTime || 0, displayer);
      };
    }

    play_or_pause_youtube(ytPlayer, which) {
      // https://developers.google.com/youtube/iframe_api_reference#Playback_status
      // ytPlayer is passed in so this might be either the main video or a response vid
      const ytp = YT.PlayerState;
      const UNSTARTED = -1;
      const pState = ytPlayer.getPlayerState();
      // pState in [ytp.PLAYING]
      const paused = [ UNSTARTED, ytp.BUFFERING, ytp.ENDED , ytp.PAUSED, ytp.CUED ].includes(pState);
      if (which == null) { which = (paused && 'play') || 'pause'; }
      if (which === 'play') {
        ytPlayer.playVideo();
      }
      if (which === 'pause') {
        ytPlayer.pauseVideo();
      }
    }

    getVideoId(curie) {
      if (curie == null) { curie = this.video_curie; }
      return localPartOfCurie(curie);
    }

    get_or_create_displayer_for_curie(curie) {
      let displayerRec = this.displayersByCurie[curie];
      if (!displayerRec) {
        const content_type = 'video';
        if (content_type === 'video') {
          displayerRec = this.create_video_displayer(curie);
          this.displayersByCurie[curie] = displayerRec;
        }
      }
      if (!displayerRec) {
        throw new Error(`get_or_create_displayer_for_curie() does not know how to deal with ${curie}`);
      }
      return displayerRec;
    }

    insert_displayer_html_for_curie_and_class(html, curie, css_class) {
      this.myQrySel('.main_vid_wrapper').insertAdjacentHTML('beforeend', html);
      return this.myQrySel('[curie="' + curie + '"].' + css_class); // get DOM not jquery obj
    }

    create_video_player(curie, displayer) {
      const subj = curie;
      const video_id_und_ext = subj.split('.'); // FIXME this is sooo weak!
      const video_id = this.getVideoId(video_id_und_ext[0]);
      const video_ext = video_id_und_ext[1] || "webm";
      const video_fname = `${video_id}.${video_ext}`;
      const video_fullpath = `/m/v/${video_fname}`;
      const video_html = main_file_player
        .replace(/CURIE/g, curie)
        .replace(/SRC/g, video_fullpath)
        .replace(/TYP/g, video_ext);
      const video_player = this.insert_displayer_html_for_curie_and_class(video_html, curie, 'main_video');
      $(video_player).append(
        '<source src="'+video_fullpath.replace('.webm','.mp4')+'"type="video/mp4"/>');
      video_player.ontimeupdate = evt => {
        //@on_video_time_update(evt, displayer)
        return this.update_currentTime_listeners(video_player.currentTime || 0, displayer);
      };
      return video_player;
    }

    change_displayer_duration(evt, displayer) {
      displayer.video_duration = evt.target.duration;
      this.drainQueuedAnnotationsIfReady();
    }

    create_video_responses_box() {
      $(`#${this.content_id} .playvid_flexbox`).
          append("<div id='level2_vids' class='row flower_wrap'>" + con_video_dispay + "</div>");
      this.response_box = this.localize("#level2_vids");
      //@record_video_response_button.click(@start_video_response)
    }

    show_related_media(elem) {
      if (elem == null) { elem = $('#level2_vids').append('<div class="a_flower"></div>')[0].lastElementChild; }
      if (elem) {
        this.zeFlower = putDiversusFlowerInElemOrId(elem, {
          demoModeAfterNoDataSec: -1,
          log: noop,
          warn: noop,
          showThumbnails: true
        }
        );
        // see props at: https://github.com/DIVERSUSandTIM/diversus-flower/blob/master/src/index.js#L506
        this.zeFlower.setRootPetal({
            title: this.main_video_title_title,
            key: this.video_curie,
            thumbUrl: this.make_thumb_uri_from_curie(this.video_curie)
        });
        this.zeFlower.setRootClickHandler(this.flowerRootClickHandler);
        this.zeFlower.setPetalClickHandler(this.flowerPetalClickHandler);
        this.zeFlower.setPetalDblClickHandler(this.flowerPetalDblClickHandler);
      } else {
        console.error('no elem for flower');
      }
    }

    getCurieFromPetal(petal) {
      return petal.props.myKey;
    }

    petalIsFocused(petal) {
      // The petal represents an Annotation, not a video!
      // The body of the annotation is displayed as the content of a non-root petal.
      // The target of an annotation is the root of flower.
      // The annoations are equivalent to the edges in an arbitrary graph structure.
      // It is important that the distinction between a petal representing an annotation
      // and the content which is to be displayed within the associated petal be maintained.
      // One reason is that the same content (eg the same video) might appear many many times
      // as the body (ie the displayable content) associated with multiple annotations, ie petals.
      // In truth the region of the body (ie the response)
      // https://www.w3.org/TR/annotation-model/#web-annotation-principles
      return this.focusedCurie === this.getCurieFromPetal(petal);
    }

    getFocusedDisplayer() {
      return this.displayersByCurie[this.focusedCurie];
    }

    focusOnDisplayer(displayer) {
      const focusedDisplayer = this.getFocusedDisplayer();
      this.hide_displayer(focusedDisplayer);
      this.play_or_pause_displayer(focusedDisplayer, 'pause');
      this.focusedCurie = displayer.curie;
      this.show_displayer(displayer);
    }

    flowerRootClickHandler(evt, rootPetal) {
      if (this.petalIsFocused(rootPetal)) {
        const focusedDisplayer = this.getFocusedDisplayer();
        this.play_or_pause_displayer(focusedDisplayer);
      } else {
        this.focusOnDisplayer(this.displayersByCurie[this.getCurieFromPetal(rootPetal)]);
      }
    }

    YTPlayer_onReady(evt, displayer) {
      colorlog('YTPlayer_onReady');
      //@main_video_duration = evt.target.getDuration()
      displayer.video_duration = evt.target.getDuration();
      this.drainQueuedAnnotationsIfReady();
    }

    flowerPetalClickHandler(evt, petal) {
      console.log("flowerPetalClickHandler() called", evt, petal);
      if (this.petalIsFocused(petal)) {
        this.play_or_pause_displayer(this.getFocusedDisplayer());
      } else {
        const displayer = this.get_or_create_displayer_for_curie(this.getCurieFromPetal(petal));
        this.focusOnDisplayer(displayer);
      }
    }

    flowerPetalDblClickHandler(evt, petal) {
      const curie = this.getCurieFromPetal(petal);
      const displayer = this.get_or_create_displayer_for_curie(curie);
      const {
        video_curie
      } = displayer;
      // TODO convert to a transformation of the Flower to make this Petal the Root
      this.run_playmedia_formurla(video_curie);
    }

    main_video_is_focused() {
      return this.focusedCurie === this.video_curie;
    }

    get_root_video_elem() {
      return this.get_video_elem_by_curie(this.video_curie);
    }

    get_video_elem_by_curie(curie) {
      const displayerRec = this.get_or_create_displayer_for_curie(curie);
      return displayerRec.elem;
    }

    hide_displayer(displayer) {
      displayer.elem.setAttribute('style', 'display:none');
    }

    show_displayer(displayer) {
      displayer.elem.setAttribute('style','display:inline');
      this.display_metadata(displayer);
    }

    display_metadata(displayer) {
      $(this.main_video_creation_date).text(displayer.formatted_creation_date || '');
      $(this.main_video_title).text(displayer.video_title || '');
      $(this.main_video_description).text(displayer.video_description || '');
      $(this.main_video_motivation).text(displayer.motivation || '');
    }

    DEFUNCT_hide_main_video() {
      let elem;
      if (elem = this.get_root_video_elem()) {
        elem.setAttribute('style','display:none');
      }
    }

    DEFUNCT_show_main_video() {
      let elem;
      if (elem = this.get_root_video_elem()) {
        elem.setAttribute('style','display:inline');
      }
    }

    DEFUNCT_hide_secondary_video(petal) {
      let elem;
      const curie = petal.props.myKey;
      if (elem = this.get_video_elem_by_curie(curie)) {
        elem.setAttribute('style','display:none');
      } else {
        console.log('hide_secondary_video() could not find', curie);
      }
    }

    DEFUNCT_show_secondary_video(petal) {
      let elem;
      const curie = petal.props.myKey;
      if (elem = this.get_video_elem_by_curie(curie)) {
        elem.setAttribute('style','display:none');
      } else {
        console.log('show_secondary_video() could not find', curie);
        this.get_or_create_displayer_for_curie(curie);
      }
    }

    reset_layout() { // Resizes comment videos & columns when screen is resized
      const width_col = this.calculate_cols_width();
      const vid_package_width = width_col.px;
      const width = `${width_col.pct}%`;
      $(".vid-wrap").css({"width": width, "padding-top": width});
      const startAngle = 0;
      const endAngle = 359.9;
      const slideBarArc = this.video_slide_bar(vid_package_width, startAngle, endAngle);
      return $("path.slide-arc").attr("d",slideBarArc);
    }

    DEFUNCT_create_video_player_package(video_info) { // Comment videos with formatting and controls
      let video_player_package;
      const video_display_id = this.make_unique_id("NOOVID_");
      const video_html = this.build_video_player(video_info);
      const vid_package_width =  this.calculate_cols_width(); //percentage and px width
      //console.log video_info.comm_type
      const vid_color = this.vid_type_color(video_info.comm_type);
      const video_svg = this.build_svg_controls(vid_package_width.px, vid_color);
      return video_player_package =
        `<div id='${video_display_id}' class='vid-wrap' \
style='width: ${vid_package_width.pct}%; padding-top: ${vid_package_width.pct}%;'> \
${video_html} ${video_svg} </div>`;
    }

    calculate_cols_width() {
      // return column widths
      let cols, width;
      const {
        min
      } = video_defaults.comment_video_width;
      const {
        max
      } = video_defaults.comment_video_width;
      const window_width = window.innerWidth;
      //console.log window_width
      const size_check = window_width / num_vids;
      if (size_check > max) {
        cols = (window_width / max)  + 1;
      } else if (size_check < min) {
        cols = window_width / min;
      } else {
        cols = window_width / size_check;
      }
      const col_width_px = window_width / Math.floor(cols);
      const col_width_pct = 100 / Math.floor(cols);
      return width = {
        "px": col_width_px,
        "pct": col_width_pct
      };
    }

    build_comment_video_grid(comment_video_items, num_vids) {
      // Based on the width of the window and setting min-size
      let video_grid;
      return video_grid = "<div class='vid-row'>" + comment_video_items + "</div>";
    }

    build_video_player(video_info) {
      // Get the file parameters for the video
      // file name, format type (e.g. video/mp4), height, width, thumbnail file name
      // TODO Test if thumbnail exists, otherwise create image? // File or URL?
      let thumbnail = video_info.thumbnail || "video_default_thumb.png";
      thumbnail = "/m/v/" + thumbnail;
      const vid_url = "/m/v/" + video_info.src;

      // If the vid_url is missing an extension give it the one from the type
      // TODO clearly this is a violent hack
      const guess_ext = !vid_url.match(/\.(webm|mp4)$/);
      const mksrc = (url, ext) => `<source src="${url}.${ext}" type="video/${ext}">`;
      const sources = [];
      if (guess_ext) {
        sources.push(mksrc(vid_url, 'webm'));
        sources.push(mksrc(vid_url, 'mp4'));
      }
      const type = "video/" + video_info.type;
      let resize = '';
      // Set height and width depending on orientation
      if ((video_info.size != null) && (video_info.size.height > video_info.size.width)) {
        resize = "width: 100%";
      } else {
        resize = "height: 100%";
      }
      //TODO Should set the video type from data
      return `<div class="vid-display vid-level2">
<video class="comment-video" preload="metadata" xxposter="${thumbnail}" style='${resize}'>
  				  ${sources.join("\n")}
              Your browser does not support the video tag.
</video>
  	    </div>`; // """ # for emacs
    }

    video_playing(video) {
      let inside;
      if (video.target.duration === Infinity) {
        console.log("Video has duration set to Infinity");
        //set current time to a very large number to force video duration to be reset
        video.target.currentTime = 99999999;
        const delay = () => {
          // HACK delay the restart of the video once the proper duration is discovered
          return this.play_or_pause_video(video.srcElement);
        };
        setTimeout(delay, 500);
      }
      const value = (100 / video.target.duration) * video.target.currentTime;
      const arc_pos = (359.9 * value)/100;
      const width = video.target.offsetParent.offsetParent.clientWidth;
      const vid_offset = width * 0.05;
      const vid_width = (width * 0.9) / 2;
      const cntrl_radius = vid_width-vid_offset;
      const x = vid_width + vid_offset;
      const y = vid_width + vid_offset;
      const slide_cntrl = video.target.parentNode.nextElementSibling.getElementsByClassName("slide-cntrl")[0];
      const cntrl_pos = this.polar_to_cartesian(x, y, cntrl_radius, arc_pos);
      const radius = cntrl_pos.y;
      slide_cntrl.setAttribute("cy", cntrl_pos.y);
      slide_cntrl.setAttribute("cx", cntrl_pos.x);
      slide_cntrl.setAttribute("r", "7px");
      const cx = cntrl_pos.x;
      const cy = cntrl_pos.y;
      return inside = "false";
    }

    build_svg_controls(vid_package_width, vid_color) {
      let svg;
      const startAngle = 0;
      const endAngle = 359.9;
      const stroke = "5px";
      const slideBarArc = this.video_slide_bar(vid_package_width, startAngle, endAngle);
      return svg =
        `<svg class='vid-type-circle'> \
<circle class='play-cntrl' cx='50%' cy='50%' r='35%' stroke='none' fill='none' \
pointer-events='visible'/> \
<path class='slide-arc' d='${slideBarArc}'style='stroke-width: ${stroke}; \
stroke: ${vid_color}; fill: none;></path> \
<path class='comment-arc'/> \
<circle class='slide-cntrl draggable ui-widget-content' cx='0' cy='0' r='0' \
stroke='${vid_color}' stroke-width= '2px'; fill='#eee'/> \
</svg>`;
    }

    vid_type_color(comm_type) {
      let vid_color;
      console.log(comm_type);
      if (!comm_type) {
        return 'grey';
      }
      const color = (Array.from(video_types).filter((record) => record.name === comm_type));
      if (color) {
        return vid_color = color[0].color;
      } else {
        return vid_color = 'pink';
      }
    }

    get_motivation_entries() {
      const terms = [{g: 'nrn:oa'},{p: 'rdf:type'},{o: 'oa:Motivation'}];
      const entries = [
        ['dvrsont:assessing','assessing'],
        ['dvrsont:contradicting','contradicting'],
        ['dvrsont:questioning','questioning'],
        ['dvrsont:supporting','supporting'],
        ];
      const resultSet = this.noodb.query(terms);
      for (let quad of Array.from(resultSet)) {
        const val = quad.s.key();
        const label = val.replace(/^.*\:/,'');
        entries.push([val,label]);
      }
      return entries;
    }

    insert_form_additions() {
      // FIXME: This should be refactored so the (rdf:type oa:Motivation)
      //   are lazily injected into the form from knowledge, dynamically
      //   thanks to the ol hivemind.
      //@insert_motivations_inputs()
      this.insert_time_inputs();
    }

    insert_motivations_inputs() {
      const additions = $(`#${this.content_id} .video_provider .additions`);
      if (!additions.length) {
        console.warn(".additions not found");
        return;
      }
      const motivationsFieldset = this.make_checkbox_fieldset({
        legend: 'Nature of Response',
        field_name: 'oa:motivatedBy',
        entries: this.get_motivation_entries()});
      additions.append(motivationsFieldset);
    }

    insert_time_inputs() {
      const additions = $(`#${this.content_id} .video_provider .additions`);
      if (!additions.length) {
        console.warn(".additions not found");
        return;
      }
      const id = this.make_unique_id();
      additions.append(this.time_inputs.replace(/UNIQUE/g, id));
      this.timeRangeLock2input = {};

      this.rangeBegin_input = $(this.localize("[name='rangeBegin']"));
      this.timeRangeLock2input[`${id}_begin`] = this.rangeBegin_input;
      this.rangeBeginLock =  $(this.localize(".begin_lock"));
      this.rangeBeginLock.on('click', this.toggleRangeLock);

      this.rangeEnd_input  = $(this.localize("[name='rangeEnd']"));
      this.timeRangeLock2input[`${id}_end`] = this.rangeEnd_input;
      this.rangeEndLock =  $(this.localize(".end_lock"));
      this.rangeEndLock.on('click', this.toggleRangeLock);
    }

    toggleRangeLock(evt) {
      const target = $(evt.target);
      window.thingy = target;
      const for_id = $(target).attr('for');
      const slave = this.timeRangeLock2input[for_id];
      const newState = !$(slave).prop('disabled');
      $(slave).prop('disabled', newState);
      if (newState) {
        target.removeClass('fa-lock-open');
        return target.addClass('fa-lock');
      } else {
        target.removeClass('fa-lock');
        return target.addClass('fa-lock-open');
      }
    }

    subscribe_to_annotations() {
      // REVIEW: This is redundant (and maybe not working).
      //   See server.coffee setUpVHostOrRedir() read_kb_qrys
      const qry = [];
      qry.push({g: 'nrn:dvrsdata'}); // FIXME how do you spec a KB?
      qry.push({g: 'nrn:oa'}); // FIXME how do you spec a KB?
      return this.noodb.subscribe_visualization(this, qry);
    }

    receive(spogi) {
      //console.log spogi.p.key()
      if (this.kwargs.dump > 1) {
        $(`#${this.content_id}`).append(`<h3 style=\"color:blue\">${spogi.asTTL().join('  ')} .</h3><br>`);
      }
      super.receive(spogi);
      const main_video_curie = this.args[0];
      const s_curie = spogi.s.key();
      // TODO move all of this to create_video_displayer
      if (s_curie === main_video_curie) {
        const displayer = this.get_or_create_displayer_for_curie(s_curie);
        const p_curie = spogi.p.key();
        const val = unquoteLiteral(spogi.o.literal_value());
        if (p_curie === 'rdfs:label') {
          displayer.video_title = val;
        } else if (p_curie === 'schema:description') {
          displayer.video_description = val;
        } else if (p_curie === 'oa:motivation') {
          displayer.motivation = val;
        } else {
          return; // so we skip display_metadata()
        }
        if (this.focusedCurie === s_curie) { // we do not want to change the display if a petal is focused
          this.display_metadata(displayer);
        }
      }
    }

    create_video_player_controls(video) {
      let player_controls;
      return player_controls = `\
<div id="vc-${video}">
</div>`; // """
    }

    svg_play_video(e) {
      const vid_wrap = e.currentTarget.parentNode.parentNode;
      let video = vid_wrap.getElementsByTagName('video');
      video = video[0];
      //console.log "duration:" + video.duration
      //console.log "currentTime:" + video.currentTime
      video.addEventListener("timeupdate", this.video_playing);
      this.play_or_pause_video(video);
    }

    play_or_pause_secondary_video(video, which) {
      return console.warn("play_or_pause_secondary_video() called, but not implemented");
    }

    play_or_pause_displayer(displayer, which) {
      // Toggle between play and pause or do as indicated by the optional which argument
      if (displayer.type === 'video') {
        this.play_or_pause_video(displayer.elem, which);
      }
      if (displayer.type === 'youtube') {
        this.play_or_pause_youtube(displayer.ytPlayer, which);
      }
    }

    play_or_pause_video(video, which) {
      if (which == null) { which = (video.paused && 'play') || 'pause'; }
      if (which === 'play') {
        video.play();
      }
      if (which === 'pause') {
        return video.pause();
      }
    }

    video_slide_bar(vid_package_width, startAngle, endAngle) {
		  //vid_offset = document.getElementsByClassName('vid_display')[0].offsetLeft
      const vid_offset = vid_package_width * 0.05;
		  //vid_width = (document.getElementsByClassName('vid_display')[0].offsetWidth)/2
      const vid_width = (vid_package_width * 0.9) / 2;
      const cntrl_radius = vid_width-vid_offset;
      const x = vid_width + vid_offset;
      const y = vid_width + vid_offset;
      return this.describe_arc(x, y, cntrl_radius, startAngle, endAngle);
    }

    polar_to_cartesian(centerX, centerY, radius, angleInDegrees) {
  	  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  	  return {
  		  x: centerX + (radius * Math.cos(angleInRadians)),
  		  y: centerY + (radius * Math.sin(angleInRadians))
      };
    }

    describe_arc(x, y, radius, startAngle, endAngle) {
      //console.log "x: #{x}, y: #{y}, r: #{radius}"
      let arc;
      const start = this.polar_to_cartesian(x, y, radius, endAngle);
      const end = this.polar_to_cartesian(x, y, radius, startAngle);
      const arcSweep = (endAngle - startAngle) <= 180 ? "0" : "1";
      //console.log "M #{start.x} #{start.y} A #{radius} #{radius} 0 #{arcSweep} 0 #{end.x} #{end.y}"
      return arc = `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${arcSweep} 0 ${end.x} ${end.y}`;
    }

    make_responses_draggable() {
      const DNDer = new DragAndDropOrClickToVisualize(this);
      const thumb_croppers = $(this.localize('.thumb-cropper'));
      thumb_croppers.draggable({start: DNDer.drag});
      return thumb_croppers.dblclick(DNDer.click); // TODO should make this video the main one
    }

    describeDraggable(sourceElem) {
      const desc = new Description();
      desc.viz = this;
      desc.frac_id = this.fracpanel.frac_id;
      desc.thing_isa = 's';
      desc.thing_valuetype = 'string';
      desc.thing_value = sourceElem.id;
      return desc;
    }

    on_video_time_update(e, displayer) { // moved body of this method into create_video_player
      // TODO If the user has manually adjusted the start or stop time,
      // we should not automatically change it.  But for now...
      this.update_currentTime_listeners(this.main_video.currentTime || 0, displayer);
    }

    update_currentTime_listeners(currentTime, displayer) {
      this.update_response_range_inputs(currentTime);
      this.zeFlower.update_currentTime_and_relPos(currentTime, currentTime/displayer.video_duration);
    }

    update_response_range_inputs(currentTime) {
      if (!this.rangeBegin_input) {
        return;
      }
      const val = (currentTime || 0).toFixed(2);
      if (!this.rangeBegin_input.prop('disabled')) {
        this.rangeBegin_input.val(val);
      }
      if (!this.rangeEnd_input.prop('disabled')) {
        this.rangeEnd_input.val(val);
      }
    }

    make_response_button_draggable() {
      let ignore;
      this.record_video_response_button.draggable({
        axis: 'x',
        XXstart: () => {
          //alert('oink')
          return this.main_video.pause();
        },
        XXend: () => {
          return this.main_video.play();
        }
      });

      return ignore = {
        refreshPositions: true,
        scroll: false,
        //snapTolerance: 500
        cursorAt: {
          top: 5,
          left: 5
        },
        XXXhelper(e, ui) {
          const elem_to_drag = e.target;
          $(elem_to_drag).css('left', e.clientX).css('top', e.clientY);
          return true;
        }
      };
    }

    build_videoProvider() {
      super.build_videoProvider(...arguments);
      this.insert_form_additions();
    }

    start_video_response() {
      this.main_video.pause();
      this.ensure_videoProvider();
      return true;
    }

    get_video_played_fraction(currentTime) {
      if (currentTime == null) { ({
        currentTime
      } = this.main_video); }
      return currentTime / this.main_video.duration;
    }

    set_video_played_fraction(new_fraction) {
      const newTime = newFraction * this.main_video.duration;
      return this.main_video.currentTime = newTime;
    }

    submit_video_response() {
      let jsonld_annot;
      const time_1 = this.main_video.currentTime;
      // https://www.w3.org/TR/annotation-model/
      return jsonld_annot = {
        body: "http://uri.of.response",
        target: {
          source: this.get_main_video_uri(),
          selector: {
            range: "t=npt:10,20"
          }
        }
      };
    }
}
PlayMediaAndAnnotations.initClass();
// from sec 10, to sec 20
