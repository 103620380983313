/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// The KnobController is a superclass for things like the:
//  * visualization_button
//  * action_button
//  * search_button
// which occupy the corners of the FractalComponent.
//
// Notes:
//   There is no reason why KnobController subclasses could not be put
//   in various places along the edges of FractalComponent (rather like
//   the control knobs around the corners of edges of Morphic objects in
//   Squeak).

export class KnobController {
  static initClass() {
    this.prototype.elem_tmpl = '<div></div>';
    this.prototype.button_defaults =
      {icon: 'cog'};
  }
  constructor(fractalcomponent, trigger_button) {
    // @fractalcomponent is the FractalComponent into which the panel
    //   which this knob presents should be drawn
    this.fractalcomponent = fractalcomponent;
    this.trigger_button = trigger_button;
    this.make_panel();
  }
  make_panel(literal_html) {
    if (literal_html == null) { literal_html = this.elem_tmpl; }
    this.elem = $(literal_html);
    if (this.css_classes != null) {
      this.elem.attr('class',this.css_classes);
    }
    if (this.main_icon != null) {
      this.elem.append($(`<i class=\"fa fa-${this.main_icon}\"></i> `));
    }
    if (this.elem_label != null) {
      this.elem.append($(`<span>${this.elem_label}</span>`));
    }
    this.fractalcomponent.elem.append(this.elem);
    this.trigger_button.on("click", () => {
      return this.elem.show();
    });
    this.elem.on("mouseleave", () => {
      return this.elem.hide();
    });
    return this.elem.hide();
  }
  hide_popup() {
    return this.elem.hide();
  }
  get_visualization_I_accessorize() {
    return this.fractalcomponent.visualization_instance;
  }
  make_button_for(label, action, opt) {
    if (opt == null) { opt = {}; }
    opt.__proto__ = this.button_defaults;
    const style = ((opt.color != null) && `style=\"color:${opt.color}\"`) || '';
    const icon = ((opt.icon != null) && `<i ${style}class=\"fa fa-${opt.icon}\"></i> `) || '';
    const button = $(`<button style="display:block">${icon}${label}</button>`);
    this.elem.append(button);
    return button.on('click', action);
  }
  watch_for(condition, triggers, callback) {
    if (triggers == null) { triggers = { attributes: true }; }
    this.target = this.elem[0];
    const observer = new MutationObserver(mutations => // http://caniuse.com/#feat=mutationobserver
    mutations.forEach(function(mutation) {
      if ($(mutation.target).is(condition)) {
        return callback();
      }
    }));
    return observer.observe(this.target, triggers);
  }
}
KnobController.initClass();

