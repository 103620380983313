import {SpecialColumnsTable} from './specialcolumnstable.js';

export class DCIMenu extends SpecialColumnsTable {
  static initClass() {
    this.func_name = 'dcimenu';
    this.pretty_name = 'Action Menu';
    this.docs = `This visualization shows the menu of Actions in the 'action_menu'
in the lower left corner of every FractalPanel.
  
Test with http://localhost:9998/__/dcimenu(g=nrn:NooronActions)\
`;
  
    this.prototype.vm_settings = {
      prefix: 'action:',
      caching: true
    };
  
    this.prototype.special_column_handlers = {
      'naa:font_awesome_icon': false, // @prepend_font_awesome_icon
      'naa:css_color_name': false,    // @color_icon_with
      'naa:FNC': this.register_to_run_the_FNC
    };
  }

  constructor() {
    super(...arguments);
    this.register_to_run_the_FNC = this.register_to_run_the_FNC.bind(this);
    this.run_FNC = this.run_FNC.bind(this);
    this.vm = this.noodb.make_vm(this.vm_settings);
  }

  get_title() {
    return "Pick an Action or Verb...";
  }

  register_to_run_the_FNC(spogi) {
    const fnc_body = spogi.o.key();
    return spogi;
  }

  run_FNC(evt) {
    let func_name = this.get_qname_from_elem(evt.target);
    try {
      if (func_name) {
        func_name = func_name.replace(this.vm_settings.prefix, "");
        const retval = this.noodb.apply_by_name(func_name, this.vm);
        if (retval != null) {
          alert(`${func_name} returns: ${retval}`);
        }
        return retval;
      }
    } catch (e) {
      alert(e.toString());
      throw e;
    }
  }

  get_candidate_click_callback() {
    return this.run_FNC;
  }
}
DCIMenu.initClass();
