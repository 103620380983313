import {AbstractBlocklyController} from './abstractblocklyctrl.js';

export class BlocklyControllerTest extends AbstractBlocklyController {
  static initClass() {
    this.func_name = 'blockly';
    this.pretty_name = "Blockly Panel";
    this.docs = "Programming panel using Blockly";
  }
  constructor() {
    super(...arguments);
    this.showCodeScript = this.showCodeScript.bind(this);
    this.resize_handler = this.resize_handler.bind(this);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    $("#"+`${this.content_id}`).append('<div id="blocklyDiv-' + this.content_id + '" style="height: ' + this.get_panelSize().height + 'px; width: ' + this.get_panelSize().width + 'px;overflow:hidden"></div>');

    //add custom blocks (test)
    Blockly.Blocks['formurla_viz_picker'] = { init() {
      return this.jsonInit({
        message0: "%1 %2",
        args0: [
            {
              type: "field_dropdown",
              name: "vis_type",
              options: [
                [
                  "Subjects Table",
                  "subjects"
                ],
                [
                  "Scatter Plot",
                  "scatter"
                ],
                [
                  "Node-Link Layout",
                  "graph"
                ],
                [
                  "Bar Graph",
                  "bar"
                ]
              ]
            },
            {
              type: "input_value",
              name: "value_vis_type"
            }
          ],
        output: "String",
        colour: 330,
        tooltip: "Returns number of letters in the provided text.",
        helpUrl: "http://www.w3schools.com/jsref/jsref_length_string.asp"
        });
    }
  };

    Blockly.Blocks['data_set'] = { init() {
      return this.jsonInit({
        message0: "Data Set: %1",
        args0: [
            {
              type: "field_input",
              name: "data_set",
              text: "test"
            }
          ],
        output: "string",
        colour: 160,
        tooltip: "Returns number of letters in the provided text.",
        helpUrl: "http://www.w3schools.com/jsref/jsref_length_string.asp"
        });
    }
  };

    //Code generator for custom Blocks
    Blockly.JavaScript['formurla_viz_picker'] = function(block) {
      this.dropdown_vis_type = block.getFieldValue('vis_type');
      this.value_value_vis_type = Blockly.JavaScript.valueToCode(block, 'value_vis_type', Blockly.JavaScript.ORDER_ATOMIC);
      const code = this.dropdown_vis_type + '(' + this.value_value_vis_type + ')';
      return [code, Blockly.JavaScript.ORDER_ATOMIC ];
    };

    Blockly.JavaScript['data_set'] = function(block) {
      this.text_data_set = block.getFieldValue('data_set');
      const code = "g=nrn:" + this.text_data_set;
      return [code, Blockly.JavaScript.ORDER_ATOMIC ];
    };

    //Code version of Blockly injection
    this.toolbox = '<xml>';
    //@toolbox += '  <block type="controls_if"></block>'
    //@toolbox += '  <block type="text"></block>'
    this.toolbox += '  <block type="formurla_viz_picker"></block>';
    this.toolbox += '  <block type="data_set"></block>';
    //@toolbox += '  <category name="Control"><block type="controls_if"></block></category>';
    //@toolbox += '  <category name="Logic"><block type="controls_whileUntil"></block></category>';
    this.toolbox += '</xml>';
    this.workspace = Blockly.inject('blocklyDiv-' + this.content_id, {toolbox: this.toolbox, scrollbars: false});
    //move toolbox into proper div
    console.log($(".blocklyToolboxDiv").html());

    //Show code area
    $("#blocklyDiv-" + this.content_id).append('<div class="codeDisplay" style="position:absolute;top:0;right:0;width:400px;background-color:#eee;padding:10px;">Code Window</div>');

    this.workspace.addChangeListener(this.showCodeScript);
    this.addResizeListener();
    this.initBlockly();
  }


  initBlockly() {
    // Currently pulls XML info from the page itself. For our purposes it would be better if we more directly created the initial workspace from code
    this.workspaceDefault = jQuery.parseXML(`<xml><block type="controls_if" inline="false" x="20" y="20"> \
<mutation else="1"></mutation> \
<value name="IF0"> \
<block type="logic_compare" inline="true"> \
<field name="OP">EQ</field> \
<value name="A"> \
<block type="math_arithmetic" inline="true"> \
<field name="OP">MULTIPLY</field> \
<value name="A"> \
<block type="math_number"> \
<field name="NUM">6</field> \
</block> \
</value> \
<value name="B"> \
<block type="math_number"> \
<field name="NUM">7</field> \
</block> \
</value> \
</block> \
</value> \
<value name="B"> \
<block type="math_number"> \
<field name="NUM">42</field> \
</block> \
</value> \
</block> \
</value> \
<statement name="DO0"> \
<block type="text_print" inline="false"> \
<value name="TEXT"> \
<block type="text"> \
<field name="TEXT">Don\'t panic</field> \
</block> \
</value> \
</block> \
</statement> \
<statement name="ELSE"> \
<block type="text_print" inline="false"> \
<value name="TEXT"> \
<block type="text"> \
<field name="TEXT">Panic</field> \
</block> \
</value> \
</block> \
</statement> \
</block></xml>`);
    return Blockly.Xml.domToWorkspace( this.workspace, $(this.workspaceDefault).find('xml')[0]); //initialize the workspace to a default
  }


  showCodeScript() {
    return $(this.localize(".codeDisplay")).text(Blockly.JavaScript.workspaceToCode(this.workspace));
  }

  addResizeListener() {
    return $(this.fracpanel.content_area).bind("_splitpaneparentresize", this.resize_handler);
  }

  resize_handler() {
    this.bheight = this.get_panelSize().height-20; //panel -20px to avoid scroll bars appearing on resize
    this.bwidth = this.get_panelSize().width-20;
    return $("#"+`${this.content_id}` + " #blocklyDiv").width(this.bwidth).height(this.bheight);
  }

  get_panelSize() {
    return {
      width: $(this.visContent).width(),
      height: $(this.visContent).height()
    };
  }
}
BlocklyControllerTest.initClass();
