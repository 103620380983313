import {TabularVisualizationController} from './tabular.js';

export class EvaluationsTable extends TabularVisualizationController {
    static initClass() {
      this.func_name = 'evaluations';
      this.pretty_name = "Evaluations";
      let id = "evaulations_table";
    }

    get_criteria() {
      const retval = [{
        label: 'when',
        id: 'whn',
        updateable_by: 'system',
        valuetype_id: 'datetime'
      }
      , {
        label: 'author',
        id: 'author',
        updateable_by: 'system',
        valuetype_id: 'plain'
      }
      , {
        label: this.get_predicate_id(), // TODO calculate this like: getLabelForPredicate(p:) where p: is a kwarg
        id: 'predicate',            // this could be the predicate id, but maybe that does not matter
        updateable_by: 'system',
        valuetype_id: 'plain'
      }      // TODO calculate this like: getDataTypeForPredicate(p:)
      ];

      if (this.include_subject_column()) { // if no subject was specified then show a column for the subjects of the evaluations
        retval.splice(2,0,{
          label: 'subject',
          id: 'subject',
          updateable_by: 'system',
          cells_visualizable: true,
          valuetype_id: 'plain'});
      }

      return retval;
    }

    get_title() {
      let topic = "";
      for (let k of Array.from("s,p,o,g,i".split(","))) {
        if (this.kwargs[k] != null) {
          if (topic) {
            topic += ", ";
          }
          topic += `${k}=${this.kwargs[k]} `;
        }
      }
      // topic = @graph_uri or @kwargs.s or @kwargs.i or @kwargs.g or "improve get_title()"
      topic = topic || "improve get_title()";
      return `${this.constructor.name} for (${topic})`;
    }

    include_subject_column() {
      return (this.kwargs.s == null);
    }

    get_predicate_id() {
      return this.kwargs.p;
    }

    receive(spogi) {
      const i = spogi.i.key();

      this.widget.add_candidate({
        id: i,
        //url: "allegations(i=#{i})"
        label: i
      });
      const whn = spogi.ww().getISO();
      this.widget.add_datum([i, 'whn', whn]);
      const author = i.split('_')[0];
      this.widget.add_datum([i, 'author', author]);
      const the_eval = spogi.o.getNativeValue();
      this.widget.add_datum([i, 'predicate', the_eval]);
      if (this.include_subject_column()) {
        const subj = spogi.s.key();
        return this.widget.add_datum([i, 'subject', subj]);
      }
    }

    describeDraggable(draggableElem) {
      // Our purpose in this method is to return a description of cell which
      // was clicked or dragged so it can be determined how to visualize it.
      // These descriptions should be equivalent to english sentences such as:
      //   "'rdfs:label' is the URI of a predicate."
      //      .thing_value: "rdfs:label"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'p'  # a predicate
      //   "'nrn:primordialKB' is the qname of a graph."
      //      .thing_value: "nrn:primordialKB"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'g'  # a graph
      // Weirdly, for the allegations() visualization anyway, the .i of the
      // row the cell is from doesn't really matter.  It seems hard to assert,
      //  but the graph doesn't really matter either.
      //
      // WARNING this was copied from above. MUST BE CHANGED DRAMATICALLY
      const desc = super.describeDraggable(draggableElem);
      const cand_id = this.findTRid(draggableElem);
      const crit_idx = this.getTableColumnNum0(draggableElem);
      const weak_cand_id = this.unstrengthen_id(cand_id);
      if (crit_idx != null) {
        const criterion = this.widget.get_criterion_by_idx(crit_idx);
        if (criterion != null) {
          const crit_id = criterion.id;
          if (crit_id != null) {
            desc.criterion_id = crit_id;
            const datum = this.widget.get_datum_by_cand_id_and_crit_id(cand_id, crit_id);
            desc.thing_isa = crit_id; // eg: s,p,o,g,whn,who,i
            desc.thing_valuetype = datum.get_valuetype();
            desc.thing_value = datum.get_saveable_value();
          }
        }
      }

      // desc.id = weak_cand_id # does not matter!!!
      return desc;
    }
}
EvaluationsTable.initClass();
