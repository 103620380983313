/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

export const one_million = 1000 * 1000;
const one_billion = 1000 * one_million;

export const date_to_now = function(date) {
  const now = {};
  const now_msec = date.getTime();
  now.sec = Math.floor(now_msec/1000);
  now.nsec = (now_msec % 1000) * one_million;
  return now;
};

export class Clock {
  longAgo() { return new Date(1); } // one second after the epoch

  now(date_or_now) {
    // Accept either:
    //   a 'now' object like {sec: 123412343, nsec: 192837465}
    //   a Date()
    //   or nothing
    // Returns a 'now' object at least one nanosecond later
    let now;
    date_or_now = date_or_now != null ? date_or_now : new Date();

    if (date_or_now.sec != null) {
      now = date_or_now;
    } else {
      now = date_to_now(date_or_now);
    }
    if (this.last != null) {
      if (this.last.sec === now.sec) {
        if (this.last.nsec === now.nsec) {
          now.nsec++;
          if (now.nsec >= one_billion) { // carry!
            now.nsec = 0;
            ++now.nsec;
          }
        } else {
          now.nsec = this.last.nsec + 1;
        }
      }
    }
    this.last = now;
    return {sec:this.last.sec, nsec:this.last.nsec};
  }

  asDate() {
    return new Date(this.asMsec());
  }
  asMsec() {
    const now = this.now();
    return (now.sec * 1000) + ((now.nsec / one_million) | 0);
  }
}

export class ControlledClock extends Clock {
  constructor(date) {
    if ((date == null)) {
      throw new Error("ControlledClock(initial_date) requires an initial Date()");
    }
    this.last = date_to_now(date);
  }
  now(date_or_now) {
    return super.now(this.last);
  }
}
