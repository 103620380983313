import {
  VisualizationController
} from './visualizationcontroller.js';

export class AbstractKnowledgeHostedVisualizationController extends VisualizationController {
  static initClass() {
    this.func_name = null; // this is Abstract ie only subclasses can be instantiated
    this.docs = `This abstract visualization is a foundation and bridge from
conventionally-hosted code to knowledge-hosted visualizations.\
`;
  }
  static constructor() {
    return super.constructor(...arguments);
  }
}
AbstractKnowledgeHostedVisualizationController.initClass();
