import {SubjectsTable} from './subjectstable.js';

export class SpecialColumnsTable extends SubjectsTable {
  static initClass() {
    this.func_name = null; // TODO null could be used to signal that it is not callable as a function
    this.docs = `Subclasses of SpecialColumnsTable can introduce special processing
for various columns in the table.

The first motivation was so DCIMenu could cause the naa:font_awesome_icon predicate
to be excluded as a column but rendered as a font-awesome icon in the label column.`;
  }

  constructor() {
    super(...arguments);
    this.expand_special_column_handlers();
    console.log(this.special_column_handlers);
  }

  receive(spogi) {
    const p_key = spogi.p.key();

    const col_handler = this.get_special_column_handler(p_key);
    //alert("#{p_key} is #{col_handler}")
    if (col_handler != null) { // if col_handler is defined (ie if there is one)
      if (col_handler === false) { // and it is false, that means SKIP spogis with the p_key
        return;
      } else {
        if (typeof(col_handler) === 'function') {
          spogi = col_handler(spogi);
          if (spogi === false) { // A col_handler returning false is suppressing the spogi
            return;          // or maybe fully handling the spogi itself.
          }
          // If we get here the col_handler might have modified the spogi it returns
          // or done something extra with the spogi and returned it for normal handling.
        } else {
          this.log.warning(`The col_handler for ${p_key} should be false or a function returning false or a spogi`);
        }
      }
    }
    return super.receive(spogi);
  }

  get_special_column_handler(p_key) {
    const retval = this.special_column_handlers[p_key];
    return retval || SubjectsTable.receive;
  }

  expand_special_column_handlers() {
    // TODO replace this by handling issue #36 (system for dealing with prefixes)
    this.noodb.log.warning("expand_special_column_handlers() should go away with issue #36");
    return (() => {
      const result = [];
      for (let k in this.special_column_handlers) {
        const v = this.special_column_handlers[k];
        const [prefix, key] = Array.from(k.split(':'));
        const prefrag = this.prefixes[prefix];
        if (prefrag) {
          const xpsn = prefrag + key;
          result.push(this.special_column_handlers[xpsn] = v);
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }
}
SpecialColumnsTable.initClass();
