var cmp = function (a, b) {
  if (a == b) {return 0}
  if (a < b) {return -1}
  return 1;
}

var ASSERTS = true;
var TRACE = false; //function(msg) {};
var TRACE_LOG = "";
export var TRACE_FUNC = function(line) {
  // TRACE(str) adds to the TRACE_LOG
  // TRACE() empties it
  // TRACE(true) prints it
  if (line) {
    if (line == true) {
      console.log(TRACE_LOG);
    } else {
      TRACE_LOG = TRACE_LOG + `\n${line}`;
    }
  } else {
    TRACE_LOG="";
  }
  return true;
}
//TRACE = TRACE_FUNC; // uncomment this to turn on the TRACE function
if (typeof process != 'undefined') {
  if (process.env && process.env.TRACE_NOICETRIE) {
    TRACE = TRACE_FUNC;
  }
} else {
  console.log("set envar TRACE_NOICETRIE=true to trace execution");
}

function pad_out(str, width, pad_char) {
  width = typeof(width) == 'undefined' ? 80 : width;
  //width = width || 80;
  pad_char = pad_char || ' ';
  //width = Math.max(width, str.length);
  while (str.length < width) {
    str += pad_char;
  }
  //console.log(`str.length: ${str.length}, width: ${width}, ==: ${str.length != width}, str: ${str}`);
  if (str.length != width) { // truncate str if it is already LONGER than width
    str = str.substr(0, width-3) + '...';
  }
  return str;
}

const HASH_SPLIT_REGEX = /^(.+#)(.+)$/;
const SLASH_SPLIT_REGEX = /^(.+\/)(.+)$/;
const SPLITTER_SPLIT_REGEX = /^(.+[\/#\:=])(.+)$/;

export class NoiceTrie {
  constructor(parent, k, v) {
    this.L = []; // do this before the "this.__proto__ = parent" trick so it IS local
    if (parent) {
      this.p = parent;
      this.r = parent.r; // REVIEW this link to root costs space, getRoot() crawling .p would cost time
      if (typeof(v) == 'undefined' || v == "") {
        throw new Error("leaf node missing value argument", k, v);
      }
      this.v = v;
      this.k = k;
      TRACE && TRACE(); // empty the TRACE_LOG
    } else { // this is the ROOT of a Trie
      this.node_count = 0; // the root node counts too
      this.r = this; // the root is its own root (for k2leaf) see is_root() for test
      this.p = this; // the root is its own parent (for getValue)
      this.r.k2leaf = {}; // there should only be an idx on the root NoiceTrie
      TRACE && (this.history = []); // put .history on the root only if there is a TRACE
    }
    this.r.node_count++;
    this.n = this.r.node_count; // record the index of this node
  }

  is_root() {
    return this == this.r; // if .r is this then this is the root
  }

  has_k(k) {
    return !!this.r.k2leaf[k]; // bang bang casts to bool
  }

  getHistoryScript() {
    const s = "\n    ";
    return (this.history && (s+this.history.join(s)) || "// no history, TRACE must be truthy");
  }

  add(k, v) {
    TRACE && TRACE(`• add('${k}', '${v}')`);
    const existing_v = this.get(k);
    //if (existing_v && existing_v != k) {
    if (this.has_k(k)) {
      throw new Error(`collision for key: ${k}`);
    }
    TRACE && (this.history.push(`trie.add("${k}","${v}")`));
    var leaf = new NoiceTrie(this, k, v)
    this.r.k2leaf[k] = leaf; // goes on root
    this.r.latest = leaf;
    this.binarySearchAndGraft(leaf, '');
    //TRACE && TRACE(this.tree());
    return this;
  }

  getValue(k, v) {
    v = (typeof(v) == 'undefined') ? '' : v;
    if (k) {
      var leaf = this.r.k2leaf[k];
      if (!leaf) {
        return null;
      }
      return leaf.p.getValue(null, leaf.v);
    } else {
      if (this.p == this) {
        return v;
      } else {
        return this.p.getValue(null, this.v + v);
      }
    }
  }

  get(k) {
    return this.r.k2leaf[k];
  }

  expand(curie) {
    let parts = curie.split(':');
    return this.getValue(parts[0]) + parts[1];
  }

  abbrev(s, make_key, noice_trie) {
    /*
      Do a binary search on the leaf values and find the LONGEST keyed value
      then return k:remainder where remainder is s.ltrim(value).
      If there is no branch with a key then error.

      Success Cases:
        * there is a good match (ie with appropriate keyed and local parts)
          - an appropriate keyed part ends with / or #
          - an appropriate local part has non-zero length and no / or # chars
      Do Not Abbreviate Cases
        * there are no # or / chars in the string to abbreviate
      New Key Cases
        * there are no matches, however short
        * there is a good match, but it has no key
        * there is a short match (ie with an inappropriately long local part left over)
        * there is an exact match (ie too long, no local part left over)

      Algorithm A:
        * split off ideal local part
        * find or generate key for the keyed part

      Algorithm B -- the path not taken:
        * find longest matching node (even if flawed)
        * which should be chained to the longest node which yields an appropriate local part
        * do complicated things to find the best abbreviation

        */
    //if (!make_key) throw new Error('abbrev() make_key is not passed');
    // permit abbreviations of the form 'prefix:'
    let abbr, is_abbrev = this.find_key_for(s, 14); // uh, why this number?
    if (abbr && is_abbrev) {
      return abbr;
    }
    let prfx = this.find_key_for(s);
    if (prfx) {
      return `${prfx}:`;
    }
    let pair = this.split_ideal_base_and_local(s);
    TRACE && TRACE(`.abbrev('${s}') pair: ${pair}`);
    if (pair) {
      let prefix = this.find_or_make_key_for(pair[0], make_key, noice_trie);
      return prefix + ':' + pair[1];
    }
  }

  split_ideal_base_and_local(s) {
    /*
      Split on last hash or last slash, failing those, return false.
    */
    var m = s.match(SPLITTER_SPLIT_REGEX);
    if (m) {
      return [m[1], m[2]];
    }
    var m = s.match(HASH_SPLIT_REGEX);
    if (m) {
      return [m[1], m[2]];
    }
    m = s.match(SLASH_SPLIT_REGEX);
    if (m) {
      return [m[1], m[2]];
    }
  }

  /**
   * Add a pair to the
   *
   * @param {Arry} pair
   * @return {String
   * @api private
   */
  insertSortedly(leaf, indent) {
    indent = indent || "";
    let TRACE = false; // leaf.k == 'cfo'; // cb -> cowbell
    //TRACE = true;
    if (leaf.k == 'dog') debugger;
    if (TRACE) console.log(`\n${indent}* ${this.getValue()}.insertSortedly(${leaf.v}, "${indent}")`);
    if (! this.L.length) {
      if (TRACE) console.log(indent,`  ${this.getValue()}.push()`);
      this.L.push(leaf);
      leaf.p = this;
      if (TRACE) console.log(this.r.tree(indent+'  '))
      return;
    }
  }

  find_or_make_key_for(sought, make_key) {
    // if (!make_key) throw new Error('find_or_make_key_for() make_key is not passed');
    make_key = make_key || function(sought, retval, ignore){ return retval};
    TRACE && TRACE(`• this.find_or_make_key_for('${sought}')`);
    let retval = this.find_key_for(sought);
    let real_retval = make_key(sought, retval, this); // TODO only call if no retvalOOOO
    if (retval != real_retval) { // add the key (aka prefix) if new
      this.add(real_retval, sought);
    }
    return real_retval;
  }

  insertAt(idx, rmv, leaf, why) {
    idx = (typeof(idx) == 'undefined') ? this.L.length : idx;
    why = why || "";
    TRACE && TRACE(`• <${this.getValue()||'ROOT'}>.insertAt(${idx}, ${rmv}, '${leaf.v}', '${why}')`);
    var retval = this.L.splice(idx, rmv, leaf);
    leaf.p = this;
    if (leaf.k) {
      this.r.latest = leaf;
      this.r.k2leaf[leaf.k] = leaf;
    }
    if (ASSERTS) {
      let current = this.L[idx].v;
      if (this.L[idx-1]) {
        let preceding = this.L[idx-1].v;
        console.assert(current > preceding, `'${current}' less than '${preceding}' ${why}\n`);
      }
      if (this.L[idx+1]) {
        let following = this.L[idx+1].v;
        console.assert(current < following, `'${current}' greater than '${following}' ${why}\n`);
      }
    }
    return retval; // TODO discipline the output!  What should it return? null OR leaf OR the removed node?
  }

  binarySearchAndGraft(leaf, indent) { // TODO re-implement based on find_key_for()
    TRACE && TRACE(`<${this.getValue()}>.binarySearchAndGraft('${leaf.v}')`);
    var seeking = true;
    if (this.L.length < 1) {
      this.insertAt(0, 0, leaf, "because L is empty");
      return;
    }
    var mid;
    var bot = 0,
        top = this.L.length,
        best = {'mc': 0},
        c, mc, current, current_mc;
    while (seeking) {
      mid = bot + Math.floor((top - bot)/2);
      current = this.L[mid];
      TRACE && TRACE(`current.idx: ${mid} current.v: ${current.v}`);
      if (current == null) {
        // a null was found at mid
        throw new Error(`a null was found at position: ${mid}`);
        //return {idx: null, action: this.action};
      }
      c = cmp(current.v, leaf.v);
      //mc = this.count_matching(current.v, leaf.v);
      if (c == 0) {
        // A node with an exactly matching value was found
        // so we will just copy leaf's key to that node.
        if (!leaf.k) {
          throw new Error(`about to assing leaf.k to current.k but no leaf.k when leaf.v: ${leaf.v} count: ${this.r.count}`);
        }
        if (current.k) {
          //console.log("binarySearchAndGraft()",this.r.tree());
          throw new Error(`about to assign the key from leaf.k: "${leaf.k}" to current, but it collides with existing current.k: "${current.k}" for current.v: "${current.v}" AND leaf.v: "${leaf.v}"\n`);
        }
        current.k = leaf.k;
        this.r.latest = leaf;
        this.r.k2leaf[leaf.k] = leaf;
        return current;
      }
      current_mc = Math.abs(this.count_matching(current.v, leaf.v));
      if (!best.mc || current_mc > best.mc) {
        best.mc = current_mc;
        best.match = current.v.substr(0, current_mc);
        best.remainder = current.v.substr(current_mc);
        best.node = current;
        best.idx = mid;
        TRACE && TRACE(`best = {mc:${best.mc}, match:${best.match}, remainder:<${best.remainder}>, idx:${best.idx}, v:<${best.node.v}>}`);
      }
      if (c < 0){ // ie this[mid] < sought
        bot = mid + 1;
      } else {
        top = mid;
      }
      if (bot == top){
        // conveys CLOSEST VALUE: best
   	if (best.mc) {
    	  let orig_leaf_v = leaf.v;
  	  leaf.v = leaf.v.substr(best.mc);
   	  if (best.remainder) { // MUST SPLIT best.node INTO branch_node AND demoted_node
  	    var branch_node = new NoiceTrie(this, null, best.match);
   	    var demoted_node = this.insertAt(best.idx, 1, branch_node,
   					     `because ${best.mc} matching characters`)[0];
            TRACE && TRACE(indent+`SPLIT (${orig_leaf_v}, ${best.idx}) ${best.match}+${best.remainder}`);
    	    //console.log("demoted_node", demoted_node);
    	    //console.log("best.node", best.node);
            // FIXME The following assert() should pass
    	    //console.assert(demoted_node.v == best.node.v,
            //             `these should match ${demoted_node.v} == ${best.node.v}`)
            best.node.v = best.remainder;
            best.node.p = this;
            branch_node.binarySearchAndGraft(best.node);
            if (! leaf.v) { // the leaf value is no different from the branch
              if (leaf.k && (! branch_node.k)) {
                branch_node.k = leaf.k;

              }
              return; // REVIEW should branch_node be returned?
            }
            if (false) {
              if (leaf.v) {
                return branch_node.binarySearchAndGraft(leaf);
              } else { // the leaf value is no different from the branch_node value SO grab leaf.k if present
                if (branch_node.k) {
                  throw new Error(`not expecting branch_node.k='${branch_node.k}'`);
                }
                branch_node.k = leaf.k;
                return branch_node;
              }
            }
            return branch_node.binarySearchAndGraft(leaf); // the leaf is still somehow different from the branch SO graft it on
  	  } else { // DO NOT SPLIT because
   	    TRACE && TRACE(indent+`NO SPLIT (${orig_leaf_v}, ${best.idx}) ${best.match}+${best.remainder}`);
   	    leaf.p = best.node;
   	    return best.node.binarySearchAndGraft(leaf, indent + "  ");
   	  }
        } else {
   	  // There were no matching characters
          let idx_adj = 1;
          if (leaf.v < best.node.v) {
            idx_adj = 0;
          }
	  let adjusted = best.idx + idx_adj;
          TRACE && TRACE(`NO MATCHING CHARACTERS ${best.node.v} ${best.match}+${best.remainder} ${adjusted}`);
          let at_idx = Math.max(Math.min(adjusted, this.L.length), 0);
          this.insertAt(at_idx, 0, leaf, `no initial chars in common in this list`);
	  return;
	}
      };
    }
  }

  count_matching(a, b) {
    /*
       Return the number of consecutive matching characters between the starts of a and b.
       If is a is alphabetically greater than b OR if a has no more characters
       then return a negative value.
     */
    let more = true;
    let retval = 0;
    let ac, bc;
    let mc = 0;
    while (more) {
      ac = a[mc];
      bc = b[mc];
      if (ac && ac == bc) {
        //TRACE && TRACE(`------ count_matching ------- ${mc} ${ac}`);
        mc++;
        continue;
      }
      if (ac > bc ||
          ac === undefined) {
        mc = -1 * mc; // to convey that a is larger
      }
      break;
    }
    return mc;
  }

  take(node, indent) {
    this.insertSortedly(node, indent);
    node.p = this;
  }

  tree(pad, quiet) {
    quiet = typeof(quiet) != 'undefined' ? quiet : false;
    pad = typeof(pad) != 'undefined' && pad || "";
    let details = '', is_latest = '';
    if (!quiet) {
      details = (this.k && pad_out(`${this.k}:`, 12) +  `<${this.getValue(null, '')}> ${this.n}` || "");
      is_latest = ((this.r.latest == this) && ' •'||'');
    }
    let retval = "" + pad_out((this.v || ""), 60 - pad.length) + details + is_latest + "\n";
    let last;
    this.L.forEach((child, idx, arr) => {
      last = !!(arr.length == idx + 1);
      retval = retval + pad +  (last && "┗━" || "┣━" ) + child.tree(pad + (last && "  " || "┃ "));
    });
    return retval;
  }

  joined() {
    let retval = ""
    for (let trie of this.L) {
      retval += trie.v + "|";
    }
    return retval.replace(/\|$/,'')
  }

  binary_search(sought, ret_ins_idx) {
    // return -1 or the idx of sought in this
    // if ret_ins_idx instead of -1 return [n] where n is where it ought to be
    // AKA "RETurn the INSertion INdeX"
    ret_ins_idx = ret_ins_idx || false;
    var seeking = true;
    if (this.length < 1) {
      if (ret_ins_idx) {
        return {idx: 0};
      }
      return -1; // undefined could convey THE LIST IS EMPTY
    }
    var mid;
    var bot = 0,
        top = this.L.length,
        c, cv, current;
    while (seeking) {
      mid = bot + Math.floor((top - bot)/2);
      current = this.L[mid];
      if (!current) { // better would be a test for null or undefined
        //console.log("    A", 0, sought);
        if (ret_ins_idx){
          return {idx: 0};
        }
        return 0;  // perhaps throw new Error(`list idx: ${mid} contains null`)
      }
      cv = (typeof(current.v) == 'undefined') ? current : current.v;
      c = cmp(cv, sought);
      //console.log(`cmp(${cv},${sought})`, c);
      if (c == 0) {
        // sought was found!
        //console.log("    B", mid, sought);
	if (ret_ins_idx) {
	  return {idx: mid};
	}
        return mid;
      }
      if (c < 0){ // ie this[mid] < sought
        bot = mid + 1;
      } else {
        top = mid;
      }
      if (bot == top){
        if (ret_ins_idx){
          //console.log("    C", bot, sought)
          return {idx: bot};  // conveys CLOSEST VALUE: bot
        }
        return -1; // -1 conveys SOUGHT NOT FOUND
      };
    }
  }

  find_key_for(sought, abbreviate) {
    TRACE && TRACE(`• <${this.v || 'ROOT'}>.find_key_for('${sought}')`);
    if (this.L.length == 0) {
      return; // undefined conveys THE-LIST-IS-EMPTY or HAS-NO-FULL-MATCH
    }
    var mid;
    var list_exhausted = false,
        bot = 0, c, cv, current, match_count, abs_match_count,
        top = this.L.length - 1, // the case of length==0 is handled above
        current_is_prefix_of_sought;
    const cmp_name = ['MOVE ALONG', 'EQUALS', 'MOVE BACK'];
    /*
      The strategy is to perform a binary search within the L at this level
      and a recursive crawl down the trie when branches are matched.
      Return FOUND value if the current value EQUALS the sought value.
      MOVE ALONG if the current value is BELOW the sought value.
      MOVE BACK if the current value is BEYOND the sought value.
      GO INTO a deeper level if the whole of the current.v is matched by sought.
    */
    while (! list_exhausted) {
      TRACE && TRACE('  ------ loop -------');
      mid = bot + Math.floor((top - bot)/2); // mid = bot when top and bot are adjacent or the same
      list_exhausted = bot == top; // This should be the last time through the loop
      if (ASSERTS && (bot > top || mid > top)) {
        throw new Error(`find_key_for(${sought}) <${this.v}>.L[${mid}] bot=${bot} > top=${top} which is CRAZY`);
      }
      if (ASSERTS && this.L.length < 1) {
        throw new Error(`find_key_for(${sought}) <${this.v}>.L.length < 1`)
      }
      current = this.L[mid];
      if (!current) {
        throw new Error(`<${this.v || 'ROOT'}>.find_key_for(${sought}) <${this.v||'ROOT'}>.L[${mid}] is null while bot=${bot} top=${top} THIS SHOULD Never HAPPEN`)
      }
      cv = (typeof(current.v) == 'undefined') ? current : current.v;
      c = cmp(cv, sought);
      if (typeof sought == 'undefined') {debugger}
      match_count = this.count_matching(cv, sought); // negative indicates cv fully match
      TRACE && TRACE(`  c=${c} match_count=${match_count} bot=${bot} mid=${mid} top=${top} cv=<${cv}> sought=<${sought}>`);
      TRACE && TRACE(`  ${cmp_name[c+1]} <== cmp('${cv}', '${sought}') k=${current.k}`);
      if (c == 0) {
        /*
          EQUALS (ie the sought string matches the current string)
        */
        const key = current.k; // might be null, ie there is no key for this node;
        if (! key) {
          // TRACE && TRACE(true) && TRACE(); // print the TRACE_LOG then empty it
          //  ${this.getValue()}
          //throw new Error(`  find_key_for(<${sought}>) ==> ${key} sought was found but has no key`);
          console.warn('find_key_for(sought',sought, ') current.n',current.n);
        }
        return key; // TODO return null vs undefined vs false
      }
      abs_match_count = Math.abs(match_count);
      current_is_prefix_of_sought =
        abs_match_count == cv.length          // ie cv is totally consumed
        // && abs_match_count > 0             //
        && sought.length > abs_match_count;   //    and sought is longer than cv
        TRACE && TRACE(`find_key_for() STATUS -- CURRENT:${current_is_prefix_of_sought && 'IS_PREFIX,' || ''}${list_exhausted&&'EXHAUSTED,'||''} c=${c}, match_count=${match_count}, bot=${bot}, mid=${mid}, top=${top}, cv=<${cv}>, sought=<${sought}>`);
      var theRest = sought.substr(cv.length);
      if (list_exhausted && abbreviate && current.k) {
        /*
          If we are asked to abbreviate and the list is exhausted
          then current.k is the longest match we are going to find.
        */
        let retval = `${current.k}:${sought}`;
        let cur_val = current.getValue();
        if (cur_val.length >= abbreviate) {
          //console.log(`find_key_for(${sought}) ==> ${retval} value=${cur_val}`)
          let fullSought = cur_val + sought;
          if (this.r.expand(retval) == fullSought) {
            return retval, true; // note return of TWO values to signify abbreviation
          } else {
            if (! this.r.expansion_warnings) {
              // REVIEW this hideousness MUST BE AVOIDABLE!!!
              let msg = `abbreviation='${retval}' expands to <${this.r.expand(retval)}> not <${fullSought}>\nSIMILAR SUPPRESSED BUT COUNTED AS .expansion_warnings`;
              console.warn(msg, this.r.expansion_warnings);
              this.r.expansion_warnings = 1;
            } else {
              this.r.expansion_warnings++;
            }
          }
        }
      }
      if (current_is_prefix_of_sought) {
        TRACE && TRACE(`  GO INTO <${cv}> to find_key_for(<${sought}>)`);
        return current.find_key_for(theRest, abbreviate);
      }
      if (c < 0) {
        /*
          MOVE ALONG (ie the sought value is later in the alphabet than the current value at mid)
          [bot,mid,top]
        */
        bot = mid + 1;
      } else {
        /*
           MOVE BACK (the sought value is earlier in the alphabet than the current value at mid)
        */
        top = mid;
      }
    }
    return null;  // signifies that no NODE was found
  }

} // class NoiceTrie

export var test_NoiceTrie = function() {
  var expect = function(val, throwIt) {
    return {
      to: {
        equal: function(check, msg) {
          if (check != val) {
            var theMsg = `expected ${val} to equal ${check} `+ (msg || "");
            if (throwIt) {
              throw new Error(theMsg);
            } else {
              console.log(msg);
            }
          } else {
            console.log(".");
          }
        }
      }
    }
  };

  var trie = new NoiceTrie();
  trie.add('wp', 'https://en.wikipedia.org/wiki/');
  expect(trie.L.length).to.equal(1, 'only one thing');
  trie.add('sunsite', 'ftp://sunsite.unc.edu/pub/');
  expect(trie.L.length).to.equal(2, 'nothing in common');
  trie.add('imdbfilm', 'http://www.imdb.com/title/');

  expect(trie.joined()).to.equal(trie.getValue('sunsite')+'|http', trie.tree());
  expect(trie.L.length).to.equal(2, 'http in common');

  trie.add('imdbperson', 'http://www.imdb.com/name/');
  expect(trie.L.length).to.equal(2, "http vs ftp://...");
  trie.add('aimit', 'ftp://ftp.ai.mit.edu/');
  expect(trie.L.length).to.equal(2, "ftp:// vs http");
  expect(trie.joined()).to.equal('ftp://|http', trie.tree());
  trie.add('isbn', 'isbn:');
  expect(trie.L.length).to.equal(3); // ftp:// vs http vs isbn:
  expect(trie.joined()).to.equal('ftp://|http|isbn:');
  //expect(trie.abbrev('isbn:978-0201517521')).to.equal('isbn:978-0201517521'); // hmmmm BLKBS

  trie = new NoiceTrie()
  trie.add('dh', 'doghouse')
  trie.add('db', 'dogbone')
  trie.add('di', 'dingo')
  trie.add('dog', 'dog')
  expect(trie.get('dog').p.joined()).to.equal('ingo|og',trie.tree())

  RDF_SCHEMA = 'http://www.w3.org/2000/01/rdf-schema#'
  trie = new NoiceTrie();
  trie.add('foaf', 'http://xmlns.com/foaf/0.1/');
  trie.add('xsd', 'http://www.w3.org/2001/XMLSchema#');
  trie.add('rdf', 'http://www.w3.org/1999/02/22-rdf-syntax-ns#');
  trie.add('rdfs', RDF_SCHEMA);
  trie.add('naa', 'http://nooron.com/_/NooronAppArchitecture.ttl#');
  //TRACE = TRACE_FUNC;
  expect(trie.find_key_for(RDF_SCHEMA), true).to.equal('rdfs');
};

if (true) {
  function throw_if_ne(a,b) {
    if (a != b) {
      throw new Error(`"${a}" isnt "${b}"`);
    }
  }
  throw_if_ne(pad_out('XXX', 8, '-'), "XXX-----");
  throw_if_ne(pad_out('12345678', 6, ' '), "123...")
  throw_if_ne(pad_out('12345678', 6), "123...")
};

//(typeof exports !== "undefined" && exports !== null ? exports : this).NoiceTrie = NoiceTrie;
//(typeof exports !== "undefined" && exports !== null ? exports : this).test_NoiceTrie = Suite;
//(typeof exports !== "undefined" && exports !== null ? exports : this).TRACE_FUNC = TRACE_FUNC;
