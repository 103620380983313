import {
  VisualizationController
} from '../visualizationcontroller.js';

export class AbstractBlocklyController extends VisualizationController {
  static initClass() {
    /*
    this.prototype.DeferredCSSDependencies = [];
    this.prototype.DeferredScriptDependencies = [
      '/blockly/blockly_uncompressed.js',
      '/blockly/blocks_compressed.js',
      '/blockly/javascript_compressed.js',
      '/blockly/msg/js/en.js',
      '/closure-library/closure/goog/base.js'];
    */
    //import Blockly from '/blockly/browser';
    //import En from '/blockly/lib/i18n/en';
    //Blockly.setLocale(En);
  }
}
AbstractBlocklyController.initClass();
