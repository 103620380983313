/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import * as huviz from 'huviz'; // Huviz, Orlando (soon TextCursor, ColorTreePicker)
import {
  colorlog, noop, strip_quotes, unquoteLiteral,
  Description,
  Discriminator, DataReducer,
  DragAndDropOrClickToVisualize,
  VisualizationController
} from './vis/visualizationcontroller.js';
import {AbstractVideoProvider} from './vis/vid/abstractvideoprovider.js';
import {AllegationsTable} from './vis/allegationstable.js';
import {BarPlot, BubblePlot, LinePlot, ScatterPlot} from './vis/vegaplots.js';
import {BlocklyController} from './vis/blkly/blocklyctrl.js';
import {BlocklyControllerTest} from './vis/blkly/blocklyctrltest.js';
import {DCIMenu} from './vis/dcimenu.js';
import {DiscriminatorMenu, VisualizationMenu} from './vis/menu.js';
import {DiscriminatorReader} from './discriminator.js';
import {EvaluationsTable} from './vis/evaluationstable.js';
import {Graph, OntoGraph} from './vis/graph.js';
import {AbstractKnowledgeHostedVisualizationController} from './vis/abstractknowledgehostedvisctrl.js';
import {LeafletMap} from './vis/mapvisctrl.js';
import {MakeDiscriminator} from './vis/blkly/makediscriminator.js';
import {ProvideVideo} from './vis/vid/providevideo.js';
import {Spangler} from './vis/spangler.js';
import {SpecialColumnsTable} from './vis/specialcolumnstable.js';
import {Spreadsheet} from './vis/spreadsheettable.js';
import {SubjectsTable, ClassTable, KBsTable} from './vis/subjectstable.js';
import {Timesheet} from './vis/timesheettable.js';
import {TabularVisualizationController} from './vis/tabular.js';

import {AutoSem} from './autosem.js';
import {AnnotationSemClass} from './annotationsem.js';
import {Annotation} from './annotation.js';
import {VideoSemClass} from './vis/vid/videosem.js';
import {AbstractAnnotationRecorder} from './vis/vid/abstractannotationrecorder.js';
import {PlayMediaAndAnnotations} from './vis/vid/playmediaandannotations.js';
/*
// this is an experiment in building blockly right into the nooron dist file
// see src/blockly.js for the rest of this experiment
import {Blockly} from './wip_blockly.js';
*/
/*
import * as Blockly from 'node-blockly/browser.js';
import * as En from 'node-blockly/lib/i18n/en.js';
Blockly.setLocale(En)
*/


class ActionTable extends VisualizationController {
  static initClass() {
    this.docs  = `\
The ActionTable shows Actions (Intransitive) and (Transitive) Verbs.
  
Columns:
  FontAwesome icon name
  FontAwesome icon recipe (ie layered and colored)
  Unicode character
  Action name (Verb name)
  Verb name template (ala Smalltalk or ObjectC method signature)
    eg: "AddNew:Class:"
    eg: "AddNew:Spogi:"
    eg: "AddNew()owl:Criterion;"
    eg: "AddNew()owl:KB;"\
`;
  }
}
ActionTable.initClass();


class GraphMedia extends PlayMediaAndAnnotations {
  static initClass() {
    this.func_name = 'graphmedia';
    this.pretty_name = "Graph Media";
    this.docs = "like PlayMedia but leveraging HuViz";
    this.prototype.succintTopic = true;

    // This duplication is because the technique used to load those
    // dependencies isn't smart about inheritance... in other words, if a
    // subclass wants to change the set of CSSDependencies then it must
    // state the exact deps it needs, it can't "extend" them
    this.prototype.CSSDependencies = [
      '/css/huviz/huvis.css',
      '/css/huviz/huvis_controls.css',
      '/css/huviz/CRRT.css',
      '/css/huviz/gclui.css'];
    this.prototype.ScriptDependencies = ['/huviz/huviz.js'];
  }

  show_related_media(elem) {
    const Huviz = require('huviz').Orlando;
    const huviz_top_sel = this.localize('.a_flower');
    if (elem == null) { elem = $('#level2_vids').append('<div class="a_flower" style="position:relative"></div>')[0].lastElementChild; }
    if (!elem) {
      throw new Error('can not make a_flower');
    }
    this.huviz = new Huviz({
      default_node_url: '/huviz/docs/cwrc_logo.png',
      hide_fullscreen_button: true,
      huviz_top_sel,
      settings: {
        fisheye_radius: 0,
        fisheye_zoom: 1,
        make_nodes_for_literals: false,
        show_edges: true,
        single_chosen: true,
        show_thumbs_dont_graph: true
      },
      show_tabs: false,
      stay_square: true
    });

    return this.add_root_node();
  }

  add_root_node() {
    // Rightly, when quad.o.value is a url then quad.o.type should equal:
    //   http://www.w3.org/1999/02/22-rdf-syntax-ns#object
    this.huviz.add_quad({
      s: this.video_curie,
      p: 'rdf:type',
      o: {value: 'video:Video'}});
    this.huviz.add_quad({
      s: this.video_curie,
      p: 'rdfs:label',
      o: {value: this.main_video_title_title}});
    return this.huviz.add_quad({
      s: this.video_curie,
      p: 'foaf:thumbnail',
      o: {value: this.make_thumb_uri_from_curie(this.video_curie)}});
  }

  addAnnotationToVisualization(annot) {
    const label = annot.getBodyLabel();
    const edge_label = this.huviz.add_quad({
      s: annot.id,
      p: 'rdfs:label',
      o: {value: label || 'no label'}});
    const edge_a = this.huviz.add_quad({
      s: annot.id,
      p: 'rdf:type',
      o: {value: 'oa:Annotation'}});
    this.huviz.add_quad({
      s: annot.id,
      p: this.xxx_get_random_motivation(), // TODO make this the actual motivation for this annotation
      o: {value: this.video_curie}});
    this.huviz.add_quad({
      s: this.video_curie,
      p: 'foaf:thumbnail',
      o: {value: this.make_thumb_uri_from_curie(this.video_curie)}});

    return console.log(label);
  }
    //edge_a.subj
    //relPos = @getAnnotationStartMomentAsRelPos(annot)
    //if not relPos or relPos > 1
    //  colorlog("#{annot.body.id} has bad relPos #{relPos}")
    //@huviz.add_quad

  xxx_get_random_motivation() {
    const items = ['oa:questioning', 'oa:linking', 'oa:commenting'];
    return items[Math.floor(Math.random()*items.length)];
  }
}
GraphMedia.initClass();

class ResearchMedia extends PlayMediaAndAnnotations {
  static initClass() {
    this.func_name = 'researchmedia';
    this.pretty_name = "Research Media";
    this.docs = "like PlayMedia but using the Blossom";
    this.prototype.succintTopic = true;
  }

  //CSSDependencies: [
  // '/css/huviz/huvis.css',
  // '/css/huviz/huvis_controls.css',
  // '/css/huviz/CRRT.css',
  // '/css/huviz/gclui.css']
  //ScriptDependencies: ['/blossom/src/index.js']

  show_related_media(elem) {
    // This is where we should instantiate the blossom widget
    // See GraphMedia for an example of instantiating Huviz and saving that instance as @huviz
    const blossom_selector = this.localize('.a_flower');
    $('#level2_vids').append('<div class="a_flower" style="position:relative"><H1>blossom goes here</H1></div>')[0].lastElementChild;
    this.blossom = {};  // Put blossom instance here
    return this.add_root_node();
  }

  add_root_node() {
    // This method just shows how to get at the information about the root petal
    return;
    // these are the things about the video that the visualization likely needs
    this.video_curie;
    this.main_video_title_title;
    return this.make_thumb_uri_from_curie(this.video_curie);
  }

  addAnnotationToVisualization(annot) {
    // as each new Annotation flows into the visualization, possibly real-time, it is received here
    console.info("addAnnotationToVisualization() should do something with incoming Annotations");
    const petalArgs = {
      key: annot.id,
      title: annot.getBodyLabel(),
      thumbUrl: this.make_thumb_uri_from_curie(annot.getBodyCurie()),
      stroke: this.motivationToColor(annot.getMotivation()),
      relPos: .3
    };
    const relPos = this.getAnnotationStartMomentAsRelPos(annot);
    if (!relPos || (relPos > 1)) {
      colorlog(`${annot.body.id} has bad relPos ${relPos}`);
    } else {
      petalArgs.relPos = relPos;
    }
    // At this point the petalArgs should feed the blossom as appropriate.
    // This works on initial load or realtime as a new response arrives.
    // I get the sense that the blossom should have all this queued and then triggered just once.
  }
}
ResearchMedia.initClass();

let widget_html = undefined;
class ListMedia extends AbstractVideoProvider {
    static initClass() {
      this.func_name = 'listmedia';
      this.pretty_name = "List media";
      this.docs = "List available media";
      this.prototype.succinctTopic = true;
  
      widget_html = `\
<div class="video_list" border=0>
</div>
<div class="video_list_background"><h1>Please Login</h1><p>Access through main menu below</p></div>\
`;
  
      this.prototype.available_vidsrc = ['record', 'upload', 'hosted'];
  
      this.prototype.dbStore =
        {_uses: {}};
    }
    //unavailable_vidsrc: ['hosted']

    constructor() { // ListMedia extends AbstractVideoProvider
      super(...arguments);
      this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
      this.renderedVideos = {};
      //@ensure_videoProvider()
      this.visContent.append(widget_html);
      this.video_list_JQ_elem = $(this.myQrySel('.video_list'));
      this.attach_myNavigator();
      this.perform_subscriptions();
    }

    receive(spogi) {
      super.receive(...arguments);
      this.accumulateVideos(spogi);
    }
    accumulateVideos(spogi) {
      // Purpose:
      //   We collect the spogi which arrive and use them to reconstitute JS objects
      //   with .id which comes from the subj of the triples and property names which
      //   come from predicates and property values which come from the objects.
      //
      //   We then render()
      let anObj = null;
      const {
        p
      } = spogi;
      const p_curie = p.key();
      const s_curie = spogi.s.key();
      const o_key = spogi.o.key();
      let a_Video = false;
      a_Video = (p_curie === 'rdf:type') && (o_key === 'video:Video');
      if (a_Video) {
        anObj = Video._getOrCreateResource(s_curie, this.dbStore, Video);
      }
      if (!anObj) {
        anObj = AutoSem._getOrCreateResource(s_curie, this.dbStore, VideoSemClass);
      }
      if (anObj) {
        if (this.kwargs.debug) {
          this.add_to_video_list(`<tr><td>${anObj.constructor.name}</td><td>${spogi.asTTL().join(' ')}</td></tr>`);
        }
        if (!a_Video) {
          // skip if "rdf:type video:Video" because that has already been handled
          anObj._setCurieKV(p_curie, o_key, this.dbStore); // PREFIX:LOCAL becomes PREFIX__LOCAL
        }
        return this.renderOrUpdate(anObj);
      }
    }

    getListItemId(vidCurie) {
      const domSafeId = vidCurie.replace(':','__');
      return `listitem__${domSafeId}`;
    }

    renderOrUpdate(vidObj) {
      if (vidObj instanceof Video) {
        const [elem, created] = Array.from(this.getOrCreateVideoListItem(vidObj));
        const shouldUpdate = !created;
        // If a ListItem for vidObj already exists it needs updating.
        // Otherwise it just got created so is already up-to-date.
        if (shouldUpdate) {
          this.updateVideoElem(vidObj, elem);
        }
      }
    }

    getOrCreateVideoListItem(vidObj) {
      const elemId = "#" + this.getListItemId(vidObj.id);
      let elem = this.myQrySel(elemId); // TODO cache these in a dict for performance, right?
      const created = (elem == null); // must create if not found
      if (created) {
        const html = this.render_video(vidObj, this.kwargs.g);
        this.add_to_video_list(html);
        elem = this.myQrySel(elemId);
      }
      return [elem, created];
    }

    add_to_video_list(html) {
      this.video_list_JQ_elem.prepend(html);
    }

    updateVideoElem(vidObj, elem) {
      for (let k of Array.from(vidObj._dirtyKeys || [])) {
        const v = vidObj[k];
        if (['schema__description', 'rdfs__label'].includes(k)) {
          $(elem).find("."+k).text(unquoteLiteral(v));
        }
      }
      return vidObj._dirtyKeys = []; // we have just cleared them
    }

    render_video(vidObj, graf) {
      let prettyCreationDate;
      const localPart = localPartOfCurie(vidObj.id);
      const playmedia_uri = vidObj => {
        const moreTerms = [];
        if (graf) {
          moreTerms.push(`g=${graf}`);
        }
        return this.make_formurla_into_url_path(this.make_playmedia_formurla(vidObj.id, moreTerms));
      };
      try {
        const creationDate = this.noodb.extractDateFromCurie(vidObj.id);
        prettyCreationDate = this.format_post_date(creationDate);
      } catch (e) {
        console.log('unable to make prettyCreationDate for',vidObj.id);
        prettyCreationDate = '';
      }

      const thumb = `<img src="${this.make_thumb_uri_from_curie(vidObj.id)}"/>`;
      return `\
<div id="${this.getListItemId(vidObj.id)}" class="video_item">
  <div class="video_image">
    <a href="${playmedia_uri(vidObj)}">
  ${thumb}
    </a>
  </div>
  <a class="description_link_box" href="${playmedia_uri(vidObj)}"></a>
  <div class="video_description">
    <div class="feedback__likes">Links: 0, Views: 0</div>
    <div class="creation__date">${prettyCreationDate}</div>
    <h4><a class="rdfs__label" href="${playmedia_uri(vidObj)}">${vidObj.rdfs__label || ''}</a></h4>
    <div class="schema__description">${vidObj.schema__description || ''}</div>
    <div class="uploader__profile">Anonymous</div>
    <div class="uploader_icon">A</div>
  </div>
</div>\
`; // emacs coffee-mode needs this -> "
    }

    display_new_video(subj, graf) {
      return this.add_to_video_list(this.render_video(subj, graf));
    }

    on_done_submitting_common(fullUri, triples, curie) {
      super.on_done_submitting_common(fullUri, triples, curie);
      alert('now close provider pane');
      this.run_playmedia_formurla(curie);
    }
}
ListMedia.initClass();

let appnav_widget_html = undefined;
class AppNavigator extends VisualizationController {
    static initClass() {
      // FIXME move this out into a template (or react component?) in the VHost
      this.func_name = "navigator";
      this.pretty_name = "App Navigator";
      this.docs = "Navigation visualizaton for websites and applications (incl. mobile)";
      appnav_widget_html = `\
<div class="navigation_wrap" border=0>
  <div class="app_logo">
    <a href="/"><img src="/vhost/graphics/diversus_logo.svg" alt="Diversus Logo"></a>
  </div>
  <div class="app_action_menu"><i class="fas fa-chevron-left"  style="visibility:hidden"></i><i class="fas fa-plus add_new_video"></i><i class="fas fa-chevron-right" style="visibility:hidden"></i></div>
  <div class="nav_menu">
    <div class="mobile_nav_icon"><i class="fas fa-bars"></i></div>
  </div>
  
  <div class="mobile_add_video_wrap">
    <ul class="main_nav_options">
      <li class="choose-provid-record">
        <div class="nav_icon"><i class="fas fa-video"></i></div>Camera</li>
      <li class="choose-provid-hosted">
        <div class="nav_icon"><i class="fab fa-youtube"></i></div>YouTube</li>
      <li class="choose-provid-upload">
        <div class="nav_icon"><!--i class="fas fa-grip-horizontal"></i--><i class="fas fa-cloud-upload-alt"></i></div>Upload</li>
      <!--
      <li><div class="nav_icon"><i class="far fa-images"></i></div>Media Library</li>
        -->
      <li><div class="nav_icon cancel_button">Cancel</li>
    </ul>
  </div>
  
  <div class="mobile_nav_menu_wrap">
    <div class="user_nav_wrap">
      <div class="user_image"><img src=""></div>
      <div class="user_info">
        <h2>Name of User</h2>
        <h3>Name of Channel</h3>
      </div>
    </div>
    <ul class="main_nav_options">
  
      <li class="disabled"><div class="nav_icon"><i class="fas fa-user-plus"></i></div>Invite Friends</li>
      <li class="disabled"><div class="nav_icon"><i class="fas fa-grip-horizontal"></i></div>My Content</li>
      <li class="disabled"><div class="nav_icon"><i class="fas fa-sliders-h"></i></div>Filters</li>
      <li class="disabled"><div class="nav_icon"><i class="fas fa-cogs"></div></i>Settings</li>
      <li>
         <a href="http://diversus.me" style="color:inherit; text-decoration: none;">
            <div class="nav_icon"><i class="fas fa-info"></i></div>About Diversus</a>
      </li>
      <li class="logout-button"><div class="nav_icon"><i class="fas fa-sign-out-alt"></i></div>Sign out</li>
      <li class="login-button"><div class="nav_icon"><i class="fas fa-sign-in-alt"></i></div>Sign in</li>
    </ul>
  </div>
</div>\
`;
       // emacs coffee-mode needs this -> "
    }
  constructor() {
    super(...arguments);
      this.choose_provid = this.choose_provid.bind(this);
      this.goToLogin = this.goToLogin.bind(this);
      this.logUserOut = this.logUserOut.bind(this);
      this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    }
      // Normally the constructor builds the content for a visualization, but there is a race
      // condition such that the navigator has already been constructed before the
      // .navigatingFor property on the Navigator has been assigned.  So the @attach_myNavigator()
      // method calls our begin_navigating() method once all the connections are made.
      //@visContent.html("""<h3 style="">AppNavigator should be called by other visualizations using @attach_myNavigator()</pre>""")

    begin_navigating() {
      const navList = this.createNavigationList;
      this.visContent.html(appnav_widget_html);
      $(".mobile_nav_icon").click(this.mobileNavToggle);
      $(".add_new_video").click(this.addNewVideoToggle);
      this.addLoginHandlers();

      const navForFunc = this.get_navigatingFor_func_name();
      if (navForFunc === 'listmedia') {
        $(".choose-provid-record").click(() => this.choose_provid('record'));
        $(".choose-provid-hosted").click(() => this.choose_provid('hosted'));
        $(".choose-provid-upload").click(() => this.choose_provid('upload'));
        $(".mobile_add_video_wrap .cancel_button").click(function() {
          $(".mobile_add_video_wrap").hide();
          $("form.video_provider").hide();
          console.log("Loookning for back");
          if ($(".video_list").html()) { // Must be on list page so stay there
            $("form.video_provider").hide();
            return console.log("on list page so just hide");
          } else {  // else must be on provid page so back button to list view
            window.history.back();
            return console.log("back up to previous page");
          }
          });
      } else if (['playmedia', 'provid'].includes(navForFunc)) {
        $(".choose-provid-record").click(() => this.navigatingFor_choose_provid('record'));
        $(".choose-provid-hosted").click(() => this.navigatingFor_choose_provid('hosted'));
        $(".choose-provid-upload").click(() => this.navigatingFor_choose_provid('upload'));
        $(".mobile_add_video_wrap .cancel_button").click(function() {
          $(".mobile_add_video_wrap").hide();
          return $("form.playmedia.video_provider").hide();
          });
      }
    }

    get_navigatingFor_func_name() {
      if (this.navigatingFor != null) {
        return this.navigatingFor.constructor.func_name;
      }
    }

    navigatingFor_choose_provid(vidsrc) {
      this.addNewVideoToggle();
      this.navigatingFor.choose_provid(vidsrc);
    }

    choose_provid(vidsrc) {
      if (vidsrc) {
        const kwargs = ((this.navigatingFor != null) && this.navigatingFor.kwargs) || this.kwargs;
        const kb_arg = (kwargs.g && ("g=" + kwargs.g + ',')) || '';
        return this.run_formurla(`provid(${kb_arg}vidsrc=${vidsrc})`);
      }
    }

    mobileNavToggle() {
      if ($(".mobile_nav_menu_wrap").is(":hidden")) { $(".mobile_add_video_wrap").hide(); }
      return $(".mobile_nav_menu_wrap").toggle();
    }

    addNewVideoToggle() {
      if ($(".mobile_add_video_wrap").is(":hidden")) { $(".mobile_nav_menu_wrap").hide(); }
      return $(".mobile_add_video_wrap").toggle();
    }

    addLoginHandlers() {
      $(".login-button").click(this.goToLogin);
      $(".logout-button").click(this.logUserOut);
      return this.updateLoginButtons();
    }

    updateLoginButtons() {
      if (this.noodb.email_login.logged_in) {
        $('.login-button').hide();
        $('.logout-button').show();
        return $('body').addClass("logged_in");
      } else {
        $('.logout-button').hide();
        $('.login-button').show();
        return $('body').removeClass("logged_in");
      }
    }

    goToLogin() {
      return window.location = "/tmpl/login.html";
    }

    logUserOut() {
      this.noodb.email_login.log_out();
      return this.updateLoginButtons();
    }
}
AppNavigator.initClass();





// ############################################################################
// The BUILTINs
// ############################################################################

function use_viz_by_class(formurlaManager, vizclass, fracpanel, args, kwargs, expression_ast) {
  const viz = new vizclass(formurlaManager, fracpanel, args, kwargs, expression_ast);
  if (window.nooron_views == null) { window.nooron_views = []; }
  window.nooron_views.push(viz);
  return viz;
}

class KWARGS {}

// Classes placed in VIZ are available to FormURLaManager to run as commands
const VIZ = {
  AllegationsTable,
  Graph,
  //OntoGraph,  // Probably no longer needed
  SubjectsTable,
  ClassTable,
  EvaluationsTable,
  KBsTable,
  ActionTable,
  DiscriminatorMenu,
  VisualizationMenu,
  Spreadsheet,
  //Timesheet,  // must fix require('reactor') on line 17
  DCIMenu,
  BubblePlot,
  BarPlot,
  LinePlot,
  LeafletMap,
  // start diversus
  ProvideVideo,
  PlayMediaAndAnnotations,
  GraphMedia,
  ResearchMedia,
  ListMedia,
  AppNavigator,
  // end diversus
  Spangler,
  BlocklyControllerTest,
  BlocklyController,
  MakeDiscriminator
}

function get_args_and_kwargs(formurla_args) {
  const retval = {
    args: [],
    kwargs: new KWARGS()
  };
  for (let arg of Array.from(formurla_args)) {
    if (['var','num','str'].includes(arg.type)) {
      retval.args.push(arg.value);
    }
    if ((arg.type === 'assign') && (arg.operator === '=')) {
      retval.kwargs[arg.left.value] = arg.right.value;
    }
  }
  return retval;
}

const build_builtin = a_class => (function() {
  const all_args = Array.prototype.slice.call(arguments); // make an Array from arguments
  const frac = all_args.shift();
  const expression_ast = all_args[0];
  const {args, kwargs} = get_args_and_kwargs(expression_ast.args);
  const viz_inst = use_viz_by_class(this, a_class, frac, args, kwargs, expression_ast);
  return viz_inst;
});

export function register_visualizations(formurlaManager) {
  const result = [];
  formurlaManager.prototype.name2class = {};
  for (let a_name in VIZ) { // "evaluations,subjects,allegations,graph".split(",")
    const a_class = VIZ[a_name];
    const {func_name} = a_class;
    if (func_name) { // these are the visualizations which are not abstract
      formurlaManager.prototype.name2class[func_name] = a_class;
      result.push(
        formurlaManager.prototype[`BUILTIN_${func_name}`] = build_builtin(a_class)
      );
    }
  }
  return result;
}
