import {AutoSem} from '../../autosem.js';

export class VideoSemClass extends AutoSem {
  static initClass() {
    this.prototype.__context = "http://videoontology.org/BOGUS_PATH";
  }
}
VideoSemClass.initClass();

export class Video extends VideoSemClass {}
