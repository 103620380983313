/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// http://raganwald.com/2014/04/10/mixins-forwarding-delegation.html

export const permeate = (provider, client) => (() => {
  const result = [];
  for (let k in provider) {
    if (provider.hasOwnProperty(k)) {
      //console.log("permeate() #{provider.constructor.name}.#{k} to",client.constructor.name)
      result.push(client[k] = provider[k]);
    } else {
      result.push(undefined);
    }
  }
  return result;
})();

export class NoorPlugin {
  constructor(noodb, app) {
    this.noodb = noodb;
    this.app = app;
    this.log = this.noodb.log;
    this.cache_resources();
    if (this.init_plugin) {
      this.init_plugin();
    }
  }

  cache_resources(resource_names) {
    if (this._resources_to_cache != null) {
      return (() => {
        const result = [];
        for (let name of Array.from(this._resources_to_cache)) {
          const uri = `nrn:${name}`;
          // TODO make these direct links to the resources, for speed
          result.push(this[name] = uri);
        }
        return result;
      })();
    }
  }

  init_plugin() {}
}
