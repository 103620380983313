
import {Discriminator, DataReducer} from '../datareduction.js';
import {DragAndDropOrClickToVisualize} from '../dnd.js';

export {
  DragAndDropOrClickToVisualize,
  Discriminator,
  DataReducer
};

export class Description {}

export const colorlog = function(msg, color, size) {
    if (color == null) { color = "red"; }
    if (size == null) { size = "1.2em"; }
    return console.log(`%c${msg}`, `color:${color};font-size:${size};`);
  };

export const noop = function() {};

export const strip_quotes = s => s.replace(/\"$/,'').replace(/^\"/,'');

export const localPartOfCurie = curie => curie.split(':')[1];

export const unquoteLiteral = function(v) {
  let depth = 3;
  let m = true;
  while (m && depth) {  // remove up to three sets of nested outer double quotes
    m = v.match(/^"(.*)"$/);
    if (m) {
      v = m[1];
    }
    depth = depth - 1;
  }
  v = v.replace(/\\n/g,"\n"); // unescape \n
  v = v.replace(/\\\x22/g, "\x22"); // unescape baskslash double-quote
  return v;
};

export class Controller {
  //@loadCSSDependenciesStarted = "fred" # give loadDependenciesIfNeeded() somewhere to stop
  run_formurla(formurla) {
    // Purpose:
    //   Provide a single point of entry for going from screen to screen.
    //   At this point Nooron is not yet acting as a full Single Page Application,
    //   because it is hitting the server for each new url change, but by
    //   funnelling those transitions through this function we can make
    //   the change to full SPA gracefully.
    return window.location = this.make_formurla_into_url_path(formurla);
  }

  make_formurla_into_url_path(formurla) {
    if (!formurla.startsWith('/__/')) {
      formurla = "/__/" + formurla;
    }
    if (formurla.includes(',,')) {
      console.debug("there should not be ',,' in", formurla);
      formurla = formurla.replace(',,', ',');
    }
    return formurla;
  }
}

export class VisualizationController extends Controller {
    static initClass() {
      // Subclasses are the controllers for particular visualization styles
      //
      // For example:
      //   AllegationsTable portrays a set of allegations using the
      //   table_widget with columns for s,p,o,g,i and a row per spogi.
      //
      //   SubjectTable uses the table_widget with columns per predicate,
      //   rows per subject and "evaluations" being aggregations of objects.
      //

      this.prototype.prefixes = { // TODO this should GO AWAY see issue #36
        naa: 'http://nooron.com/_/NooronAppArchitecture.ttl#',
        rdfs: 'http://www.w3.org/2000/01/rdf-schema#',
        nrndscr: 'http://nooron.com/_/NooronDiscriminators.ttl#'
      };
  
      this.prototype.default_discriminator_src = "Latest(Latest(Latest(everyone)))";
      this.prototype.default_discriminator_src = "Latest(everyone)";
  
      this.prototype.default_discriminator_qname = 'nrndscr:latest_everyone';
      this.prototype.permitKeysDuringQueryFromKWARGS = 'spogi'.split('');
  
      // To protect visualizations from using html ids which collide with other
      // visualizations showing the same information we need ways to strengthen
      // the ids by localizing them to the visualization.  We do this by prepending
      // the local, probably semantically significant id with the id of Sometimes its necessary
      // to recover the semantic content of the
  
      this.prototype.strong_id_delimiter = "=-=";
    }
  static loadDependenciesStart(depList, selector, template) {
        // This sucker does not use 'this' and works right on the document so can be a function
        let injected = "";
        if (depList) {
          const prevElem = document.querySelector(selector);
          const lines = [];
          for (let depUri of Array.from(depList)) {
            const line = template.replace('REPLACE_URI', depUri);
            lines.push(line);
          }
            //$(prevElem).after(line)
          injected = lines.join("\n");
          $(prevElem).after(injected);
        }
        return injected;
      }

  static loadScripts(scriptList, callback) {
        // WORK IN PROGRESS
        if (scriptList) {
          return Array.from(scriptList).map((script) =>
            $.getScript(script).done(callback));
        }
      }

  static loadDependenciesIfNeeded(aClass) {
        // We want every class up the hierarchy to run this function and since it is not the function
        // which is overridden at each level (and hence present) it is the CSSDependencies and/or the
        // ScriptDependencies, the normal use of super does not work.
        // The order of operations is:
        //   0) bail if the loading has already happened for this class
        //   1) climb down toward the root of the class hierarchy
        //   2) stop climbing down until the next level down has already been loaded
        //   3) ensure that known CSS and Script resources are loaded, from the most rootward up
        //
        // So we must take responsibility for crawling up the prototype tree recursively ourselves:
        let injected, msg;
        if (aClass.hasOwnProperty('loadCSSDependenciesStarted') && aClass.loadCSSDependenciesStarted) {
          // loading has already happened for aClass and hence for all its parents so bail
          return;
        }
        if ((aClass.__proto__.__proto__ == null)) {
          // we have gone too far so bail
          return;
        }

        // We have not been here before and there is deeper to go so load the parent first
    this.loadDependenciesIfNeeded(aClass.__proto__);
        // Then we take care of any dependencies THIS level in the class hierarchy has
        let theClass = aClass.constructor;
        theClass = aClass;
        if (!aClass.hasOwnProperty('loadCSSDependenciesStarted')) {
          theClass.loadCSSDependenciesStarted = true;
          if (theClass.hasOwnProperty('CSSDependencies')) {
            injected = this.loadDependenciesStart(theClass.CSSDependencies,
              "head > link:last-of-type",
              "<link href=\"REPLACE_URI\" rel=\"stylesheet\" type=\"text/css\"/>"); // """
            msg = theClass.constructor.name + " " + injected;
            console.info(msg);
          }
        }

        if (!theClass.hasOwnProperty('loadScriptDependenciesStarted')) {
          theClass.loadScriptDependenciesStarted = true;
          if (theClass.hasOwnProperty('ScriptDependencies')) {
            // this IS THE CLASS
            injected = this.loadDependenciesStart(theClass.ScriptDependencies,
              "head > script:last-of-type",
              "<script src=\"REPLACE_URI\" type=\"text/javascript\"></script>"); // """
            msg = theClass.constructor.name + " " + injected;
            console.info(msg);
          }
        }
            //alert(msg) if injected
      }

    //CSSDependencies: ['VisualizationController.css']
    //ScriptDependencies: ['VisualizationController.js']

    constructor(formurlaManager, fracpanel, args, kwargs, expression_ast) {
      // cache a couple of things for local access
      super();
      this.make_add_new_cursors = this.make_add_new_cursors.bind(this);
      this.formurlaManager = formurlaManager;
      this.fracpanel = fracpanel;
      this.args = args;
      this.kwargs = kwargs;
      this.expression_ast = expression_ast;
      this.constructor.loadDependenciesIfNeeded(this);
      this.noodb = this.formurlaManager.noodb;
      this.rootPanel = this.formurlaManager.rootPanel;
      this.expose_to_console();
      this.creation_time = (new Date()).toISOString();
      this.id = this.noodb.synthetic_key_factory_next();
      this.content_id = "CID" + this.noodb.synthetic_key_factory_next();
      this.spogi_count = 0;

      this.respect_discriminator_in_kwargs();
      this.register_visualization_with_fracpanel();
      this.register_actions();
      if (this.rootPanel.clientArgs.suppress_fracpanel_buttons_by_default) {
        this.fracpanel.hide_all_buttons();
      }
    }

    attach_myNavigator() {
      this.myNavigatorsPanel = this.fracpanel.split("south");
      this.myNavigator = this.formurlaManager.run_formurla_src_in_panel("navigator()", this.myNavigatorsPanel);
      this.myNavigator.navigatingFor = this;
      this.myNavigator.begin_navigating();
    }

    expose_to_console() {
      if (window.location.origin.includes('localhost')) {
        if (window.VIZes == null) { window.VIZes = []; }
        return window.VIZes.push(this);
      }
    }

    localSel(selector) { // This is a nicer name than myQrySel which it is a replacement for
      return this.myQrySel(selector);
    }

    localSelAll(selector) { // This is a nicer name than myQrySelAll which it is a replacement for
      return this.myQrySelAll(selector);
    }

    localize(selector) {
      // localize selector so it only matches DOM elems within this visualization
      return "#" + this.content_id + ' ' + selector;
    }

    myQrySel(selector) {
      return document.querySelector(this.localize(selector));
    }

    myQrySelAll(selector) {
      return document.querySelectorAll(this.localize(selector));
    }

    contentAppend(html) {
      return $(this.localize('')).append(html);
    }

    make_unique_id(prefix) {
      return (prefix || "") + this.noodb.synthetic_key_factory_next();
    }

    submitTransaction(quads, defaultQuad) {
      // Apply the defaults.
      for (let q of Array.from(quads)) {
        if (q[0] == null) { q[0] = defaultQuad[0]; }
        if (q[1] == null) { q[1] = defaultQuad[1]; }
        if (q[2] == null) { q[2] = defaultQuad[2]; }
        if (q[3] == null) { q[3] = defaultQuad[3]; }
      }
      return this.noodb.allege_transaction(quads);
    }

    get_writable_graph() {
      return this.graph_uri || this.kwargs.g || this.formurlaManager.global_args.write_kb;
    }

    make_checkbox_fieldset(params) {
      let legend;
      let i = -1;
      const checkboxes = [];
      const base_id = this.make_unique_id('M__');
      for (let [value, label] of Array.from(params.entries)) {
        i++;
        const id = `${base_id}_${i}`;
        checkboxes.push(`\
<label for="${id}">
  <input type="checkbox" id="${id}" value="${value}"
         name="${params.field_name}">
  ${label}
</label>\
`);
      } // """
      let blob = checkboxes.join("\n");

      if (legend = params.legend) { // yes, assign and test in one line
        blob = `<fieldset><legend>${legend}</legend>${blob}</fieldset>`;
      }
      return blob;
    }

    respect_discriminator_in_kwargs() {
      const discr_uri_or_qname = this.kwargs.discr;
      if (discr_uri_or_qname) {
        // "not not" forces the value to be a boolean
        discr_uri_or_qname.is_qname = !discr_uri_or_qname.match(/^http\:/);
        return this.set_discriminator(this.kwargs.discr, null, true);
      }
    }

    expand_prefix(qname) { // TODO this should GO AWAY see issue #36
      if (((qname.is_qname == null) || !qname.is_qname) && (!qname.match(/^http/))) {
        console.warn("NOT A QNAME", qname);
        return qname;
      }
      const [prefix, key] = Array.from(qname.split(':'));
      const prefrag = this.prefixes[prefix];
      if (prefrag) {
        return prefrag + key;
      }
      throw new Error(`not able to expand_prefix: ${prefix}`);
    }

    uri_to_qname(uri) {
      if ((uri == null)) {
        return uri;
      }
      for (let prefix in this.prefixes) {
        const prefrag = this.prefixes[prefix];
        if (uri.startsWith(prefrag)) {
          const retval =  `${prefix}:${uri.substr(prefrag.length)}`;
          retval.is_qname = true;
          return retval;
        }
      }
      return uri;
    }

    register_actions() {
      return;
      const action_ctlr = this.fracpanel.action_button__ctlr;
      if (action_ctlr != null) {
        const say_hello = () => alert('"Hello!"');
        //action_ctlr.make_button_for('say "hello"', say_hello)
        return action_ctlr.make_button_for('Add New...', this.make_add_new_cursors, {
          icon: 'plus-circle',
          color: 'green'
        }
        );
      }
    }

    get_title() {
      const title = `${this.constructor.name}`;
      if (this.succinctTopic) {
        return title;
      }
      const topic = this.graph_uri || this.kwargs.s || this.kwargs.i || this.kwargs.g || this.kwargs.p || "...";
      return title + ` for ${topic}`;
    }
    //default_discriminator_src: "Latest(@A)"
    //Default_discriminator_src: "↻(↻(↻(everyone)))"
    //default_discriminator_src: "Earliest(Earliest(Earliest(everyone)))"
    //default_discriminator_src: "Latest(everyone)"
    //default_discriminator_src: "Latest(@A)"
    //default_discriminator_src: "Latest(Earliest(@A,@Bob))"

    set_discriminator(discriminator_uri, discriminator_src, suppress_reload) {
      // TODO figure out how to inject criterion_reducer_mapping if specified
      // TODO figure out how to minimally but sufficiently alter the criterion_reducer_mapping
      let uri;
      if (discriminator_uri && !discriminator_src) {
        uri = this.expand_prefix(discriminator_uri);
        const l = 'rdfs:label';
        l.is_qname = true;
        const label = this.expand_prefix(l);
        console.info("set_discr...()", uri);
        const terms = [{s: uri}, {p: label}];
        console.log("  terms:", JSON.stringify(terms));
        const labels = this.noodb.query(terms);
        if (!labels.length) {
          this.noodb.log.error(`Discriminator not found: '${uri}'`);
          return;
        }
        const disc_label_spogi = labels[0];
        discriminator_src = disc_label_spogi.o.getNativeValue();
      }

      this.discriminator_old = this.discriminator;
      this.discriminator = new Discriminator(discriminator_src, this.noodb.get_server_session().user_symbol);
      this.discriminator.uri = this.uri_to_qname(discriminator_uri);
      this.fracpanel.set_knob_label(this.fracpanel.voices_button, discriminator_src);
      //fracpanel = @visualization_picker_for.fracpanel
      return this.formurlaManager.replace_discriminator(this, suppress_reload);
    }

    discriminate(spogi) {
      if ((this.discriminator == null)) {
        this.set_discriminator(this.default_discriminator_qname, this.default_discriminator_src, true); // true means suppress_reload
      }
      return this.discriminator.discriminate(spogi, this.noodb);
    }

    make_add_new_cursors() {
      let evl, TextCursor;
      if ((TextCursor == null)) {
        ({
          TextCursor
        } = require('textcursor'));
      }
      //txtcrsr = new TextCursor('div', "click elsewhere")
      const cand = new TextCursor(this.localize('.candidateCell'), 'new candidate');
      const crit = new TextCursor(this.localize('.criterionControl'), 'new criterion');
      return evl  = new TextCursor(this.localize('td.eval'), 'new evaluation');
    }

    get_id() {  // this is a unique id for this content
      return this.id;
    }
    receive() {
      return this.spogi_count++;
    }
    getQueryFromKWARGS() {
      const qry = [];
      for (let k in this.kwargs) {
        const v = this.kwargs[k];
        if (!(Array.from(this.permitKeysDuringQueryFromKWARGS).includes(k))) {
          continue;
        }
        const term = {};
        term[k] = v;
        qry.push(term);
      }
      return qry;
    }
    showDocs() {
      if (this.docs) {
        return $("#"+`${this.content_id}`).append(this.docs);
      }
    }
    perform_subscriptions() {
      let qry = [{g: this.args[0]}];
      // TODO: if kwargs then make qry from them else use args else do not subscribe
      if (this.kwargs) {
        qry = this.getQueryFromKWARGS();
      }
      const queries = [qry];
      const {
        subscribe_kb_qrys
      } = this.formurlaManager.global_args;
      if (subscribe_kb_qrys != null) {
        for (qry of Array.from(subscribe_kb_qrys)) {
          //alert("perform_subscriptions() #{JSON.stringify(qry)}")
          queries.push(qry);
        }
      }
      if ((this.subscriptions_performed == null)) { // ensure perform_subscriptions only runs once
        // TODO: ensure that subscriptions are shared among active visualizations
        this.subscriptions_performed = [];
        return (() => {
          const result = [];
          for (qry of Array.from(queries)) {
          //alert(JSON.stringify(qry))
            this.subscriptions_performed.push(qry);
            result.push(this.noodb.subscribe_visualization(this, qry));
          }
          return result;
        })();
      }
    }

    describeDraggable(draggableElem) {
      // Our purpose is to figure out what kind of thing this draggable represents
      // presumably so a selection of what kind of visualization ought to be
      // performed on it.
      //
      // It should probably what type of thing it is: s,p,o,g,i,a,w (a=Author, w=When)
      // Is it an object or a literal?
      // What is it's literal datatype?
      // Does it have an id? a nid?
      // Is it an aggregated amount?

      // All subclasses of VisualizationController should call super (ie, this method)
      // then add their own insight into what was clicked, for only they can know.

      const desc = new Description();
      desc.viz = this;
      desc.frac_id = this.fracpanel.frac_id;
      return desc;
    }
    strengthen_id(weak_id) {
      return `${this.fracpanel.frac_id}${this.strong_id_delimiter}${weak_id}`;
    }
    unstrengthen_id(strong_id) {
      return strong_id.replace(`${this.fracpanel.frac_id}${this.strong_id_delimiter}`,"");
    }

    make_fracpanel_css_classname() {
      return 'vis-func-' + this.constructor.func_name;
    }

    register_visualization_with_fracpanel() {
      // Introduce this visualization instance to the fracpanel containing it
      // Also pass an identifier to fracpanel to use as a CSS classname on the panels outer div
      return this.fracpanel.register_visualization(this, this.make_fracpanel_css_classname());
    }

    cachedScript(url, options) {
      if (options == null) { options = {}; }
      $.extend(options, {datatype: 'script', cache: true, url});
      this.setInnerHTML(`cachedScript('${url}', ${JSON.stringify(options)})`);
      return $.ajax(options);
    }

    setInnerHTML(html) {
      return this.fracpanel.content_area[0].innerHTML = html;
    }

    format_post_date(creationDate) {
      const now = new Date();
      if (creationDate) {
        const posted_vid_yr = creationDate.getUTCFullYear();
        const posted_vid_month = creationDate.getUTCMonth();
        const posted_vid_day = creationDate.getUTCDate();
        const previous_post_day = new Date(creationDate);
        const now_yr = now.getUTCFullYear();
        const now_month = now.getUTCMonth();
        const now_day = now.getUTCDate();

        let day_before = now.setDate(now.getDate() - 1);
        day_before = new Date(day_before);

        const day_before_yr = day_before.getUTCFullYear();
        const day_before_month = day_before.getUTCMonth();
        const day_before_day = day_before.getUTCDate();

        //console.log "The posted date: #{posted_vid_yr} #{posted_vid_month} #{posted_vid_day}"
        //console.log "Today's date: #{now_yr} #{now_month} #{now_day}"
        //console.log "Yesterday is #{day_before_yr} #{day_before_month} #{day_before_day}"

        if ((now_yr === posted_vid_yr) && (now_month === posted_vid_month) && (now_day === posted_vid_day)) {
          const formatted_hours = ('0' + creationDate.getUTCHours()).slice(-2);
          const formatted_minutes = ('0' + creationDate.getUTCMinutes()).slice(-2);
          creationDate = `${formatted_hours}:${formatted_minutes}`;
        } else if ((day_before_yr === posted_vid_yr) && (day_before_month === posted_vid_month) && (day_before_day === posted_vid_day)) {
          creationDate = "Yesterday";
        } else { //display the date only
          const formatted_months = ('0' + (creationDate.getUTCMonth()+1)).slice(-2);
          const formatted_days = ('0' + creationDate.getUTCDate()).slice(-2);
          creationDate = `${creationDate.getUTCFullYear()}-${formatted_months}-${formatted_days}`;
        }
          //creationDate = creationDate.toISOString().replace(/T|Z/g, ' ')
      } else {
        creationDate = 'unknown';
      }
      return creationDate;
    }
}
VisualizationController.initClass();
