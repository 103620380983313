import {
  VisualizationController
} from './visualizationcontroller.js';

export class VegaVisualizationController extends VisualizationController {
  static initClass() {
    this.prototype.CSSDependencies = [];
    this.prototype.ScriptDependencies = [
      '/bower_components/vega/vega.js',
      '/bower_components/vega-lite/vega-lite.js'];
  }
  // This is an Abstract class
  // https://vega.github.io/vega-lite/tutorials/getting_started.html
  constructor() {
    super(...arguments);
    this.resize_handler = this.resize_handler.bind(this);
    this.changeDimension = this.changeDimension.bind(this);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    this.availablePredicates = {};
    this.showDocs();
    this.perform_subscriptions();
    this.initialize_plottedPredicates();
    this.initialize_vlSpec();
    this.addResizeListener();
    //create select options
    this.insertVlControls();
  }

  addResizeListener() {
    return $(this.fracpanel.content_area).bind("_splitpaneparentresize", this.resize_handler);
  }

  resize_handler() {
    this.updateVegaSize();
    this.render();
  }

  initialize_plottedPredicates() {
    console.log(("initialize_plottedPredicates called"));
    this.plottedPredicates = [];
    if (this.kwargs.x) {
      console.log(("set kwargs x"));
      this.plottedPredicates.push(this.kwargs.x);
    }
    if (this.kwargs.y) {
      this.plottedPredicates.push(this.kwargs.y);
    }
    if (this.kwargs.size) {
      this.plottedPredicates.push(this.kwargs.size);
    }
    if (this.kwargs.color) {
      this.plottedPredicates.push(this.kwargs.color);
    }
    if (this.kwargs.text) {
      return this.plottedPredicates.push(this.kwargs.text);
    }
  }

  updateVegaSize() {
    this.vlSpec.config.cell = this.get_panelSize();
    if (this.vlSpec.plot === 'bar') { return this.set_barScale(); }
  }

  get_panelSize() {
    return {
      width: $(this.visContent).width() - 200,
      height: $(this.visContent).height() - 100
    };
  }

  set_barScale() {
    const newBarWidth_x = (this.get_panelSize().width / this.vlSpec.data.values.length) - 5;
    const newBarWidth_y = (this.get_panelSize().height / this.vlSpec.data.values.length) - 5;
    this.vlSpec.encoding.x.scale.bandSize = Math.round(newBarWidth_x);
    return this.vlSpec.encoding.y.scale.bandSize = Math.round(newBarWidth_y);
  }

  initialize_vlSpec() {
    console.log("initialize_vlSpec");
    this.vlSpec = {
      description: "Vega-lite visualization",
      mark: 'point', // value that determines the type of plotting; default 'point'
      encoding: {
        x: {
          field:  this.plottedPredicates[0] || "stub", // URL - or - selected control Selection
          type: "quantitative"
        },
        y: {
          field:  this.plottedPredicates[1] || "stub", // second numerical option
          type: "quantitative"
        },
        size: {
          field:  this.plottedPredicates[2] || "stub", // third numerical option
          type: "quantitative"
        }
      },
      config: {
        cell: this.get_panelSize()
      },
      data: {
        values: []
      }
    };

    this.vlSpec.mark = this.plotSet().mark;
    this.vlSpec.plot = this.plotSet().plot;
    if (this.plotSet().encoding.x) {
      jQuery.extend(this.vlSpec.encoding.x, this.plotSet().encoding.x);
    }
    if (this.plotSet().encoding.y) {
      jQuery.extend(this.vlSpec.encoding.y, this.plotSet().encoding.y);
    }
    if (this.plotSet().encoding.size) {
      return this.vlSpec.encoding.size = this.plotSet().encoding.size;
    }
  }

  insertVlControls() {
    $("#"+`${this.content_id}`).append('X: <select name="x" style=""></select> ').change(this.changeDimension);
    $("#"+`${this.content_id}`).append('Y: <select name="y" style=""></select> ').change(this.changeDimension);
    if (this.vlSpec.plot === 'bubble') {
      return $("#"+`${this.content_id}`).append('Size: <select name="size" style=""></select> ').change(this.changeDimension);
    }
  }

  render() {
    this.vgSpec = vl.compile(this.getVlSpec()).spec;
    if (this.vlSpec.plot === 'bar') { this.set_barScale(); }

    const visctlr = this;
    return vg.parse.spec(this.vgSpec, chart => {
      this.view = chart({el: "#"+this.content_id}).update();
      this.view_data = this.view.data();
    });
  }

  getEmbedSpec() {
    return {
      mode: "vega-lite",
      spec: this.getVlSpec()
    };
  }

  receivePredicate(p_key) {
    this.sels = {};
    console.log("init receivePredicate ");
    const length_to_name = {1:'x', 2:'y', 3:'size'};
    if (!this.availablePredicates[p_key]) {
      this.availablePredicates[p_key] = true;
      $(this.localize("[name='x']")).append(`<option value='${p_key}'>${p_key}</option>`);
      $(this.localize("[name='y']")).append(`<option value='${p_key}'>${p_key}</option>`);
      if (this.vlSpec.plot === 'bubble') {
        $(this.localize("[name='size']")).append(`<option value='${p_key}'>${p_key}</option>`);
      }
      if (this.plottedPredicates.length < 3) {
        this.plottedPredicates.push(p_key);
        const name = length_to_name[this.plottedPredicates.length];
        this.changeDimension({
          target: {
            name,
            value: p_key
          }
        });
        this.sels[name] = p_key;
      }
      if (this.kwargs.x) {
        this.sels.x = this.kwargs.x;
      }
      if (this.kwargs.y) {
        this.sels.y = this.kwargs.y;
      }
      if (this.kwargs.size) {
        this.sels.size = this.kwargs.size;
      }

      return this.updateSelectors();
    }
  }

  updateSelectors() {
      let sel = this.localize(`select[name='x'] option[value='${this.sels.x}']`);
      $(sel).prop('selected', true);
      sel = this.localize(`select[name='y'] option[value='${this.sels.y}']`);
      $(sel).prop('selected', true);
      sel = this.localize(`select[name='size'] option[value='${this.sels.size}']`);
      return $(sel).prop('selected', true);
    }

  changeDimension(evt) {
    evt.target.value;
    this.vlSpec.encoding[evt.target.name].field = evt.target.value;
    if (this.plottedPredicates.length > 1) {
      return this.render();
    }
  }

  receive(spogi) {
    super.receive(...arguments);
    //console.log("receive initiated")
    const s_key = spogi.s.key();
    const o_key = spogi.o.key();
    let p_key = spogi.p.key();
    if (p_key.includes('#')) {
      p_key = p_key.split('#')[1];
    } else if (p_key.includes(':')) {
      p_key = p_key.split(':')[1];
    }
    this.receivePredicate(p_key);

    let found = false;
    for (let record of Array.from(this.vlSpec.data.values)) {
      if (record._id === s_key) {
        found = record;
        break;
      }
    }
    if (!found) {
      found =
        {_id: s_key};
      this.vlSpec.data.values.push(found);
    }
    found[p_key] = o_key;
    return this.render();
  }

  getVlSpec() {
     return this.vlSpec;
   }
}
VegaVisualizationController.initClass();
