import {ActionKnob} from './actionknob.js';
export class VoicesKnob extends ActionKnob {
  static initClass() {
    this.prototype.elem_label = "Voices Menu";
    this.prototype.main_icon = "users";
    this.prototype.css_classes = "voices_menu";
    this.prototype.function_spec = {
      name: 'discriminators',
      g: 'nrn:NooronDiscriminators'
    };
  }
  make_panel() {
    super.make_panel(...arguments);
    this.elem.hide();
    this.watch_for(':visible', null, () => this.reveal());
  }
}
VoicesKnob.initClass();
