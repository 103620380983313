import {
  strip_quotes,
  DragAndDropOrClickToVisualize,
  VisualizationController
} from './visualizationcontroller.js';
import * as huviz from 'huviz'; // Huviz, Orlando (soon TextCursor, ColorTreePicker)

export class AbstractD3VisualizationController extends VisualizationController {}
  ScriptDependencies: ['/bower_components/d3/d3.min.js']

export class GraphVisualizationController extends AbstractD3VisualizationController {
  static initClass() {
    this.prototype.CSSDependencies = [
      '/css/huviz/huvis.css',
      '/css/huviz/huvis_controls.css',
      '/css/huviz/CRRT.css',
      '/css/huviz/gclui.css'];
    //this.prototype.ScriptDependencies = ['/huviz/huviz.js'];
  }

  constructor() {
    let callback;
    super(...arguments);
    this.script = decodeURIComponent(this.args[0] || this.kwargs.script || '');
    const div_id = this.noodb.synthetic_key_factory_next();

    this.huviz = huviz; //require("huviz");

    //main_panel = @fracpanel
    const main_panel = this.fracpanel.content_area.append(`<div id="HUVIZ_TOP_${div_id}" class="HUVIZ_TOP huviz_wrapper"></div>`);

    //contentSel = "#" + @fracpanel.content_area.attr("id")
    const contentSel = `#HUVIZ_TOP_${div_id}`;


    //gclui_id = @make_unique_id('gclui_')
    //@fracpanel.content_area.append("""<div id="#{gclui_id}"></div>""")
    //gclui_id = @make_unique_id('gclui_')
    //@fracpanel.content_area.append("""<div id="#{gclui_id}"></div>""")

    //picker_panel = main_panel.split("east")
    //controls_panel = picker_panel.split("south")
    //controls_panel.TLBR_handles().hide()
    //controls_panel.visualization_button.hide()
    //picker_panel.TLBR_handles().hide()
    //drag_and_dropper = new DragAndDropOrClickToVisualize(@)

    const args = {
      hide_fullscreen_button: true,
      show_tabs: true,
      skip_discover_names: true,
      tab_specs: ['commands','settings','history'], // things break if these are not present
      skip_log_tick: true,
      make_pickers: false,
      huviz_top_sel: contentSel
    };
      //drag_start_handler: drag_and_dropper.drag

    this.widget = this.make_widget(args);
    this.widget.updateWindow();
    this.noodb.log.info("graph widget:",this.widget);
    if (this.script) {
      //alert "script:\n  #{@script}"
      callback = () => {
        this.noodb.log.info("running script:",this.script);
        this.widget.gclui.run_script(this.script);
        return this.noodb.log.info("ran script:",this.script);
      };
    }
    this.perform_subscriptions(callback);
  }

  make_widget(args) {
    return new this.huviz.Huviz(args);
  }
}
GraphVisualizationController.initClass();
