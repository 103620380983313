// Reactor
//   A reactor is a cell in a semantic spreadsheet.
//
// Notes:
//   Consider using Jailed https://github.com/asvd/jailed or be inspired by it.
//
// Example formulae:
//
//  1) ☾dog☽ = ☾good☽ + ☾god☽
//  2) ☾dog☽ = ☾subj●predicate1☽ + ☾predicate2☽  # If a subject is not specified
//
// Concepts:
// * Data lookups are contained within moons: ☾☽
// * The left moon   ☾  is expanded to 'this.get("'
// * The right moon  ☽  is expanded to '").get_value()'
// * So ☾shoesize☽ becomes 'this.get("shoesize").get_value()'
// * If there are two terms within moons they are subject and predicate: ☾bob●birthdate☽
// * If there is one term within moons it is understood to be a predicate: ☾shoesize☽
// * If there is no subject (eg ☾shoesize☽) then the formula relates to the current subject ie row.
// * So
//     "☾workSessionDuration☽ = ☾workSessionEnd☽ - ☾workSessionStart☽"
//   is expanded to the javascript:
//     this.get('workSessionDuration').set_formula(
//       "return this.get_val('workSessionStart') + this.get_val('workSessionStart')")
//  More realistically:
//     ☾nrn:bob●foaf:firstName☽ is bob's first name
//  where
//     nrn:bob         ==>  http://nooron.com/__/bob
//     foaf:firstName  ==>  http://xmlns.com/foaf/0.1/firstName

export class Interactor {
  // Interactor are the @world argument to Reactor.constructor() and act as
  // the interface between the Reactor and the Visualization it is in the
  // context of.  Interactor subclasses interface with particular visualizations.
  constructor() {
    this.reactors = {};
  }
  discover(id) {
    const found = this.reactors[id];
    if (!found) {
      throw new Error(`\"${id}\" not found`);
    }
    return found;
  }
  register(reactor) {
    return (this.reactors[reactor.id] = reactor);
  }
}

export class Reactor {
  static initClass() {
    this.prototype.L = "☾";
    this.prototype.left_expansion = 'this.get("';
    this.prototype.R = "☽";
    this.prototype.right_expansion = '").get_value()';
    this.prototype.S = "●"; // separates subject from predicate
    //          new RegExp('☾([^☽]*)☽','u')  ES6 equiv
    this.prototype.gather_re = new RegExp("\u263e([^\u263d]*)\u263d", "g");
  }
  constructor(id, formula, context) {
    this.id = id;
    this.formula = formula;
    this.context = context;
    this.err = null;
    this.dirty = true;
    this.inputs = {};
    this.consumers = {};
    this.value = null; // should be null
    this.set_func(new Function("throw new Error('no func assigned')"));
    this.parse();
    if (this.context) {
      this.context.register(this);
    }
  }
  get(id) {
    let input = this.inputs[id];
    if (input) {
      return input;
    }
    if (this.context) {
      input = this.context.discover(id);
      if (input) {
        this.inputs[id] = input;
        input.consumers[this.id] = this;
        return input;
      }
    } else {
      throw new Error(`no context so cannot .get('${id}')`);
    }
  }
  get_value() {
    if (this.dirty) {
      this.fire();
    }
    if (this.err) {
      return this.err;
    }
    return this.value;
  }
  set_value(v) {
    this.value = v;
    return this;
  }
  toString() {
    return `${this.L}${this.id}${this.R} = ${this.formula}`;
  }
  expand_ref(ref_id) {
    return this.left_expansion + ref_id + this.right_expansion;
  }
  parse() {
    // TODO parsing the formula should discover the input names
    //   and create false stubs for them on @inputs
    if (!this.formula) {
      this.dirty = true;
      return;
    }
    let match = this.formula.match(this.gather_re);
    let body = this.formula;
    match = this.gather_re.exec(body);
    while (match) {
      // loop through the body until all references are expanded
      if (match[1]) {
        const ref_id = match[1];
        this.inputs[ref_id] = false; // placeholder
        body =
          body.substr(0, match.index) + // the beginning
          this.expand_ref(ref_id) +
          body.substr(match.index + ref_id.length + 2); // the remainder
      }
      match = this.gather_re.exec(body);
    }
    this.body = "return " + body;
    return this.set_func(new Function(this.body));
  }
  set_func(f) {
    this.func = f; // it is not enough to attach it
    return this.func.bind(this); // it must also be bound to this so this.get_value() can work
  }
  fire() {
    // fire is called by a reactors outputs
    this.started = Date.now();
    try {
      const v = this.func();
      this.set_value(v);
      this.err = null;
    } catch (e) {
      //@value = e.toString()
      this.err = e;
    }
    this.stopped = Date.now();
    this.dirty = false;
    this.update_consumers();
    return this;
  }
  input(id) {
    // return input identified by id
    let input = this.inputs[id];
    if (input == null) {
      input = this.context.get_reactor(id);
      if (input == null) {
        throw new Error(`'${id}' not found`);
      }
      this.inputs[id] = input;
    }
    return input;
  }
  update_consumers() {
    for (let id in this.consumers) {
      const consumer = this.consumers[id];
      consumer.fire();
    }
    return this;
  }
}
Reactor.initClass();

export class ReactorWithPuppet extends Reactor {
  //puppet: (puppet) -> # a puppet is (optionally) manipulated by the reactor
  //  if puppet?
  //    @_puppet = puppet
  //  return @_puppet
  //
  constructor() {
    super(...arguments);
    this._puppet_cb = null;
  }
  set_value() {
    super.set_value(...arguments);
    console.log(`set_value('${value}')`);
    this.update_puppet();
    return this;
  }
  update_puppet() {
    const cb = this.puppet_set_value_cb();
    if (cb) {
      return cb(this.value);
    }
  }
  puppet_getter(cb) {
    if (cb != null) {
      this._puppet_getter = cb;
    }
    return this._puppet_getter;
  }
  puppet_setter(cb) {
    if (cb != null) {
      this._puppet_setter = cb;
    }
    return this._puppet_setter;
  }
}

export class TabularInteractor extends Interactor {
  // This is an Interactor which interfaces with the TabularVisualizationController
  XXXXX_get_reactor(id) {
    let reactor = this.reactors[id]; // the criterion_id normally
    if (reactor == null) {
      reactor = new Reactor(null, this);
      this.reactors[id] = reactor;
    }
    return reactor;
  }
  spawn_reactor(datum, formula) {
    const id = datum.id();
    console.log(`spawn_reactor('${id}')`);
    const reactor = new ReactorWithPuppet(id, formula, this);
    reactor.puppet_setter(datum.set_value);
    return reactor.puppet_getter(datum.value); // TODO should this be the native value?
  }
}
