
export var AutoSem = (function() {
  let seqNo = undefined;
  AutoSem = class AutoSem {
    static initClass() {
      /*
       * Purpose:
       *   Provide an intermediary between OWL/RDF classes and JavaScript classes
       *   so it is easy to deal with semantically driven class structures programmatically.
       * What?
       *   A graph of interconnected JS objects, keyed by their @id amasses in dbStore.
       */
      seqNo = 0;
        //@type = @constructor.name
      this.prototype._curieToJsonld = {};
    }
    static _getOrCreateResource(subjCurie, dbStore, whichClass) {
      // Return the annotation after creating it if need be
      let resource = dbStore[subjCurie];
      if ((resource == null)) {
        resource = new whichClass(subjCurie);
        dbStore[subjCurie] = resource;
        this._replaceUses(resource, dbStore);
      }
      return resource;
    }
    static _replaceUses(theObj, dbStore) {
      // Find all the existing _uses and replace theObj.id with theObj
      const uses = dbStore._uses[theObj.id] || [];
      for (let [objId, propName] of Array.from(uses)) { // FIXME ensure uses is deduped
        dbStore[objId]._replaceAnyKV(propName, theObj);
      }
    }
    constructor(id) {
      this.id = id;
      this["@context"] = this._context;
      this._seqNo = seqNo++;
    }
    _curie_to_parts(curie) {
      // Usage:
      //   [prefix, local] = thing._curie_to_parts(curie)
      return curie.split(':');
    }
    _curie_without_colons(curie) {
      // Purpose:
      //   @_curie_without_colons(curie) ==> 'prefix__local'
      const prefix_local_pair = this._curie_to_parts(curie);
      return prefix_local_pair.join('__');
    }
    _getProp(curie) {
      // the values in this list could be obtained by querying noodb for
      //   subjects matching: p=rdfs:domain,o=oa:Annotation
      return this._curieToJsonld[curie];
    }
      //return curie in ['oa:hasBody', 'oa:hasTarget', 'oa:motivatedBy']
    _replaceAnyKV(propName, theObj) {
      // Purpose:
      //   this[propName] has theObj.id as a value, put theObj in its place
      const arrayOrValue = this[propName];
      if (arrayOrValue != null) {
        const {
          id
        } = theObj;
        if (Array.isArray(arrayOrValue)) {
          arrayOrValue.forEach(function(elem, i, array) {
            if (elem === id) {
              return array[i] = theObj;
            }
          });
        } else {
          if (this[propName] === id) {
            this[propName] = theObj;
          }
        }
      }
    }
    _setCurieKV(p_curie, o_val, dbStore) {
      let theKey = this._curieToJsonld[p_curie];
      if ((theKey == null)) { // no mapping to jsonld is available, so just use the curie
        const [prefix, local] = Array.from(this._curie_to_parts(p_curie));
        if (prefix === this._context_prefix) {
          theKey = local;  // eg bodyValue
        } else {
          theKey = this._curie_without_colons(p_curie); // eg rdf__type
        }
      }
      const theObj = dbStore[o_val] || o_val;
      if ((this[theKey] == null)) {
        this[theKey] = theObj;
      } else {
        if (this[theKey].constructor.name !== 'Array') {
          this[theKey] = [this[theKey]];
        }
        this[theKey].push(theObj);
      }
      if (true) { // TODO make this if o_val has type 'rdf:object'
        // keep a mapping of curies like o_val to the object.id and
        if (dbStore._uses == null) { dbStore._uses = {}; }
        if (dbStore._uses[o_val] == null) { dbStore._uses[o_val] = []; }
        dbStore._uses[o_val].push([this.id, theKey]);
        if (this._dirtyKeys == null) { this._dirtyKeys = []; } // list of keys which have change since last cleaning of dirt
        return this._dirtyKeys.push(theKey);
      }
    }
    _dump() {
      return JSON.stringify(Object.entries(this));
    }
  };
  AutoSem.initClass();
  return AutoSem;
})();
