import {TabularVisualizationController} from './tabular.js';

export class AllegationsTable extends TabularVisualizationController {
    static initClass() {
      this.func_name = 'allegations';
      this.pretty_name = "Allegation Table";
      let id = "allegations_table";
    }

    discriminate(spogi) {
      // this performs NO discrimination at all, perfect when you want to see all the allegations
      return spogi;
    }

    get_criteria() {
      return [{
        label: 'subject',
        id: 's',
        updateable_by: 'system',
        valuetype_id: 'plain',
        cells_visualizable: true
      }
      , {
        label: 'predicate',
        id: 'p',
        updateable_by: 'system',
        valuetype_id: 'plain',
        cells_visualizable: true
      }
      , {
        label: 'object',
        id: 'o',
        updateable_by: 'system',
        valuetype_id: 'plain'
      }
      , {
        label: 'graph',
        id: 'g',
        updateable_by: 'system',
        valuetype_id: 'plain',
        cells_visualizable: true
      }
      , {
        label: 'when',
        id: 'whn',
        updateable_by: 'system',
        valuetype_id: 'datetime'
      }
      ];
    }

    receive(spogi) {
      const i = spogi.i.key();

      const candidate_id = this.strengthen_id(i);
      const candidate = this.widget.add_candidate({
        id: candidate_id,
        //url: "allegations(i=#{i})"
        label: i
      });

      if (candidate && (candidate.tr != null)) {
        //console.log "candidate.tr()", candidate.tr()
        candidate.tr().setAttribute('id',candidate_id);
      }

      this.widget.add_datum([candidate_id, 's', spogi.s.key()]);
      this.widget.add_datum([candidate_id, 'p', spogi.p.key()]);
      this.widget.add_datum([candidate_id, 'o', spogi.o.getNativeValue()]);
      this.widget.add_datum([candidate_id, 'g', spogi.g.key()]);
      return this.widget.add_datum([candidate_id, 'whn', spogi.ww().getISO()]);
    }

    describeDraggable(draggableElem) {
      // Our purpose in this method is to return a description of cell which
      // was clicked or dragged so it can be determined how to visualize it.
      // These descriptions should be equivalent to english sentences such as:
      //   "'rdfs:label' is the URI of a predicate."
      //      .thing_value: "rdfs:label"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'p'  # a predicate
      //   "'nrn:primordialKB' is the qname of a graph."
      //      .thing_value: "nrn:primordialKB"
      //      .thing_valuetype: xsd:anyUri OR xsd:qname OR ????
      //      .thing_isa: 'g'  # a graph
      // Weirdly, for the allegations() visualization anyway, the .i of the
      // row the cell is from doesn't really matter.  It seems hard to assert,
      //  but the graph doesn't really matter either.
      const desc = super.describeDraggable(draggableElem);
      const cand_id = this.findTRid(draggableElem);
      const crit_idx = this.getTableColumnNum0(draggableElem);
      const weak_cand_id = this.unstrengthen_id(cand_id);
      if (crit_idx != null) {
        const criterion = this.widget.get_criterion_by_idx(crit_idx);
        if (criterion != null) {
          const crit_id = criterion.id;
          if (crit_id != null) {
            desc.criterion_id = crit_id;
            const datum = this.widget.get_datum_by_cand_id_and_crit_id(cand_id, crit_id);
            desc.thing_isa = crit_id; // eg: s,p,o,g,whn,who,i
            desc.thing_valuetype = datum.get_valuetype();
            desc.thing_value = datum.get_saveable_value();
          }
        }
      }

      // desc.id = weak_cand_id # does not matter!!!
      return desc;
    }
}
AllegationsTable.initClass();
