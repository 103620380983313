/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {RdfObject} from './quadparser.js';
import {NoiceTrie} from './noicetrie.js';

// SOME ENUMERATED TYPES
export const LITERAL = 1;
export const URI = 2;
export const SAFE_CURIE = 3;
export const CURIE = 4;
export const BLANK = 5;
export const BLANK_AS_CURIE = 6;

export const TYPS = [];  // give names to each of the TYP numbers
TYPS[LITERAL] = 'LITERAL';
TYPS[URI] = 'URI';
TYPS[SAFE_CURIE] = 'SAFE_CURIE';
TYPS[CURIE] = 'CURIE';
TYPS[BLANK] = 'BLANK';
TYPS[BLANK_AS_CURIE] = 'BLANK_AS_CURIE'; // stored as a CURIE but repr() as BLANK

// see get_canonical_typ() define the TYP that each different TYP ought to be stored as
const TYP_2_CANONICAL_TYP = {};                     // default: URI
TYP_2_CANONICAL_TYP[LITERAL] = LITERAL;       // ie 1: LITERAL
TYP_2_CANONICAL_TYP[SAFE_CURIE] = CURIE;      // ie 3: CURIE
TYP_2_CANONICAL_TYP[CURIE] = CURIE;           // ie 4: CURIE
TYP_2_CANONICAL_TYP[BLANK] = BLANK_AS_CURIE;  // ie 5: CURIE
//TYP_2_CANONICAL_TYP[BLANK_AS_CURIE] = CURIE  # ie 5: CURIE
TYP_2_CANONICAL_TYP.http = CURIE;
TYP_2_CANONICAL_TYP.https = CURIE;
TYP_2_CANONICAL_TYP.ftp = CURIE;
TYP_2_CANONICAL_TYP.file = URI;

const SAFE_CURIE_REGEX = /\[(.*)\:(.*)\]/;
const DOMAIN_NAME_REGEX = /^http[s]*:\/\/(.*)\/.*/;
const BARE_URI_REGEX = /^.+:\/\/([^\/]*)\/?$/;
const FILE_URI_REGEX = /^file:\/\/\/(.*)$/;

const blanks_docs_md = `\
A 'blank' url should end up with an internal prefix which unites all the
blank urls within that same import operation.  This concept should be
closely examined because ideally the 'same blank url' across two imports
would be recognized as such and have the same internal representation.
Actually NO! They can not be trusted to be the same but an assertion of their
equivalence can be made (and disputed if need be) so they should actually be
different -- in other words each occasion the same file (with different contents)
is imported the blank uris should get a new BLANK_PREFIX.

So is there a relationship between the BLANK_PREFIX and the 'graph url'?
Each time an RDF document is retrieved at some URL, that URL becomes the
fourth 'graph' term of the quads

Proposals:
  Base the prefix for blanks on the prefix for the graph the blank appears in.
  ========
    # filename: booger.ttl
    _:one a _:whatzit .
  ========
  In this case the prefix for the file would be booger: and so
  booger_:one a booger_:whatzit .

 TODO problems to solve:
    * how to access all the blank urls associated with some importation of a document?
    * how to access all the different imports of a particular graph?
    * how to record the unauthenticated authorship of spogis from remote urls
    * how to discover the blank_prefix for a session
    * hot to deal with graphs which are themselves named with a blank node

TODO evolve these concepts until they are coherently implementable
    * each distinct occasion (ImportEvent) a graph is imported a new unique blank prefix will be used
    * and every triple will really be a quad, if you cannot afford a graph term, one will be provided for you
    * and if an ImportEvent is not the context in which the allegation happened then a per-session-graph-unique prefix will be used
    * AKA each graph alleged about in a session will have a unique blank_prefix generated for it
    * and note that the last-change-date HTTP header will provide the WHEN term

    * RDF files containing triples will use the their source URL as the
      'graph term' of resultant quads
    * Quads will keep their 'graph term'
        * TODO figure out how to protect against spoofing
    * the synthetic prefix for the 'graph term' should become the prefix for the blank
      urls in that file

    * the allegations for a session will use a unique blank_prefix
    * and each file import operation will use a unique blank_prefix

    * create ImportEvent instances for each remote document loading event, with properties
        * who requested
        * HTTP timestamp offered by source server TODO which header?
        * SHA-2 hash of entire file
        * the unique prefix for blank urls
        * the URL imported
        * the request headers?
    * stash ImportEvent instances in a graph with the local parts being the SHA-2 hash FIXME or the unique prefix for blank urls?
    * ImportEvent benefit from the nature of SPOG ids (the 'ID term') being cryptographically derived from the SPOG


So the next question is what to do when the 'graph url' is empty or a blank url.
What the heck does a blank 'context or graph url' even mean? Of course that is
up to the author.  The question is whether they are legal -- Shawn suspects they
are (TODO check!).
\
`;

export const isLiteral = function(str) {
  // Do not sanitize str because it should be a string, if it is not a string -- explode
  if (typeof str !== 'string') {
    if (str.constructor.name !== 'String') {
      throw `isLiteral(str) expects str to be a string, but it was ${typeof str }`;
    }
  }
  //m = str.match(/^[\-\"\+\d]/)
  //m = str.match('^[\"\d]')
  //console.log "match", m, str
  // FIXME is a single-quote a legal way to start a string in .rdf, .ttl, .nq etc
  const retval = (str.match(/^[\-\"\+\d]/) && true) || false; // In other words, return true iff str[0] in '"+-0123456789'
  if (/YAGO3/.exec(str) && !retval) {
    console.log('YAGO3 MATCH!!!! should return true but will return', retval, `first char is: <${str[0]}>`);
  }
  return retval;
};


// https://themify.me/docs/extending-allowed-url-protocols
//   Contains a good list of uri schemes AKA protocols
// https://developer.apple.com/library/content/featuredarticles/iPhoneURLScheme_Reference/SMSLinks/SMSLinks.html
//   sms:
// https://en.wikipedia.org/wiki/XMPP
//   xmpp: aka Jabber
export const STANDARD_PROTOCOLS_REGEX = new RegExp('^(http|https|ftp|ftps|file|mailto|geo|news|isbn|irc|gopher|tel|fax|xmpp|sms):', 'i');

const CURIE_REGEX = new RegExp('^\w+\:.+');

const curie_to_safe_curie = curie => `[${curie}]`;

const safe_curie_to_curie = function(safe_curie) {
  if (safe_curie.length > 2) {
    return safe_curie.substr(1, safe_curie.length - 2);
  }
  throw new Error(`safe_curie_to_curie() expects a safe_curie, unlike '${safe_curie}'`);
};

export const getTyp = function(str) {
  if (str.TYP) {
    return str.TYP;
  }
  if (str.startsWith('"_:') || str.startsWith('_:')) {
    return BLANK;
  }
  if (isLiteral(str)) {
    return LITERAL;
  }
  if (str.startsWith('[') && str.endsWith(']')) {
    return SAFE_CURIE;
  }
  if (str.match(STANDARD_PROTOCOLS_REGEX)) {
    return URI;
  }
  return CURIE;
};

export class QueryForListenersInProgress {
  constructor(q4l) {
    this.q4l = q4l;
    this.next_term_idx = 0;
  }
  getNextTerm() {
    const retval = this.q4l.query[this.next_term_idx];
    if (this.next_term_idx === this.q4l.query.length) {
      this.next_term_idx;
    }
    return this.next_term_idx++;
  }
}

export class PrefixDb {
  constructor() {
    this.prefixes = new NoiceTrie();
  }
  add_prefix(k, v) {
    try {
      if (!k) { // eg a TTL line like """@prefix : <http://www.w3.org/ns/prov#> ."""
        k = this.synthesize_prefix(v);
      }
      return this.prefixes.add(k, v);
    } catch (e) {
      // TODO handle the situation where a prefix has already been defined as something else
      const old_v = this.prefixes.getValue(k);
      if (old_v !== v) {
        // console.log(@prefixes.tree())
        const msg = `prefix ${k}: <${v}> has already been defined as <${old_v}> node_count:${this.prefixes.node_count}`;
        //throw new Error(msg)
        const new_k = this.synthesize_non_colliding_prefix(k, v, old_v);
        return this.add_prefix(new_k, v);
      }
    }
  }
        //throw e
  dump(msg) {
    console.log(((msg != null) && `dump(\"${msg}\")`) || "dump()");
    //console.log @prefixes.tree()
    return (() => {
      const result = [];
      for (let k in this.prefixes.k2leaf) {
        const v = this.prefixes.k2leaf[k];
        result.push(console.log(`  ${k}: ${v.getValue()}`));
      }
      return result;
    })();
  }
  synthesize_prefix() {
    if (!this.synth_count) {
      this.synth_count = 1;
    }
    this.synth_count++;
    return 'SYN'+this.synth_count;
  }
  synthesize_non_colliding_prefix(k, v, existing_v) {
    // create a new prefix based on prefix which does not collide with the already existing
    return k + this.synthesize_prefix();
  }
  make_key(to_abbrev, retval, noice_trie) {
    if ((this.prefixes == null)) {
      throw new Error("make_key() called unbound");
    }
    // if abbrev failed to find a key, make_key is called
    // retval is passed to make_key so custom implementations can override it
    if (retval) {
      return retval;
    }
    const candidate_match = to_abbrev.match(DOMAIN_NAME_REGEX);
    if (candidate_match) {
      let prfx = this.synthesize_prefix(candidate_match);
      while (noice_trie.has_k(prfx)) {
        prfx = this.synthesize_prefix(candidate_match);
      }
      return prfx; // TODO improve algorithm for synthesizing prefixes
      const dn = candidate_match[1].toLowerCase();
      const candidate_voweless = dn.replace(new RegExp('[aeiou]','g'),'');
      //console.log('to_abbrev', to_abbrev, 'candidate_voweless', candidate_voweless)
      const candidate_dotless = candidate_voweless.replace('.','');
      const candidate_wwwless = candidate_dotless.replace('www','');
      const candidate_tldless = candidate_wwwless.replace(/(com|net|org|mil|edu)$/, '');
      const candidates = [candidate_tldless, candidate_wwwless, candidate_dotless];
      for (let candidate of Array.from(candidates)) {
        if (candidate.length < 3) {
          continue;
        }
        if (!noice_trie.has_k(candidate)) {
          return candidate;
        }
      }
      throw new Error(`could not make_key(${candidates.join('|')}) for ${to_abbrev} ${this.prefixes.tree()}`);
    }
    if (to_abbrev.match(FILE_URI_REGEX)) {
      return this.synthesize_prefix(to_abbrev);
    }
    return "BADKEY";
  }
  make_cb_for_make_key() {
    return (a,b,c) => {
      return this.make_key(a,b,c);
    };
  }
  toSafeCURIE(uri_or_curie, make_key) {
    // THIS is where a Trie is tempting because:
    // * we want to search
    const typ = getTyp(uri_or_curie);
    if (typ === SAFE_CURIE) {
      return uri_or_curie;
    }
    if (typ === CURIE) {
      return curie_to_safe_curie(uri_or_curie);
    }
    // URI then!
    return curie_to_safe_curie(this.prefixes.abbrev(uri_or_curie, make_key || this.make_cb_for_make_key()));
  }
  toCURIE(uri_or_curie, context, make_key) {
    if (typeof(context) === 'function') {
      console.log("toCURIE() is being called with the make_key arg in the wrong position");
      process.exit();
    }
    const typ = getTyp(uri_or_curie);
    if (typ === SAFE_CURIE) {
      return safe_curie_to_curie(uri_or_curie);
    }
    if (typ === CURIE) {
      return uri_or_curie;
    }
    // URI then!
    return this.prefixes.abbrev(uri_or_curie, make_key || this.make_cb_for_make_key());
  }
  fromSafeCURIE(safe_curie) { // eg '[dc:title]'
    const [ignore, prefix, local_part] = Array.from(safe_curie.match(SAFE_CURIE_REGEX));
    let expansion = this.prefixes.getValue(prefix);
    if ((expansion == null)) {
      if (prefix === '_') {
        expansion = prefix;
      } else {
        throw new Error(`no prefix found for ${safe_curie}`);
      }
    }
    if ((local_part == null)) {
      throw new Error(`uh no local_part found in ${safe_curie}`);
    }
    return  expansion + local_part;
  }
  fromCURIE(curie) { // eg 'dc:title'
    const [prefix, local_part] = Array.from(curie.split(':'));
    let expansion = this.prefixes.getValue(prefix);
    if ((expansion == null)) {
      //@dump("fromCURIE() failing to find '#{prefix}'")
      if (prefix === '_') { // TODO add TYP called BLANK for when prefix is underscore
        expansion = '_:';
      } else {
        throw new Error(`no prefix found for ${curie} in @prefixes with ${this.prefixes.node_count} nodes`);
      }
    }
    if ((local_part == null)) {
      throw new Error(`uh no local_part found in ${curie}`);
    }
    return  expansion + local_part;
  }
  XXXaddPrefix(prefix, expansion) {
    if (this.prefixes[prefix] != null) {
      throw new Error(`prefix ${prefix} already exists`);
    }
    return this.prefixes[prefix] = expansion;
  }
  canonicalize_BLANK(blank_uri, context) {
    let prefix = context.blank_prefix;
    if (!prefix) {
      prefix = this.getOrCreate_prefix_for_blanks_in_ctx(context);
    }
    if (global.TRACE) {
      console.log(`canonicalize_BLANK('${blank_uri}') prefix='${prefix}', context=`, context);
    }
    if (prefix === '_') {
      throw new Error('BLANKS should never be stored with _ as their prefix');
    }
    return prefix + blank_uri.substr(1); // replace the leading _ with the prefix
  }
  getOrCreate_prefix_for_blanks_in_ctx(context) {
    // Purpose:
    //   Provide a way to have prefixes which define a separate namespace for
    //   the BLANK nodes of each RDF document (and each time it was retrieved).
    // Examples:
    //    ('http://gov.ca/stats.ttl', '2017-07-02T20:58:34') ==> 'SYN123.1'
    //        this would hold if this was the first retrieval of the ctx_uri
    //        AND SYN123 was the prefix for ctx_uri
    //    ('http://gov.ca/stats.ttl', '2017-07-02T21:15:34') ==> 'SYN123.2'
    //        this would hold if this was the second retrieval of the ctx_uri, etc

    // prepend a special something to uri to make an uri which signifies
    //   prx4bl4uri, ie 'prefix for BLANKs for the URI'
    // if there is already a prefix for bl4uri return it, else make it
    // OGHAM FEATHER MARK (U+169B, Ps): ᚛
    // OGHAM REVERSED FEATHER MARK (U+169C, Pe): ᚜
    // Reserve the possibility of putting a timestamp between the feather marks
    // eg '᚜2017-07-02T20:22:00᚛' signifying that the file was retrieved at that time
    const special_something = `᚜${context.timestamp || ''}᚛`;
    const uri_for_blanks = context.uri + special_something;
    const ctx_uri_key = this.getOrCreate_prefix_for(context.uri);
    const prefix_for_blanks = ctx_uri_key + '.1';
    this.add_prefix(prefix_for_blanks, uri_for_blanks);
    context.blank_prefix = prefix_for_blanks;
    return prefix_for_blanks;
  }
  getOrCreate_prefix_for(uri) {
    if (!uri) throw new Error('uri must be defined');
    return this.prefixes.find_or_make_key_for(uri, this.make_cb_for_make_key());
  }
}

export class RsrcDb {
  constructor(prefixdb) {
    this.prefixdb = prefixdb;
    this.mbrs = {};
  }
  get_canonical_typ(key, current_typ_or_uri_scheme) {
    // The canonical_typ for a key is the representation_typ it should be stored
    // and compared in.  The return value is one of the TYPs themselves: URI,
    // SAFE_CURIE, CURIE, LITERAL instead of being the key IN that representation.
    // The canonical_typ for URI representation in RsrcDb is CURIE, but some URI
    // schemes (the non-DNS ones particularly) do not benefit from the compression
    // provided by conversion to CURIE so their canonical_typ is URI.
    // (eg geo:145.33,-23.44 OR isbn:91233123333)
    // In other words, for such resources the URI itself is used as the key not the CURIE.
    // CURIE is used for typical urls because it is most compact and there is no need
    // for the 'safety' of SAFE_CURIEs.
    // The case of BLANK is interesting.  BLANKs (eg '_:boo') are represented by CURIEs
    // where the CURIE has a structured synthetic prefix which establishes a unique
    // namespace for the contents of each different retrieval of the same document.
    //
    // NOTE: An unsolved problem is how literals should be stored vs how TYPED
    // literals (eg "1964-07-24^^xsd:date") should be handled vs how
    // typed literals with custom types (eg "3/5^^<http://example.org/rationalNumber>")
    // (how Jena does this: https://jena.apache.org/documentation/notes/typed-literals.html)
    //console.log("get_canonical_typ(#{JSON.stringify(key)}, #{current_typ_or_uri_scheme})")
    if ((current_typ_or_uri_scheme == null)) {
      current_typ_or_uri_scheme = getTyp(key); // get it if missing
    }
    let canonical_typ = TYP_2_CANONICAL_TYP[current_typ_or_uri_scheme];
    if ((canonical_typ === CURIE) && key.match(BARE_URI_REGEX)) {
      canonical_typ = URI;
    }
    if ((canonical_typ == null)) {
      if (current_typ_or_uri_scheme === URI) {
        const scheme = key.split(':')[0];
        if (scheme) {
          // get the canonical_typ for uri schemes with particular ones
          canonical_typ = this.get_canonical_typ(key, scheme);
        }
      }
      if (canonical_typ == null) { canonical_typ = URI; }
    }
    return canonical_typ || URI;
  }
  get_canonical_form_typ_pair(key, context) {
    const current_typ = getTyp(key);
    const canonical_typ = this.get_canonical_typ(key, current_typ);
    key = key.valueOf();
    if (false) {
      try {
        if (key.includes('rdf-schema')) {
          console.log("BOO XXXXXXXXXXXXXXXXXXXXXXXXXXXX GCFTP:",key,TYPS[current_typ],'-->',TYPS[canonical_typ], key);
        }
      } catch (e) {
        console.log(e);
      }
    }
    if (current_typ === BLANK) {
      const retval = [this.prefixdb.canonicalize_BLANK(key, context), canonical_typ];
      //console.log("current_typ: #{TYPS[current_typ]}, canonical_typ: #{TYPS[canonical_typ]}, key: #{key} retval:", retval)
      return retval;
      throw new Error(`${key} is BLANK`);
    }
    if (current_typ === canonical_typ) {
      return [key, canonical_typ];
    }
    if (canonical_typ === CURIE) {
      return [this.prefixdb.toCURIE(key, context), CURIE];
    }
    if (canonical_typ === URI) {
      if (current_typ === CURIE) {
        return [this.prefixdb.fromCURIE(key), URI];
      }
      if (current_typ === CURIE) {  // TODO hmmm redundant!
        return [this.prefixdb.fromCURIE(key), URI];
      }
    }
    if (canonical_typ === SAFE_CURIE) {
      return [this.prefixdb.toSafeCURIE(key), SAFE_CURIE];
    }
  }
  check_isUri_and_key(key) {
    const typ = getTyp(key);
    //if typ is SAFE_CURIE
    //  #[ignore, prefix, local] = key.match(SAFE_CURIE_REGEX)
    //  key = safe_curie_to_curie(key)
    if (typ !== LITERAL) {
      key = this.prefixdb.toCURIE(key);
      const to_typ = CURIE;
      if (typ === BLANK) {
        const XXXto_typ = BLANK;
      }
      return {isUri: typ !== LITERAL, key:  this.prefixdb.toCURIE(key), typ: to_typ};
    }
    return {isUri: false, key, typ: LITERAL};
  }
  get(key, typ, context) {
    if ((typ == null)) {
      [key, typ] = Array.from(this.get_canonical_form_typ_pair(key, context));
    }
      //{isUri, key, typ} = @check_isUri_and_key(key)
    if (global.TRACE) {
      console.log(`get('${key}', ${typ})`);
      for (let k in this.mbrs) {
        const v = this.mbrs[k];
        console.log("  ", k);
      }
    }
    const retval = this.mbrs[key];
    return retval;
  }
  getOrCreate(key, isUri, context) {
    const [canonical_key, canonical_typ] = Array.from(this.get_canonical_form_typ_pair(key, context));
    //key = key.valueOf()
    if (canonical_typ > CURIE) {
      if (canonical_key.startsWith('_')) {
        throw new Error(TYPS[canonical_typ]+" should not start with _");
      }
    }
      //else
      //  console.log("HUBBA, get_canonical_form_typ_pair() is converting _ to stuff:", canonical_key)
    isUri = !(canonical_typ === LITERAL);
    let rsrc = this.mbrs[canonical_key];
    if ((rsrc == null)) {
      rsrc = new Rsrc(canonical_key, isUri, canonical_typ, this);
      this.mbrs[canonical_key] = rsrc;
    }
    //if canonical_typ > CURIE
    //  console.log(rsrc.key(),"SHOULD NOT START WITH _",rsrc.repr())
    return rsrc;
  }
  XXgetOrCreate(key, isUri) {
    let typ;
    if ((isUri == null)) { // "is isUri specified?" NOT "is it true?"
      ({isUri, key, typ} = this.check_isUri_and_key(key));
    }
    let rsrc = this.mbrs[key];
    if ((rsrc == null)) {
      rsrc = new Rsrc(key, isUri, typ, this);
      this.mbrs[key] = rsrc;
    }
    return rsrc;
  }
  set(key, obj) {
    return this.getOrCreate(key).addObj(obj);
  }
  getAll(key, typ, context) {
    const rsrc = this.get(key, typ, context);
    if (rsrc != null) {
      return rsrc.all();
    }
    return [];
  }
  getOnly(key, typ) {
    const rsrc = this.get(key, typ);
    if (rsrc != null) {
      return rsrc.first();
    }
    return undefined;
  }
  exists(key) {
    if ((key == null)) {
      throw new Error("exists() requires that key have a value");
    }
    return (this.get(key) != null);
  }
  dump() {
    let buffer = '-'.repeat(72) + "\n";
    for (let k in this.mbrs) {
      const v = this.mbrs[k];
      buffer += `  ${k} = ${v.getValue()}\n`;
    }
    return buffer;
  }
}

export class Rsrc {
  // Each URI has one Rsrc instance built for it.
  constructor(_key, isUri, typ, db) {
    this._key = _key;
    this.isUri = isUri;
    this.typ = typ;
    this.db = db;
    this.occs = []; // occurrences
    this.queriesForListeners = []; // this resource is the first one mentioned in these queries
  }
  addObj(spogi, processQueries) {
    this.occs.push(spogi);
    if (processQueries) {
      if (this.queriesForListeners.length) {
        return this.queriesForListeners.forEach(q4l => q4l.sendIfSatisfies(spogi));
      }
    }
  }
  addQueryForListeners(q4l) {
    return this.queriesForListeners.push(q4l);
  }
    //console.log "Rsrc.addQueryForListeners(#{@key()}) @queriesForListeners.len: #{@queriesForListeners.length} q4l:", q4l
  last() {
    return this.occs[this.occs.length - 1];
  }
  first() {
    return this.occs[0];
  }
  all() {
    return this.occs;
  }
  key() {
    return this._key;
  }
  raw() {
    return `${this._key}`;
  }
  getNativeValue() {
    if ((this._ntval == null)) {
      const obj = new RdfObject(this._key);
      this._ntval = obj.getNativeValue();
    }
    return this._ntval;
  }
  getValue() {
    if ((this._val == null)) {
      const obj = new RdfObject(this._key);
      this._val = obj.value;
    }
    return this._val;
  }
  eql(val) {
    const retval = this._key === val;
    if (!retval) {
      console.log(this._key, '<>', val);
    }
    return retval;
  }
  repr() {
    // TODO if this Rsrc is really a value (because its the @obj)
    //      then return it properly formatted
    if (this.typ === BLANK_AS_CURIE) {
      return `<_:${this.local_part()}>`;
    }
    if (this.isUri) {
      try {
        return `<${this.uri()}>`;
      } catch (e) {
        //console.log("repr:", @)
        throw e;
      }
    } else {
      return this.literal_value();
    }
  }
  literal_value() {
    const val = this._key;
    if (val.match(/\n/)) {
      return '"""' + val + '"""';
    }
    return `${val}`;
  }
  asTTL() {
    if (this.isUri) {
      return this.curie();
    }
    return this.literal_value();
  }
  local_part() {
    return this._key.split(':')[1];
  }
  prefix_part() {
    return this._key.split(':')[0];
  }
  safe_curie() {
    if (this.typ === SAFE_CURIE) {
      return this._key;
    }
    return '[#{@curie()}]';
  }
  uri() {
    if (!this.isUri) {
      throw new Error('#{@_key} has typ: #{@typ} and is hence not a uri');
    }
    if (this.typ === SAFE_CURIE) {
      return this.db.prefixdb.fromSafeCURIE(this._key);
    }
    if (this.typ === CURIE) {
      return this.db.prefixdb.fromCURIE(this._key);
    }
    return this._key; // by elimination, @_key is already typ URI
  }
  curie() {
    if (!this.isUri) {
      throw new Error('#{@_key} has typ: #{@typ} and is hence not a uri');
    }
    if ([CURIE, BLANK_AS_CURIE].includes(this.typ)) {
      return this._key;
    }
    if (this.typ === URI) {
      return this.db.prefixdb.toCURIE(this._key);
    }
    if (this.typ === SAFE_CURIE) {
      // is SAFE_CURIE by elimination
      return safe_curie_to_curie(this._key);
    }
    throw new Error(`'${this._key}' is of mysterious typ: ${this.typ}`);
  }
}
