import {KnobController} from './knobctrl.js';
import { FractalPanel } from "./fractalpanel.js";

export class ActionKnob extends KnobController {
  static initClass() {
    this.prototype.main_icon = 'gears';
    this.prototype.css_classes = 'action_menu';
    this.prototype.elem_label = 'Action Menu';
    this.prototype.fp_spec = {
      make_frame: true,
      suppress_corner_buttons: true,
      propagate: {
        always_show_NEWS_buttons: false,
        make_frame: false,
        color_panels_uniquely: false,
        inject_tree_path: false,
        suppress_corner_buttons: true,  // just suppress the close button
        suppress_content_area: false,
        always_show_close_button: false,
        show_NEWS_handle: {}
      }
    };
  }
  make_panel() {
    super.make_panel(this.elem_tmpl);
    //ColoredTreePicker = require('coloredtreepicker').ColoredTreePicker
    //@verb_box = @elem.append($('<div><i class="fa fa-pied-piper"></i></div>'))
    //@action_picker = new ColoredTreePicker(@verb_box, 'anything', [], true)
    this.elem.hide();
    this.watch_for(':visible', null, () => this.reveal()); // supress side drop zones
  }

  get_formurlaManager() {
    return this.fractalcomponent.get_formurlaManager();
  }

  get_unique_panel_name() {
    return "knob_" + (new Date()).getTime();
  }

  get_formurla() {
    return `${this.function_spec.name}(g=${this.function_spec.g})`;
  }

  reveal() {
    if (!this.my_panel) {
      this.my_panel = new FractalPanel(
        this.target, this.get_unique_panel_name(), this.fp_spec);
      // HACK? Perhaps the global formurlaManager should NOT be used?
      const forMan = this.get_formurlaManager();
      if (!forMan) {
        // in case forMan is not defined (such as in fractalpanel_sandbox.html
        return;
      }
      const menuFunc = forMan.resolve_function(this.function_spec.name);
      const src = this.get_formurla();
      // alert src
      const formurla = forMan.compile_formurla(src);
      const expression = forMan.get_first_expression(formurla);
      this.menu_widget = menuFunc.apply(forMan,[this.my_panel.first_frac, expression]);

      // One should not normally do such intimate things TO somebody else, but
      //   this means that the menu_widgets do not have to be subclasses of
      //   the same parent just to inherit a method like
      //   register_knob_which_controls_me()
      this.menu_widget.knob_which_controls_me = this;
    }
  }
}
ActionKnob.initClass();
