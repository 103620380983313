
import {AbstractVideoProvider} from './abstractvideoprovider.js';

export class ProvideVideo extends AbstractVideoProvider {
  static initClass() {
    this.func_name = 'provid';
    this.pretty_name = "Provide Video";
    this.docs = "Record, upload or point at hosted video on the web.";
    this.prototype.succinctTopic = true;
  }

  constructor() {
    super(...arguments);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    this.ensure_videoProvider();
    this.choose_vidsrc_if_indicated();
    $(this.localize('.select_vid_type')).hide(); // select_vid_type not relevant when creating a root video
    this.attach_myNavigator();
  }

  on_done_submitting_common(fullUri, triples, curie) {
    super.on_done_submitting_common(fullUri, triples, curie);
    this.run_playmedia_formurla(curie);
  }
}
ProvideVideo.initClass();
