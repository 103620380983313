import {Spreadsheet} from './spreadsheettable.js';
import {TabularInteractor} from '../reactor.js';


export class Timesheet extends Spreadsheet {
  static initClass() {
    this.resources = `\
https://www.npmjs.com/package/durational
  fromSeconds(seconds), fromString(string), toString(object|integer)
https://www.npmjs.com/package/repeating-interval
  eg new Interval('R/2016-08-23T04:00:00Z/P1D');
  ie                 start time           period
https://www.npmjs.com/package/humanize-duration
  humanizeDuration(97320000) // '1 day, 3 hours, 2 minutes'
  
http://www.datypic.com/sc/xsd/t-xsd_duration.html
http://books.xmlschemata.org/relaxng/ch19-77073.html
https://www.npmjs.com/package/parse-xsd-duration
  pxd('P2Y6M5DT12H35M30S') // ==> 79317330
https://github.com/revington/xsdurationjs
  add or subtract xsd:durations to Date()
https://www.npmjs.com/package/iso8601-duration
  obj = parse("PT1H30M") obj = {hours: 1, minutes: 30}
  toSeconds(obj) "PT1H30M"
https://www.npmjs.com/package/date-duration
  Duration .addTo(Date), .subtractFrom(Date)\
`;
    this.func_name = 'timesheet';
    this.docs = "Timesheet is a spreadsheet which initially has hardcoded computed columns and rows.";
    this.prototype.vm_settings = {
      prefix: 'action', // should this be @kwargs.g ???
      caching: true
    };
    this.prototype.column_specs = [{
        id: 'nrn:workSessionStart',
        datatype: 'dateTime',
        formula: "Date.now() - 10000"
      }
      , {
        id: 'nrn:workSessionEnd',
        datatype: 'dateTime',
        formula: "Date.now()"
      }
      , { // ☾ ☽
        id: 'nrn:workSessionDuration',
        formula: "☾nrn:workSessionEnd☽ - ☾nrn:workSessionStart☽",
        datatype: "duration"
      }
    ];
  }

  constructor() {
    super(...arguments);
    //@vm = @noodb.make_vm(@vm_settings)
    const {
      TabularInteractor
    } = require('reactor');
    this.interactor = new TabularInteractor();
    this.make_columns();
  }

  make_columns() {
    return (() => {
      const result = [];
      for (let col of Array.from(this.column_specs)) {
        col.id = col.id.replace(/^nrn\:/, 'http://nooron.com/__/');
        if (col.datatype) {
          this.tryToTypeCandidateOrCriterion(col.datatype, col.id);
        }
        const criterion = this.getOrCreatePredicate(col.id);
        if (col.formula) {
          criterion.interactor(this.interactor);
          result.push(criterion.formula(col.formula));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }
}
Timesheet.initClass();

