
import {
  strip_quotes,
  DragAndDropOrClickToVisualize,
  VisualizationController
} from './visualizationcontroller.js';

import { ponderwidget } from 'ponderwidget';

export class TabularVisualizationController extends VisualizationController {
  // This is where generic visualization methods specialized for the table_widget live

  //CSSDependencies: ['TabularVisualizationController.css']
  //ScriptDependencies: ['TabularVisualizationController.js']

  constructor() {
    super(...arguments);
    this.graph_uri = this.args[0]; // TODO remove this hackery once all uses of graph_uri are gone
    const tw_args = this.build_tw_args();
    this.widget = ponderwidget(tw_args);
    this.widget.make_widget_here(tw_args);
    this.widget.fill_widget_from_ponderspec(tw_args);
    this.perform_subscriptions();
  }

  build_tw_args() {
    let frame_id;
    if (this.fracpanel.first_frac) { // TODO uh, why is this needed? HMM we should have @fracComponent here not @fracpanel
      frame_id = this.fracpanel.first_frac.content_area.attr('id');
    } else {
      frame_id = this.fracpanel.content_area.attr('id');
    }
    //table_id = @noodb.synthetic_key_factory_next()

    const drag_and_dropper = new DragAndDropOrClickToVisualize(this);

    const candidate_click_callback = this.get_candidate_click_callback() || drag_and_dropper.click;

    const tw_args = {
      hide_footer: true,
      candidate_click_callback,
      frame_id,
      table_id: this.content_id,
      //pndr_id: frame_id + "_unclear_what_value_is_appropriate" # TODO issue #121
      simple_list: [],
      title: this.get_title(),
      display_candidate_delete_control: false,
      display_candidate_link_edit_control: true,
      show_edit_button: true,
      show_footer: true,
      candidate_label_editable: false,
      //candidate__drop_handler: drag_and_dropper.drop
      candidate__drag_start_handler: drag_and_dropper.drag,
      candidate__target: "",
      ponderspec: {
        candidates: this.get_candidates(),
        criteria: this.get_criteria(),
        data: this.get_data()
      }
    };
    return tw_args;
  }

  get_candidate_click_callback() {}

  get_criteria() {
    const example_criteria = [{
      label: 'who',
      id: 'who',
      updateable_by: 'system',
      valuetype_id: 'plain'
    } // plain, integer, etc
    , {
      label: 'when',
      id: 'whn',
      updateable_by: 'system',
      valuetype_id: 'datetime'
    } // plain, integer, etc
    ];
    return [];
  }

  get_candidates() {
    const example_candidates = [{
      id: 'google',
      url: 'http://google.com',
      label: "Google"
    }
    , {
      id: 'apple',
      url: 'http://apple.com',
      label: "Apple"
    }
    ];
    return [];
  }

  get_data() {
    const data_example = [
      ['google', 'who', 'Larry and Sergei'],
      ['apple', 'who', 'Steve and Steve']
    ];
    return [];
  }

  findTRid(draggableElem) {
    // draggableElem is actually the table row
    let thing = draggableElem;
    let id = thing.getAttribute('id');
    // if not id?
    //  id = $(thing).parent("[id]").attr('id')
    while ((id == null)) {
      thing = thing.parentElement;
      id = thing.getAttribute('id');
    }
    return id;
  }

  get_qname_from_elem(elem) {
    // https://www.w3.org/TR/2009/CR-curie-20090116/
    const cand_id = this.findTRid(elem);
    return this.unstrengthen_id(cand_id);
  }

  getTableColumnNum0(draggableElem) {
    // Return the 0-based column index of this cell AKA the criterion idx
    return draggableElem.cellIndex;
  }

  getOrCreatePredicate(pred_id, status) {
    let criterion = this.widget.get_criterion_by_id(pred_id);
    if ((criterion == null)) {
      const label = this.popQueuedLabel(pred_id) || pred_id;
      const valuetype_id = this.popQueuedType(pred_id) || 'plain';
      const args = {
        label,
        id: pred_id,
        updateable_by: 'system',
        valuetype_id
      };
      args.cells_visualizable = true;
      criterion = this.widget.add_criterion(args);
      if (status) { // if the caller wants to know, tell them whether it is newly created
        status.created = true;
      }
    }
    return criterion;
  }

  getOrCreateSubject(subj_url, subj_label_fallback, status) {
    let candidate = this.widget.find_candidate_by_url(subj_url);
    if ((candidate == null)) {
      const weak_id = this.unstrengthen_id(subj_url);
      const label = this.popQueuedLabel(weak_id) || subj_label_fallback || subj_url;
      candidate = this.widget.add_candidate({
        id: subj_url,
        //url: "allegations(s=#{subj_url})"
        label
      });
      if (status) { // if the caller wants to know, tell them whether it is newly created
        status.created = true;
      }
    }
    return candidate;
  }

  popQueuedLabel(obj_id) {
    if (this.label_queue == null) { this.label_queue = {}; } // initialize if need be
    const retval = this.label_queue[obj_id];
    if (retval != null) {
      delete this.label_queue[obj_id];
    }
    return retval;
  }

  popQueuedType(obj_id) {
    if (this.type_queue == null) { this.type_queue = {}; } // initialize if need be
    const retval = this.type_queue[obj_id];
    if (retval != null) {
      delete this.type_queue[obj_id];
    }
    return retval;
  }

  tryToLabelCandidateOrCriterion(label, obj_id) {
    if (this.label_queue == null) { this.label_queue = {}; } // initialize if need be
    const candidate = this.widget.find_candidate_by_url(obj_id) ||
      this.widget.find_candidate_by_url(this.strengthen_id(obj_id));
    if (candidate != null) {
      candidate.label(label);
      candidate.update_cand_visible();
      delete this.label_queue[obj_id];
      return;
    }
    const criterion = this.widget.get_criterion_by_id(obj_id);
    if (criterion != null) {
      criterion.label(label);
      criterion.update_crit_visible();
      delete this.label_queue[obj_id];
      return;
    }
    // If an already existing candidate or criterion is not found, queue the label for later.
    return this.label_queue[obj_id] = label; // TODO properly handle situation with muliple names
  }

  setLabelOnCandidateOrCriterion(spogi) {
    // We might receive the names for things which haven't been created yet.
    // So name them or queue them for later naming when they do get created.
    //
    // The assumption is that the predicate is 'rdfs:label' or 'rdfs:name' or such
    let label = spogi.o.key();
    label = strip_quotes(label); // strip leading and trailing quotes HACK!!
    const obj_id = spogi.s.key();
    return this.tryToLabelCandidateOrCriterion(label, obj_id);
  }

  tryToTypeCandidateOrCriterion(xsd_type, obj_id) {  // just Criteria at this point
    if (this.type_queue == null) { this.type_queue = {}; }
    const valuetype_id = this.widget.xsd_to_valuetype(xsd_type);
    const msg = `${obj_id} ${xsd_type} ${valuetype_id}`;
    const criterion = this.widget.get_criterion_by_id(obj_id);
    this.noodb.log.info(msg);
    if (criterion != null) {
      criterion.set_valuetype(valuetype_id);
      delete this.type_queue[obj_id];
      return;
    }
    // If an already existing candidate or criterion is not found, queue the label for later.
    return this.type_queue[obj_id] = valuetype_id; // TODO properly handle situation with muliple names
  }

  setCriterionType(spogi, xsd_type) {
    const obj_id = spogi.s.key();
    return this.tryToTypeCandidateOrCriterion(xsd_type, obj_id);
  }
}
