import {
  VisualizationController
} from './visualizationcontroller.js';

export class LeafletMap extends VisualizationController {
  static initClass() {
    this.prototype.CSSDependencies = ['/leaflet/dist/leaflet.css'];
    this.prototype.ScriptDependencies = ['/leaflet/dist/leaflet.js'];
    this.func_name = 'map';
    this.pretty_name = "Leaflet Map";
    this.docs = "Map visualization";
  }
  constructor() {
    super(...arguments);
    this.resize_handler = this.resize_handler.bind(this);
    this.visContent = $(this.fracpanel.content_area).attr('id', this.content_id);
    $("#"+`${this.content_id}`).append('<div id="mapid' + this.content_id + '" style="height: ' + this.get_panelSize().height + 'px; width: ' + this.get_panelSize().width + 'px;overflow:hidden"></div>');
    this.showDocs();
    this.markerArray = [];
    this.leafSpec = {
      data: {
        values: []
      }
    };
    this.mapViz = L.map('mapid'+this.content_id).setView([0,0], 3);
    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    	maxZoom: 19,
    	attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }
    ).addTo(this.mapViz);
    this.defaultMessage();
    this.perform_subscriptions();
    this.addResizeListener();
    this.render_kwargs_points();
  }

  render_kwargs_points() {
    if (this.kwargs.points != null) {
      const point_strings = this.kwargs.points.split(';');
      let i = 0;
      for (let point_str of Array.from(point_strings)) {
        i++;
        const point_args = point_str.split(',');
        const lat = point_args.shift();
        const long = point_args.shift();
        point_args.unshift([`${lat},${long}`]);
        if (point_args[1] == null) { point_args[1] = false; } // let automatic stuff happen about the color
        if (point_args[2] == null) { point_args[2] = `point-${i}`; } // label defaults to 'point-N'
        this.render.apply(this, point_args);
      }
    }
  }

  defaultMessage() {
    return L.popup({
      className: 'nodata'
      })
      .setLatLng([0, 0])
      .setContent('<h1>No Geodata found in dataset.</h1>')
      .openOn(this.mapViz);
  }

  receive(spogi) {
      // FIXME: indent by standard 2
      const s_key = spogi.s.key();
      const o_key = spogi.o.key();
      let p_key = spogi.p.key();
      p_key = p_key.split(':')[1];
      let found = false;
      for (let record of Array.from(this.leafSpec.data.values)) {
        if (record._id === s_key) {
          found = record;
          break;
        }
      }
      if (!found) {
        found =
          {_id: s_key};
        this.leafSpec.data.values.push(found);
      }
      found[p_key] = o_key;
      const geoCheck = o_key.split(':'); //split off geo coordinantes
      const label = found._id.split(':')[1];
      if (geoCheck[0] === "geo") {
        const geoInfo = geoCheck[1].split(';');
        return this.render(geoInfo, 'blue', label, found);
      }
    }

  render(geo, color, label, spogi) {
      // FIXME: indent by standard 2
      // http://leafletjs.com/reference-1.3.0.html
      const location = geo[0].split(",");
      const uncertainty = geo[1] ? geo[1].split('=')[1] : false;
      this.mapViz.closePopup();
      if (uncertainty) {
        color = color || 'blue';
        L.circle([location[0],location[1]], {
          color,
          fillColor: color,
          fillOpacity: 0.1,
          radius: uncertainty
        }
        ).bindPopup(label).addTo(this.mapViz);
      } else {
        const marker_options = {title:label, riseOnHover:true, draggable: true};
        if (color) { // is specified then draw a circleMarker
          marker_options.color = color;
          marker_options.fillColor = color;
          marker_options.fillOpacity = .6;
          L.circleMarker([location[0],location[1]],marker_options).bindPopup(label).addTo(this.mapViz);
        } else {
          L.marker([location[0],location[1]],marker_options).bindPopup(label).addTo(this.mapViz);
        }
      }
      this.markerArray.push([location[0],location[1]]); //create array of markers for map zoom
      return this.renderMapZoom();
    }

  renderMapZoom() {
    if (this.markerArray.length > 0) { return this.mapViz.fitBounds(this.markerArray); }
  }

  addResizeListener() {
    return $(this.fracpanel.content_area).bind("_splitpaneparentresize", this.resize_handler);
  }

  resize_handler() {
    this.bheight = this.get_panelSize().height;
    this.bwidth = this.get_panelSize().width;
    $("#mapid"+`${this.content_id}`).width(this.bwidth).height(this.bheight);
    return this.renderMapZoom();
  }

  get_panelSize() {
    return {
      width: $(this.visContent).width(),
      height: $(this.visContent).height()
    };
  }
}
LeafletMap.initClass();
