
import {VegaVisualizationController} from './vegavisctrl.js';

export class ScatterPlot extends VegaVisualizationController {
   static initClass() {
     this.example_formurla = "scatter(g=nrn:70sCars)";
     this.func_name = 'scatter';
     this.pretty_name = "Scatter Plot";
     this.docs = "A simple scatter plot with Vega-Lite";
   }

   // scatter defaults
   plotSet() {
     return {
       plot: 'scatter',
       mark: 'circle', // 'circle','square', 'point'
       encoding: {
         x: {
           type: "quantitative"
         },
         y: {
           type: "quantitative"
         }
       }
     };
   }
 }
ScatterPlot.initClass();

export class BubblePlot extends VegaVisualizationController {
  static initClass() {
    this.func_name = 'bubble';
    this.pretty_name = "Bubble Plot";
    this.docs = "A bubble scatter plot (3 dimensions) use of Vega-Lite";
  }

  plotSet() {
    return {
      plot: 'bubble',
      mark: 'point', // 'circle','square', 'point'
      encoding: {
        x: {
          type: "quantitative"
        },
        y: {
          type: "quantitative"
        },
        size: { //z dimension - only valid for scatter? (Bubble)
          field:  this.plottedPredicates[2] || "stub",
          type: "quantitative"
        }
      }
    };
  }
}
BubblePlot.initClass();

export class BarPlot extends VegaVisualizationController {
  static initClass() {
    this.func_name = 'bar';
    this.pretty_name = "Bar Graph";
    this.docs = "Plot of Bar Graph with Vega-lite";
  }

  plotSet() {
    return {
      plot: 'bar',
      mark: 'bar',
      encoding: {
        x: {
          type: "ordinal",
          scale: {
            bandSize: 30
          }
        },
        y: {
          //type: "ordinal"
          scale: {
            bandSize: 30
          }
        }
      }
    };
  }
}
BarPlot.initClass();
//console.log ("BarPlot initiated")

export class LinePlot extends VegaVisualizationController {
  static initClass() {
    this.func_name = 'line';
    this.pretty_name = "Simple Line Plot";
    this.docs = "A line plot using Vega-Lite";
  }

  plotSet() {
    return {
      plot: 'line',
      mark: 'line', // 'circle','square', 'point'
      encoding: {
        x: {
          type: "quantitative"
        },
        y: {
          type: "quantitative"
        }
      }
    };
  }
}
LinePlot.initClass();

class AreaPlot extends VegaVisualizationController {
  static initClass() {
    this.func_name = 'area';
    this.pretty_name = "Simple Area Plot";
    this.docs = "A area plot using Vega-Lite";
  }

  plotSet() {
    return {
      plot: 'area',
      mark: 'area', // 'circle','square', 'point'
      encoding: {
        x: {
          type: "quantitative"
        },
        y: {
          type: "quantitative"
        }
      }
    };
  }
}
AreaPlot.initClass();
