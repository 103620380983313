/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// Why is the solution to "Uncaught ReferenceError: require is not defined"
// to comment out the following?
//KnobController = require('knobctrl').KnobController

import { ActionKnob } from "./actionknob.js";

export class SandboxActions extends ActionKnob {
  constructor(...args) {
    super(...args);
    this.add_contents = this.add_contents.bind(this);
    this.remove_action_menus = this.remove_action_menus.bind(this);
    this.deknob = this.deknob.bind(this);
    this.discontent = this.discontent.bind(this);
    this.remove_split_pane_css = this.remove_split_pane_css.bind(this);
    this.remove_all = this.remove_all.bind(this);
  }

  static initClass() {
    this.prototype.elem_label = 'Sandbox Actions';
  }
  make_panel() {
    super.make_panel(...arguments);
    this.elem.show(); // in the sandbox we leave the action_menu open by default
    this.elem.append($('<li><a href="/tests/fractalpanel.html">tests/fractalpanel</a></li>'));
    this.elem.append($('<hr/>'));
    this.make_button_for('add contents', this.add_contents, {
      icon: 'plus-circle',
      color: 'green'
    }
    );
    this.make_button_for('remove contents', this.discontent, {
      icon: 'minus-circle',
      color: 'orange'
    }
    );
    this.elem.append($('<hr/>'));
    this.make_button_for('remove knobs', this.deknob, {
      icon: 'eraser',
      color: 'red'
    }
    );
    this.make_button_for('remove action menus', this.remove_action_menus);
    this.make_button_for('remove split-pane CSS', this.remove_split_pane_css);
    this.elem.append($('<hr/>'));
    return this.make_button_for('remove all', this.remove_all, {
      icon: 'ban',
      color: 'red'
    }
    );
  }
  add_contents() {
    return $('.fractalpanel_content_area').append('<li>another one'.repeat(5));
  }
  remove_action_menus() {
    return $('.action_menu').remove();
  }
  deknob() {
    this?.fractalcomponent?.parent_frac.deknob();
  }
  discontent() {
    this?.fractalcomponent?.parent_frac.discontent();
  }
  remove_split_pane_css() {
    return $('[href="/bower_components/split-pane/split-pane.css"]').remove();
  }
  remove_all() {
    this.deknob();
    //@discontent()
    this.remove_action_menus();
    return this.remove_split_pane_css();
  }
}
SandboxActions.initClass();

