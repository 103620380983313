/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import {BrowserLog} from './browserlog.js';
import {DCIKnob} from './dciknob.js';
import {DragAndDropOrClickToVisualize} from './dnd.js';
import {FractalPanel} from './fractalpanel.js';
import {FormurlaManager} from './formurla-mngr.js';
import {int_to_base} from './rebase.js';
import {NooDBBrowser} from './noodbbrowser.js';
import {NoorVM} from './noorvm.js';
import {one_million} from './nanoclock.js';
import {permeate} from './noorplugin.js';
import {Query} from './noodbabstract.js';
import {SandboxActions} from './sandboxactions.js';
import {register_visualizations} from './visctrl.js';
import {VoicesKnob} from './voicesknob.js';

export {FractalPanel};
export {SandboxActions}; // used by fractalpanel_sandbox.html.ejs

const nooron_logo_html = `\

<div class="header">
  <a id="logolink" href="/">
      <div class="nooron_logo_box">
        <img class="afterScroll hmlogo" src="../img/header-logo.png" alt="logo">
      </div>
  </a>
  <div id="user_login_and_actions">
    <span id="login_form_box"></span><br/>
    <span id="login_form_prompt">Login, Join or SignUp for Beta</span>
  </div>
</div>
\
`;

const create_local_noodb = function(socket, email_login) {
  const base = location.href;
  const args =
    //log_level: 'debug'
    {log_level: 'error'};
    //log_level: 'notice'
  if (email_login.logged_in) {
    args.auth_cookie = `auth=${email_login.split_cookies().auth}`;
    $("#login_form_prompt").hide();
  }
    //console.log "auth_cookie", args
  const fname_url = `${base}/q`;
  const noodb = new NooDBBrowser(socket, base, args);
  noodb.email_login = email_login;
  permeate(NoorVM, noodb);
  window.show_noorontime = function() {
    const n = noodb.now_baseXX();
    $("#noorontime").html(n);
    return n;
  };
  setInterval(show_noorontime,1000);
  noodb.log.help();
  return noodb;
};

const make_login_pane = function(fracpanel, enable_login) {
  const header = fracpanel.split_frac_on_side('fp_2','north');
  $("#ca_2").empty();
  $("#ca_2").addClass("nooHeader");
  $("#ca_2").html(nooron_logo_html);
  header.hide_all_buttons();

  // TODO make the disabling of NEWS buttons work
  header.always_show_NEWS_buttons = false;
  header.show_NEWS_handle = {
    N: false,
    E: false,
    W: false,
    S: false
  };

  // TODO move the following logic into FractalPanel as methods snugWidth() and snugHeight()
  let height = $("#ca_2")[0].clientHeight + 5; // padded for scrollbar suppression
  height = 0; //Minimize header pane
  header.parent_frac.move_divider_to(undefined,height);

  //width = $("#ca_2")[0].clientWidth + 35 # padded for scrollbar suppression
  //header.parent_frac.move_divider_to(width)

  let retval = {};
  if (enable_login) {
    retval = new EmailLogin("#login_form_box");
  } else {
    $("#user_login_and_actions").hide();
  }

  return retval;
};

let root_fracpanel = undefined;

// TODO export start_nooron using proper es6 module style
//      then fix views/front.html.ejs
window.start_nooron = function(clientArgs) {
  // TODO fix this ugliness of needing to require things to globals
  const socket = io();
  root_fracpanel = new FractalPanel("body", "root", {
    make_frame: true,
    suppress_corner_buttons: false,
    propagate: {
      always_show_NEWS_buttons: false,
      make_frame: false,
      color_panels_uniquely: false,
      inject_tree_path: false,
      suppress_corner_buttons: false,
      suppress_content_area: false,
      action_button__class: DCIKnob,
      voices_button__class: VoicesKnob,
      //vantages_button__class: VoicesKnob
      show_NEWS_handle: {
        E: true,
        S: true,
        N: true,
        W: true
      }
    }
  }
  );
  window.root_fracpanel = root_fracpanel;
  const {
    first_frac
  } = root_fracpanel;
  root_fracpanel.clientArgs = clientArgs;

  if (typeof window !== 'undefined' && window !== null) {
    if ((window.global == null)) {
      window.global = {};
    }
  }

  const {
    enable_login
  } = clientArgs;
  const email_login = make_login_pane(root_fracpanel, enable_login);

  register_visualizations(FormurlaManager);
  window.noodb = create_local_noodb(socket, email_login);
  window.formurlaManager = new FormurlaManager(
    root_fracpanel, noodb, window, clientArgs);

  const default_formurla = clientArgs['default_formurla'] || 'print("no formurla found")';
  let formurla = formurlaManager.get_location_formurla();

  const showDefaultView = function() {
    // Run this if no initialLoggedInFormurla is found
    formurla = formurla || default_formurla;
    // alert("showDefaultView() #{formurla}")
    return formurlaManager.run_formurla(first_frac, formurla);
  };
  const showDefaultHandle = setTimeout(showDefaultView, 500);

  const useLoggedInFormurla = !formurla && email_login.logged_in;
  if (useLoggedInFormurla) {
    const replaceFormurlaWith = function(spogi) {
      clearTimeout(showDefaultHandle);
      const newFormurla = spogi.o.getValue();
      //msg = "useNew: #{useNew} nrn:initialLoggedInFormurla: «#{newFormurla}»"
      return formurlaManager.run_formurla(first_frac, newFormurla);
    };
    const qryTerms = [{p: 'nrn:initialLoggedInFormurla'}];
    //query = new Query(qryTerms, {which: "last"})
    noodb.on_do(qryTerms, replaceFormurlaWith);
  }

  if (clientArgs.close_login_panel) {
    return root_fracpanel.second_frac.close_button.click();
  }
};

