import {AbstractBlocklyController} from './abstractblocklyctrl.js';

export class MakeDiscriminator extends AbstractBlocklyController {
  static initClass() {
    this.func_name = 'makeDiscriminator';
    this.pretty_name = "Discriminator Panel";
    this.docs = "Creates a Discriminator which constrains data by provenance";
  }
  //add custom blocks (test)

  initBlocks() {
    Blockly.Blocks['discriminator'] = { init() {
          return this.jsonInit({
            type: "discriminator",
            message0: "Discriminator %1 %2",
            args0: [
              {
                "type": "input_dummy"
              },
              {
                type: "input_statement",
                name: "new_level",
                check: "aggregator_core"
              }
            ],
            tooltip: "",
            helpUrl: ""
            });
        }
  };

    Blockly.Blocks['aggregator_core'] = { init() {
          return this.jsonInit({
            type: "aggregator_core",
            message0: "Aggregator %1 %2 Who to Heed or Aggregator %3 %5 %4",
            args0: [
              {
                type: "field_dropdown",
                name: "agg_function",
                options: [
                  [
                    "Minimum",
                    "MIN"
                  ],
                  [
                    "Maximum",
                    "MAX"
                  ],
                  [
                    "Average",
                    "AVG"
                  ],
                  [
                    "Earliest",
                    "EARLIEST"
                  ],
                  [
                    "Latest",
                    "LATEST"
                  ]
                ]
              },
              {
                type: "input_dummy",
                class: "test-blockly"
              },
              {
                type: "input_statement",
                name: "new_level",
                check: [
                  "who_to_heed",
                  "aggregator"
                  ]
              },
              {
                type: "input_statement",
                name: "criteria",
                check: "criteria",
                align: "RIGHT"
              },
              {
                type: "field_label",
                text: "for Criteria",
                class: "block-criteria"
              }
            ],
            previousStatement: "aggregator_core",
            nextStatement: "aggregator_core",
            colour: 230,
            tooltip: "",
            helpUrl: ""
            });
        }
  };

    Blockly.Blocks['aggregators'] = { init() {
          return this.jsonInit({
            type: "aggregator",
            message0: "Aggregator %1 %2 %3",
            args0: [
              {
                type: "field_dropdown",
                name: "agg_function",
                options: [
                  [
                    "Minimum",
                    "MIN"
                  ],
                  [
                    "Maximum",
                    "MAX"
                  ],
                  [
                    "Average",
                    "AVG"
                  ],
                  [
                    "Earliest",
                    "EARLIEST"
                  ],
                  [
                    "Latest",
                    "LATEST"
                  ]
                ]
              },
              {
                type: "input_dummy"
              },
              {
                type: "input_statement",
                name: "new_level",
                check: [
                  "who_to_heed",
                  "aggregator"
                  ]
              }
            ],
            previousStatement: "aggregator",
            colour: 210,
            tooltip: "",
            helpUrl: ""
          });
        }
  };

    Blockly.Blocks['who_to_heed'] = { init() {
        return this.jsonInit({
          message0: "Who To Heed %1 Heed %2 NOT %3 OR %4",
          type: "who_to_heed",
          args0: [
            {
              type: "input_dummy"
            },
            {
              type: "input_statement",
              name: "include",
              check: "a_user",
              align: "RIGHT"
            },
            {
              type: "input_statement",
              name: "exclude",
              check: "a_user",
              align: "RIGHT"
            },
            {
              type: "input_statement",
              name: "otherwise",
              check: "who_to_heed",
              align: "RIGHT"
            }
          ],
          previousStatement: "who_to_heed",
          colour: 65,
          tooltip: "Returns filter function",
          helpUrl: "http://www.w3schools.com/jsref/jsref_length_string.asp"
          });
      }
  };

    Blockly.Blocks['who_to_heed_or'] = { init() {
        return this.jsonInit({
          message0: "OR Who To Heed %1 Heed %2 NOT %3 OR %4",
          type: "who_to_heed_or",
          args0: [
            {
              type: "input_dummy"
            },
            {
              type: "input_statement",
              name: "include",
              check: "a_user",
              align: "RIGHT"
            },
            {
              type: "input_statement",
              name: "exclude",
              check: "a_user",
              align: "RIGHT"
            },
            {
              type: "input_value",
              name: "otherwise",
              check: "who_to_heed_or",
              align: "RIGHT"
            }
          ],
          output: "who_to_heed_or",
          colour: 45,
          tooltip: "",
          helpUrl: ""
          });
      }
  };

    Blockly.Blocks['predicate_reducer'] = { init() {
        return this.jsonInit({
          type: "predicatereducer",
          message0: "%1 %2 %3 %4",
          args0: [
            {
              type: "field_input",
              name: "NAME",
              text: "usesReducer"
            },
            {
              type: "input_value",
              name: "dataReducer",
              check: "DataReducer",
              align: "RIGHT"
            },
            {
              type: "field_input",
              name: "forCriterion",
              text: "forCriterion"
            },
            {
              type: "input_value",
              name: "criteria",
              check: "Criterion",
              align: "RIGHT"
            }
          ],
          inputsInline: false,
          output: "PredicateReducer",
          colour: 315,
          tooltip: "",
          helpUrl: ""
          });
      }
  };

    Blockly.Blocks['a_user'] = { init() {
          return this.jsonInit({
            type: "a_user",
            message0: "@ %1",
            args0: [
              {
                type: "field_input",
                name: "userName",
                text: "default"
              }
            ],
            inputsInline: true,
            previousStatement: "a_user",
            nextStatement: "a_user",
            colour: 120,
            tooltip: "",
            helpUrl: ""
            });
        }
  };

    Blockly.Blocks['user_sel'] = { init() {
        return this.jsonInit({
          type: "user_sel",
          message0: "%1",
          args0: [
            {
              type: "field_dropdown",
              name: "userName",
              options: [
                [
                  "me",
                  "me"
                ],
                [
                  "others",
                  "others"
                ],
                [
                  "everyone",
                  "everyone"
                ]
              ]
            }
          ],
          inputsInline: true,
          previousStatement: "a_user",
          nextStatement: "a_user",
          colour: 120,
          tooltip: "",
          helpUrl: ""
          });
      }
  };

    Blockly.Blocks['predicate_reducer_2'] = { init() {
        return this.jsonInit({
          type: "predicatereducer",
          message0: "PredicateReducer %1 %2 %3 %4 %5",
          args0: [
              {
                type: "input_dummy"
              },
              {
                type: "field_input",
                name: "NAME",
                text: "usesDataReducer"
              },
              {
                type: "input_value",
                name: "dataReducer",
                check: "DataReducer",
                align: "RIGHT"
              },
              {
                type: "field_input",
                name: "forCriteria",
                text: "forCriteria"
              },
              {
                type: "input_statement",
                name: "NAME",
                check: "Criterion",
                align: "RIGHT"
              }
            ],
          inputsInline: false,
          output: "PredicateReducer",
          colour: 315,
          tooltip: "",
          helpUrl: ""
          });
      }
  };

    Blockly.Blocks['data_set'] = { init() {
          return this.jsonInit({
            message0: "Data Set: %1",
            args0: [
                {
                  type: "field_input",
                  name: "data_set",
                  text: "test"
                }
              ],
            output: "string",
            colour: 160,
            tooltip: "Returns number of letters in the provided text.",
            helpUrl: "http://www.w3schools.com/jsref/jsref_length_string.asp"
            });
        }
  };

    Blockly.Blocks['dscrm_picker'] = { init() {
          return this.jsonInit({
            message0: "%1",
            type: "dscrm_picker",
            args0: [
                {
                  type: "field_dropdown",
                  name: "dscrm_type",
                  options: [
                    [
                      "Minimum",
                      "MIN"
                    ],
                    [
                      "Maximum",
                      "MAX"
                    ],
                    [
                      "Average",
                      "AVG"
                    ],
                    [
                      "Earliest",
                      "EARLIEST"
                    ],
                    [
                      "Latest",
                      "LATEST"
                    ]
                  ]
                }
              ],
            output: "filter",
            colour: 280,
            tooltip: "Returns filter function",
            helpUrl: "http://www.w3schools.com/jsref/jsref_length_string.asp"
            });
        }
  };

    Blockly.Blocks['criteria'] = { init() {
          return this.jsonInit({
            type: "criteria",
            message0: "%1",
            args0: [
              {
                type: "field_input",
                name: "CriteriaName",
                text: "Criteria"
              }
            ],
            inputsInline: true,
            previousStatement: "criteria",
            nextStatement: "criteria",
            colour: 20,
            tooltip: "",
            helpUrl: ""
            });
        }
  };

    //Code generator for custom Blocks
    Blockly.JavaScript['discriminator'] = function(block) {
          let code;
          this.statements_new_level = Blockly.JavaScript.statementToCode(block, 'new_level', Blockly.JavaScript.ORDER_ADDITION);
          return code = this.statements_new_level;
        };

    Blockly.JavaScript['aggregator_core'] = function(block) {
          let code;
          this.value_agg_function = block.getFieldValue('agg_function');
          this.statements_new_level = Blockly.JavaScript.statementToCode(block, 'new_level', Blockly.JavaScript.ORDER_ADDITION) || '-';
          this.statements_criteria = Blockly.JavaScript.statementToCode(block, 'criteria', Blockly.JavaScript.ORDER_COMMA);
          this.add_criteria="";
          if(this.statements_criteria) {
            this.add_criteria=".re(" + this.statements_criteria.slice(0,-1) + ")";
          }
          return code = this.value_agg_function + "(" + this.statements_new_level + ")" + this.add_criteria + ";";
        };

    Blockly.JavaScript['aggregators'] = function(block) {
          let code;
          this.value_agg_function = block.getFieldValue('agg_function');
          this.statements_new_level = Blockly.JavaScript.statementToCode(block, 'new_level', Blockly.JavaScript.ORDER_ADDITION) || '-';
          return code = this.value_agg_function + "(" + this.statements_new_level + ")";
        };

    Blockly.JavaScript['who_to_heed'] = function(block) {
          let code;
          this.statements_include = Blockly.JavaScript.statementToCode(block, 'include', Blockly.JavaScript.ORDER_COMMA) || '-';
          this.statements_exclude = Blockly.JavaScript.statementToCode(block, 'exclude', Blockly.JavaScript.ORDER_ADDITION);
          this.value_otherwise = Blockly.JavaScript.statementToCode(block, 'otherwise', Blockly.JavaScript.ORDER_ATOMIC);
          this.incl = "";
          this.excl = "";
          if(this.statements_exclude) {
            this.incl = "~" + this.statements_exclude.slice(0,-1);
          }
          if(this.value_otherwise) {
            this.excl = "|" + this.value_otherwise;
          }
          return code = this.statements_include.slice(0,-1) + this.incl + this.excl;
        };

    Blockly.JavaScript['who_to_heed_or'] = function(block) {
          let code;
          this.statements_include = Blockly.JavaScript.statementToCode(block, 'include', Blockly.JavaScript.ORDER_ADDITION) || '-';
          this.statements_exclude = Blockly.JavaScript.statementToCode(block, 'exclude', Blockly.JavaScript.ORDER_ADDITION);
          this.value_otherwise = Blockly.JavaScript.statementToCode(block, 'otherwise', Blockly.JavaScript.ORDER_ATOMIC);
          this.incl = "";
          this.excl = "";
          if(this.statements_exclude) {
            this.incl = "~" + this.statements_exclude.slice(0,-1);
          }
          if(this.value_otherwise) {
            this.excl = "|" + this.value_otherwise;
          }
          return code = this.statements_include.slice(0,-1) + this.incl + this.excl;
        };

    Blockly.JavaScript['a_user'] = function(block) {
          let code;
          this.text_username = block.getFieldValue('userName');
          return code = "@" + this.text_username + ",";
        };

    Blockly.JavaScript['user_sel'] = function(block) {
          let code;
          this.dropdown_user_name = block.getFieldValue('userName');
          return code = this.dropdown_user_name + ",";
        };

    Blockly.JavaScript['filter_picker'] = function(block) {
          let code;
          this.dropdown_filter_type = block.getFieldValue('filter_type');
          this.value_value_filter_type = Blockly.JavaScript.valueToCode(block, 'value_filter_type', Blockly.JavaScript.ORDER_ATOMIC);
          return code = this.dropdown_filter_type + '(' + this.value_value_filter_type + ')';
        };

    Blockly.JavaScript['dscrm_picker'] = function(block) {
          let code;
          this.dropdown_dscrm_type = block.getFieldValue('dscrm_type');
          return code = this.dropdown_dscrm_type;
        };

    Blockly.JavaScript['data_set'] = function(block) {
          let code;
          this.text_data_set = block.getFieldValue('data_set');
          return code = "g=nrn:" + this.text_data_set;
        };

    return Blockly.JavaScript['criteria'] = function(block) {
          let code;
          this.text_criteria = block.getFieldValue('CriteriaName');
          return code = this.text_criteria + ",";
        };
  }

  toolbox() {
    this.toolbox = '<xml>';
    this.toolbox += '  <block type="aggregator_core"></block>';
    this.toolbox += '  <block type="aggregators"></block>';
    this.toolbox += '  <block type="who_to_heed"></block>';
    this.toolbox += '  <block type="a_user"></block>';
    this.toolbox += '  <block type="user_sel"></block>';
    this.toolbox += '  <block type="criteria"></block>';
    return this.toolbox += '</xml>';
  }

  initializeWorkspace() {
    this.initXML = '<xml>';
    this.initXML += '<block type="discriminator" deletable="false" movable="false" x="40" y="40"></block>';
    return this.initXML += '</xml>';
  }

  initializeDiscriminator() {
    this.jsonDiscrm = {
      prog: [
        {
          type: "call",
          method: {
            type: "method",
            name: "re",
            args: [
              "Criteria1",
              "Criteria2"
            ]
          },
          func: {
            type: "var",
            value: "AVG"
          },
          args: [{
            type: "call",
            func: {
              type: "var",
              value: "MAX"
            },
            args: [
              {
                type: "atuser",
                value: "Clinton"
              },
              {
                type: "atuser",
                value: "Obama"
              },
              {
                type: "punc",
                value: "~"
              },
              {
                type: "genuser",
                value: "everyone"
              },
              {
                type: "punc",
                value: "|"
              },
              {
                type: "atuser",
                value: "ReadySet"
              },
              {
                type: "punc",
                value: "|"
              },
              {
                type: "atuser",
                value: "SpecialPerson"
              }
            ]
          }
          ]
        },
        {
          type: "call",
          method: {
            type: "method",
            name: "re",
            args: [
              "myCriterion"
            ]
          },
          func: {
            type: "var",
            value: "LATEST"
          },
          args: [
            {
              type: "atuser",
              value: "Me"
            },
            {
              type: "punc",
              value: "~"
            },
            {
              type: "atuser",
              value: "God"
            }
          ]
        },
        {
          type: "call",
          func: {
            type: "var",
            value: "MAX"
          },
          args: [
            {
              type: "call",
              func: {
                type: "var",
                value: "AVG"
              },
              args: [
                {
                  type: "call",
                  func: {
                    type: "var",
                    value: "EARLIEST"
                  },
                  args: [
                      {
                        type: "genuser",
                        value: "everyone"
                      }
                //      {
                //        type: "punc"
                //        value: "|"
                //      }
                //      {
                //        type: "atuser"
                //        value: "craxy"
                //      }
                  ]
                }
              ]
            }
          ]
        }
      ]
    };

    // Go through each Aggregator in the prog list
    return (() => {
      const result = [];
      for (let i = 0; i < this.jsonDiscrm.prog.length; i++) {
      // check args if there func then look to see if there are more args
        var filterList;
        const aggCntrl = this.jsonDiscrm.prog[i];
        const baseAgg = this.create_aggregator_core(aggCntrl.func.value); //create base Agg
        if (aggCntrl.args[0].args) {
          var newAgg;
          if (aggCntrl.args[0].args[0].args) {
            newAgg = this.create_aggregator(aggCntrl.args[0].func.value, baseAgg);
            newAgg = this.create_aggregator(aggCntrl.args[0].args[0].func.value, newAgg);
            //now work on the WhoToHeed Block Set
            filterList = aggCntrl.args[0].args[0].args;
            this.create_whoToHeed_set(filterList,newAgg);

          } else {
            newAgg = this.create_aggregator(aggCntrl.args[0].func.value, baseAgg);
            // WhoToHeed Block Set
            filterList = aggCntrl.args[0].args;
            this.create_whoToHeed_set(filterList,newAgg);
          }
        } else {
          console.log("one level only");
          const parent = baseAgg;
          // WhoToHeed Block Set
          filterList = aggCntrl.args;
          this.create_whoToHeed_set(filterList,baseAgg);
        }

        // check for criteria filters (methods)
        if (aggCntrl.method) {
          const criteriaArray = aggCntrl.method.args;
          result.push(this.create_criteria_set(criteriaArray, baseAgg));
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }

  create_aggregator_core(param) {
    let block;
    const discriminatorBlock = this.workspace.getTopBlocks()[0];
    const blockAttributes = {
      type: "aggregator_core",
      parent: discriminatorBlock,
      input: "new_level",
      value: {
        field: "agg_function",
        value: param
      }
    };
    return block = this.create_block(blockAttributes);
  }

  create_aggregator(param, parent) {
    let block;
    const blockAttributes = {
      type: "aggregators",
      parent,
      input: "new_level",
      value: {
        field: "agg_function",
        value: param
      }
    };
    return block = this.create_block(blockAttributes);
  }

  create_criteria_set(criteria, parent) {
    //console.log(block)
    return (() => {
      const result = [];
      for (let i = 0; i < criteria.length; i++) {
      //console.log(cntrl)
        const cntrl = criteria[i];
        const blockAttributes = {
          type: "criteria",
          parent,
          input: "criteria",
          value: {
            field: "CriteriaName",
            value: cntrl
          }
        };
        result.push(this.create_block(blockAttributes));
      }
      return result;
    })();
  }

  create_whoToHeed_set(params, parent) {
    //console.log(blockAtt)
    //blockAtt =
    //  args: []
    let blkInput, j, user;
    let blockAttributes = {
      type: "who_to_heed",
      parent,
      input: "new_level"
    };
    parent = this.create_block(blockAttributes);
    //console.log(parent)
    let usrGrpSet = [];
    let includeVals = true;
    for (let i = 0; i < params.length; i++) {
      //console.log(crtr)
      const crtr = params[i];
      if ((crtr.type === 'punc') && (crtr.value === '|')) {
        //console.log('need to create next block of who to heed')
        for (j = 0; j < usrGrpSet.length; j++) {
          user = usrGrpSet[j];
          if (includeVals) { blkInput = "include"; } else { blkInput = "exclude"; }
          this.create_userFilter_block(user, blkInput, parent);
        }
        blockAttributes = {
          type: "who_to_heed",
          parent,
          input: "otherwise"
        };
        parent = this.create_block(blockAttributes);
        usrGrpSet = [];
        includeVals = true;
      } else if ((crtr.type === 'punc') && (crtr.value === '~')) {
        //console.log('what follows should be user blocks in NOT on current block')
        //@create_userFilter_block(usrGrpSet, parent)
        //console.log(@usrGrpSet)
        includeVals = false;
        blkInput = "include";
        for (j = 0; j < usrGrpSet.length; j++) {
          user = usrGrpSet[j];
          this.create_userFilter_block(user, blkInput, parent);
        }
        usrGrpSet = [];
      } else if ((crtr.type === 'genuser') || (crtr.type === 'atuser')) {
        //console.log('Create genuser block for currentCreate atuser block for current ')
        usrGrpSet.push(crtr);
      } else {
        console.log('error here');
      }
    }
    if (usrGrpSet) {
      return (() => {
        const result = [];
        for (j = 0; j < usrGrpSet.length; j++) {
          user = usrGrpSet[j];
          if (includeVals) { blkInput = "include"; } else { blkInput = "exclude"; }
          result.push(this.create_userFilter_block(user, blkInput, parent));
        }
        return result;
      })();
    }
  }

  create_userFilter_block(param, input, parent) {
    let type;
    console.log(input);
    if (param.type ==='genuser') {
      type = "user_sel";
    } else {
      type = "a_user";
    }

    //type = param.type=='genuser' then "user_sel" else "a_user"
    const blockAttributes = {
      type,
      parent,
      input,
      value: {
        field: "userName",
        value: param.value
      }
    };
    return this.create_block(blockAttributes);
  }

  create_api_workspace() {
    let inputValue;
    this.initializeDiscriminator();

    const discriminatorBlock = this.workspace.getTopBlocks()[0];

    //new block created to fit into the Discrimnator
    //block = workspace.newBlock('aggregator_core')
    //block.setParent(descriminatorBlock)
    //block.initSvg()
    //block.render()

    // Aggregator base
    let blockAttributes = {
      type: "aggregator_core",
      parent: discriminatorBlock,
      input: "new_level",
      value: {
        field: "agg_function",
        value: "MAX"
      }
    };
    let block = this.create_block(blockAttributes);

    blockAttributes = {
      type: "criteria",
      parent: block,
      input: "criteria",
      value: {
        field: "CriteriaName",
        value: "Criteria 1"
      }
    };
    let block2 = this.create_block(blockAttributes);

    blockAttributes = {
      type: "criteria",
      parent: block,
      input: "criteria",
      value: {
        field: "CriteriaName",
        value: "Criteria 2"
      }
    };
    block2 = this.create_block(blockAttributes);

    blockAttributes = {
      type: "who_to_heed",
      parent: block,
      input: "new_level"
    };
    block = this.create_block(blockAttributes);

    //console.log(block)

    blockAttributes = {
      type: "user_sel",
      parent: block,
      input: "include",
      value: {
        field: "userName",
        value: "everyone"
      }
    };
    block = this.create_block(blockAttributes);

    //parentConnection = descriminatorBlock.getInput(blockAttributes.input).connection;
    //childConnection = block.previousConnection;
    //parentConnection.connect(childConnection);

    return inputValue = blockAttributes.input;
  }
    //console.log(blockAttributes)

    //parentConnection = descriminatorBlock.getInput(blockAttributes.input).connection
    //childConnection = block.previousConnection
    //parentConnection.connect(childConnection)

  create_block(blockAttributes) {
    const block = this.workspace.newBlock(blockAttributes.type);
    block.setParent(blockAttributes.parent);
    if (blockAttributes.value) {
      block.setFieldValue(blockAttributes.value.value, blockAttributes.value.field);
    }
    block.initSvg();
    block.render();

    const parentBlock = blockAttributes.parent;
    //console.log(parentBlock)
    const parentConnection = parentBlock.getInput(blockAttributes.input).connection;
    const childConnection = block.previousConnection;
    parentConnection.connect(childConnection);

    return block;
  }


  create_xml_workspace_DEPRECIATED(jsonDiscrm) {}
    // Create a discrinator block to ititialize workspace
    //@xml_workspace = '<xml><block type="discriminator" deleteable="false" movable="false" x="20" y="20">'
    //@xml_workspace += '</block></xml>'


    // Create a aggregator_core block (ALWAYS NEED AT LEAST ONE)
    //@xml_workspace += '<block type="aggregator_core">'
    //@xml_workspace += @create_agg_block(jsonDiscrm.prog[0].func.value)

      // if there is another aggrecator then add basic aggregator level (OPTIONAL)
        // <block type="aggregators">
        // '<field name="agg_function">' + json_descrim_code.filter  + '</field>'
        // '<statement name="new_level">'
          //
        // '</statement></block>'

      // Else just add Who to Heed
        // whoToHeed() ->
        //   <statement name="include">
          // user() -> build
        //   if for NOT then add <statement name="exclude">
          // user() -> build
        //   <statement name="otherwise">
          // @whoToHeed() loop inside]
    //@xml_workspace += '<statement name="new_level">' + @create_whoToHeed_block(jsonDiscrm.prog[0].args.args[0].value) + '</statement>'
    //@xml_workspace += '<statement name="new_level">' + @create_whoToHeed_block(jsonDiscrm.prog[0].args[0].args[0].value) + '</statement>'
    //@xml_workspace += '<statement name="criteria">' + @create_criteria_block(jsonDiscrm.prog[0].method.args) + '</statement>'
    //console.log @xml_workspace
    //@xml_workspace += '</block>'
    //@xml_workspace += '</statement></block></xml>'

  create_criteria_block_DEPRECIATED(criteria) {
    return (() => {
      const result = [];
      for (let i = 0; i < criteria.length; i++) {
        const crtr = criteria[i];
        if (i === 0) {
          result.push(this.crit_block = '<block type="criteria">' + this.create_criteria_field(crtr) + '</block>');
        } else {
          this.newEntry = '<block type="criteria">' + this.create_criteria_field(crtr) + '<next>' + this.crit_block + '</next></block>';
          result.push(this.crit_block += this.newEntry);
        }
      }
      return result;
    })();
  }

  create_criteria_field_DEPRECIATED(value) {
    return this.crit_field_block = '<field name="CriteriaName">' + value + '</field>';
  }

  create_agg_block_DEPRECIATED(agg) {
    return this.agg_block = '<field name="agg_function">' + agg + '</field>';
  }

  create_whoToHeed_block_DEPRECIATED(heed) {
    //console.log(heed.include)
    this.heed_block = '<block type="who_to_heed">';

    this.heed_block += '<statement name="include"><block type="a_user">';
    this.heed_block += '<field name="UserName">' + heed + '</field>';
    //  for user, i in heed.include
    //    if i == 0
    //      @heed_block += @create_user_block(user)
    //      console.log("yes: " + i + ": " + user)
    //    else
    //      @heed_block += '<next><block type="a_user">' + @create_user_block(user) + '</block></next>'
    //      console.log("no: " + i + ": " + user)
    this.heed_block += '</block></statement>';

    //@heed_block += '<statement name="exclude"><block type="a_user">' + @create_user_block(heed.exclude) + '</block></statement>'
    return this.heed_block += '</block>';
  }
    //console.log(@heed_block)

  create_user_block_DEPRECIATED(users) {
    return (() => {
      const result = [];
      for (let i = 0; i < users.length; i++) {
        const crtr = users[i];
        if (i === 0) {
          result.push(this.user_block = '<block type="a_user">' + this.create_user_field(crtr) + '</block>');
        } else {
          this.newEntry = '<block type="a_user">' + this.create_user_field(crtr) + '<next>' + this.crit_block + '</next></block>';
          result.push(this.user_block += this.newEntry);
        }
      }
      return result;
    })();
  }

  create_user_field_DEPRECIATED(user) {
    //console.log("just user: " + user)
    // if this is a single user (i.e. by name) then add this kind of block
    return this.user_block = '<field name="UserName">' + user + '</field>';
  }
}
MakeDiscriminator.initClass();
    // if this 'me' or a group (e.g. 'other' or workgroup) then add this kind of block
    //@user_block = '<block type="user_sel"><field name="UserName">' + user + '</field></block>'

