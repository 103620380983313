/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

const BASE52 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const BASE57 = "23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
// Our base57 digit set is A-Za-z0-9 less the five ambiguous ones:
//   zero, one, capital I, capital O and lower case ell: 01OIl
// so 52+10-5 = 57

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const BASE10 = "0123456789";
const BASE2 = "01";
const BASE8 = "01234567";
const BASE16 = "0123456789abcdef";
const BASE75 = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_.,!=-*(){}[]";

//
//       convert.js
//       http://rot47.net
//       Dr Zhihua Lai
//
const convert = function(src, srctable, desttable) {
  const srclen = srctable.length;
  const destlen = desttable.length;
  // first convert to base 10
  let val = 0;
  const numlen = src.length;
  let i = 0;

  while (i < numlen) {
    val = (val * srclen) + srctable.indexOf(src.charAt(i));
    i++;
  }
  if (val < 0) { return 0; }

  // then covert to any base
  let r = val % destlen;
  let res = desttable.charAt(r);
  let q = Math.floor(val / destlen);
  while (q) {
    r = q % destlen;
    q = Math.floor(q / destlen);
    res = desttable.charAt(r) + res;
  }
  return res;
};

var DEFAULT_BASE = BASE57;
export const base_to_int = base => parseInt(convert(base, DEFAULT_BASE, BASE10));
//EXPORTER.base_to_int = base_to_int;

const int_to_base = intgr => convert(""+intgr, BASE10, DEFAULT_BASE);

const modulo_char_at = (base, byte) => base[byte % base.length];

const symbol_from_bytes = function(bytes, base) {
  // Purpose
  //   Return a symbol (ie does not start with a digit) from an array
  // Accept
  //   bytes: an array of bytes
  //   base: an array of characters used as a base (optional or DEFAULT_BASE used)
  let nondig, DEFAULT_BASE_nondig;
  if ((base == null)) {
    base = DEFAULT_BASE;
    if (typeof(DEFAULT_BASE_nondig) == 'undefined') {
      // cache nondig for reuse
      DEFAULT_BASE_nondig = base.replace(/\d/g,'');
    }
    nondig = DEFAULT_BASE_nondig;
  } else {
    nondig = base.replace(/\d/g,'');
  }
  const out = [modulo_char_at(nondig,bytes[0])];
  const iterable = bytes.slice(1, bytes.length);
  for (let i = 0; i < iterable.length; i++) {
    const b = iterable[i];
    out.push(modulo_char_at(base, b));
  }
  return out.join('');
};

const logBASE = Math.log(DEFAULT_BASE.length);
// We count the number of digits in the base.
// WARNING this assumes that the digits are all at the beginning of the BASE
const numDigitsBASE = DEFAULT_BASE.match(/^\d*/g)[0].length;

const int_to_base_symbol = function(intgr) {
  // FIXME(smurp) bugged!!!! because several inputs will produce the same output
  // Return a value which does not start with a digit
  //
  // If the intgr is going to cause an response which starts with
  // a digit \d then increase intgr to the first value which will
  // not do so.
  //
  // This line is a presumably slow workaround
  //   if (""+(Math.log(intgr)/logBASE)).indexOf(".") is -1
  // for this line, which is legal only in more recent coffeescript
  //   if ((Math.log(intgr)/logBASE) %% 1) is 0
  // so we will gear down to javascript native code
  if (((Math.log(intgr)/logBASE) % 1) === 0) {
    intgr = intgr * numDigitsBASE;
   }
  return [int_to_base(intgr), intgr];
};

export {int_to_base_symbol as make_symbol, int_to_base, symbol_from_bytes, convert as rebase};
