/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import {WhoWhen} from './whowhen.js';

export class Spogi {
  constructor(s, p, o, g, i) {
    this.s = s;
    this.p = p;
    this.o = o;
    this.g = g;
    this.i = i;
  }
  ww() {
    if ((this._ww == null)) {
      this._ww = new WhoWhen().parse(this.i.key());
    }
    return this._ww;
  }
  whn() {
    return this.ww().whn();
  }
  sess() {
    return this.ww().sess();
  }
  toString() {
    return [this.s.repr(),this.p.repr(),this.o.repr(),this.g.repr(),". #",this.i.key()].join(" ");
  }
  asLine() {
    return this.toString() + "\n";
  }
  asLineWithIdAsContext() {
    return [this.s.repr(), this.p.repr(), this.o.repr(), `<${this.i.key()}>`].join(" ") + " .\n";
  }
  asPenta() {
    return [this.s.repr(), this.p.repr(), this.o.repr(), this.g.repr(), this.i.repr()];
  }
  asTTL() {
    return [this.s.asTTL(), this.p.asTTL(), this.o.asTTL(), this.g.asTTL(), this.i.asTTL()];
  }
  asRaw() {
    // Return the terms as an array of raw values
    return [this.s.raw(), this.p.raw(), this.o.raw(), this.g.raw(), this.i.raw()];
  }
  forWire() {
    return [this.s.asTTL(), this.p.asTTL(), this.o.key(), this.g.asTTL(), this.i.asTTL()];
  }
  eql(it) {
    return this.i.eql(it.i) && this.s.eql(it.s) && this.p.eql(it.p) && this.o.eql(it.o) && this.g.eql(it.g);
  }
  eqlQuint(q) {
    return this.s.eql(q[0]) && this.p.eql(q[1]) && this.o.eql(q[2]) && this.g.eql(q[3]) && this.i.eql(q[4]);
  }
  asN5Line() {
    return [this.s.asTTL(), this.p.asTTL(), this.o.key(), this.g.asTTL()].join(' ') + " . # " +this.i.local_part();
  }
}

