
import {AnnotationSemClass} from '../../annotationsem.js';
import {Annotation} from '../../annotation.js';
import {VideoSemClass} from './videosem.js';
import {AbstractVideoProvider} from './abstractvideoprovider.js';

export class AbstractAnnotationRecorder extends AbstractVideoProvider {
  static initClass() {
  
    this.prototype.dbStore =
      {_uses: {}};
  }
  //  should provide triples for
  //   motivatedBy, hasBody, startBody, stopBody, hasTarget, stopTarget, startTarget, MORE????

  // Wonderfully simple demo of video recording and form upload
  //   https://gist.github.com/rissem/d51b1997457a7f6dc4cf05064f5fe984
  //
  // Multimedia Ontologies for Video Semantics
  // https://pdfs.semanticscholar.org/presentation/d34a/12c811124c347811ade26a4a1f1630101b5b.pdf
  //
  // Video Ontology (VidOnt)
  //   http://videoontology.org/video.ttl
  //
  // wget https://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_5mb.mp4
  // /__/playmedia(big_buck_bunny_720p_5mb)
  //
  constructor() {
    super(...arguments);
    this.annotations = {};
  }

  receive(spogi) {
    super.receive(...arguments);
    // TODO: @replace get_motivation_entries with @accumulateLookups(spogi)
    return this.accumulate(spogi);
  }
  accumulate(spogi) {
    let anObj = null;
    const {
      p
    } = spogi;
    const p_curie = p.key();
    const s_curie = spogi.s.key();
    const o_key = spogi.o.key();
    if (p_curie === 'rdf:type') {
      if (o_key === 'oa:Annotation') {
        anObj = Annotation._getOrCreateResource(s_curie, this.dbStore, Annotation);
      } else {
        anObj = AnnotationSemClass._getOrCreateResource(s_curie, this.dbStore, AnnotationSemClass);
      }
    }
        //anObj._setCurieKV(p_curie, o_key, @dbStore)
    if (p_curie.startsWith('oa:')) { // this is going to be interesting!
      const annot_pred = Annotation.prototype._getProp(p_curie);
      if (annot_pred) {
        // ie the subject is an Annotation
        anObj = AnnotationSemClass._getOrCreateResource(s_curie, this.dbStore, AnnotationSemClass);
      }
    }
        //anObj._setCurieKV(p_curie, o_key, @dbStore)
    if (!anObj) {
      // the subject is NOT an Annotation
      anObj = AnnotationSemClass._getOrCreateResource(s_curie, this.dbStore, AnnotationSemClass);
    }
    if (anObj) {
      //if p_curie isnt 'rdf:type'
      anObj._setCurieKV(p_curie, o_key, this.dbStore);
      this.revisitParents(anObj);
    }
    return anObj;
  }

  revisitParents(anObj, onlyReceiveOnce) {
    if (onlyReceiveOnce == null) { onlyReceiveOnce = {}; } // set it at beginning of recursion
    if (onlyReceiveOnce[anObj.id]) {
      return; // bail at this point if we have already run revisitParents() on anObj with id
    }
    if (this.kwargs.dump) {
      $(`#${this.content_id}`).append(`<li><code style='color:red'>${anObj._dump()}</code></li>`);
      window.scrollTo(0,document.body.scrollHeight);
    }
    onlyReceiveOnce[anObj.id] = true;
    this.receiveAnnotation(anObj);
    for (let [parentObjId, keyOnParent] of Array.from((this.dbStore._uses[anObj.id] || []))) {
      this.revisitParents(this.dbStore[parentObjId], onlyReceiveOnce);
    }
  }

  get_motivation_entries() {
    const terms = [{p: 'rdf:type'},{o: 'oa:Motivation'}]; // {g: 'nrn:oa'} or nrn:dvrsont
    const entries = [];
    const resultSet = this.noodb.query(terms);
    for (let quad of Array.from(resultSet)) {
      const val = quad.s.key();
      const label = val.replace(/^.*\:/,'');
      entries.push([val,label]);
    }
    return entries;
  }

  create_triples_for_transaction(fullUri, triples) {
    let select, val;
    super.create_triples_for_transaction(...arguments);
    // This is where triples are created which are only needed in the event that this is a
    // response which is being created.
    const targetVidCurie = this.video_curie;
    const bodyVidCurie = this.make_vid_curie_from_fullUri(fullUri);

    // Triples about the Annotation and hence... Only needed for a response
    const annotPlcHldr = this.makePlaceholder('dvrsdata:annot');
    triples.push([annotPlcHldr, 'rdf:type', 'oa:Annotation']);
    const elem = this.videoProvider.querySelector("[name='rdfs:label']");
    if (elem && (elem.value !== undefined)) {
      triples.push([annotPlcHldr, 'rdfs:label', this.n3Quote(elem.value)]);
    }
    if (select = this.videoProvider.querySelector("[name='oa:motivatedBy']")) {
        if (val = select.options[select.selectedIndex].value) {
          triples.push([annotPlcHldr, 'oa:motivatedBy',  val]);
        }
      }
    triples.push([annotPlcHldr, 'oa:hasBody', bodyVidCurie]);

    // Triples which connect the annotation to the response and main videos. Only needed for a response
    //targetBNodePlcHldr = @makePlaceholder('dvrsdata_:targetBNode') # note _: makes it a BNode
    const targetBNodePlcHldr = this.makePlaceholder('_:targetBNode'); // note _: makes it a BNode
    triples.push([annotPlcHldr, 'oa:hasTarget', targetBNodePlcHldr]);
    triples.push([targetBNodePlcHldr, 'oa:hasSource', targetVidCurie]);

    // Triples capturing the fragment selector (ie the region of the target video).  Only needed for a response
    //targetSelectorBNodePlcHldr = @makePlaceholder('dvrsvid_:targetSelector') # note _: makes it a BNode
    const targetSelectorBNodePlcHldr = this.makePlaceholder('_:targetSelector'); // note _: makes it a BNode
    triples.push([targetBNodePlcHldr, 'oa:hasSelector', targetSelectorBNodePlcHldr]);
    triples.push([targetSelectorBNodePlcHldr, 'rdf:type', 'oa:FragmentSelector']);
    triples.push([targetSelectorBNodePlcHldr, 'dcterms:conformsTo', 'medifrag:']);
    const timeRange = `t=${this.rangeBegin_input.val()},${this.rangeEnd_input.val()}`;
    return triples.push([targetSelectorBNodePlcHldr, 'rdf:value', this.n3Quote(timeRange)]);
  }

  show_progress_info(msg, fade) {
    $(".progress_info").html(msg);
    if (fade) {
      $("div.submit_progress").delay( 1000 ).fadeOut( 1400 );//.hide()
      return $("div.progress_info").delay( 1000 ).fadeOut( 1400 );//.hide()
    }
  }

  hide_provide_widget() {
    $(".video_provider.playmedia").hide();
    $("div.submit_progress").delay( 1000 ).fadeOut( 1400 );//.hide()
    $("div.progress_info").delay( 1000 ).fadeOut( 1400 );//.hide()
  }
}
AbstractAnnotationRecorder.initClass();
