import {BrowserLog as Log} from './browserlog.js';
import {Clock} from './nanoclock.js';
import {InsecureListener, NooDBAbstract} from './noodbabstract.js';
import {Spogi} from './spogi.js';

export class NooDBBrowser extends NooDBAbstract {
  constructor(socket, server_uri, args, read_callback) {
    super();
    this.receive_transaction_reponse = this.receive_transaction_reponse.bind(this);
    this.set_server_session = this.set_server_session.bind(this);
    this.alert_user = this.alert_user.bind(this);
    this.from_upstream = this.from_upstream.bind(this);
    this.set_prefix = this.set_prefix.bind(this);
    this.socket = socket;
    this.server_uri = server_uri;
    this.queries = [];
    args = args || {};
    const defaults = {
      verbose: false,
      warn_time_threshold: 5000,
      verbosity: 3,
      clock: new Clock(),
      instrumented: false, // instrumented = true turns on demonstrative code
      //log: new Log('warning') # https://github.com/tj/log.js
      log_level: 'ERROR',
      // What the default_graph should be is a puzzle, if it's even needed.
      // Should it be @server_uri?
      // Or @server_uri + "/somepath/"?
      default_graph: `${this.server_uri}/erewhon/`,
      last_user_no_int: (57 * 57) - 1 //ie zz when base57
    };
    this.resolve_defaults(defaults, args, Log);
    this.build_indices();
    //console.warn "DO THE EQUIVALENT OF @init_db()"
    this.init_db(read_callback);
    this.register_socket();
  }

  read_db(fname, read_more, fname_queue, callback) {
    return super.read_db();
  }

  get_server_session() {
    // The serverSession is the one which embodies the current execution
    // of the server.  Ideally it will have the session start time and
    // the git code version associated with it.
    if ((this.session_no == null)) {
      const all = this.by_pred.getAll("nrn:serverStartedAt");
      this.session_no = all.length + 1;
    }
    // user_no this should be an identifier for the server
    if ((this.session_no == null)) {
      this.log.alert("@session_no is blank");
    }
    return {user_symbol: this.user_symbol, session_no: this.session_no};
  }

  allege(s, p, o, g, sess, date) {
    // sess and date are up to the server-side
    if ([s, p, o].includes(undefined)) {
      throw new Error(`everything must be defined of <s:${s}> <p:${p}> <o:${o}>`);
    }
    this.socket.emit('allege_upstream', [s, p, o, g]);
  }

  allege_local(s, p, o, g, sess, date) {
    return this.__proto__.__proto__.allege.call(this, s, p, o, g, sess, date);
  }

  allege_transaction(quads) {
    this.socket.emit('allege_transaction', quads);
  }

  receive_transaction_reponse(data) {
    alert(JSON.stringify(data,null,4));
  }

  register_socket() {
    this.log.info("register_socket() socket:",this.socket);
    this.socket.on('from_upstream', this.from_upstream);
    this.socket.on('transaction_response', this.receive_transaction_response);
    this.socket.on('alert_user', this.alert_user);
    this.socket.on('set_server_session', this.set_server_session);
    this.socket.on('set_prefix', this.set_prefix);
    this.register_socket_session();
  }

  set_server_session(session_id) {
    // the session_id looks like "<user_symbol>_<session_no>" eg s8uY7_489
    const part = session_id.split('_');
    if ((part.length !== 2) || (part[0].length < 1) || (part[1].length < 1)) {
      throw new Error(
        `set_server_session() expects session_id like 'usym_int' not '${session_id}'`);
    }
    this.user_symbol = part[0];
    this.session_no = Number.parseInt(part[1]);
    //console.log "set_server_session() ==>",@get_server_session()
  }

  subscribe(qry) {
    this.log.info("subscribe()", qry);
    this.queries.push(qry);
    this.socket.emit('error', "ABOUT TO EMIT subscribe");
    return this.socket.emit('subscribe', qry);
  }

  alert_user(data) {
    const msg = data.msg || data;
    alert(msg);
  }

  register_socket_session() {
    if (this.auth_cookie) {
      this.socket.emit('register_session', this.auth_cookie);
    }
  }

  from_upstream(quint) { // quint is a 5-tuple for brevity
    this.log.debug("from_upstream()", quint);
    const spogi = this.index(quint);
    if (spogi) {
      this.ensure_prefixes(spogi);
      this.log.debug("  ", spogi.asTTL());
    }
  }

  ensure_prefixes(spogi) {
    const sought = {};
    for (let t of ['s','p','o','g','i']) {
      if (!spogi[t].isUri) { // spogi.o might not be an URI
        continue;
      }
      const prfx = spogi[t].prefix_part();
      if (!(sought[prfx] || this.prefixdb.prefixes.has_k(prfx))) {
        sought[prfx] = true;
        this.request_prefix_from_upstream(prfx);
      }
    }
  }

  request_prefix_from_upstream(prfx) {
    if (!this.prefixdb.prefixes.has_k(prfx)) {
      this.socket.emit('get_prefix', prfx);
    }
  }

  set_prefix(pair) {
    this.prefixdb.add_prefix(pair[0], pair[1]);
  }

  persist(spogi) {
    this.socket.emit('allege', spogi.asLine());
    this.log.warning("NooDBBrowser.persist is a NOOP");
  }

  query_upstream(q) {
    this.queries.push(q);
    this.socket.emit('subscribe', q);
  }

  subscribe_visualization(viz, qry) {
    //@subscribe(qry) # TODO why isn't this required?
    this.log.debug("subscribe_visualization() qry:", qry);
    this.query_upstream(qry);
    return this.subscribe_listener(new VisualizationListener(viz), qry);
  }
}

export class VisualizationListener extends InsecureListener {
  constructor(viz) {
    super();
    this.send = this.send.bind(this);
    this.viz = viz;
  }
  get_id() {
    return this.viz.id;
  }
  send(spogi) {
    const onward = this.viz.discriminate(spogi);
    if (onward != null) {
      this.viz.receive(onward);
    }
    return null;
  }
}

